import React from 'react';
import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import { Logo } from '../../components';

function Newsletter() {
  return (
    <Container className="content">
      <Row>
        <Col xs="12" className="mb-3 mb-lg-5">
          <div className="position-relative w-100 min-vh-100 rounded-4 overflow-hidden">
            <Image
              src="https://source.unsplash.com/random/?interiordesign&1"
              className="position-absolute w-100 h-100 zi-1"
              style={{ objectFit: 'cover' }}
            />
            <div
              className="position-absolute w-100 h-100 zi-2"
              style={{
                background: 'rgba(0,0,0,0.5)',
              }}
            />
            <div
              className="position-absolute w-100 h-50 zi-3"
              style={{
                background:
                  'linear-gradient(to bottom, #000, rgba(0,0,0,0.8) 50%, rgba(0,0,0,0) 100%)',
              }}
            />
            <div className="position-relative d-flex flex-column flex-grow-1 w-100 h-100 zi-3">
              <div className="w-100 display-1 text-center my-5">
                <Logo
                  variant="light"
                  className="w-100 my-5"
                  style={{ minWidth: '50rem', maxWidth: '50rem' }}
                />
              </div>
              <div
                className="d-flex flex-column align-items-end mt-5 me-5 align-self-end text-end"
                style={{ width: '40%' }}
              >
                <Card
                  bg="transparent"
                  className="w-100 border-0 card-ghost mb-3 mb-lg-5"
                >
                  <Card.Body>
                    <Card.Title
                      as="h3"
                      className="text-white display-3 text-uppercase"
                    >
                      Red flags to watch out for
                    </Card.Title>
                    <Card.Text className="text-white display-5 text-uppercase">
                      Potential red flags that can turn your dream home into a
                      nightmare
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card
                  bg="transparent"
                  className="w-100 border-0 card-ghost mb-3 mb-lg-5"
                >
                  <Card.Body>
                    <Card.Title
                      as="h3"
                      className="text-white display-3 text-uppercase"
                    >
                      Do you know what your is home worth?
                    </Card.Title>
                    <Card.Text className="text-white display-5 text-uppercase">
                      It might be worth more than you think
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card
                  bg="transparent"
                  className="w-100 border-0 card-ghost mb-3 mb-lg-5"
                >
                  <Card.Body>
                    <Card.Title
                      as="h3"
                      className="text-white display-3 text-uppercase"
                    >
                      Q&A: What is the difference between mildew and mold?
                    </Card.Title>
                    <Card.Text className="text-white display-5 text-uppercase">
                      You have heard the terms mildew and mold used
                      interchangeably, but they&apos;re not the same thing
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="12" className="mb-3 mb-lg-5">
          <Card className="h-100" style={{ minHeight: '25rem' }}>
            <Card.Body>chart 1</Card.Body>
          </Card>
        </Col>
        <Col sm="6" className="mb-3 mb-lg-5">
          <Card className="h-100" style={{ minHeight: '25rem' }}>
            <Card.Body>chart 2</Card.Body>
          </Card>
        </Col>
        <Col sm="6" className="mb-3 mb-lg-5">
          <Card className="h-100">
            <Card.Body>chart 3</Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Newsletter;
