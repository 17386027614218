export const humanFileSize = (bytes, si = true, dp = 1) => {
  let nBytes = bytes;
  const thresh = si ? 1000 : 1024;

  if (Math.abs(nBytes) < thresh) {
    return `${nBytes} B`;
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    nBytes /= thresh;
    u += 1;
  } while (
    Math.round(Math.abs(nBytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return `${nBytes.toFixed(dp)} ${units[u]}`;
};

export const checkUrlProtocol = (url) => {
  if (/(http(s?)):\/\//i.test(url)) {
    return true;
  }

  return false;
};

export const trimUrl = (url) => {
  let domain = url;
  if (checkUrlProtocol(domain)) {
    domain = new URL(domain);
    domain = domain.hostname.replace('www.', '');
  }

  return domain;
};

export const withHttps = (url) => {
  let domain = url;

  if (!checkUrlProtocol(domain)) {
    domain = `https://${domain}`;
  }

  domain = new URL(domain);
  domain = domain.href;

  return domain;
};
