import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Row,
  Col,
  Button,
  Modal,
  ListGroup,
  Accordion,
  Badge,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useAxiosQuery } from '../../../../hooks';
import { RequestLoading, RequestResult, Tooltip } from '../../../../components';

const accordionItems = [
  'all_links',
  'css_links',
  'imgs_load',
  'other_load',
  'scripts_load',
];

function GoogleSpeedCard({ url }) {
  const [showModal, setShowModal] = useState(false);

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url,
    requestType: 'get',
    preventFetch: !showModal,
  });

  const closeModal = () => {
    setShowModal(false);
  };

  const handleCheck = () => {
    setShowModal(true);
  };

  return (
    <>
      <Card className="h-100">
        <Card.Body>
          <Row className="align-items-center gx-2">
            <Col>
              <Card.Subtitle className="mb-2">
                <FormattedMessage id="app.common.googleSpeed" />
                <Tooltip
                  content={
                    <FormattedMessage id="app.helpers.seoManager.overview.tooltips.googleSpeed" />
                  }
                >
                  <i className="bi-question-circle text-body ms-1" />
                </Tooltip>
              </Card.Subtitle>
              <Button variant="primary" size="xs" onClick={handleCheck}>
                <FormattedMessage id="app.common.check" />
              </Button>
            </Col>
            <Col xs="auto">
              <span className="icon icon-circle icon-primary">
                <i className="fs-4 bi-search" />
              </span>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={closeModal} scrollable size="lg">
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            <FormattedMessage id="app.common.googleSpeed" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RequestLoading loading={apiLoading} />
          <RequestResult type="error" message={apiError} />

          {!apiLoading && !apiError && apiData && (
            <div>
              {apiData.all_time && (
                <div className="fs-4 mb-3 d-flex justify-content-between align-items-center">
                  <FormattedMessage id="app.common.totalLoadTime" />:
                  <Badge>{apiData.all_time}</Badge>
                </div>
              )}
              <Accordion>
                {accordionItems.map((key, index) => (
                  <Accordion.Item key={key} eventKey={`google_speed_${index}`}>
                    <Accordion.Header>
                      <span className="text-capitalize">
                        {key.split('_').join(' ')}
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <ListGroup>
                        {apiData[key].map((item, i) => (
                          <ListGroup.Item
                            key={`google_speed_${key}_${i.toString()}`}
                            className="d-flex justify-content-between align-items-center"
                          >
                            <div className="overflow-hidden me-2">
                              <Badge className="me-2">{item[0]}</Badge>
                              {item[1]}
                            </div>
                            <Badge bg="secondary">{item[2]}</Badge>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={closeModal} variant="white">
            <FormattedMessage id="app.common.close" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
GoogleSpeedCard.propTypes = {
  url: PropTypes.string.isRequired,
};

export default GoogleSpeedCard;
