import React, { createContext, useMemo, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useEffectOnce } from 'react-use';
import { useLocation, useParams } from 'react-router-dom';
import { requestForToken, onMessageListener } from '../lib/firebase';
import { useAxiosMutation, useToast } from '../hooks';
import { AuthContext } from './AuthContext';
import Utils from '../utils';
import {
  Routes as AppRoutes,
  protectedAreaBasePath,
} from '../router/routeMapping';

const FirebaseContext = createContext();

function FirebaseProvider({ children }) {
  const location = useLocation();
  const { id } = useParams();

  const { token: userAppToken } = useContext(AuthContext);
  const { showToast } = useToast();
  const { formatMessage } = useIntl();

  const [onMessage, setOnMessage] = useState();

  const { mutate } = useAxiosMutation({
    url: '/conversations/save_token',
  });

  useEffectOnce(() => {
    const requestToken = async () => {
      let requestedToken;
      try {
        requestedToken = await requestForToken();
      } catch (error) {
        showToast({
          type: 'danger',
          autohide: true,
          title: formatMessage({ id: 'app.firebase.errorTitle' }),
          message: formatMessage({
            id: `app.firebase.errors.${error.message}`,
          }),
        });
      }

      if (!requestedToken || !userAppToken) {
        return;
      }

      mutate(
        { fcm: requestedToken },
        {
          onError: (error) => {
            showToast({
              type: 'danger',
              autohide: true,
              title: formatMessage({ id: 'app.firebase.errorTitle' }),
              message: formatMessage({ id: error.message }),
            });
          },
        }
      );
    };

    requestToken();
  });

  onMessageListener()
    .then((payload) => {
      const nPayLoad = payload;
      if (
        nPayLoad?.data?.link &&
        !Utils.String.checkUrlProtocol(nPayLoad.data.link)
      ) {
        nPayLoad.data.link = `${protectedAreaBasePath}${payload.data.link}`;
      }

      if (
        location.pathname === AppRoutes.protected.MESSAGES.path ||
        (id &&
          location.pathname ===
            AppRoutes.protected.MESSAGES_DETAIL.path.replace(':id', id))
      ) {
        setOnMessage(nPayLoad);
        setOnMessage(null);
        return;
      }

      showToast({
        type: 'primary',
        autohide: false,
        delay: 10000,
        title: nPayLoad?.data?.title,
        message: nPayLoad?.data?.body,
        image: nPayLoad?.data?.image,
        link: nPayLoad?.data?.link,
      });
    })
    .catch((error) => {
      showToast({
        type: 'danger',
        autohide: true,
        title: formatMessage({ id: 'app.firebase.errorTitle' }),
        message: formatMessage({ id: error.message }),
      });
    });

  const value = useMemo(
    () => ({
      onMessage,
    }),
    [onMessage]
  );

  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  );
}

FirebaseProvider.propTypes = {
  children: PropTypes.node,
};

FirebaseProvider.defaultProps = {
  children: null,
};

export { FirebaseContext, FirebaseProvider };
