import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Dropdown, ListGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Constants from '../../../../../constants';
import { useConfirmModal, useFormModal } from '../../../../../hooks';
import Utils from '../../../../../utils';
import { yup } from '../../../../../lib';
import { PropertyContext } from '../../../../../context/PropertyContext';
import { BrandIcon } from '../../../../../components';

function ReviewSites({ property }) {
  const { updateProperty } = useContext(PropertyContext);
  const { confirm } = useConfirmModal({ confirmVariant: 'danger' });
  const { form } = useFormModal();

  const onConnectItem = async (id, title, url) => {
    const fields = [
      {
        cols: [
          {
            key: 'url',
            schema: yup.string().customUrl().required(),
          },
        ],
      },
    ];

    const formData = await form({
      title,
      confirmLabel: 'save',
      requestUrl: '/review/update',
      requestParams: { property_id: property.id, type: id },
      defaultValues: { url },
      fields,
      fetchOnStart: false,
    });

    if (formData) {
      const nProperty = { ...property };

      const founded = nProperty.PlatformsReview.find(
        (platform) => platform.site === id
      );
      if (founded) {
        nProperty.PlatformsReview = nProperty.PlatformsReview.map((platform) =>
          platform.site === id ? formData.Result : platform
        );
      } else {
        nProperty.PlatformsReview.push(formData.Result);
      }

      updateProperty(nProperty);
    }
  };

  const onDisconnectItem = async (id, title) => {
    const isSuccess = await confirm({
      message: (
        <FormattedMessage
          id="app.common.areYouSureYouWantToDisconnectX"
          values={{
            x: title,
          }}
        />
      ),
      requestUrl: '/review/update',
      requestParams: { property_id: property.id, type: id, url: '' },
    });

    if (isSuccess) {
      const nProperty = { ...property };
      nProperty.PlatformsReview = nProperty.PlatformsReview.filter(
        (platform) => platform.site !== id
      );

      updateProperty(nProperty);
    }
  };

  const getSortedSites = () => {
    const propertyBusinessCategories = Utils.Object.isArray(property.type)
      ? property.type
      : [property.type];

    const filteredReviewSites = Object.keys(Constants.Review.Sites)
      .filter((key) => Constants.Review.Sites[key].categories?.includes('all'))
      .reduce(
        (obj, key) =>
          Object.assign(obj, {
            [key]: Constants.Review.Sites[key],
          }),
        {}
      );

    propertyBusinessCategories.forEach((category) => {
      Object.keys(Constants.Review.Sites).forEach((key) => {
        const site = Constants.Review.Sites[key];
        if (site.categories?.includes(category)) {
          filteredReviewSites[key] = site;
        }
      });
    });

    const data =
      property.PlatformsReview?.length > 0 ? property.PlatformsReview : [];
    const founded = Object.values(filteredReviewSites).filter((o1) =>
      data.some((o2) => o1.id === o2.site)
    );
    const others = Object.values(filteredReviewSites).filter(
      (o1) => !data.some((o2) => o1.id === o2.site)
    );
    return [...founded, ...others];
  };

  const sortedSites = getSortedSites();

  return (
    <ListGroup variant="flush" className="list-group-lg list-group-no-gutters">
      {sortedSites.map((item) => {
        const { id, label, color } = item;
        const founded = property.PlatformsReview.find(
          (platform) => platform.site === id
        );
        const url = founded ? Utils.String.withHttps(founded.url) : '';

        const colorClass = url ? '' : 'text-muted';
        const colorStyle = url ? color : '';
        const iconStyle = url ? {} : { filter: 'grayscale(100%)' };

        return (
          <ListGroup.Item key={id}>
            <div className="d-flex">
              <div className="flex-shrink-0">
                <BrandIcon
                  brand={id}
                  style={iconStyle}
                  className="list-group-icon w-auto"
                />
              </div>
              <div className="flex-grow-1">
                <div className="row align-items-center">
                  <div className="col-sm mb-2 mb-sm-0">
                    <h4
                      className={`mb-0 ${colorClass}`}
                      style={{ color: colorStyle }}
                    >
                      {label}
                    </h4>
                    {url ? (
                      <a
                        className="fs-5 text-break"
                        href={url}
                        target="_blank"
                        rel="nofollow noreferrer"
                      >
                        {url}
                      </a>
                    ) : (
                      <FormattedMessage id="app.common.notConnected" />
                    )}
                  </div>

                  <div className="col-sm-auto">
                    {url ? (
                      <Dropdown align="end">
                        <ButtonGroup>
                          <Button
                            variant="white"
                            size="sm"
                            onClick={() => {
                              onDisconnectItem(id, label);
                            }}
                          >
                            <i className="bi-ban me-1" />
                            <FormattedMessage id="app.common.disconnect" />
                          </Button>
                          <ButtonGroup>
                            <Dropdown.Toggle
                              variant="white"
                              size="sm"
                              className="btn-icon dropdown-toggle-empty"
                            />
                          </ButtonGroup>
                        </ButtonGroup>
                        <Dropdown.Menu className="m-0" renderOnMount>
                          <Dropdown.Item
                            onClick={() => {
                              onConnectItem(id, label, url);
                            }}
                          >
                            <i className="bi-pencil dropdown-item-icon" />
                            <span>
                              <FormattedMessage id="app.common.edit" />
                            </span>
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item
                            href={url}
                            target="_blank"
                            rel="nofollow noreferrer"
                          >
                            <i className="bi-box-arrow-up-right dropdown-item-icon" />
                            <span>
                              <FormattedMessage id="app.common.visitProfile" />
                            </span>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      <Button
                        variant="white"
                        size="sm"
                        onClick={() => {
                          onConnectItem(id, label);
                        }}
                      >
                        <FormattedMessage id="app.common.connect" />
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
}

ReviewSites.propTypes = {
  property: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ReviewSites;
