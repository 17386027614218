import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const allowedHtmlTags = [
  'section',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'ul',
  'ol',
  'ol_a', // alphabetic list
  'li',
  'strong',
  'i',
  'b',
  'a',
  'span',
  'div',
];

function FormattedHtml({ id, values }) {
  const renderValues = (tag, val) => {
    const Comp = tag;

    switch (tag) {
      case 'ol_a':
        return (
          <ol type="a" className="mt-2">
            {val}
          </ol>
        );

      case 'a': {
        return (
          <Comp
            className="link-primary"
            href={val}
            target="_blank"
            rel="nofollow noreferrer"
          >
            {val}
          </Comp>
        );
      }

      default:
        return <Comp>{val}</Comp>;
    }
  };

  const defaultValues = allowedHtmlTags.reduce(
    (a, v) => ({ ...a, [v]: (msg) => renderValues(v, msg) }),
    {}
  );

  const mergedValues = { ...defaultValues, ...values };

  return <FormattedMessage id={id} values={mergedValues} />;
}

FormattedHtml.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.objectOf(PropTypes.any),
};

FormattedHtml.defaultProps = {
  values: {},
};

export default FormattedHtml;
