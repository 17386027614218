import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { yup } from '../../../../lib';
import { SmartForm } from '../../../../components';

function BusinessSocialInfoStep({ onNext, onPrev, defaultValues }) {
  const formRef = useRef();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const fields = [
    {
      cols: [
        {
          key: 'facebook',
          schema: yup.string().nullable(),
        },
        {
          key: 'twitter',
          schema: yup.string().nullable(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'instagram',
          schema: yup.string().nullable(),
        },
        {
          key: 'pinterest',
          schema: yup.string().nullable(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'linkedin',
          schema: yup.string().nullable(),
        },
      ],
    },
  ];

  const onSubmit = (data) => {
    setIsSubmitted(true);
    onNext(data);
  };

  useEffect(() => {
    if (!isSubmitted) {
      formRef.current.reset(defaultValues);
    }
  }, [isSubmitted, defaultValues]);

  return (
    <Row className="justify-content-sm-center py-10">
      <Col sm="8">
        <SmartForm
          ref={formRef}
          fields={fields}
          onSubmit={onSubmit}
          isWizard
          onWizardPrev={onPrev}
          submitButtonText="next"
          defaultValues={defaultValues}
          footerAlign="between"
        />
      </Col>
    </Row>
  );
}

BusinessSocialInfoStep.propTypes = {
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  defaultValues: PropTypes.objectOf(PropTypes.any),
};

BusinessSocialInfoStep.defaultProps = {
  onNext: () => {},
  onPrev: () => {},
  defaultValues: null,
};

export default BusinessSocialInfoStep;
