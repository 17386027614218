import { useContext } from 'react';
import { ToastContext } from '../context/ToastContext';

const useToast = ({
  type = 'primary',
  title = '',
  message = '',
  image = null,
  link = null,
  autohide = false,
  delay = 3000,
} = {}) => {
  const context = useContext(ToastContext);

  const showToast = (opts) => {
    const props = {
      type: opts?.type || type,
      title: opts?.title || title,
      message: opts?.message || message,
      image: opts?.image || image,
      link: opts?.link || link,
      autohide: opts?.autohide || autohide,
      delay: opts?.delay || delay,
    };

    context.addToast({
      ...props,
    });
  };

  return { showToast };
};

export default useToast;
