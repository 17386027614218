import React from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  BrandRatingList,
  RecentReviews,
  RecommendedActions,
  ReviewSummary,
  TopKeywords,
  RatingBreakdown,
  RatingSummary,
} from './partials';

function Summary() {
  return (
    <Row>
      <Col lg="8" className="mb-3 mb-lg-5">
        <RatingSummary />
      </Col>
      <Col lg="4" className="mb-3 mb-lg-5">
        <ReviewSummary />
      </Col>
      <Col lg="8" className="mb-3 mb-lg-5">
        <RecentReviews />
      </Col>
      <Col lg="4" className="mb-3 mb-lg-5">
        <RecommendedActions />
      </Col>
      <Col lg="6" className="mb-3 mb-lg-5">
        <TopKeywords />
      </Col>
      <Col lg="6" className="mb-3 mb-lg-5">
        <RatingBreakdown />
      </Col>
      <BrandRatingList />
    </Row>
  );
}

export default Summary;
