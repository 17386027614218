import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Card,
  ListGroup,
  Image,
  Badge,
  Form,
  InputGroup,
  Button,
  Offcanvas,
  Spinner,
  Dropdown,
} from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { Link, useParams, useNavigate } from 'react-router-dom';
import {
  PageHeader,
  RequestLoading,
  RequestResult,
  Tooltip,
} from '../../../components';
import { useAxiosMutation, useAxiosQuery } from '../../../hooks';
import DefaultProfileImage from '../../../assets/images/default-profile-img.jpg';
import ConversationImage from '../../../assets/images/svg/illustrations/oc-chatting.svg';
import ConversationImageLight from '../../../assets/images/svg/illustrations-light/oc-chatting.svg';
import { AuthContext } from '../../../context/AuthContext';
import Constants from '../../../constants';
import { Routes as AppRoutes } from '../../../router/routeMapping';
import { FirebaseContext } from '../../../context/FirebaseContext';

const HEIGHT = 580;
const SEARCH_INPUT_WRAPPER_HEIGHT = 70;
const SEND_INPUT_WRAPPER_HEIGHT = 86;
const allowDelete = false;

const onImageError = (e) => {
  e.target.src = DefaultProfileImage;
};

function MessageItem({ item, currentUserId }) {
  const isRightAligned = item.user_id === currentUserId;
  const deleteItem = () => {
    console.log('deleteItem');
  };
  return (
    <div
      className={`mb-3 d-flex justify-content-${
        isRightAligned ? 'end' : 'start'
      }`}
    >
      <div
        className={`d-flex flex-column align-items-${
          isRightAligned ? 'end' : 'start'
        }`}
        style={{ maxWidth: '70%' }}
      >
        <div className="d-flex align-items-center">
          {allowDelete && (
            <Dropdown
              align={isRightAligned ? 'end' : 'start'}
              className={isRightAligned ? 'me-1' : 'order-1 ms-1'}
            >
              <Dropdown.Toggle
                bsPrefix="btn"
                variant="ghost-secondary"
                size="xs"
                className="btn-icon rounded-circle card-dropdown-btn"
              >
                <i className="bi-three-dots-vertical" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    deleteItem(item);
                  }}
                >
                  <i className="bi-person dropdown-item-icon" />
                  <span>
                    <FormattedMessage id="app.common.deleteForMe" />
                  </span>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    deleteItem(item);
                  }}
                >
                  <i className="bi-people dropdown-item-icon" />
                  <span>
                    <FormattedMessage id="app.common.deleteForEveryone" />
                  </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
          <Badge
            as="div"
            className="p-2 fs-6 fw-normal text-wrap"
            bg={`${isRightAligned ? 'primary' : 'dark'}`}
          >
            {item.content || ''}
          </Badge>
        </div>
        <div>
          <div className="small">
            <span className="initialism">
              <span className="initialism">
                {moment(item.sent_date).format('HH:mm')}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

MessageItem.propTypes = {
  item: PropTypes.shape({
    user_id: PropTypes.number.isRequired,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    sent_date: PropTypes.string.isRequired,
  }).isRequired,
  currentUserId: PropTypes.number.isRequired,
};

function ConversationItem({ item, active }) {
  const [notReadCount, setNotReadCount] = useState();

  useEffect(() => {
    setNotReadCount(Number(item.not_read_count));
  }, [item]);

  return (
    <ListGroup.Item
      as={Link}
      className="d-flex align-items-center rounded-0"
      active={active}
      to={AppRoutes.protected.MESSAGES_DETAIL.path.replace(':id', item.id)}
      onClick={() => {
        setNotReadCount(0);
      }}
    >
      <div className="avatar avatar-circle">
        <Image
          src={item.users?.[0]?.photo || DefaultProfileImage}
          className="avatar-img w-100"
          onError={onImageError}
        />
      </div>
      <div className="px-3">
        <h4 className="mb-0">{item.users?.[0]?.name}</h4>
      </div>
      {notReadCount > 0 && (
        <Badge bg="primary" pill className="ms-auto">
          {item.not_read_count}
        </Badge>
      )}
    </ListGroup.Item>
  );
}

ConversationItem.propTypes = {
  item: PropTypes.shape({
    not_read_count: PropTypes.number.isRequired,
    users: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    id: PropTypes.number.isRequired,
  }).isRequired,
  active: PropTypes.bool.isRequired,
};

function Messages() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { onMessage } = useContext(FirebaseContext);
  const chatScrollerRef = useRef();

  const { formatMessage } = useIntl();

  const [show, setShow] = useState(false);
  const [search, setSearch] = useState('');
  const [message, setMessage] = useState('');
  const [activeConversation, setActiveConversation] = useState();
  const [messagessData, setMessagessData] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const scrollToEnd = () => {
    if (chatScrollerRef.current) {
      setTimeout(() => {
        chatScrollerRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  };

  const {
    data: conversationsData,
    isLoading: conversationsLoading,
    error: conversationsError,
    refetch: conversationsRefetch,
  } = useAxiosQuery({
    url: '/conversations/list',
  });

  const {
    isLoading: messagesLoading,
    error: messagesError,
    refetch: messagesRefetch,
  } = useAxiosQuery({
    url: '/messages/list',
    preventFetch: !activeConversation,
    params: { quantity: 9999999, user_id: activeConversation?.users?.[0]?.id },
    // params: { conversation_id: activeConversation?.id },
    onSuccess: (data) => {
      let result = [];
      if (data.length > 0) {
        const dateGroups = data.reverse().reduce((groups, item) => {
          const nGroups = groups;
          const date = moment(item.sent_date).format('YYYY-MM-DD');
          if (!nGroups[date]) {
            nGroups[date] = [];
          }
          nGroups[date].push(item);
          return nGroups;
        }, {});

        result = Object.keys(dateGroups).map((date) => ({
          date,
          items: dateGroups[date],
        }));
      }

      setMessagessData(result);

      scrollToEnd();
    },
  });

  const {
    isLoading: sendMessageLoading,
    error: sendMessageError,
    mutate: sendMessageRequest,
  } = useAxiosMutation({
    url: '/messages/insert',
  });

  const getFilteredConversations = () => {
    const dataToFilter = conversationsData || [];
    const filtered = dataToFilter.filter(
      (item) =>
        item.users?.[0]?.name?.toLowerCase().indexOf(search.toLowerCase()) > -1
    );

    return filtered;
  };

  const getHistoryDate = (date) => {
    const today = moment();
    const yesterday = moment().subtract(1, 'days');

    if (today.isSame(date, 'day')) {
      return formatMessage({ id: 'app.common.today' });
    }
    if (yesterday.isSame(date, 'day')) {
      return formatMessage({ id: 'app.common.yesterday' });
    }
    if (moment(date).isAfter(moment().subtract(7, 'days'))) {
      return moment(date).format('dddd');
    }

    return moment(date).format(Constants.DateFormats.APP.Moment.Common);
  };

  const sendMessage = () => {
    if (!message || !activeConversation?.users?.[0]?.id) {
      return;
    }

    sendMessageRequest(
      { user_id: activeConversation?.users?.[0]?.id, content: message },
      {
        onSuccess: (data) => {
          if (data) {
            setMessagessData((prev) => {
              let prevData = cloneDeep(prev);
              const date = moment(data.sent_date).format('YYYY-MM-DD');

              let founded = false;
              prevData = [...prevData].map((section) => {
                const nSection = section;
                if (nSection.date === date) {
                  const items = [...nSection.items];
                  nSection.items = [...items, data];
                  founded = true;
                }
                return nSection;
              });

              if (founded) {
                return prevData;
              }

              return [
                {
                  date,
                  items: [data],
                },
              ];
            });

            setMessage('');
            scrollToEnd();
          }
        },
      }
    );
  };

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      sendMessage();
    }
  };

  useEffect(() => {
    if (id && conversationsData?.length > 0) {
      const founded = conversationsData.find(
        (item) => `${item.id}` === `${id}`
      );
      if (founded) {
        setShow(false);
        setActiveConversation(founded);
        scrollToEnd();
        setMessage('');
      } else {
        navigate(AppRoutes.protected.MESSAGES.path, { replace: true });
      }
    } else {
      setShow(false);
      setActiveConversation(null);
      setMessage('');
    }
  }, [conversationsData, id, navigate]);

  useEffect(() => {
    if (onMessage?.data && conversationsData?.length > 0) {
      const { userId } = onMessage.data;

      if (`${userId}` === `${activeConversation?.users?.[0]?.id}`) {
        messagesRefetch();
        return;
      }

      conversationsRefetch();
    }
  }, [
    onMessage,
    conversationsData,
    activeConversation,
    conversationsRefetch,
    messagesRefetch,
  ]);

  return (
    <div className="content container">
      <PageHeader
        className="d-block"
        title="messages"
        breadcrumbData={{
          current: 'messages',
        }}
      />
      <Row>
        <Col>
          <Button
            variant="secondary"
            className="d-lg-none mb-3"
            onClick={handleShow}
          >
            <i className="bi-chat me-1" />
            <FormattedMessage id="app.common.viewConversations" />
          </Button>
          <Card>
            <Row className="g-0">
              <Col lg="4" xl="3">
                <div className="rounded-start overflow-hidden">
                  <Offcanvas show={show} onHide={handleClose} responsive="lg">
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title as="h4" className="mb-0">
                        <FormattedMessage id="app.common.conversations" />
                      </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className="d-flex flex-column p-0">
                      <div className="p-3">
                        <InputGroup className="input-group-merge input-group-hover-light">
                          <InputGroup.Text className="input-group-prepend bg-transparent border-end-0">
                            <i className="bi-search" />
                          </InputGroup.Text>
                          <Form.Control
                            type="search"
                            size="sm"
                            placeholder={formatMessage({
                              id: 'app.common.search',
                            })}
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                            }}
                          />
                        </InputGroup>
                      </div>
                      {(conversationsLoading ||
                        conversationsError ||
                        (!conversationsLoading &&
                          !conversationsError &&
                          getFilteredConversations().length === 0)) && (
                        <div className="p-3">
                          <RequestLoading
                            loading={conversationsLoading}
                            size="lg"
                            margin="5"
                          />
                          <RequestResult
                            type="error"
                            message={conversationsError}
                          />
                          {!conversationsLoading &&
                            !conversationsError &&
                            getFilteredConversations().length === 0 && (
                              <RequestResult
                                type="secondary"
                                title="warnings.noResultsFound.title"
                                message="warnings.noResultsFound.message"
                              />
                            )}
                        </div>
                      )}
                      {!conversationsLoading &&
                        !conversationsError &&
                        conversationsData?.length > 0 && (
                          <SimpleBar
                            className="w-100 h-down-lg-100"
                            style={{
                              height:
                                HEIGHT +
                                SEND_INPUT_WRAPPER_HEIGHT -
                                SEARCH_INPUT_WRAPPER_HEIGHT,
                            }}
                          >
                            <ListGroup
                              variant="flush"
                              className="list-group-striped"
                            >
                              {getFilteredConversations().map((item) => (
                                <ConversationItem
                                  key={item.id}
                                  item={item}
                                  active={item.id === activeConversation?.id}
                                />
                              ))}
                            </ListGroup>
                          </SimpleBar>
                        )}
                    </Offcanvas.Body>
                  </Offcanvas>
                </div>
              </Col>
              <Col lg="8" xl="9">
                <div className="rounded-end overflow-hidden position-relative h-100">
                  <div className="d-none d-lg-block border-start position-absolute w-100 h-100" />
                  {activeConversation ? (
                    <>
                      {(messagesLoading || messagesError) && (
                        <div className="p-3">
                          <RequestLoading
                            loading={messagesLoading}
                            size="lg"
                            margin="5"
                          />
                          <RequestResult type="error" message={messagesError} />
                        </div>
                      )}

                      <>
                        <SimpleBar
                          style={{ height: HEIGHT }}
                          className="content-min-h-100"
                        >
                          {!messagesLoading && !messagesError && (
                            <div
                              className="d-flex flex-column p-4 flex-grow-1 justify-content-end"
                              style={{ overflowX: 'hidden' }}
                            >
                              {messagessData.map(({ date, items }) => (
                                <React.Fragment key={date}>
                                  <Badge
                                    bg="secondary"
                                    className="align-self-center"
                                  >
                                    {getHistoryDate(date)}
                                  </Badge>
                                  {items.map((item, index) => (
                                    <MessageItem
                                      key={`message_${index.toString()}`}
                                      item={item}
                                      currentUserId={user.id}
                                    />
                                  ))}
                                </React.Fragment>
                              ))}
                            </div>
                          )}
                          <span ref={chatScrollerRef} />
                        </SimpleBar>
                        <Card.Footer>
                          <InputGroup>
                            <Form.Control
                              disabled={sendMessageLoading}
                              value={message}
                              onChange={(e) => {
                                setMessage(e.target.value);
                              }}
                              onKeyDown={onKeyDown}
                            />
                            <Button
                              variant={sendMessageError ? 'danger' : 'primary'}
                              onClick={sendMessage}
                            >
                              {sendMessageLoading ? (
                                <Spinner animation="border" size="xs" />
                              ) : (
                                <div>
                                  {sendMessageError ? (
                                    <Tooltip
                                      content={formatMessage({
                                        id: 'app.common.somethingWentWrong',
                                      })}
                                      placement="right"
                                    >
                                      <i className="bi-exclamation-triangle me-1" />
                                    </Tooltip>
                                  ) : (
                                    <i className="bi-send" />
                                  )}
                                </div>
                              )}
                            </Button>
                          </InputGroup>
                        </Card.Footer>
                      </>
                    </>
                  ) : (
                    <div className="h-100 d-flex align-items-center justify-content-center p-5 p-lg-0">
                      <RequestResult
                        type="secondary"
                        title="warnings.noConversationsSelected.title"
                        message="warnings.noConversationsSelected.message"
                        image={{
                          src: ConversationImage,
                          darkSrc: ConversationImageLight,
                        }}
                      />
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Messages;
