import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Table,
  Card,
  ProgressBar,
  Image,
  Badge,
} from 'react-bootstrap';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { capitalize } from 'lodash';
import { useAxiosQuery } from '../../../hooks';
import { PropertyContext } from '../../../context/PropertyContext';
import {
  PageHeader,
  RequestLoading,
  RequestResult,
  Tooltip,
} from '../../../components';

import PlaceHolderImg from '../../../assets/images/placeholder.png';

const onImageError = (e) => {
  e.target.src = PlaceHolderImg;
};

const columns = [
  {
    titleKey: 'directory',
    id: 'directory',
  },
  {
    titleKey: 'status',
    id: 'status',
  },
  {
    titleKey: 'details',
    id: 'details',
  },
];

function SmallCard({ titleKey, descKey, children }) {
  return (
    <Card className="card-hover-shadow h-100">
      <Card.Body>
        <Card.Subtitle>
          <FormattedMessage id={`app.common.${titleKey}`} />
          <Tooltip
            content={
              <FormattedMessage
                id={`app.helpers.listingManager.tooltips.${titleKey}`}
              />
            }
          >
            <i className="bi-question-circle text-body ms-1" />
          </Tooltip>
        </Card.Subtitle>
        <Card.Text className="small">
          <FormattedMessage id={`app.common.${descKey}`} />
        </Card.Text>
        {children}
      </Card.Body>
    </Card>
  );
}

SmallCard.propTypes = {
  titleKey: PropTypes.string.isRequired,
  descKey: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

function ListingManager() {
  const { activeProperty } = useContext(PropertyContext);

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/reputation_manager/listingmanager',
    preventFetch: !activeProperty?.id,
    params: {
      id: activeProperty?.id,
    },
  });

  const getScoreType = (value, type) => {
    let variant = 'danger';
    let titleKey = 'bad';

    if (value > 50 && value < 80) {
      variant = 'warning';
      titleKey = 'average';
    } else if (value >= 80) {
      variant = 'success';
      titleKey = 'good';
    }

    return type === 'color' ? variant : titleKey;
  };

  const getVolumeColor = (value) => {
    let variant = 'primary';
    if (value > 50000 && value < 100000) {
      variant = 'info';
    } else if (value >= 100000) {
      variant = 'danger';
    }
    return variant;
  };

  const getIssueDesc = (type) => {
    let desc = 'wrongBusinessName';
    if (type === 'address') {
      desc = 'wrongAddress';
    } else if (type === 'phone') {
      desc = 'wrongPhoneNumber';
    }

    return desc;
  };

  const columnParser = (id, row) => {
    const notListed = row.Rating === 0;

    let desc;

    try {
      desc = JSON.parse(row.Description);
    } catch (error) {
      desc = undefined;
    }

    let noIssuesFound = true;
    const issues = {};
    if (desc?.similarity) {
      Object.keys(desc?.similarity).forEach((key) => {
        if (desc.similarity[key].rateText !== 'Good') {
          noIssuesFound = false;
          issues[key] = desc.similarity[key];
        }
      });
    }

    if (id === 'directory') {
      return (
        <div>
          <a
            className="d-flex align-items-center"
            href={`http://${row.fc_listings_Url}`}
            target="_blank"
            rel="nofollow noreferrer"
          >
            {row.Logo && (
              <Image
                src={row.Logo}
                onError={onImageError}
                className="rounded-circle"
                style={{
                  width: 48,
                  height: 48,
                  objectFit: 'cover',
                  backgroundColor: 'white',
                }}
              />
            )}
            <span className="ms-2">{capitalize(row.Platform)}</span>
          </a>
        </div>
      );
    }
    if (id === 'status') {
      if (notListed) {
        return <FormattedMessage id="app.common.notPresent" />;
      }
      if (noIssuesFound) {
        return (
          <div className="text-success">
            <FormattedMessage id="app.common.noIssuesFound" />
          </div>
        );
      }
      return Object.keys(issues).map((key) => (
        <div key={key} className="text-danger">
          <FormattedMessage id={`app.common.${getIssueDesc(key)}`} />
        </div>
      ));
    }

    if (notListed) {
      return (
        <div className="d-flex flex-column">
          <span
            className={`me-1 text-${getVolumeColor(row.fc_listings_Volume)}`}
          >
            <FormattedMessage id="app.common.missedOpportunity" />
          </span>
          <div className="d-flex align-items-center">
            <span className="me-2">
              <FormattedMessage id="app.common.directorysMonthlyVisits" />
            </span>
            <i className="bi-arrow-right me-2" />
            <Badge pill bg={getVolumeColor(row.fc_listings_Volume)}>
              <FormattedNumber
                value={row.fc_listings_Volume}
                notation="compact"
              />
            </Badge>
          </div>
        </div>
      );
    }

    if (noIssuesFound && desc?.similarity) {
      return (
        <div>
          {Object.keys(desc.similarity).map((key, index) => (
            <span key={key} className="me-1">
              {desc.similarity[key].detected}
              {index < Object.keys(desc.similarity).length - 1 && ','}
            </span>
          ))}
        </div>
      );
    }

    return Object.keys(issues).map((key) => (
      <div key={key}>
        {issues[key].detected || <FormattedMessage id="app.common.n/a" />}
      </div>
    ));
  };

  return (
    <div className="content container">
      <PageHeader
        className="d-block"
        title="listingManager"
        breadcrumbData={{
          current: 'listingManager',
        }}
      />

      <RequestLoading loading={apiLoading} size="lg" margin="5" />
      <RequestResult type="error" message={apiError} />
      {!apiLoading && !apiError && !apiData?.own?.total && (
        <RequestResult
          type="secondary"
          title="emptyState.listingManager.title"
          message="emptyState.listingManager.message"
          image={{ withBorder: true }}
        />
      )}

      {!apiLoading && !apiError && !!apiData?.own?.total && (
        <Row>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <SmallCard titleKey="onlinePresence" descKey="overallAssessment">
              <div className="d-flex align-items-center">
                <div
                  className={`fs-5 me-2 text-${getScoreType(
                    apiData.own.score,
                    'color'
                  )}`}
                >
                  <strong>{apiData.own.score}</strong>
                </div>
                <div className="flex-grow-1">
                  <ProgressBar
                    style={{ height: 20 }}
                    now={apiData.own.score}
                    variant={getScoreType(apiData.own.score, 'color')}
                  />
                </div>
                <div
                  className={`fs-3 ms-2 text-${getScoreType(
                    apiData.own.score,
                    'color'
                  )}`}
                >
                  <strong>
                    <FormattedMessage
                      id={`app.common.${getScoreType(apiData.own.score)}`}
                    />
                  </strong>
                </div>
              </div>
            </SmallCard>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <SmallCard titleKey="listingsToFix" descKey="toImproveCoverage">
              <Card.Title
                as="h2"
                className="text-inherit d-flex align-items-center"
              >
                <span className="me-1">
                  {apiData.own.notfound + apiData.own.missing}
                </span>
                <span>/</span>
                <span className="ms-1 fs-4">{apiData.own.total}</span>
              </Card.Title>
            </SmallCard>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <SmallCard
              titleKey="averageStarRating"
              descKey="customersLoveHighRatings"
            >
              <Card.Title
                as="h2"
                className="text-inherit d-flex align-items-center"
              >
                {apiData.own.average_rating ? (
                  <>
                    <span className="me-1">
                      {apiData.own.average_rating.toFixed(1)}
                    </span>
                    <span>/</span>
                    <span className="ms-1 fs-4">5.0</span>
                  </>
                ) : (
                  <FormattedMessage id="app.common.n/a" />
                )}
              </Card.Title>
            </SmallCard>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <SmallCard titleKey="totalReviews" descKey="reviewsBuildTrust">
              <Card.Title as="h2" className="text-inherit">
                {apiData.own.total_review}
              </Card.Title>
            </SmallCard>
          </Col>
        </Row>
      )}
      {!apiLoading && !apiError && apiData.own.scans.length > 0 && (
        <Card>
          <Card.Header>
            <Card.Title bsPrefix="card-header-title" as="h4">
              <FormattedMessage id="app.common.directoryListings" />
            </Card.Title>
          </Card.Header>
          <Table
            responsive
            className="table-thead-bordered table-nowrap table-align-middle card-table"
          >
            <thead className="thead-light">
              <tr>
                {columns.map(({ id, titleKey }) => (
                  <th key={id}>
                    <FormattedMessage id={`app.common.${titleKey}`} />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {apiData.own.scans.map((row, rowIndex) => (
                <tr key={`row_${rowIndex.toString()}`}>
                  {columns.map(({ id }, index) => (
                    <td
                      key={`row_${rowIndex.toString()}_col_${id}_${index.toString()}`}
                    >
                      {columnParser(id, row)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      )}
    </div>
  );
}

export default ListingManager;
