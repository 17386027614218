import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Col,
  Row,
  Nav,
  Card,
  Tab,
  Badge,
  Dropdown,
  Button,
} from 'react-bootstrap';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { orderBy } from 'lodash';
import { useAxiosQuery } from '../../../hooks';
import {
  NoAccessInfo,
  RequestLoading,
  RequestResult,
  Tooltip,
} from '../../../components';
import { RatePulseCard } from './partials';
import { Routes as AppRoutes } from '../../../router/routeMapping';
import { PropertyContext } from '../../../context/PropertyContext';
import { AuthContext } from '../../../context/AuthContext';

const MIN_PROPERTY_REQUIREMENT = 5;

const getSortedItems = (data, sortOptions) => {
  const sorted = orderBy(
    data,
    `${sortOptions.parents ? `${sortOptions.parents}.` : ''}${
      sortOptions.sortBy
    }`,
    sortOptions.order
  );

  return sorted;
};

const sections = [
  {
    titleKey: 'ratePulseHub',
    Component: ({ ...props }) => <RatePulseHub {...props} />,
    sortingTypes: [
      {
        id: 'averageRatingHighLow',
        titleKey: 'averageRating',
        sortOptions: {
          sortBy: 'avg',
          order: 'desc',
          parents: 'nums',
        },
      },
      {
        id: 'averageRatingLowHigh',
        titleKey: 'averageRating',
        sortOptions: {
          sortBy: 'avg',
          order: 'asc',
          parents: 'nums',
        },
      },
      {
        id: 'totalReviewsHighLow',
        titleKey: 'totalReviews',
        sortOptions: {
          sortBy: 'total',
          order: 'desc',
          parents: 'nums',
        },
      },
      {
        id: 'totalReviewsLowHigh',
        titleKey: 'totalReviews',
        sortOptions: {
          sortBy: 'total',
          order: 'asc',
          parents: 'nums',
        },
      },
      {
        id: 'positiveHighLow',
        titleKey: 'positiveRating',
        sortOptions: {
          sortBy: 'positivePercentage',
          order: 'desc',
          parents: 'nums',
        },
      },
      {
        id: 'positiveLowHigh',
        titleKey: 'positiveRating',
        sortOptions: {
          sortBy: 'positivePercentage',
          order: 'asc',
          parents: 'nums',
        },
      },
      {
        id: 'negativeHighLow',
        titleKey: 'negativeRating',
        sortOptions: {
          sortBy: 'negativePercentage',
          order: 'desc',
          parents: 'nums',
        },
      },
      {
        id: 'negativeLowHigh',
        titleKey: 'negativeRating',
        sortOptions: {
          sortBy: 'negativePercentage',
          order: 'asc',
          parents: 'nums',
        },
      },
      {
        id: 'propertyNameAz',
        titleKey: 'propertyName',
        isAlpha: true,
        sortOptions: {
          sortBy: 'name',
          order: 'asc',
        },
      },
      {
        id: 'propertyNameZa',
        titleKey: 'propertyName',
        isAlpha: true,
        sortOptions: {
          sortBy: 'name',
          order: 'desc',
        },
      },
    ],
  },
  {
    titleKey: 'tapFlowAnalytics',
    Component: ({ ...props }) => <TapFlowAnalytics {...props} />,
    sortingTypes: [
      {
        id: 'totalClicksHighLow',
        titleKey: 'totalClicks',
        sortOptions: {
          sortBy: 'total',
          order: 'desc',
        },
      },
      {
        id: 'totalClicksLowHigh',
        titleKey: 'totalClicks',
        sortOptions: {
          sortBy: 'total',
          order: 'asc',
        },
      },
      {
        id: 'totalLinksHighLow',
        titleKey: 'totalLinks',
        sortOptions: {
          sortBy: 'co',
          order: 'desc',
        },
      },
      {
        id: 'totalLinksLowHigh',
        titleKey: 'totalLinks',
        sortOptions: {
          sortBy: 'co',
          order: 'asc',
        },
      },
      {
        id: 'propertyNameAz',
        titleKey: 'propertyName',
        isAlpha: true,
        sortOptions: {
          sortBy: 'name',
          order: 'asc',
        },
      },
      {
        id: 'propertyNameZa',
        titleKey: 'propertyName',
        isAlpha: true,
        sortOptions: {
          sortBy: 'name',
          order: 'desc',
        },
      },
    ],
  },
];

function SmallCard({ titleKey, descKey, infoKey, children }) {
  return (
    <div>
      <div className="d-flex">
        <div className="flex-grow-1">
          <h6 className="card-subtitle mb-1">
            <FormattedMessage id={`app.common.${titleKey}`} />
            {infoKey && (
              <Tooltip
                content={
                  <FormattedMessage
                    id={`app.helpers.${infoKey}.tooltips.${titleKey}`}
                  />
                }
              >
                <i className="bi-question-circle text-body ms-1" />
              </Tooltip>
            )}
          </h6>
          {descKey && (
            <div className="card-text small mb-2">
              <FormattedMessage id={`app.common.${descKey}`} />
            </div>
          )}
        </div>
      </div>
      <div className="w-100">{children}</div>
    </div>
  );
}

SmallCard.propTypes = {
  titleKey: PropTypes.string.isRequired,
  descKey: PropTypes.string,
  infoKey: PropTypes.string,
  children: PropTypes.node.isRequired,
};

SmallCard.defaultProps = {
  descKey: null,
  infoKey: null,
};

function RatePulseHub({ sortOptions, onViewDetail }) {
  const {
    data: apiData,
    isFetching: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/reputation_manager/avg_rating_prop',
    select: (data) => {
      data.map((item) => {
        const nItem = item;
        nItem.nums.positivePercentage = Math.round(
          (item.nums.positive * 100) / item.nums.total
        );
        nItem.nums.negativePercentage = Math.round(
          (item.nums.negative * 100) / item.nums.total
        );
        return nItem;
      });
      return data;
    },
  });

  return (
    <>
      <RequestLoading loading={apiLoading} size="lg" margin="5" />
      <RequestResult type="error" message={apiError} />
      {!apiLoading && !apiError && !apiData && (
        <RequestResult type="secondary" message="app.common.noData" />
      )}
      {!apiLoading && !apiError && apiData && (
        <Row>
          {getSortedItems(apiData, sortOptions).map((item, index) => (
            <Col
              key={`${index.toString()}`}
              md="6"
              xl="4"
              className="mb-3 mb-lg-5"
            >
              <RatePulseCard
                data={item}
                order={index + 1}
                onViewDetail={() => {
                  onViewDetail(item);
                }}
              />
            </Col>
          ))}
        </Row>
      )}
    </>
  );
}

RatePulseHub.propTypes = {
  sortOptions: PropTypes.shape({
    sortBy: PropTypes.string,
    order: PropTypes.string,
  }),
  onViewDetail: PropTypes.func,
};

RatePulseHub.defaultProps = {
  sortOptions: sections[0].sortingTypes[0].sortOptions,
  onViewDetail: () => {},
};

function TapFlowAnalytics({ sortOptions, onViewDetail }) {
  const {
    data: apiData,
    isFetching: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/_link/prop_clicks',
  });

  const getDiff = (item) => {
    const numA = item.last_month;
    const numB = item.prev_month;

    const percentageInc = (numA - numB) / numB;

    let color = 'danger';
    let dir = 'down';

    if (percentageInc > 0) {
      color = 'success';
      dir = 'up';
    }

    if (numB > 0 && percentageInc !== 0) {
      return (
        <Badge bg={`soft-${color}`} className={`text-${color}`}>
          <i className={`bi-graph-${dir}-arrow`} />
          <span className="ms-1 fw-bold">
            <FormattedNumber
              value={percentageInc}
              // eslint-disable-next-line react/style-prop-object
              style="percent"
              maximumFractionDigits={0}
            />
          </span>
        </Badge>
      );
    }

    return null;
  };

  return (
    <>
      <RequestLoading loading={apiLoading} size="lg" margin="5" />
      <RequestResult type="error" message={apiError} />
      {!apiLoading && !apiError && !apiData && (
        <RequestResult type="secondary" message="app.common.noData" />
      )}
      {!apiLoading && !apiError && apiData && (
        <Row>
          {getSortedItems(apiData, sortOptions).map((item, index) => (
            <Col
              key={`${index.toString()}`}
              md="6"
              lg="4"
              xl="3"
              className="mb-3 mb-lg-5"
            >
              <Card className="h-100">
                <Card.Header>
                  <Card.Title
                    bsPrefix="card-header-title text-truncate d-flex align-items-center"
                    as="h5"
                    title={item.name}
                  >
                    <Badge pill className="me-2">
                      {index + 1}
                    </Badge>
                    {item.name}
                  </Card.Title>
                  <Card.Text
                    className="small text-truncate"
                    title={item.formatted_address}
                  >
                    {item.formatted_address || <span>&nbsp;</span>}
                  </Card.Text>
                  <Badge>
                    <FormattedMessage id="app.common.totalLinks" />:
                    <span className="ms-1">{`${item.co}`}</span>
                  </Badge>
                </Card.Header>
                <Card.Body>
                  <Row className="mb-3">
                    <Col>
                      <SmallCard titleKey="totalClicks">
                        <Card.Title
                          as="h2"
                          className="text-inherit d-flex align-items-center"
                        >
                          {item.total}
                        </Card.Title>
                      </SmallCard>
                    </Col>
                  </Row>
                  <Row className="col-sm-divider">
                    <Col sm="6">
                      <SmallCard titleKey="lastMonth">
                        <div className="d-flex align-items-center">
                          <Card.Title
                            as="h2"
                            className="text-inherit d-flex align-items-center mb-0 me-1"
                          >
                            {item.last_month}
                          </Card.Title>
                          {getDiff(item)}
                        </div>
                      </SmallCard>
                    </Col>
                    <Col sm="6">
                      <SmallCard titleKey="previousMonth">
                        {item.prev_month === 0 ? (
                          <Badge bg="soft-secondary" className="text-secondary">
                            <FormattedMessage id="app.common.n/a" />
                          </Badge>
                        ) : (
                          <Card.Title
                            as="h2"
                            className="text-inherit d-flex align-items-center"
                          >
                            {item.prev_month}
                          </Card.Title>
                        )}
                      </SmallCard>
                    </Col>
                  </Row>
                  <hr />
                  <div className="d-flex justify-content-center">
                    <Button
                      size="sm"
                      onClick={() => {
                        onViewDetail(item);
                      }}
                    >
                      <i className="bi-search me-1" />
                      <FormattedMessage id="app.common.viewDetails" />
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </>
  );
}

TapFlowAnalytics.propTypes = {
  sortOptions: PropTypes.shape({
    sortBy: PropTypes.string,
    order: PropTypes.string,
  }),
  onViewDetail: PropTypes.func,
};

TapFlowAnalytics.defaultProps = {
  sortOptions: sections[1].sortingTypes[0].sortOptions,
  onViewDetail: () => {},
};

function RetailRanger() {
  const { pack } = useContext(AuthContext);
  const { properties, activeProperty, updateActiveProperty } =
    useContext(PropertyContext);
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState(sections[0]);

  const initialSort = sections.reduce(
    (a, { titleKey, sortingTypes }) => ({ ...a, [titleKey]: sortingTypes[0] }),
    {}
  );

  const [activeSort, setActiveSort] = useState(initialSort);

  const getSortLabel = (
    titleKey,
    order,
    isActive,
    isAlpha = false,
    isDropdownMenu = false
  ) => {
    let dir = order;
    let affixKey = dir === 'desc' ? 'sortAffixHighLow' : 'sortAffixLowHigh';

    if (isAlpha) {
      dir = order === 'asc' ? 'desc' : 'asc';
      affixKey = dir === 'desc' ? 'sortAffixAZ' : 'sortAffixZA';
    }

    return (
      <div className="d-flex align-items-center">
        <i
          className={`bi-sort${isAlpha ? '-alpha' : ''}${
            dir === 'desc' ? '-down' : '-down-alt'
          } ${isActive ? 'text-primary' : ''} me-2 fs-4`}
        />
        <FormattedMessage id={`app.common.${titleKey}`} />

        <Badge bg="soft-primary" className="ms-1 mt-1 text-primary">
          <FormattedMessage id={`app.common.${affixKey}`} />
        </Badge>
        {isActive && isDropdownMenu && (
          <i className="bi-check-lg mt-1 ms-2 text-primary" />
        )}
      </div>
    );
  };

  const onViewDetail = (x) => {
    const founded = properties.find((item) => item.title === x.name);
    if (founded) {
      if (activeProperty.id !== founded.id) {
        updateActiveProperty(founded);
      }

      const link =
        activeSection.titleKey === sections[0].titleKey
          ? AppRoutes.protected.REPUTATION_MANAGER.children
              .REPUTATION_MANAGER_SUMMARY.path
          : AppRoutes.protected.REPUTATION_MANAGER.children
              .REPUTATION_MANAGER_REVIEW_GENERATOR.path;

      navigate(link);
    }
  };

  if (pack.usage.max_property < MIN_PROPERTY_REQUIREMENT) {
    return <NoAccessInfo />;
  }

  return (
    <>
      <Row className="mb-5">
        <Col xs="12">
          <div className="d-md-flex align-items-md-center justify-content-md-between">
            <Nav
              variant="segment"
              fill
              as="ul"
              activeKey={activeSection.titleKey}
              onSelect={(key) => {
                const founded = sections.find((item) => item.titleKey === key);
                setActiveSection(founded);
              }}
            >
              {sections.map(({ titleKey }) => (
                <Nav.Item key={titleKey} as="li">
                  <Nav.Link eventKey={titleKey}>
                    <FormattedMessage id={`app.common.${titleKey}`} />
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
            <Dropdown
              align="end"
              onSelect={(key) => {
                const founded = activeSection.sortingTypes.find(
                  (item) => item.id === key
                );
                setActiveSort((prevData) => ({
                  ...prevData,
                  [activeSection.titleKey]: founded,
                }));
              }}
              className="mt-3 mt-md-0"
              size="xs"
            >
              <Dropdown.Toggle
                as={Button}
                size="sm"
                variant="white"
                className="w-100 w-md-auto"
              >
                {getSortLabel(
                  activeSort[activeSection.titleKey].titleKey,
                  activeSort[activeSection.titleKey].sortOptions.order,
                  true,
                  activeSort[activeSection.titleKey].isAlpha
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu className="m-0" renderOnMount>
                {activeSection.sortingTypes.map((item) => (
                  <Dropdown.Item key={item.id} eventKey={item.id}>
                    {getSortLabel(
                      item.titleKey,
                      item.sortOptions.order,
                      item.id === activeSort[activeSection.titleKey].id,
                      item.isAlpha,
                      true
                    )}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Tab.Container activeKey={activeSection.titleKey}>
            <Tab.Content>
              {sections.map(({ titleKey, Component }) =>
                activeSection.titleKey === titleKey ? (
                  <Tab.Pane key={titleKey} eventKey={titleKey}>
                    <Component
                      sortOptions={
                        activeSort[activeSection.titleKey].sortOptions
                      }
                      onViewDetail={onViewDetail}
                    />
                  </Tab.Pane>
                ) : null
              )}
            </Tab.Content>
          </Tab.Container>
        </Col>
      </Row>
    </>
  );
}

export default RetailRanger;
