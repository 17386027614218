import React from 'react';
import PropTypes from 'prop-types';
import { Card, ProgressBar, Badge } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { BrandLogo, Rating } from '../../../../components';
import { Routes as AppRoutes } from '../../../../router/routeMapping';

function BrandRatingCard({ brand, totalReviews, ratings }) {
  const total = ratings.reduce((acc, obj) => acc + obj.co, 0);
  const all = ratings.reduce((acc, obj) => acc + obj.rating * obj.co, 0);
  const avarage = Math.round(all / total / 0.5) * 0.5 || 0;

  return (
    <Card className="h-100">
      <Card.Body className="text-center mb-1">
        <div
          className="d-flex justify-content-center align-items-center mb-3"
          style={{ height: 30 }}
        >
          <Link
            to={`${AppRoutes.protected.REPUTATION_MANAGER.children.REPUTATION_MANAGER_REVIEWS.path}?sources=${brand}`}
          >
            <BrandLogo brand={brand} />
          </Link>
        </div>
        <div>
          <Badge
            bg="light"
            className="d-inline-flex flex-wrap align-items-center justify-content-center my-1"
          >
            <Rating initVal={avarage} className="mx-1" />
            <span className="text-body mx-1">
              <FormattedMessage
                id="app.common.xOutOfY"
                values={{ x: avarage, y: 5 }}
              />
            </span>
          </Badge>
        </div>

        {/* <div>
          <span className="text-muted small mt-1">
            <i className="bi-star-fill me-1" />
            <FormattedMessage
              id="app.common.nCustomerRatings"
              values={{ count: total }}
            />
          </span>
        </div> */}

        <div>
          <span className="text-muted small mt-1">
            <i className="bi-person-fill me-1" />
            <FormattedMessage
              id="app.common.nCustomerReviews"
              values={{ count: totalReviews }}
            />
          </span>
        </div>

        <div className="d-flex flex-column gap-3 pt-4">
          {ratings
            .sort((a, b) => b.rating - a.rating)
            .map(({ co, rating }, nIndex) => (
              <div
                className="d-flex gap-3 align-items-center"
                key={`${nIndex.toString()}`}
              >
                <div className="text-body fs-6">
                  {rating} <FormattedMessage id="app.common.star" />
                </div>
                <div className="flex-grow-1">
                  <ProgressBar
                    now={(co / total) * 100}
                    color="primary"
                    style={{ height: '0.375rem' }}
                  />
                </div>
                <div className="text-body fs-6">{`${Math.round(
                  (co / total) * 100
                )}%`}</div>
              </div>
            ))}
        </div>
      </Card.Body>
    </Card>
  );
}

BrandRatingCard.propTypes = {
  brand: PropTypes.string.isRequired,
  totalReviews: PropTypes.number.isRequired,
  ratings: PropTypes.arrayOf(
    PropTypes.shape({ co: PropTypes.number, rating: PropTypes.number })
  ).isRequired,
};

export default BrandRatingCard;
