import React, { useContext } from 'react';
import { Row, Col, Form, InputGroup, Button, Badge } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffectOnce } from 'react-use';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PropertyContext } from '../../../context/PropertyContext';
import {
  BrandLogo,
  FormError,
  RequestLoading,
  RequestResult,
} from '../../../components';
import { useAxiosQuery } from '../../../hooks';
import { yup } from '../../../lib';

const brands = [
  { id: 'google', logo: 'google' },
  { id: 'yahoo', logo: 'yahoo' },
];

function Visibility() {
  const { formatMessage } = useIntl();
  const { activeProperty } = useContext(PropertyContext);

  const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: { errors, isSubmitted },
  } = useForm({
    resolver: yupResolver(
      yup
        .object({
          keyword: yup.string().required().min(3),
        })
        .required()
    ),
  });

  const { keyword } = watch();

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
    refetch: apiFetch,
  } = useAxiosQuery({
    url: `/detail/?url=${activeProperty?.web}&keywordPosition=1`,
    requestType: 'get',
    preventFetch: true,
    params: { keyword },
  });

  const handleOnSubmit = () => {
    apiFetch();
  };

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(handleOnSubmit);
    }
  };

  useEffectOnce(() => {
    reset({ keyword: '' });
  });

  return (
    <>
      <Row>
        <Col xs="12">
          <div className="mb-4">
            <Form noValidate onSubmit={handleSubmit(handleOnSubmit)}>
              <div className="d-flex flex-column flex-sm-row gap-2">
                <div className="flex-grow-1">
                  <InputGroup
                    className={`input-group-merge ${
                      isSubmitted && !!errors.keyword ? 'is-invalid' : ''
                    } ${isSubmitted && !errors.keyword ? 'is-valid' : ''}`}
                  >
                    <Form.Control
                      placeholder={formatMessage({
                        id: 'app.common.searchKeyword',
                      })}
                      id="keyword"
                      name="keyword"
                      onKeyDown={onKeyDown}
                      disabled={apiLoading}
                      {...register('keyword')}
                    />
                    {keyword ? (
                      <Button
                        variant=""
                        bsPrefix="input-group-append input-group-text"
                        onClick={() => {
                          reset({ keyword: '' });
                        }}
                        disabled={apiLoading}
                      >
                        <i className="bi-x-lg" />
                      </Button>
                    ) : (
                      <InputGroup.Text className="input-group-append">
                        <i className="bi-search" />
                      </InputGroup.Text>
                    )}
                  </InputGroup>
                  <FormError error={errors.keyword} className="mb-3 mb-sm-0" />
                </div>
                <div>
                  <Button type="submit" disabled={apiLoading} className="w-100">
                    <FormattedMessage id="app.common.searchKeyword" />
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </Col>
        <Col xs="12">
          <RequestLoading loading={apiLoading} size="lg" margin="5" />
          <RequestResult type="error" message={apiError} />
        </Col>
      </Row>
      {!apiLoading && !apiError && apiData && (
        <>
          <Row className="col-lg-divider mt-5">
            <Col>
              <h2 className="h4 mb-5">
                <FormattedMessage
                  id="app.common.resultsForKey"
                  values={{ key: apiData.keyword }}
                />
              </h2>
            </Col>
          </Row>
          <Row className="col-lg-divider mt-5">
            {brands.map(({ id, logo }, index) => {
              if (apiData[id]) {
                return (
                  <Col lg="4" key={`${id}_${index.toString()}`}>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <div className="mb-4">
                        <BrandLogo brand={logo} />
                      </div>
                      <div className="d-flex">
                        <div className="mx-1 text-center bg-soft-secondary p-2 rounded">
                          <span className="text-cap">
                            <FormattedMessage id="app.common.page" />
                          </span>
                          <span className="d-block display-5 mb-2">
                            {apiData[id].page || (
                              <FormattedMessage id="app.common.n/a" />
                            )}
                          </span>
                        </div>
                        <div className="mx-1 text-center bg-soft-secondary p-2 rounded">
                          <span className="text-cap">
                            <FormattedMessage id="app.common.rank" />
                          </span>
                          <span className="d-block display-5 mb-2">
                            {apiData[id].total_count || (
                              <FormattedMessage id="app.common.n/a" />
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              }
              return null;
            })}
            <Col lg="4">
              <div className="d-flex flex-column align-items-center justify-content-center">
                <span className="display-6 mb-5">
                  <FormattedMessage id="app.common.keywordSuggestions" />
                </span>
                <ul className="list-inline text-center">
                  {apiData.keywords_suggestion.map((item, index) => (
                    <li
                      key={`${index.toString()}`}
                      className="list-inline-item mb-1"
                    >
                      <a
                        href={`https://www.google.com/search?q=${encodeURIComponent(
                          item
                        )}`}
                        target="_blank"
                        rel="nofollow noreferrer"
                        className="h4"
                      >
                        <Badge>{item}</Badge>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default Visibility;
