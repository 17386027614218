import React, { useContext, useEffect, useState } from 'react';
import { Outlet, Navigate, useLocation, useParams } from 'react-router-dom';
import { kebabCase } from 'lodash';
import { PageHeader, RequestLoading } from '../../../../components';
import { Routes as AppRoutes } from '../../../../router/routeMapping';
import { PropertyContext } from '../../../../context/PropertyContext';

function EditProperty() {
  const { id } = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [property, setProperty] = useState();

  const menuData = AppRoutes.protected.PROPERTY_EDIT.children
    ? Object.values(AppRoutes.protected.PROPERTY_EDIT.children).map(
        ({ title, path }) => ({ title, href: path.replace(':id', id) })
      )
    : [];

  const activePage = menuData.find((page) => page.href === location.pathname);
  const { properties } = useContext(PropertyContext);

  useEffect(() => {
    const foundedProperty = properties.find(
      (item) => kebabCase(item.title) === id
    );
    setProperty(foundedProperty);
    setLoading(false);
  }, [properties, id]);

  return (
    <div className="content container">
      <RequestLoading loading={loading} size="lg" margin="5" />
      {!loading && !property && (
        <Navigate to={AppRoutes.public.NOT_FOUND.path} replace />
      )}

      {!loading && property && (
        <>
          <PageHeader
            title={property?.title}
            menuData={menuData}
            breadcrumbData={{
              current: activePage?.title,
              parents: [
                {
                  name: AppRoutes.protected.PROPERTY_EDIT.title,
                  href: AppRoutes.protected.PROPERTY_EDIT.path.replace(
                    ':id',
                    id
                  ),
                },
                {
                  name: property?.title,
                  href: AppRoutes.protected.PROPERTY_EDIT.path.replace(
                    ':id',
                    id
                  ),
                },
              ],
            }}
          />

          {location.pathname ===
          AppRoutes.protected.PROPERTY_EDIT.path.replace(':id', id) ? (
            <Navigate to={menuData[0]?.href} replace />
          ) : (
            <Outlet context={{ property }} />
          )}
        </>
      )}
    </div>
  );
}

export default EditProperty;
