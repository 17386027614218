export const SubscriptionTypes = {
  monthly: { id: 'monthly', short: 'month', active: false },
  yearly: { id: 'yearly', short: 'year', active: true },
};

export const SubscriptionPlans = [
  {
    id: 'Starter',
    apiKey: 'Starter',
    prices: {
      monthly: 49,
      yearly: 499,
    },
    isBilledYearly: true,
    forceMonthlyPrice: true,
    currency: 'USD',
    paymentLink: 'https://buy.stripe.com/eVa5mf1Ae45w7Cg28f',
  },
  {
    id: 'Pro',
    apiKey: 'Pro',
    prices: {
      monthly: 99,
      yearly: 999,
    },
    isBilledYearly: true,
    forceMonthlyPrice: true,
    currency: 'USD',
    paymentLink: 'https://buy.stripe.com/bIYdSL5Qu31s5u87sA',
  },
  {
    id: 'Business',
    apiKey: 'Business',
    prices: {
      monthly: 299,
      yearly: 2999,
    },
    isBilledYearly: true,
    forceMonthlyPrice: true,
    currency: 'USD',
    paymentLink: 'https://buy.stripe.com/dR67ungv8bxYe0E8wF',
  },
];
