import React, { useState, createContext, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Image, Toast, ToastContainer } from 'react-bootstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Utils from '../utils';
import PlaceHolderImg from '../assets/images/placeholder.png';

const MAX_ITEM = 3;

const ToastContext = createContext();

const onImageError = (e) => {
  e.target.src = PlaceHolderImg;
};

function ToastProvider({ children }) {
  const [que, setQue] = useState([]);
  const [toasts, setToasts] = useState([]);

  const removeToast = (id) => {
    const filtered = toasts.filter((item) => item.id !== id);

    if (que.length > 0) {
      const nQue = [...que];
      const firstOne = nQue.shift();
      setToasts([...filtered, firstOne]);
      setQue(nQue);
      return;
    }
    setToasts(filtered);
  };

  const addToast = useCallback(
    (opts) => {
      const id = Date.now();

      const toast = { id, ...opts };
      if (toasts.length >= MAX_ITEM) {
        setQue([...que, toast]);
      } else {
        setToasts([...toasts, toast]);
      }
    },
    [que, toasts]
  );

  const getIcon = (type) => {
    let icon = 'bi-exclamation-lg fs-2';
    switch (type) {
      case 'success':
        icon = 'bi-check-lg fs-2';
        break;

      default:
        break;
    }
    return icon;
  };

  const value = useMemo(
    () => ({
      addToast,
    }),
    [addToast]
  );

  return (
    <ToastContext.Provider value={value}>
      {children}
      <ToastContainer containerPosition="fixed" position="top-end">
        {toasts.map(
          ({ id, type, title, message, image, link, autohide, delay }) => (
            <Toast
              key={id}
              onClose={() => {
                removeToast(id);
              }}
              delay={delay}
              autohide={autohide}
              className="m-3"
            >
              <Toast.Header>
                <div className="d-flex align-items-center flex-grow-1">
                  <div className="flex-shrink-0">
                    {image ? (
                      <Image
                        src={image}
                        className="avatar avatar-sm avatar-circle"
                        onError={onImageError}
                      />
                    ) : (
                      <span className={`icon icon-sm icon-circle icon-${type}`}>
                        <i className={getIcon(type)} />
                      </span>
                    )}
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h5 className="mb-0">{title}</h5>
                    <small className="ms-auto">{moment().fromNow()}</small>
                  </div>
                </div>
              </Toast.Header>
              <Toast.Body>
                {message}
                {link && (
                  <Link
                    onClick={() => {
                      removeToast(id);
                    }}
                    className="stretched-link"
                    to={link}
                    target={
                      Utils.String.checkUrlProtocol(link) ? '_blank' : undefined
                    }
                    rel={
                      Utils.String.checkUrlProtocol(link)
                        ? 'nofollow noreferrer'
                        : undefined
                    }
                  />
                )}
              </Toast.Body>
            </Toast>
          )
        )}
      </ToastContainer>
    </ToastContext.Provider>
  );
}

ToastProvider.propTypes = {
  children: PropTypes.node,
};

ToastProvider.defaultProps = {
  children: null,
};

export { ToastContext, ToastProvider };
