import React, { useContext, useState } from 'react';
import { Card, ListGroup, Nav, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { kebabCase } from 'lodash';
import { RequestLoading, RequestResult } from '../../../components';
import { PropertyContext } from '../../../context/PropertyContext';
import { useAxiosQuery } from '../../../hooks';
import Utils from '../../../utils';
import { Routes as AppRoutes } from '../../../router/routeMapping';

const types = [
  { label: 'all', value: 'all' },
  { label: 'passed', value: '1' },
  { label: 'failed', value: '0' },
];

function Recommendations() {
  const [type, setType] = useState(types[0].value);

  const { activeProperty } = useContext(PropertyContext);

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: `/detail/?url=${Utils.String.trimUrl(
      activeProperty?.web
    )}&recommendation=1`,
    requestType: 'get',
    preventFetch: !activeProperty?.web,
  });

  return (
    <>
      <RequestLoading loading={apiLoading} size="lg" margin="5" />
      <RequestResult type="error" message={apiError} />
      <RequestResult
        type="secondary"
        image={{ withBorder: true }}
        title="emptyState.noWebsite.title"
        message={!activeProperty?.web ? 'emptyState.noWebsite.message' : null}
        action={{
          type: 'button',
          variant: 'primary',
          link: AppRoutes.protected.PROPERTY_EDIT.children.PROPERTY_EDIT_SETTINGS.path.replace(
            ':id',
            kebabCase(activeProperty.title)
          ),
          title: 'emptyState.noWebsite.action.title',
        }}
      />

      {!apiLoading && !apiError && activeProperty?.web && !apiData?.point && (
        <RequestResult
          type="secondary"
          title="emptyState.seoRecommendations.title"
          message="emptyState.seoRecommendations.message"
          image={{ withBorder: true }}
        />
      )}

      {!apiLoading && !apiError && apiData?.point && apiData?.recommendation && (
        <Card>
          <Card.Header>
            <Row className="justify-content-between align-items-center flex-grow-1">
              <Col md className="mb-3 mb-md-0 mb-lg-3 mb-xl-0">
                <Card.Title bsPrefix="card-header-title" as="h4">
                  <FormattedMessage id="app.common.recommendations" />
                </Card.Title>
              </Col>
              <Col xs="auto">
                <Row className="align-items-sm-center">
                  <Col sm="auto" className="mb-2 mb-md-0">
                    <Nav variant="segment" activeKey={type} onSelect={setType}>
                      {types.map((item) => (
                        <Nav.Item key={item.value}>
                          <Nav.Link eventKey={item.value}>
                            <FormattedMessage id={`app.common.${item.label}`} />
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <ListGroup variant="flush" className="list-group-no-gutters">
              {Object.keys(apiData.recommendation).map((key) => {
                if (
                  type === types[0].value ||
                  `${Number(apiData.recommendation[key].status)}` === type
                ) {
                  return (
                    <ListGroup.Item key={key}>
                      <div className="d-flex">
                        <div className="flex-grow-1 ms-3">
                          <div className="row align-items-center">
                            <div className="col">
                              <h5 className="mb-1">
                                {apiData.recommendation[key].message}
                              </h5>
                              <span className="d-block fs-6 text-body">
                                {apiData.recommendation[key].info}
                              </span>
                            </div>
                            <div className="col-auto">
                              {apiData.recommendation[key].status ? (
                                <span className="icon icon-circle icon-success">
                                  <i className="display-5 bi-check" />
                                </span>
                              ) : (
                                <span className="icon icon-circle icon-danger">
                                  <i className="display-5 bi-x" />
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </ListGroup.Item>
                  );
                }
                return null;
              })}
            </ListGroup>
          </Card.Body>
        </Card>
      )}
    </>
  );
}

export default Recommendations;
