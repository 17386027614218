import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Row,
  Col,
  Button,
  Modal,
  ListGroup,
  Badge,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useAxiosQuery } from '../../../../hooks';
import { RequestLoading, RequestResult, Tooltip } from '../../../../components';

function MozrankCard({ url }) {
  const [showModal, setShowModal] = useState(false);

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url,
    requestType: 'get',
    preventFetch: !showModal,
  });

  const closeModal = () => {
    setShowModal(false);
  };

  const handleCheck = () => {
    setShowModal(true);
  };

  return (
    <>
      <Card className="h-100">
        <Card.Body>
          <Row className="align-items-center gx-2">
            <Col>
              <Card.Subtitle className="mb-2">
                <FormattedMessage id="app.common.mozrank" />
                <Tooltip
                  content={
                    <FormattedMessage id="app.helpers.seoManager.overview.tooltips.mozrank" />
                  }
                >
                  <i className="bi-question-circle text-body ms-1" />
                </Tooltip>
              </Card.Subtitle>
              <Button variant="primary" size="xs" onClick={handleCheck}>
                <FormattedMessage id="app.common.check" />
              </Button>
            </Col>
            <Col xs="auto">
              <span className="icon icon-circle icon-primary">
                <i className="fs-4 bi-search" />
              </span>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={closeModal} scrollable size="lg">
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            <FormattedMessage id="app.common.mozrank" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RequestLoading loading={apiLoading} />
          <RequestResult type="error" message={apiError} />

          {!apiLoading && !apiError && apiData && (
            <ListGroup>
              {Object.keys(apiData).map((key) => (
                <ListGroup.Item
                  key={`mozrank_${key}`}
                  className="d-flex flex-column flex-sm-row"
                >
                  <div className="me-auto text-capitalize">
                    <i className="bi-globe text-muted me-2" />
                    {key.split('_').join(' ')}
                  </div>
                  <div className="d-flex align-items-center mt-2 mt-sm-0">
                    <Badge bg="soft-primary" text="primary">
                      {apiData[key]}
                    </Badge>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={closeModal} variant="white">
            <FormattedMessage id="app.common.close" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
MozrankCard.propTypes = {
  url: PropTypes.string.isRequired,
};

export default MozrankCard;
