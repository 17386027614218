import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select/async';
import useAxiosMutation from '../hooks/useAxiosMutation';
import RequestResult from './RequestResult';

function FormAsyncSelect({
  requestUrl,
  requestParams,
  requestType,
  requestInputKey,
  requestResultParser,
  defaultValue,
  size,
  disabled,
  value,
  onChange,
  isInvalid,
  isValid,
  readOnly,
  zIndex,
  placeholder,
  getOptionLabel,
  getOptionValue,
  isMulti,
  ...props
}) {
  let def = [];
  if (defaultValue) {
    def = isMulti ? defaultValue : [defaultValue];
  }

  const [defaultOptions, setDefaultOptions] = useState(def);
  const [lastOptions, setLastOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const { mutate } = useAxiosMutation({
    url: requestUrl,
    requestType,
    params: requestParams,
  });

  const apiFetch = useCallback(
    (input, callback) => {
      const params = requestInputKey ? { [requestInputKey]: input } : {};
      setError(null);
      setIsLoading(true);
      setLastOptions([]);

      mutate(params, {
        onSuccess: (data) => {
          const result = requestResultParser(data);
          setLastOptions(result);
          setIsLoading(false);
          if (callback) {
            callback(result);
          }
        },
        onError: (e) => {
          setError(e.message);
          setIsLoading(false);
          if (callback) {
            callback([]);
          }
        },
      });
    },
    [mutate, requestInputKey, requestResultParser]
  );

  const getError = () => <RequestResult type="error" message={error} />;

  useEffect(() => {
    if (!requestInputKey) {
      apiFetch('', setDefaultOptions);
    }
  }, [apiFetch, requestInputKey]);

  return (
    <ReactSelect
      className={`react-select-custom-container react-select-custom-form-item react-select-custom-${size} ${
        isInvalid ? 'is-invalid' : ''
      } ${isValid ? 'is-valid' : ''} ${
        readOnly ? 'react-select-custom-transparentWithBorder' : ''
      }`}
      styles={{
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex,
        }),
      }}
      classNamePrefix="react-select-custom"
      value={[...defaultOptions, ...lastOptions].find(
        (option) => getOptionValue(option) === value
      )}
      onChange={(nValue) => {
        let v = nValue;
        if (nValue) {
          v = isMulti
            ? nValue.map((x) => getOptionValue(x))
            : getOptionValue(nValue);
        }
        onChange(v);
      }}
      isDisabled={disabled || readOnly}
      isLoading={isLoading}
      placeholder={placeholder}
      loadOptions={apiFetch}
      defaultValue={defaultValue}
      defaultOptions={requestInputKey ? undefined : defaultOptions}
      components={
        readOnly
          ? {
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }
          : undefined
      }
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      isMulti={isMulti}
      isSearchable={!!requestInputKey}
      noOptionsMessage={error ? getError : undefined}
      {...props}
    />
  );
}

FormAsyncSelect.propTypes = {
  requestUrl: PropTypes.string,
  requestParams: PropTypes.objectOf(PropTypes.any),
  requestType: PropTypes.oneOf(['get', 'post']),
  requestInputKey: PropTypes.string,
  requestResultParser: PropTypes.func,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.number,
  ]),
  size: PropTypes.oneOf(['sm', 'lg']),
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  isInvalid: PropTypes.bool,
  isValid: PropTypes.bool,
  readOnly: PropTypes.bool,
  zIndex: PropTypes.number,
  placeholder: PropTypes.string,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  isMulti: PropTypes.bool,
};

FormAsyncSelect.defaultProps = {
  requestUrl: null,
  requestParams: {},
  requestType: 'post',
  requestInputKey: null,
  requestResultParser: (arr) => arr,
  defaultValue: undefined,
  size: 'lg',
  disabled: false,
  value: undefined,
  onChange: () => {},
  isInvalid: null,
  isValid: null,
  readOnly: false,
  zIndex: 1,
  placeholder: '',
  getOptionLabel: (obj) => obj.label,
  getOptionValue: (obj) => obj.value,
  isMulti: false,
};

export default FormAsyncSelect;
