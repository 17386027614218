import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import moment from 'moment';
import { LineChart } from '../../../../components/Charts';
import Utils from '../../../../utils';
import Constants from '../../../../constants';
import { ThemeContext } from '../../../../context/ThemeContext';

const options = {
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'day',
        displayFormats: {
          day: Constants.DateFormats.APP.Moment.Common,
        },
      },
    },
    y: {
      labelType: 'currency',
      min: null,
      max: null,
      ticks: {
        beginAtZero: false,
        stepSize: null,
        // callback: (value) => `$${value}`,
      },
    },
  },
};

function GraphView({ data, date }) {
  const { theme } = useContext(ThemeContext);

  const getDataForDates = (dates, nData) =>
    dates.map(
      (nDate) => nData.find((item) => item.date === nDate)?.price || null
    );

  const getDataset = (nData) => {
    const dates = Utils.Date.getDaysBetweenDates(date[0], date[1]).map((item) =>
      moment(item).format(Constants.DateFormats.API)
    );

    const compsData = [];
    Object.values(nData.compe).forEach((item, index) => {
      if (item.prices) {
        compsData.push({
          data: getDataForDates(dates, item.prices),
          label: item.title,
          ...Utils.Chart.getLineChartOptions(index + 1, theme, {
            borderWidth: 2,
            pointRadius: 4,
            pointHoverRadius: 4,
            segment: {
              borderDash: (ctx) =>
                ctx.p0.skip || ctx.p1.skip ? [4, 6] : undefined,
            },
            spanGaps: true,
          }),
        });
      }
    });

    return {
      labels: dates,
      datasets: [
        {
          data: getDataForDates(dates, nData.own.prices),
          label: nData.own.title,
          ...Utils.Chart.getLineChartOptions(0, theme, {
            borderWidth: 4,
            pointRadius: 8,
            pointHoverRadius: 8,
          }),
        },
        ...compsData,
      ],
    };
  };

  /* const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
      tooltipEl.style.borderRadius = '3px';
      tooltipEl.style.color = 'white';
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.transform = 'translate(-50%, 0)';
      tooltipEl.style.transition = 'all .1s ease';

      const table = document.createElement('table');
      table.style.margin = '0px';

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  const externalTooltipHandler = (context) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set Text
    if (tooltip.body) {
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body.map((b) => b.lines);

      const tableHead = document.createElement('thead');

      titleLines.forEach((title) => {
        const tr = document.createElement('tr');
        tr.style.borderWidth = 0;

        const th = document.createElement('th');
        th.style.borderWidth = 0;
        const text = document.createTextNode(title);

        th.appendChild(text);
        tr.appendChild(th);
        tableHead.appendChild(tr);
      });

      const tableBody = document.createElement('tbody');
      bodyLines.forEach((body, i) => {
        const colors = tooltip.labelColors[i];

        const span = document.createElement('span');
        span.style.background = colors.backgroundColor;
        span.style.borderColor = colors.borderColor;
        span.style.borderWidth = '2px';
        span.style.marginRight = '10px';
        span.style.height = '10px';
        span.style.width = '10px';
        span.style.display = 'inline-block';

        const tr = document.createElement('tr');
        tr.style.backgroundColor = 'inherit';
        tr.style.borderWidth = 0;

        const td = document.createElement('td');
        td.style.borderWidth = 0;

        const text = document.createTextNode(body);

        td.appendChild(span);
        td.appendChild(text);
        tr.appendChild(td);
        tableBody.appendChild(tr);
      });

      const tableRoot = tooltipEl.querySelector('table');

      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }

      // Add new children
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = `${positionX + tooltip.caretX}px`;
    tooltipEl.style.top = `${positionY + tooltip.caretY}px`;
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = `${tooltip.options.padding}px ${tooltip.options.padding}px`;
  }; */

  const getChartOptions = (nData) => {
    const currency =
      nData.own.prices[0]?.currency ||
      Object.values(nData.compe)[0]?.prices[0]?.currency ||
      '$';
    options.scales.y.ticks.callback = (value) => `${currency}${value}`;

    options.plugins = {};
    options.plugins.tooltip = {
      // enabled: false,
      // external: externalTooltipHandler,
      callbacks: {
        label: (context) => {
          let label = context.dataset.label || '';

          if (label) {
            label += ': ';
          }

          if (context.parsed.y !== null) {
            label += `${currency}${context.parsed.y}`;
          }
          return label;
        },
      },
    };

    return options;
  };

  return (
    <Card>
      <Card.Body>
        <div className="h-100 d-flex flex-column justify-content-center align-items-center">
          <LineChart
            data={getDataset(data)}
            options={getChartOptions(data)}
            height="30rem"
          />
        </div>
      </Card.Body>
    </Card>
  );
}

GraphView.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  date: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default GraphView;
