import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  InputGroup,
  Row,
  Col,
  Button,
  ListGroup,
  Badge,
  Spinner,
} from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAxiosQuery } from '../../../hooks';
import { FormError, RequestLoading, RequestResult } from '../../../components';
import { yup } from '../../../lib';

const googleUrl = 'https://www.google.com/search?oe=utf-8&pws=0&complete=0';

function ListItem({ item, keyword }) {
  const [location, setLocation] = useState();
  const [url, setUrl] = useState();

  const openInNewTab = (value) => {
    window.open(value, '_blank', 'nofollow noreferrer');
  };

  const { isFetching: apiLoading, error: apiError } = useAxiosQuery({
    url: 'https://services.periger.com/geogrid/uule.php',
    requestType: 'get',
    preventFetch: !keyword || !location,
    params: {
      location,
    },
    onSuccess: (data) => {
      setUrl(null);
      if (data?.uule) {
        const nUrl = `${googleUrl}&q=${encodeURIComponent(keyword)}&uule=${
          data.uule
        }`;
        setUrl(nUrl);
        openInNewTab(nUrl);
      }
    },
  });

  return (
    <ListGroup.Item
      as={!url ? 'button' : 'a'}
      href={url || undefined}
      target={url ? '_blank' : undefined}
      rel={url ? 'nofollow noreferrer' : undefined}
      active={false}
      onClick={
        !url
          ? () => {
              setLocation(item.description);
            }
          : undefined
      }
    >
      <div className="d-flex w-100 align-items-center">
        <div>
          <FormattedMessage id="app.common.check" />
        </div>
        <div className="ms-2">
          <Badge pill className="fs-6">
            <i className="bi-tag me-1" />
            {keyword}
          </Badge>
        </div>
        <div className="ms-2">
          <FormattedMessage id="app.common.in" />
        </div>
        <div className="ms-2">
          <Badge pill className="fs-6">
            <i className="bi-geo me-1" />
            {item.description}
          </Badge>
        </div>
        {apiLoading && !apiError && (
          <div className="ms-2">
            <Spinner animation="border" size="sm" className="me-1" />
            <FormattedMessage id="app.common.loading" />
          </div>
        )}
        {!apiLoading && apiError && (
          <div className="ms-2">
            <i className="bi-exclamation-triangle me-1" />
            <FormattedMessage id="app.common.somethingWentWrong" />
          </div>
        )}
      </div>
    </ListGroup.Item>
  );
}

ListItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  keyword: PropTypes.string.isRequired,
};

function SpotFinderPro() {
  const { formatMessage } = useIntl();
  const [apiLoading, setApiLoading] = useState(false);
  const [apiData, setApiData] = useState(null);
  const [apiError, setApiError] = useState();

  const autocompleteService = useRef();

  const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: { errors, isSubmitted, isSubmitSuccessful },
  } = useForm({
    resolver: yupResolver(
      yup
        .object({
          keyword: yup.string().required().min(3),
          location: yup.string().required().min(3),
        })
        .required()
    ),
  });

  const { keyword, location } = watch();

  const displaySuggestions = (suggestions, status) => {
    setApiLoading(false);

    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
      setApiData(suggestions);
    } else if (
      status === window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS
    ) {
      setApiData([]);
    } else {
      setApiError(status);
    }
  };

  const apiFetch = () => {
    if (!autocompleteService.current) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }

    autocompleteService.current.getPlacePredictions(
      {
        input: location,
        types: ['geocode'],
      },
      displaySuggestions
    );

    setApiLoading(true);
    setApiData(null);
    setApiError(null);
  };

  const handleOnSubmit = () => {
    apiFetch();
  };

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(handleOnSubmit);
    }
  };

  useEffect(() => {
    setApiData(null);
  }, [keyword, location]);

  return (
    <Row>
      <div className="mb-4">
        <Form noValidate onSubmit={handleSubmit(handleOnSubmit)}>
          <div className="d-flex flex-column flex-sm-row gap-2">
            <div className="flex-grow-1 mb-3 mb-sm-0">
              <Form.Label htmlFor="keyword">
                <FormattedMessage id="app.common.keyword" />
              </Form.Label>
              <InputGroup
                className={`input-group-merge ${
                  isSubmitted && !!errors.keyword ? 'is-invalid' : ''
                } ${isSubmitted && !errors.keyword ? 'is-valid' : ''}`}
              >
                <Form.Control
                  placeholder={formatMessage({
                    id: 'app.common.keyword',
                  })}
                  id="keyword"
                  name="keyword"
                  onKeyDown={onKeyDown}
                  disabled={apiLoading}
                  {...register('keyword')}
                />
                {keyword ? (
                  <Button
                    variant=""
                    bsPrefix="input-group-append input-group-text"
                    onClick={() => {
                      reset({ keyword: '', location });
                    }}
                    disabled={apiLoading}
                  >
                    <i className="bi-x-lg" />
                  </Button>
                ) : (
                  <InputGroup.Text className="input-group-append">
                    <i className="bi-tag" />
                  </InputGroup.Text>
                )}
              </InputGroup>
              <FormError error={errors.keyword} className="mb-3 mb-sm-0" />
            </div>
            <div className="flex-grow-1">
              <Form.Label htmlFor="location">
                <FormattedMessage id="app.common.location" />
              </Form.Label>
              <InputGroup
                className={`input-group-merge ${
                  isSubmitted && !!errors.location ? 'is-invalid' : ''
                } ${isSubmitted && !errors.location ? 'is-valid' : ''}`}
              >
                <Form.Control
                  placeholder={formatMessage({
                    id: 'app.common.location',
                  })}
                  id="location"
                  name="location"
                  onKeyDown={onKeyDown}
                  disabled={apiLoading}
                  {...register('location')}
                />
                {location ? (
                  <Button
                    variant=""
                    bsPrefix="input-group-append input-group-text"
                    onClick={() => {
                      reset({ location: '', keyword });
                    }}
                    disabled={apiLoading}
                  >
                    <i className="bi-x-lg" />
                  </Button>
                ) : (
                  <InputGroup.Text className="input-group-append">
                    <i className="bi-geo" />
                  </InputGroup.Text>
                )}
              </InputGroup>
              <FormError error={errors.location} className="mb-3 mb-sm-0" />
            </div>
            <div>
              <Form.Label>&nbsp;</Form.Label>
              <Button type="submit" disabled={apiLoading} className="w-100">
                <FormattedMessage id="app.common.search" />
              </Button>
            </div>
          </div>
        </Form>
      </div>
      <Col xs="12">
        <RequestLoading loading={apiLoading} size="lg" margin="5" />
        <RequestResult type="error" message={apiError} />
        {!isSubmitSuccessful && (
          <RequestResult
            type="secondary"
            title="emptyState.spotFinderPro.title"
            message="emptyState.spotFinderPro.message"
            image={{ withBorder: true }}
          />
        )}
        {!apiLoading && !apiError && apiData?.length === 0 && (
          <RequestResult type="secondary" message="app.common.noResultsFound" />
        )}
        {!apiLoading &&
          !apiError &&
          apiData?.length > 0 &&
          keyword?.length >= 3 &&
          location?.length >= 3 && (
            <ListGroup className="list-group-striped">
              {apiData.map((item) => (
                <ListItem key={item.place_id} item={item} keyword={keyword} />
              ))}
            </ListGroup>
          )}
      </Col>
    </Row>
  );
}

export default SpotFinderPro;
