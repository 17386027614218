import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  InputGroup,
  Dropdown,
  ButtonGroup,
  Badge,
  Modal,
} from 'react-bootstrap';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { useCopyToClipboard } from 'react-use';
import { lowerCase } from 'lodash';
import { QRCodeSVG } from 'qrcode.react';
import { useReactToPrint } from 'react-to-print';
import { toBlob } from 'html-to-image';
import { PropertyContext } from '../../../context/PropertyContext';
import { SmartTable, Tooltip } from '../../../components';
import { useConfirmModal, useFormModal, useToast } from '../../../hooks';
import { VisitMetricCharts } from './partials';
import { yup } from '../../../lib';
import Utils from '../../../utils';

const shortenedUrl = process.env.REACT_APP_SHORTENED_URL || '';

function UrlColumn({ value }) {
  const [show, setShow] = useState(undefined);
  const [copyState, copyToClipboard] = useCopyToClipboard();
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (copyState?.value) {
      setShow(false);
      setTimeout(() => {
        setShow(undefined);
      }, 100);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    }
  }, [copyState?.value]);

  return (
    <InputGroup className="input-group-merge">
      <Form.Control defaultValue={value} readOnly />
      <InputGroup.Text
        className="input-group-append"
        style={{ cursor: 'pointer' }}
        onClick={() => {
          copyToClipboard(value);
        }}
      >
        <Tooltip
          show={show}
          placement="top"
          content={
            <FormattedMessage
              id={`app.common.${isCopied ? 'copied' : 'copyToClipboard'}`}
            />
          }
        >
          <i className={`bi-${isCopied ? 'check' : 'clipboard'}`} />
        </Tooltip>
      </InputGroup.Text>
    </InputGroup>
  );
}

UrlColumn.propTypes = {
  value: PropTypes.string,
};
UrlColumn.defaultProps = {
  value: '',
};

function VisitMetrics() {
  const { activeProperty } = useContext(PropertyContext);
  const { formatMessage } = useIntl();
  const { form } = useFormModal();

  const tableRef = useRef();
  const chartsRef = useRef();

  const [detailIsVisible, setDetailIsVisible] = useState(false);
  const [activeDetailItem, setActiveDetailItem] = useState();

  const { confirm } = useConfirmModal({ confirmVariant: 'danger' });
  const { showToast } = useToast();

  const onCreateLink = async () => {
    const fields = [
      {
        cols: [
          {
            key: 'name',
            schema: yup.string().required(),
          },
        ],
      },
      {
        cols: [
          {
            key: 'url',
            apiKey: 'link',
            schema: yup.string().customUrl().required(),
          },
        ],
      },
    ];

    const formData = await form({
      title: formatMessage({ id: 'app.common.createNewLink' }),
      confirmLabel: 'create',
      requestUrl: '/_link/insert',
      requestParams: { property_id: activeProperty.id },
      fields,
      fetchOnStart: false,
    });

    if (formData) {
      tableRef.current.reload();
      chartsRef.current.reload();
    }
  };

  const onDeleteRow = useCallback(
    async (id, title) => {
      const isSuccess = await confirm({
        message: (
          <FormattedMessage
            id="app.common.areYouSureYouWantToDeleteXY"
            values={{
              x: title,
              y: lowerCase(formatMessage({ id: 'app.common.link' })),
            }}
          />
        ),
        requestUrl: '/_link/delete',
        requestParams: { id },
      });
      if (isSuccess) {
        tableRef.current.reload();
        chartsRef.current.reload();

        showToast({
          type: 'success',
          autohide: true,
          title: <FormattedMessage id="app.common.success" />,
          message: (
            <FormattedMessage
              id="app.common.theXWasDeletedSuccessfully"
              values={{
                x: lowerCase(formatMessage({ id: 'app.common.link' })),
              }}
            />
          ),
        });
      }
    },
    [confirm, formatMessage, showToast]
  );

  const columns = [
    {
      Header: <FormattedMessage id="app.common.name" />,
      accessor: 'name',
      Cell: ({ cell: { value } }) => value,
    },
    {
      Header: <FormattedMessage id="app.common.originalUrl" />,
      accessor: 'actual_link',
      Cell: ({ cell: { value } }) => value,
    },
    {
      Header: <FormattedMessage id="app.common.link" />,
      accessor: 'shortened',
      minWidth: 300,
      Cell: useCallback(
        ({ cell: { value } }) => (
          <UrlColumn value={`${shortenedUrl}/${value}`} />
        ),
        []
      ),
    },
    {
      Header: <FormattedMessage id="app.common.totalClicks" />,
      accessor: 'total',
      textAlign: 'end',
    },
    {
      Header: <FormattedMessage id="app.common.lastMonth" />,
      accessor: 'last_month',
      textAlign: 'end',
      Cell: useCallback(({ cell: { value }, row: { original } }) => {
        const numA = value;
        const numB = original.prev_month;

        const percentageInc = (numA - numB) / numB;

        let color = 'danger';
        let dir = 'down';

        if (percentageInc > 0) {
          color = 'success';
          dir = 'up';
        }

        return (
          <div className="d-flex w-100 align-items-start justify-content-end">
            <span className="me-1">{value}</span>
            {numB > 0 && percentageInc !== 0 && (
              <Badge bg={`soft-${color}`} className={`text-${color}`}>
                <i className={`bi-graph-${dir}-arrow`} />
                <span className="ms-1 fw-bold">
                  <FormattedNumber
                    value={percentageInc}
                    // eslint-disable-next-line react/style-prop-object
                    style="percent"
                    maximumFractionDigits={0}
                  />
                </span>
              </Badge>
            )}
          </div>
        );
      }, []),
    },
    {
      Header: <FormattedMessage id="app.common.previousMonth" />,
      accessor: 'prev_month',
      textAlign: 'end',
      Cell: useCallback(
        ({ cell: { value } }) =>
          value === 0 ? (
            <Badge bg="soft-secondary" className="text-secondary">
              <FormattedMessage id="app.common.n/a" />
            </Badge>
          ) : (
            value
          ),
        []
      ),
    },
    {
      accessor: 'id',
      textAlign: 'end',
      Cell: useCallback(
        ({ cell: { value }, row: { original } }) => (
          <Dropdown align="end">
            <ButtonGroup>
              <Button
                variant="white"
                size="sm"
                onClick={() => {
                  setActiveDetailItem(original);
                  setDetailIsVisible(true);
                }}
              >
                <i className="bi-eye me-1" />
                <FormattedMessage id="app.common.view" />
              </Button>
              <ButtonGroup>
                <Dropdown.Toggle
                  variant="white"
                  size="sm"
                  className="btn-icon dropdown-toggle-empty"
                />
              </ButtonGroup>
            </ButtonGroup>
            <Dropdown.Menu className="m-0" renderOnMount>
              <Dropdown.Header>
                <FormattedMessage id="app.common.actions" />
              </Dropdown.Header>
              <Dropdown.Item
                onClick={() => {
                  onDeleteRow(
                    value,
                    `${original.actual_link} (${original.name})`
                  );
                }}
              >
                <i className="bi-trash dropdown-item-icon text-danger" />
                <span className="text-danger">
                  <FormattedMessage id="app.common.delete" />
                </span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
        [onDeleteRow]
      ),
    },
  ];

  const printComponentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });

  const handleDownload = async () => {
    const blob = await toBlob(printComponentRef.current);
    const filename = `${activeDetailItem?.name}.png`;
    Utils.Dom.fileDownloadFromData(blob, filename);
  };

  return (
    <>
      <Row>
        <Col xs="12" className="mb-3 mb-lg-5">
          <VisitMetricCharts ref={chartsRef} />
        </Col>
        <Col xs="12">
          <Card>
            <Card.Header className="card-header-content-md-between">
              <Card.Title bsPrefix="card-header-title" as="h4">
                <FormattedMessage id="app.common.links" />
              </Card.Title>
              <Button
                onClick={() => {
                  onCreateLink();
                }}
              >
                <i className="bi-plus me-1" />
                <FormattedMessage id="app.common.createNewLink" />
              </Button>
            </Card.Header>
            <SmartTable
              ref={tableRef}
              columns={columns}
              requestUrl="/_link/list"
              requestParams={{ property_id: activeProperty?.id }}
              noDataOptions={{
                title: 'emptyState.visitMetrics.title',
                message: 'emptyState.visitMetrics.message',
              }}
            />
          </Card>
        </Col>
      </Row>
      <Modal
        show={detailIsVisible}
        onHide={() => {
          setDetailIsVisible(false);
        }}
        onExited={() => {
          setActiveDetailItem(null);
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h4">{activeDetailItem?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex gap-2 justify-content-center mb-3">
            <Button onClick={handlePrint}>
              <i className="bi-printer me-1" />
              <FormattedMessage id="app.common.print" />
            </Button>
            <Button variant="white" onClick={handleDownload}>
              <i className="bi-download me-1" />
              <FormattedMessage id="app.common.download" />
            </Button>
          </div>
          <div ref={printComponentRef}>
            <QRCodeSVG
              value={`${shortenedUrl}/${activeDetailItem?.shortened}`}
              // size={PRINT_AREA_WIDTH}
              size="100%"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setDetailIsVisible(false);
            }}
          >
            <FormattedMessage id="app.common.close" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default VisitMetrics;
