import React, { useContext } from 'react';
import { Card, Button, Dropdown } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { RequestLoading, RequestResult } from '../../../../components';
import { PropertyContext } from '../../../../context/PropertyContext';
import { useAxiosQuery } from '../../../../hooks';
import { Routes as AppRoutes } from '../../../../router/routeMapping';

const actions = [
  {
    icon: 'bi-eye-fill',
    titleKey: 'readUnreadReviews',
    textKey: 'readUnreadReviewsText',
    apiValueKey: 'unread',
    linkQuery: '/?status=0',
  },
  {
    icon: 'bi-emoji-frown-fill',
    titleKey: 'replyNegativeReviews',
    textKey: 'replyNegativeReviewsText',
    apiValueKey: 'unread_negative',
    linkQuery: '/?type=Negative&status=0',
  },
  {
    icon: 'bi-emoji-smile-fill',
    titleKey: 'replyPositiveReviews',
    textKey: 'replyPositiveReviewsText',
    apiValueKey: 'unread_positive',
    linkQuery: '/?type=Positive&status=0',
  },
];

function RecommendedActions() {
  const { activeProperty } = useContext(PropertyContext);

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/reputation_manager/review_info_box',
    preventFetch: !activeProperty?.id,
    params: {
      id: activeProperty?.id,
    },
  });

  const renderText = (msg) => <strong className="text-primary">{msg}</strong>;

  return (
    <Card className="h-100">
      <Card.Header className="card-header-content-between">
        <Card.Title bsPrefix="card-header-title" as="h4">
          <FormattedMessage id="app.common.recommendedActions" />
        </Card.Title>
        <Dropdown align="end" className="d-none">
          <Dropdown.Toggle
            bsPrefix="btn"
            variant="ghost-secondary"
            size="sm"
            className="btn-icon rounded-circle"
          >
            <i className="bi-three-dots-vertical" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="m-0" renderOnMount>
            <Dropdown.Item
              onClick={(ev) => {
                ev.preventDefault();
              }}
            >
              <i className="bi-gear dropdown-item-icon" />
              <span>Action Settings</span>
            </Dropdown.Item>
            <Dropdown.Item
              onClick={(ev) => {
                ev.preventDefault();
              }}
            >
              <i className="bi-app-indicator dropdown-item-icon" />
              <span>Push Notification</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Card.Header>
      <Card.Body>
        <RequestLoading loading={apiLoading} margin="5" />
        <RequestResult type="error" message={apiError} />
        {!apiLoading && !apiError && apiData && (
          <div className="list-group list-group-lg list-group-flush list-group-no-gutters">
            {actions.map((item, index) => (
              <div className="list-group-item" key={`${index.toString()}`}>
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <i className={`${item.icon} list-group-icon`} />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <div className="row align-items-center">
                      <div className="col">
                        <h4 className="mb-0">
                          <FormattedMessage
                            id={`app.common.${item.titleKey}`}
                          />
                        </h4>
                        <p className="fs-5 text-body mb-0">
                          {apiData[item.apiValueKey] ? (
                            <FormattedMessage
                              id={`app.helpers.reputationManager.overview.actions.${item.textKey}`}
                              values={{
                                n: apiData[item.apiValueKey],
                                strong: (msg) => renderText(msg),
                              }}
                            />
                          ) : (
                            <FormattedMessage id="app.common.noActionRequired" />
                          )}
                        </p>
                      </div>

                      {!!apiData[item.apiValueKey] && (
                        <div className="col-auto">
                          <Button
                            as={Link}
                            to={`${AppRoutes.protected.REPUTATION_MANAGER.children.REPUTATION_MANAGER_REVIEWS.path}${item.linkQuery}`}
                            variant="ghost-secondary"
                            size="sm"
                            className="btn-icon rounded-circle"
                          >
                            <i className="bi-chevron-right" />
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </Card.Body>
    </Card>
  );
}
export default RecommendedActions;
