import React, { useContext, useState } from 'react';
import { Card, Button, Dropdown } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { shuffle } from 'lodash';
import {
  BrandIcon,
  RequestLoading,
  RequestResult,
  Tooltip,
} from '../../../../components';
import { PropertyContext } from '../../../../context/PropertyContext';
import { useAxiosQuery } from '../../../../hooks';
import Constants from '../../../../constants';
import { BubbleChart, DoughnutChart } from '../../../../components/Charts';

const isBubble = false;

const bubbleOptions = {
  scales: {
    x: {
      display: false,
    },
    y: {
      display: false,
    },
  },
  plugins: {
    tooltip: {
      enabled: false,
    },
  },
};

const options = {
  layout: {
    padding: {
      left: 10,
      right: 10,
      top: 10,
      bottom: 10,
    },
  },
  plugins: {
    tooltip: {
      enabled: false,
    },
    legend: {
      labels: {
        generateLabels: (chart) => {
          const { data } = chart;
          if (data.labels.length && data.datasets.length) {
            const {
              labels: { pointStyle },
            } = chart.legend.options;

            const total = chart.data.datasets[0].data.reduce(
              (acc, obj) => acc + obj,
              0
            );

            return data.labels.map((label, i) => {
              const meta = chart.getDatasetMeta(0);
              const style = meta.controller.getStyle(i);

              return {
                text: `${label} - ${
                  chart.data.datasets[0].data[i]
                } (${Math.round(
                  (chart.data.datasets[0].data[i] * 100) / total
                )}%)`,
                fillStyle: style.backgroundColor,
                strokeStyle: style.borderColor,
                lineWidth: style.borderWidth,
                pointStyle,
                hidden: !chart.getDataVisibility(i),

                index: i,
              };
            });
          }
          return [];
        },
      },
    },
    datalabels: {
      padding: {
        left: 8,
        right: 8,
        top: 4,
        bottom: 4,
      },
      display: 'auto',
      anchor: 'center',
      formatter: (value, ctx) => {
        // const label = ctx.chart.data.labels[ctx.dataIndex];
        const dataArr = ctx.chart.data.datasets[0].data;
        const total = dataArr.reduce((acc, obj) => acc + obj, 0);
        return `${value} (${Math.round((value * 100) / total)}%)`;
      },
    },
  },
};

function ReviewSummary() {
  const { formatMessage } = useIntl();
  const { activeProperty } = useContext(PropertyContext);
  const [activeSite, setActiveSite] = useState(null);

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/reputation_manager/pie_platforms',
    preventFetch: !activeProperty?.id,
    params: {
      id: activeProperty?.id,
    },
  });

  const getRates = () => {
    if (!activeSite) {
      return apiData[0]?.rates || [];
    }
    const founded = apiData.find((item) => item.site === activeSite);

    return founded?.rates || [];
  };

  const getData = () => {
    const rates = getRates();

    if (isBubble) {
      const total = Object.values(rates).reduce((acc, obj) => acc + obj, 0);
      const datasets = [];
      const posArray = Array.from(
        { length: Object.keys(rates).length },
        (_, i) => (i + 1) * 10
      );
      const xArray = shuffle(posArray);
      const yArray = shuffle(posArray);

      Object.keys(rates).forEach((key, index) => {
        const type = formatMessage({
          id: `app.common.${Constants.Review.Types[key].labelKey}`,
        });
        const val = Math.round(rates[key]);
        const percent = Math.round((val * 100) / total);
        const datasetObj = {
          label: `${type} - ${val} (${percent}%)`,
          backgroundColor: [Constants.Review.Types[key].hexColor],
          data: [
            {
              x: xArray[index],
              y: yArray[index],
              r: percent,
              value: val,
            },
          ],
          borderWidth: 0,
          datalabels: {
            color:
              percent < 20 ? [Constants.Review.Types[key].hexColor] : '#ffffff',
          },
        };
        datasets.push(datasetObj);
      });

      return {
        datasets,
      };
    }

    const datasetObj = {
      backgroundColor: [],
      data: [],
      borderWidth: 0,
      datalabels: { color: [] },
    };

    const labels = [];
    Object.keys(rates).forEach((key) => {
      datasetObj.data.push(rates[key]);
      datasetObj.datalabels.color.push(Constants.Review.Types[key].hexColor);
      datasetObj.backgroundColor.push(Constants.Review.Types[key].hexColor);
      const type = formatMessage({
        id: `app.common.${Constants.Review.Types[key].labelKey}`,
      });
      labels.push(`${type}`);
    });
    return { labels, datasets: [datasetObj] };
  };

  const getSortedData = () =>
    apiData.sort((a, b) => a.site.localeCompare(b.site));

  return (
    <Card className="h-100">
      <Card.Header className="card-header-content-between">
        <Card.Title bsPrefix="card-header-title" as="h4">
          <FormattedMessage id="app.common.reviewSummary" />
          <Tooltip
            content={
              <FormattedMessage id="app.helpers.reputationManager.overview.tooltips.reviewSummary" />
            }
          >
            <i className="bi-question-circle text-body ms-1" />
          </Tooltip>
        </Card.Title>
        {!apiLoading && !apiError && apiData?.length > 0 && (
          <Dropdown align="end" onSelect={setActiveSite}>
            <Dropdown.Toggle as={Button} size="sm" variant="white">
              <BrandIcon
                brand={activeSite || getSortedData()[0]?.site}
                className="me-1"
              />
              {
                Constants.Review.Sites[
                  `${activeSite || getSortedData()[0].site}`
                ].label
              }
            </Dropdown.Toggle>
            <Dropdown.Menu className="m-0" renderOnMount>
              {getSortedData().map((item) => (
                <Dropdown.Item
                  key={item.site}
                  eventKey={item.site}
                  className="d-flex"
                >
                  <BrandIcon brand={item.site} className="me-1" />
                  {Constants.Review.Sites[item.site].label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </Card.Header>
      <Card.Body>
        <RequestLoading loading={apiLoading} margin="5" />
        <RequestResult type="error" message={apiError} />
        {!apiLoading && !apiError && apiData?.length === 0 && (
          <RequestResult
            type="secondary"
            title="emptyState.reviews.title"
            message="emptyState.reviews.message"
          />
        )}
        {!apiLoading && !apiError && apiData?.length > 0 && (
          <div className="h-100 w-100 d-flex flex-column justify-content-center align-items-center">
            {isBubble ? (
              <BubbleChart
                data={getData()}
                options={bubbleOptions}
                plugins={{ datalabels: true }}
              />
            ) : (
              <DoughnutChart
                data={getData()}
                options={options}
                plugins={{ datalabels: true }}
              />
            )}
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

export default ReviewSummary;
