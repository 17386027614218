import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Table, Badge } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { findAll } from 'highlight-words-core';
import { useAxiosQuery } from '../../../hooks';
import { PropertyContext } from '../../../context/PropertyContext';
import {
  CountryFlag,
  DateRangePicker,
  Rating,
  RequestLoading,
  RequestResult,
  ShowMoreText,
} from '../../../components';
import Constants from '../../../constants';
import { languages } from '../../../context/LangContext';
import { KeywordsSentiment } from './partials';

const columns = [
  {
    id: 'val',
    align: 'text-start',
  },
  {
    id: 'type',
  },
  { id: 'direction' },
  { id: 'mention' },
];

const dateFrom = moment().subtract(89, 'days');
const dateTo = moment();

const initialDates = [
  dateFrom.format(Constants.DateFormats.API),
  dateTo.format(Constants.DateFormats.API),
];

function Reviews({ loading, data, word }) {
  const getHighlightedText = (sText, fullText) => {
    const chunks = findAll({
      searchWords: Array.isArray(sText) ? sText : [sText],
      textToHighlight: fullText,
    });

    const highlightedText = chunks
      .map((chunk) => {
        const { end, highlight, start } = chunk;
        const t = fullText.substr(start, end - start);
        if (highlight) {
          return `<mark class="bg-primary text-white rounded px-2">${t}</mark>`;
        }
        return t;
      })
      .join('');

    return highlightedText;
  };

  if (loading) {
    return (
      <Card.Body>
        <RequestLoading loading={loading} margin="5" />
      </Card.Body>
    );
  }

  return (
    <Card.Body>
      {data?.length === 0 && (
        <RequestResult type="secondary" message="app.common.noData" />
      )}
      {data?.length > 0 && (
        <ul className="list-unstyled list-py-3 mb-0">
          {data.map((item, index) => (
            <li key={`${index.toString()}`}>
              <div className="d-flex gap-3 align-items-center">
                {/* <BrandIcon brand={item.site} /> */}
                <Rating initVal={item.rating} />
                <div>
                  <div className="fs-6 text-body mb-0">
                    <ShowMoreText
                      text={getHighlightedText(word, item.comment)}
                      maxLine={2}
                      basedOn="words"
                    />
                  </div>
                  <div>
                    <span className="h6">
                      {item.lang && Object.keys(languages)[0] !== item.lang && (
                        <CountryFlag
                          countryCode={item.lang}
                          svg
                          className="me-2"
                        />
                      )}
                      {item.title}
                    </span>
                    <span className="text-muted small">
                      ,{' '}
                      {moment(item.date).format(
                        Constants.DateFormats.APP.Moment.Common
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </Card.Body>
  );
}

Reviews.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  word: PropTypes.string,
};

Reviews.defaultProps = {
  loading: false,
  data: null,
  word: '',
};

function SentimentInsights() {
  const { activeProperty } = useContext(PropertyContext);

  const [date, setDate] = useState(initialDates);
  const [activeItem, setActiveItem] = useState();

  const {
    data: apiData,
    isFetching: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/reputation_manager/sentiment_most_used',
    preventFetch: !activeProperty?.id && !date,
    params: {
      id: activeProperty?.id,
      date,
    },
  });

  const handleChangeDate = (d) => {
    const nDate = [
      moment(d[0]).format(Constants.DateFormats.API),
      moment(d[1]).format(Constants.DateFormats.API),
    ];
    setDate(nDate);
  };

  const columnParser = (id, section, row) => {
    const value = section ? row[section][id] : row[id];

    const isPositive = row.nums[1].nums.positive >= row.nums[1].nums.negative;
    const selector = isPositive ? 'positive' : 'negative';
    const isHigher = isPositive
      ? row.nums[1].nums[selector] >= row.nums[0].nums[selector]
      : row.nums[1].nums[selector] < row.nums[0].nums[selector];

    const mentionDiff = row.nums[1].nums.mention - row.nums[0].nums.mention;
    let mentionDir = null;
    let mentionColor = null;
    if (mentionDiff > 0) {
      mentionDir = 'up';
      mentionColor = 'success';
    } else if (mentionDiff < 0) {
      mentionDir = 'down';
      mentionColor = 'danger';
    }

    let result;
    switch (id) {
      case 'val':
        result = <span className="text-capitalize">{value}</span>;
        break;

      case 'type':
        result = (
          <Badge
            bg={`soft-${
              Constants.Review.Types[isPositive ? 'Positive' : 'Negative'].color
            }`}
            className={`text-${
              Constants.Review.Types[isPositive ? 'Positive' : 'Negative'].color
            }`}
          >
            <i
              className={`${
                Constants.Review.Types[isPositive ? 'Positive' : 'Negative']
                  .icon
              } me-1`}
            />
            <FormattedMessage
              id={`app.common.${
                Constants.Review.Types[isPositive ? 'Positive' : 'Negative']
                  .labelKey
              }`}
            />
          </Badge>
        );
        break;

      case 'direction':
        result = isHigher ? (
          <span className="text-success">
            <i className="bi-graph-up-arrow" />
          </span>
        ) : (
          <span className="text-danger">
            <i className="bi-graph-down-arrow" />
          </span>
        );
        break;

      case 'mention':
        result = (
          <div className="d-flex flex-column">
            <span>
              <strong>{row.nums[1].nums.mention}</strong>
            </span>
            {mentionDiff !== 0 ? (
              <span className={mentionColor ? `text-${mentionColor}` : ''}>
                {mentionDir && <i className={`bi-arrow-${mentionDir}-short`} />}
                {Math.abs(mentionDiff)}
              </span>
            ) : (
              <i className="bi-arrow-left-right small" />
            )}
          </div>
        );
        break;

      default:
        result = value;
        break;
    }

    return result;
  };

  useEffect(() => {
    if (apiData?.reviews?.[0]?.val) {
      setActiveItem(apiData.reviews[0]);
    }
  }, [apiData]);

  return (
    <Row>
      {(apiLoading || apiError) && (
        <Col xs="12">
          <Card className="h-100">
            <Card.Body>
              <RequestLoading loading={apiLoading} size="lg" margin="5" />
              <RequestResult type="error" message={apiError} />
            </Card.Body>
          </Card>
        </Col>
      )}
      {!apiLoading &&
        !apiError &&
        (!apiData?.reviews || apiData?.reviews?.length === 0) && (
          <Col xs="12">
            <Card className="h-100">
              <Card.Header>
                <Card.Title bsPrefix="card-header-title" as="h4">
                  <FormattedMessage id="app.common.sentimentInsights" />
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <RequestResult
                  type="secondary"
                  title="emptyState.sentimentInsights.title"
                  message="emptyState.sentimentInsights.message"
                  image
                />
              </Card.Body>
            </Card>
          </Col>
        )}
      {!apiLoading && !apiError && apiData?.reviews?.length > 0 && (
        <>
          <Col xl="6" className="mb-3 mb-xl-5">
            <Card className="h-100">
              <Card.Header className="card-header-content-between">
                <Card.Title bsPrefix="card-header-title" as="h4">
                  <FormattedMessage id="app.common.keywords" />
                </Card.Title>
                <DateRangePicker
                  type="button"
                  initialSettings={{
                    initialDates: {
                      startDate: initialDates[0],
                      endDate: initialDates[1],
                    },
                  }}
                  value={date}
                  onChange={handleChangeDate}
                  disabled={apiLoading}
                />
              </Card.Header>

              <Table
                responsive
                hover
                className="table-thead-bordered table-nowrap table-align-middle card-table"
              >
                <tbody>
                  {apiData.reviews?.map((row, rowIndex) => (
                    <tr
                      key={`row_${rowIndex.toString()}`}
                      onClick={() => {
                        setActiveItem(row);
                      }}
                      role="button"
                      className={row.val === activeItem?.val ? 'bg-light' : ''}
                    >
                      {columns.map(({ id, section, align }, index) => (
                        <td
                          key={`row_${rowIndex.toString()}_col_${id}_${index.toString()}`}
                          className={align || 'text-end'}
                        >
                          {columnParser(id, section, row)}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </Col>
          <Col xl="6">
            <Row className="h-100">
              <Col xs="12" className="mb-3 mb-lg-5">
                <KeywordsSentiment
                  loading={apiLoading}
                  word={activeItem?.val}
                  data={activeItem?.nums}
                  date={date}
                  propertyId={activeProperty?.id}
                />
              </Col>
              <Col xs="12" className="mb-3 mb-lg-5">
                <Card className="h-100">
                  <Card.Header>
                    <FormattedMessage id="app.common.recentReviews" />
                  </Card.Header>
                  <Reviews
                    loading={apiLoading}
                    word={activeItem?.val}
                    data={activeItem?.nums?.last5}
                  />
                </Card>
              </Col>
            </Row>
          </Col>
        </>
      )}
    </Row>
  );
}

export default SentimentInsights;
