import { useCallback, useState } from 'react';

const BASE_API =
  'https://content-searchconsole.googleapis.com/webmasters/v3/sites';

export const useGoogleSearchConsoleApi = (token, options) => {
  const onComplete = options?.onComplete || null;

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = useCallback(
    async (siteUrl, query = {}) => {
      if (!siteUrl) {
        setError('app.common.missingSiteUrl');
        return;
      }
      setLoading(true);
      try {
        const response = await fetch(
          `${BASE_API}/${encodeURIComponent(siteUrl)}/searchAnalytics/query`,
          {
            method: 'POST',
            headers: new Headers({
              Authorization: `Bearer ${token}`,
              Accept: 'application/json',
            }),
            body: JSON.stringify(query),
          }
        );

        const result = await response.json();
        setData(result);
        if (onComplete) {
          onComplete(result);
        }
      } catch (e) {
        setError(e);
      }
      setLoading(false);
    },
    [token, onComplete]
  );

  return {
    apiData: data,
    apiLoading: loading,
    apiError: error,
    apiFetch: fetchData,
  };
};

export default useGoogleSearchConsoleApi;
