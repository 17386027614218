import React, { forwardRef, useContext, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { ThemeContext } from '../../../../context/ThemeContext';
import { PropertyContext } from '../../../../context/PropertyContext';
import { useAxiosQuery } from '../../../../hooks';
import { RequestLoading, RequestResult, Tooltip } from '../../../../components';
import { LineChart } from '../../../../components/Charts';
import Utils from '../../../../utils';
import Constants from '../../../../constants';

const options = {
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'day',
        displayFormats: {
          day: Constants.DateFormats.APP.Moment.Common,
        },
      },
    },
    y: {
      min: 0,
      max: 5,
      ticks: {
        beginAtZero: true,
        stepSize: 1,
      },
    },
  },
};

const VisitsByDay = forwardRef(({ date }, ref) => {
  const { theme } = useContext(ThemeContext);
  const { activeProperty } = useContext(PropertyContext);

  const {
    data: apiData,
    isFetching: apiLoading,
    error: apiError,
    refetch,
  } = useAxiosQuery({
    url: '/_link/list_by_day',
    preventFetch: !date || !activeProperty?.id,
    params: {
      id: activeProperty?.id,
      start: date?.[0],
      end: date?.[1],
    },
  });

  const getDataset = (data) => {
    const dates = Utils.Date.getDaysBetweenDates(date[0], date[1]).map((item) =>
      moment(item).format(Constants.DateFormats.API)
    );

    return {
      labels: dates,
      datasets: [
        {
          data: data.map((item) => item.views),
          ...Utils.Chart.getLineChartOptions(0, theme),
        },
      ],
    };
  };

  useImperativeHandle(ref, () => ({
    reload: refetch,
  }));

  return (
    <div>
      <h4 className="mb-4">
        <FormattedMessage id="app.common.visitsByDay" />
        <Tooltip
          content={
            <FormattedMessage id="app.helpers.reputationManager.visitMetrics.tooltips.visitsByDay" />
          }
        >
          <i className="bi-question-circle text-body ms-1" />
        </Tooltip>
      </h4>
      <RequestLoading loading={apiLoading} margin="5" />
      <RequestResult type="error" message={apiError} />
      {!apiLoading && !apiError && apiData?.length === 0 && (
        <RequestResult
          type="secondary"
          title="emptyState.visitsByDay.title"
          message="emptyState.visitsByDay.message"
          image
        />
      )}
      {!apiLoading && !apiError && apiData?.length > 0 && (
        <div className="h-100 d-flex flex-column justify-content-center align-items-center">
          <LineChart data={getDataset(apiData)} options={options} />
        </div>
      )}
    </div>
  );
});

VisitsByDay.propTypes = {
  date: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default VisitsByDay;
