export const domainCards = [
  { id: 'domain_created', title: 'domainCreated', icon: 'bi-calendar-check' },
  { id: 'domain_expire', title: 'domainExpire', icon: 'bi-calendar-x' },
  { id: 'published_time', title: 'publishedTime', icon: 'bi-clock' },
  { id: 'modified_time', title: 'modifedTime', icon: 'bi-clock-history' },
];

export const seoCards = [
  { id: 'status', title: 'status', type: 'ap' },
  { id: 'schema', title: 'schema', type: 'bool' },
  { id: 'amp', title: 'ampCompatibility', type: 'bool' },
  { id: 'google', title: 'googleAnalytics', type: 'bool' },
  { id: 'captcha', title: 'googleCaptcha', type: 'bool' },
  { id: 'google_business_tag', title: 'googleBusinessTag', type: 'bool' },
  { id: 'responsive', title: 'responsive', type: 'bool' },
  { id: 'robots_txt', title: 'robotsTxt', type: 'bool' },
  { id: 'sitemap', title: 'sitemap', type: 'bool' },
  { id: 'ssl_defined', title: 'sslDefined', type: 'bool' },
  { id: 'suspicious', title: 'suspiciousDomain', type: 'bool', flip: true },
  { id: 'twitter_tag', title: 'twitterTag', type: 'bool' },
  { id: 'og_tag', title: 'openGraphTag', type: 'bool' },
];

export const propertyListOpenGraph = [
  {
    key: 'og_image',
    title: 'og_image',
    type: 'image',
  },
  {
    key: 'og_sitename',
    title: 'og_sitename',
    type: 'text',
  },
  {
    key: 'og_tag',
    title: 'og_tag',
    type: 'bool',
  },
  {
    key: 'og_title',
    title: 'og_title',
    type: 'text',
  },
];

export const propertyListGeneralInfo = [
  {
    key: 'url',
    title: 'URL',
    type: 'link',
  },
  {
    key: 'og_title',
    title: 'Page Title',
    type: 'text',
  },
  {
    key: 'cms',
    title: 'CMS',
    type: 'text',
  },
  {
    key: 'contact_form',
    title: 'Contact Form',
    type: 'link',
  },
  {
    key: 'canonical',
    title: 'Canonical URL',
    type: 'link',
  },
  {
    key: 'designed_by',
    title: 'Designed by',
    type: 'text',
  },
  {
    key: 'phone',
    title: 'Phone',
    type: 'text',
  },
  {
    key: 'fax',
    title: 'Fax',
    type: 'text',
  },
];

export const propertyListSearchEngine = [
  {
    key: 'google_console',
    title: 'Google Console',
    type: 'bool',
  },
  {
    key: 'google_verification',
    title: 'Google Verification',
    type: 'bool',
  },
  {
    key: 'yandex_verification',
    title: 'Yandex Verification',
    type: 'bool',
  },
  {
    key: 'bing_yahoo_verification',
    title: 'Bing / Yahoo Verification',
    type: 'bool',
  },
];

export const propertyListDomain = [
  {
    key: 'ip',
    title: 'IP',
    type: 'text',
  },
  {
    key: 'country',
    title: 'Country',
    type: 'text',
  },
  {
    key: 'isp',
    title: 'ISP',
    type: 'text',
  },
];
