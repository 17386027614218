import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Dropdown, ListGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Constants from '../../../../../constants';
import { useConfirmModal, useFormModal } from '../../../../../hooks';
import Utils from '../../../../../utils';
import { yup } from '../../../../../lib';
import { PropertyContext } from '../../../../../context/PropertyContext';

function SocialAccounts({ property }) {
  const { updateProperty } = useContext(PropertyContext);
  const { confirm } = useConfirmModal({ confirmVariant: 'danger' });
  const { form } = useFormModal();

  const onConnectItem = async (id, title, url) => {
    const fields = [
      {
        cols: [
          {
            key: 'url',
            schema: yup.string().customUrl().required(),
          },
        ],
      },
    ];

    const formData = await form({
      title,
      confirmLabel: 'save',
      requestUrl: '/social/update',
      requestParams: { property_id: property.id, type: id },
      defaultValues: { url },
      fields,
      fetchOnStart: false,
    });

    if (formData) {
      const nProperty = { ...property };

      const founded = nProperty.PlatformsSocial.find(
        (platform) => platform.site === id
      );
      if (founded) {
        nProperty.PlatformsSocial = nProperty.PlatformsSocial.map((platform) =>
          platform.site === id ? formData.Result : platform
        );
      } else {
        nProperty.PlatformsSocial.push(formData.Result);
      }

      updateProperty(nProperty);
    }
  };

  const onDisconnectItem = async (id, title) => {
    const isSuccess = await confirm({
      message: (
        <FormattedMessage
          id="app.common.areYouSureYouWantToDisconnectX"
          values={{
            x: title,
          }}
        />
      ),
      requestUrl: '/social/update',
      requestParams: { property_id: property.id, type: id, url: '' },
    });

    if (isSuccess) {
      const nProperty = { ...property };
      nProperty.PlatformsSocial = nProperty.PlatformsSocial.filter(
        (platform) => platform.site !== id
      );

      updateProperty(nProperty);
    }
  };

  const getSortedSites = () => {
    const data =
      property.PlatformsSocial?.length > 0 ? property.PlatformsSocial : [];
    const founded = Object.values(Constants.Social.Sites).filter((o1) =>
      data.some((o2) => o1.id === o2.site)
    );
    const others = Object.values(Constants.Social.Sites).filter(
      (o1) => !data.some((o2) => o1.id === o2.site)
    );
    return [...founded, ...others];
  };

  const sortedSites = getSortedSites();

  return (
    <ListGroup variant="flush" className="list-group-lg list-group-no-gutters">
      {sortedSites.map((item) => {
        const { id, label, icon, color } = item;
        const founded = property.PlatformsSocial.find(
          (platform) => platform.site === id
        );
        const url = founded ? Utils.String.withHttps(founded.url) : '';
        const colorClass = url ? '' : 'text-muted';
        const colorStyle = url ? color : '';
        return (
          <ListGroup.Item key={id}>
            <div className="d-flex">
              <div className="flex-shrink-0">
                <i
                  className={`list-group-icon ${icon} ${colorClass}`}
                  style={{ color: colorStyle }}
                />
              </div>
              <div className="flex-grow-1">
                <div className="row align-items-center">
                  <div className="col-sm mb-2 mb-sm-0">
                    <h4
                      className={`mb-0 ${colorClass}`}
                      style={{ color: colorStyle }}
                    >
                      {label}
                    </h4>
                    {url ? (
                      <a
                        className="fs-5 text-break"
                        href={url}
                        target="_blank"
                        rel="nofollow noreferrer"
                      >
                        {url}
                      </a>
                    ) : (
                      <FormattedMessage id="app.common.notConnected" />
                    )}
                  </div>

                  <div className="col-sm-auto">
                    {url ? (
                      <Dropdown align="end">
                        <ButtonGroup>
                          <Button
                            variant="white"
                            size="sm"
                            onClick={() => {
                              onDisconnectItem(id, label);
                            }}
                          >
                            <i className="bi-ban me-1" />
                            <FormattedMessage id="app.common.disconnect" />
                          </Button>
                          <ButtonGroup>
                            <Dropdown.Toggle
                              variant="white"
                              size="sm"
                              className="btn-icon dropdown-toggle-empty"
                            />
                          </ButtonGroup>
                        </ButtonGroup>
                        <Dropdown.Menu className="m-0" renderOnMount>
                          <Dropdown.Item
                            onClick={() => {
                              onConnectItem(id, label, url);
                            }}
                          >
                            <i className="bi-pencil dropdown-item-icon" />
                            <span>
                              <FormattedMessage id="app.common.edit" />
                            </span>
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item
                            href={url}
                            target="_blank"
                            rel="nofollow noreferrer"
                          >
                            <i className="bi-box-arrow-up-right dropdown-item-icon" />
                            <span>
                              <FormattedMessage id="app.common.visitProfile" />
                            </span>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      <Button
                        variant="white"
                        size="sm"
                        onClick={() => {
                          onConnectItem(id, label);
                        }}
                      >
                        <FormattedMessage id="app.common.connect" />
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
}

SocialAccounts.propTypes = {
  property: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default SocialAccounts;
