import React, { useState, useMemo, createRef } from 'react';
import { Row, Col, Navbar, Nav, Card } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useWindowSize } from 'react-use';
import useScrollSpy from 'react-use-scrollspy';
import {
  SettingsPhoto,
  SettingsBasicInfo,
  SettingsPassword,
  SettingsRecentDevices,
} from './partials';

const sections = [
  {
    titleKey: 'basicInformation',
    icon: 'bi-person',
    Component: () => (
      <Card.Body>
        <SettingsBasicInfo />
      </Card.Body>
    ),
  },
  {
    titleKey: 'password',
    extraTitleKey: 'changeYourPassword',
    icon: 'bi-key',
    Component: () => (
      <Card.Body>
        <SettingsPassword />
      </Card.Body>
    ),
  },
  {
    titleKey: 'recentDevices',
    icon: 'bi-phone',
    Component: () => <SettingsRecentDevices />,
  },
];

const TOP_MARGIN = 20;

function Settings() {
  const { width } = useWindowSize();
  const [expanded, setExpanded] = useState(false);

  const sectionRefs = useMemo(() => {
    const refs = [];
    sections.forEach(() => {
      refs.push(createRef(null));
    });
    return refs;
  }, []);

  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -(TOP_MARGIN + 3),
  });

  // const scrollTo = (ref) => ref.current.scrollIntoView({ behavior: 'smooth' });

  const scrollTo = (ref) => {
    const element = ref.current;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - TOP_MARGIN;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  return (
    <Row>
      <Col lg="3">
        <Navbar
          as="div"
          variant=""
          expand=""
          className="navbar-vertical navbar-expand-lg sticky-lg-top mb-3 mb-lg-5 zi-1"
          style={{ top: width >= 992 ? TOP_MARGIN : 0 }}
          expanded={expanded}
          bsPrefix=" "
        >
          <div className="d-grid">
            <Navbar.Toggle
              aria-controls="settingsMenu"
              aria-expanded={expanded}
              className="navbar-toggler btn btn-white btn-lg mb-3"
              onClick={() => {
                setExpanded(!expanded);
              }}
              bsPrefix=" "
            >
              <span className="d-flex justify-content-between align-items-center">
                <span className="text-dark">
                  <FormattedMessage id="app.common.sections" />
                </span>

                <span className="navbar-toggler-default">
                  <i className="bi-list" />
                </span>

                <span className="navbar-toggler-toggled">
                  <i className="bi-x" />
                </span>
              </span>
            </Navbar.Toggle>
          </div>
          <Navbar.Collapse id="settingsMenu">
            <Nav
              variant="tabs"
              className="nav nav-vertical card card-navbar-nav nav-lg"
              as="ul"
              navbar={false}
            >
              {sections.map((item, index) => (
                <Nav.Item as="li" key={`${index.toString()}`}>
                  <Nav.Link
                    // href={`section_${index.toString()}`}
                    onClick={() => {
                      scrollTo(sectionRefs[index]);
                    }}
                    active={activeSection === index}
                  >
                    <i className={`${item.icon} nav-icon`} />
                    <FormattedMessage id={`app.common.${item.titleKey}`} />
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Col>
      <Col lg="9">
        <div className="d-flex flex-column w-100">
          {sections.map(({ titleKey, extraTitleKey, Component }, index) => (
            <div
              className="mb-3 mb-lg-5"
              id={`section_${index.toString()}`}
              key={`${index.toString()}`}
              ref={sectionRefs[index]}
            >
              {index === 0 && <SettingsPhoto />}
              <Card>
                <Card.Header>
                  <Card.Title as="h2" className="h4">
                    <FormattedMessage
                      id={`app.common.${extraTitleKey || titleKey}`}
                    />
                  </Card.Title>
                </Card.Header>
                <Component />
              </Card>
            </div>
          ))}
        </div>
      </Col>
    </Row>
  );
}

export default Settings;
