import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, ButtonGroup, Dropdown, ListGroup } from 'react-bootstrap';
import Utils from '../../../../../utils';
import { PropertyContext } from '../../../../../context/PropertyContext';
import { useConfirmModal, useFormModal } from '../../../../../hooks';
import { yup } from '../../../../../lib';

function Website({ property }) {
  const { formatMessage } = useIntl();
  const propertyUrl = property.web ? Utils.String.withHttps(property.web) : '';
  const { updateProperty } = useContext(PropertyContext);
  const { confirm } = useConfirmModal({ confirmVariant: 'danger' });
  const { form } = useFormModal();

  const onConnectItem = async (id, title, url) => {
    const fields = [
      {
        cols: [
          {
            key: 'url',
            schema: yup.string().customUrl().required(),
          },
        ],
      },
    ];

    const formData = await form({
      title,
      confirmLabel: 'save',
      requestUrl: '/websites/update',
      requestParams: { property_id: property.id, type: 'website' },
      defaultValues: { url },
      fields,
      fetchOnStart: false,
    });

    if (formData) {
      const nProperty = { ...property };
      nProperty.web = formData.Result.url;

      updateProperty(nProperty);
    }
  };

  const onDisconnectItem = async () => {
    const isSuccess = await confirm({
      message: (
        <FormattedMessage
          id="app.common.areYouSureYouWantToDisconnectX"
          values={{
            x: formatMessage({ id: 'app.common.website' }),
          }}
        />
      ),
      requestUrl: '/websites/update',
      requestParams: { property_id: property.id, type: 'website', url: '' },
    });

    if (isSuccess) {
      const nProperty = { ...property };
      nProperty.web = '';

      updateProperty(nProperty);
    }
  };

  const url = propertyUrl ? Utils.String.withHttps(propertyUrl) : '';
  const icon = 'bi-globe';
  const colorClass = url ? 'text-primary' : 'text-muted';

  return (
    <ListGroup variant="flush" className="list-group-lg list-group-no-gutters">
      <ListGroup.Item>
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0">
            <i className={`list-group-icon ${icon} ${colorClass}`} />
          </div>
          <div className="flex-grow-1">
            <div className="row align-items-center">
              <div className="col-sm mb-2 mb-sm-0">
                {url ? (
                  <a
                    className="fs-5 text-break"
                    href={url}
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    {url}
                  </a>
                ) : (
                  <FormattedMessage id="app.common.notConnected" />
                )}
              </div>

              <div className="col-sm-auto">
                {url ? (
                  <Dropdown align="end">
                    <ButtonGroup>
                      <Button
                        variant="white"
                        size="sm"
                        onClick={() => {
                          onDisconnectItem('website', 'website');
                        }}
                      >
                        <i className="bi-ban me-1" />
                        <FormattedMessage id="app.common.disconnect" />
                      </Button>
                      <ButtonGroup>
                        <Dropdown.Toggle
                          variant="white"
                          size="sm"
                          className="btn-icon dropdown-toggle-empty"
                        />
                      </ButtonGroup>
                    </ButtonGroup>
                    <Dropdown.Menu className="m-0" renderOnMount>
                      <Dropdown.Item
                        onClick={() => {
                          onConnectItem('website', 'website', url);
                        }}
                      >
                        <i className="bi-pencil dropdown-item-icon" />
                        <span>
                          <FormattedMessage id="app.common.edit" />
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item
                        href={url}
                        target="_blank"
                        rel="nofollow noreferrer"
                      >
                        <i className="bi-box-arrow-up-right dropdown-item-icon" />
                        <span>
                          <FormattedMessage id="app.common.openWebsite" />
                        </span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <Button
                    variant="white"
                    size="sm"
                    onClick={() => {
                      onConnectItem('website', 'website');
                    }}
                  >
                    <FormattedMessage id="app.common.connect" />
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </ListGroup.Item>
    </ListGroup>
  );
}

Website.propTypes = {
  property: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Website;
