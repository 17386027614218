import React from 'react';
import PropTypes from 'prop-types';
import ReactCountryFlag from 'react-country-flag';

const langCodeToCountryCodeMap = {
  en: { langCode: 'en', countryCode: 'us' },
  hi: { langCode: 'hi', countryCode: 'in' },
  zh: { langCode: 'zh', countryCode: 'cn' },
  bn: { langCode: 'bn', countryCode: 'bd' },
  ja: { langCode: 'ja', countryCode: 'jp' },
};

function CountryFlag({ countryCode, svg, className }) {
  let code = countryCode;

  if (langCodeToCountryCodeMap[countryCode]) {
    code = langCodeToCountryCodeMap[countryCode].countryCode;
  }

  return (
    <ReactCountryFlag countryCode={code} svg={svg} className={className} />
  );
}

CountryFlag.propTypes = {
  countryCode: PropTypes.string.isRequired,
  svg: PropTypes.bool,
  className: PropTypes.string,
};

CountryFlag.defaultProps = {
  svg: true,
  className: '',
};

export default CountryFlag;
