import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form } from 'react-bootstrap';
import { useEffectOnce } from 'react-use';
import ReactSelect from 'react-select';
import { FormattedMessage } from 'react-intl';
import { useGoogleManagementApi } from '../../../../../hooks/Google';
import { RequestLoading, RequestResult } from '../../../../../components';

function AnalyticsAccountSelect({ token, onChange }) {
  const [activeAccount, setActiveAccount] = useState(null);

  const [properties, setProperties] = useState([]);
  const [activeProperty, setActiveProperty] = useState(null);

  const [views, setViews] = useState([]);
  const [activeView, setActiveView] = useState(null);

  const { apiData, apiLoading, apiError, apiFetch } = useGoogleManagementApi(
    token,
    {
      onComplete: (data) => {
        if (data.items?.length > 0) {
          setActiveAccount(data.items[0]);
        }
      },
    }
  );

  useEffect(() => {
    setProperties([]);
    setActiveProperty(null);
    setViews([]);
    setActiveView(null);

    if (activeAccount) {
      setProperties(activeAccount.webProperties);
    }
  }, [activeAccount]);

  useEffect(() => {
    setActiveProperty(null);
    setViews([]);
    setActiveView(null);
    if (properties?.length > 0) {
      setActiveProperty(properties[0]);
    }
  }, [properties]);

  useEffect(() => {
    if (activeProperty) {
      setViews(activeProperty?.profiles);
    }
  }, [activeProperty]);

  useEffect(() => {
    setActiveView(null);
    if (views?.length > 0) {
      setActiveView(views[0]);
    }
  }, [views]);

  useEffect(() => {
    onChange(activeView);
  }, [activeView, onChange]);

  useEffectOnce(() => {
    apiFetch('/accountSummaries');
  });

  return (
    <Row>
      <Col>
        <RequestLoading loading={apiLoading} size="lg" margin="5" />
        <RequestResult type="error" message={apiError} />
        {!apiLoading && !apiError && apiData?.items?.length > 0 && (
          <Row>
            <Col md="4" className="mb-3">
              <Form.Label>
                <FormattedMessage id="app.common.accounts" />
              </Form.Label>
              <ReactSelect
                isSearchable={false}
                options={apiData.items}
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => `${option.id}`}
                className="react-select-custom-container"
                classNamePrefix="react-select-custom"
                value={activeAccount}
                onChange={setActiveAccount}
              />
            </Col>

            <Col md="4" className="mb-3">
              <Form.Label>
                <FormattedMessage id="app.common.properties" />
              </Form.Label>
              <ReactSelect
                isSearchable={false}
                options={properties || []}
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => `${option.id}`}
                className="react-select-custom-container"
                classNamePrefix="react-select-custom"
                value={activeProperty}
                onChange={setActiveProperty}
              />
            </Col>

            <Col md="4" className="mb-3">
              <Form.Label>
                <FormattedMessage id="app.common.views" />
              </Form.Label>
              <ReactSelect
                isSearchable={false}
                options={views || []}
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => `${option.id}`}
                className="react-select-custom-container"
                classNamePrefix="react-select-custom"
                value={activeView}
                onChange={setActiveView}
              />
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
}

AnalyticsAccountSelect.propTypes = {
  token: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

AnalyticsAccountSelect.defaultProps = {
  onChange: () => {},
};

export default AnalyticsAccountSelect;
