import React, { useContext, useState } from 'react';
import { Button, Col, Row, Spinner, Card, Modal } from 'react-bootstrap';
import { useOutletContext } from 'react-router-dom';
import { ReactSortable } from 'react-sortablejs';
// import { Sortable, MultiDrag } from 'sortablejs';
import { FormattedMessage, useIntl } from 'react-intl';
import { lowerCase } from 'lodash';
import { RequestResult, SmartForm } from '../../../../components';
import { useConfirmModal } from '../../../../hooks';
import { yup } from '../../../../lib';
import { PropertyContext } from '../../../../context/PropertyContext';

// Sortable.mount(new MultiDrag());

const requestLoading = false;
const requestError = null;

function Competitors() {
  const { formatMessage } = useIntl();
  const { property } = useOutletContext();
  const { updateProperty } = useContext(PropertyContext);
  const [editGroupItem, setEditGroupItem] = useState(null);
  const [groupModalShow, setGroupModalShow] = useState(false);
  const { confirm } = useConfirmModal({ confirmVariant: 'danger' });

  const handleGroupModalClose = () => {
    setEditGroupItem(null);
    setGroupModalShow(false);
  };
  const handleGroupModalShow = () => setGroupModalShow(true);

  const onUpdateGroups = (id, items) => {
    // comp içindeki grup id yi update etmek gerek
    const nProperty = { ...property };
    nProperty.groups = nProperty.groups.map((group) => {
      const nGroup = group;
      if (nGroup.id === id) {
        nGroup.comps = items;
      }
      return nGroup;
    });

    updateProperty(nProperty);
  };

  const onDeleteGroup = async (group) => {
    const isSuccess = await confirm({
      message: (
        <FormattedMessage
          id="app.common.areYouSureYouWantToDeleteXY"
          values={{
            x: group.name,
            y: lowerCase(formatMessage({ id: 'app.common.compset' })),
          }}
        />
      ),
      requestUrl: '/groups/delete',
      requestParams: { id: group.id },
    });

    if (isSuccess) {
      const nProperty = { ...property };
      const nGroups = nProperty.groups.map((item, index) => {
        const nItem = item;
        if (index === 0) {
          nItem.comps = [...nItem.comps, ...group.comps];
        }
        return nItem;
      });
      nProperty.groups = nGroups.filter((item) => item.id !== group.id);
      updateProperty(nProperty);
    }
  };

  const onCreateGroup = (data) => {
    if (data.Result) {
      const nProperty = { ...property };
      nProperty.groups = [...nProperty.groups, data.Result];
      updateProperty(nProperty);
    }
    handleGroupModalClose();
  };

  const onUpdateGroup = (data) => {
    if (data.Result) {
      const nProperty = { ...property };

      nProperty.groups = nProperty.groups.map((item) =>
        item.id === data.Result.id ? data.Result : item
      );
      updateProperty(nProperty);
    }
    handleGroupModalClose();
  };

  const fields = [
    {
      cols: [
        {
          key: 'name',
          schema: yup.string().required(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'color',
          type: 'color',
          schema: yup.string().required(),
        },
      ],
    },
    /* {
      cols: [
        {
          key: 'notification',
          type: 'switch',
          label: <FormattedMessage id="app.common.allowNotifications" />,
          schema: yup.boolean(),
        },
      ],
    }, */
  ];

  return (
    <>
      <div>
        {requestLoading && (
          <div className="text-center m-3">
            <Spinner animation="border" size="sm" />
          </div>
        )}
        <RequestResult type="error" message={requestError} />
        <Row className="mb-3">
          <Col xs="12" className="d-flex justify-content-end">
            <Button onClick={handleGroupModalShow}>
              <i className="bi-plus me-1" />
              <FormattedMessage id="app.common.createANewGroup" />
            </Button>
          </Col>
          <Col xs="12">
            <hr className="my-3" />
          </Col>
        </Row>
        <div className="kanban-board">
          <ul className="row list-unstyled kanban-board-row mx-0">
            {property.groups.map((group) => (
              <li key={group.id} className="col-12 h-100 position-relative">
                <div
                  className="d-flex justify-content-between align-items-center mb-3"
                  style={{ minHeight: 30 }}
                >
                  <h6 className="text-cap mb-0">{group.name}</h6>
                  <div>
                    <Button
                      size="xs"
                      variant="white"
                      className="btn-icon me-1"
                      onClick={() => {
                        setEditGroupItem(group);
                        handleGroupModalShow();
                      }}
                    >
                      <i className="bi-pencil" />
                    </Button>
                    <Button
                      size="xs"
                      variant="danger"
                      className="btn-icon"
                      onClick={() => {
                        onDeleteGroup(group);
                      }}
                    >
                      <i className="bi-trash" />
                    </Button>
                  </div>
                </div>
                {group.comps.length === 0 && (
                  <div className="position-absolute top-50 start-50 translate-middle zi-1">
                    <FormattedMessage id="app.common.empty" />
                  </div>
                )}

                <ReactSortable
                  multiDrag={false}
                  group="competitors"
                  sort={false}
                  list={group.comps}
                  setList={(items) => {
                    console.log('setList', items);
                    onUpdateGroups(group.id, items);
                  }}
                  onEnd={(e) => {
                    console.log('onEnd', e);
                  }}
                  className={`h-100 js-sortable ${
                    group.comps.length > 0
                      ? ''
                      : 'card card-dashed shadow-none bg-transparent'
                  }`}
                  style={{ minHeight: group.comps.length > 0 ? 0 : 100 }}
                >
                  {group.comps.map((item) => (
                    <div
                      key={item.id}
                      className="js-sortable-link sortablejs-custom sortablejs-custom-rotate sortablejs-custom-handle"
                    >
                      <Card className="mb-3 overflow-hidden">
                        <div className="card-progress-wrap">
                          <div className="progress card-progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{
                                width: '100%',
                                backgroundColor: group.color,
                              }}
                              aria-valuenow={100}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            />
                          </div>
                        </div>
                        <Card.Body>
                          <div className="d-block h5 text-inherit mb-1">
                            {item.name}
                          </div>
                          <div className="d-block fs-6 text-body">
                            {item.fullAddress}
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                </ReactSortable>
                <div
                  className={`d-grid ${group.comps.length === 0 ? 'mt-3' : ''}`}
                >
                  <Button variant="white" className="btn-dashed-outline">
                    <i className="bi-plus me-1" />
                    <FormattedMessage id="app.common.addCompetitor" />
                  </Button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Modal
        show={groupModalShow}
        onHide={handleGroupModalClose}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            {editGroupItem ? (
              <FormattedMessage id="app.common.edit" />
            ) : (
              <FormattedMessage id="app.common.createANewGroup" />
            )}
          </Modal.Title>
        </Modal.Header>
        <SmartForm
          fields={fields}
          defaultValues={editGroupItem || { color: '#000000' }}
          isModal
          onModalHide={handleGroupModalClose}
          submitButtonText={editGroupItem ? 'save' : 'createANewGroup'}
          requestUrl={editGroupItem ? '/groups/update' : '/groups/add'}
          requestParams={
            editGroupItem
              ? { property_id: property.id, id: editGroupItem.id }
              : { property_id: property.id }
          }
          onRequestSuccess={(data) => {
            if (editGroupItem) {
              onUpdateGroup(data);
            } else {
              onCreateGroup(data);
            }
          }}
        />
      </Modal>
    </>
  );
}

export default Competitors;
