import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Form,
  InputGroup,
  ListGroup,
  Card,
  Alert,
  Carousel,
  Button,
} from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { cloneDeep, debounce } from 'lodash';
import SimpleBar from 'simplebar-react';
import { RequestLoading, RequestResult } from '../../../../../components';
import Constants from '../../../../../constants';

function SelectYourPropertyStep({ onNext, onLoading, value, onChange }) {
  const scrollerRef = useRef();
  const [activeCarouselIndex, setActiveCarouselIndex] = useState(0);
  const [activeProperty, setActiveProperty] = useState();
  const { formatMessage } = useIntl();
  const [apiLoading, setApiLoading] = useState(false);
  const [apiData, setApiData] = useState();
  const [apiError, setApiError] = useState();
  const [titleQuery, setTitleQuery] = useState('');
  const [placeTypeQuery, setPlaceTypeQuery] = useState('');

  const autocompleteService = useRef();

  const displaySuggestions = (suggestions, status) => {
    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
      setApiData(suggestions);
    } else if (
      status === window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS
    ) {
      setApiData([]);
    } else {
      setApiError(status);
    }
    setApiLoading(false);
  };

  const apiFetch = () => {
    if (!autocompleteService.current) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }

    autocompleteService.current.getPlacePredictions(
      {
        input: titleQuery,
        types: ['establishment'],
      },
      displaySuggestions
    );
    setApiLoading(true);
  };

  const resetData = (clearTitle = true) => {
    if (clearTitle) {
      setTitleQuery('');
    }
    setApiLoading(false);
    setApiData(null);
    setApiError(null);
  };

  const onSearch = debounce((title) => {
    if (title === '') {
      resetData();
      return;
    }
    if (title?.length > 2) {
      resetData(false);
      apiFetch();
    }
  }, 100);

  const handleOnSelect = (item) => {
    const arrToCheck = ['store', 'point_of_interest', 'establishment'];

    const isBlocked =
      arrToCheck.sort().join(',') === item.types.sort().join(',');

    if (isBlocked) {
      setActiveProperty(item);
      setActiveCarouselIndex(1);
      return;
    }
    onChange(item);
    onNext(item);
  };

  const resetPlaceTypeSection = () => {
    setActiveCarouselIndex(0);
    setTimeout(() => {
      if (scrollerRef?.current?.getScrollElement()) {
        scrollerRef.current.getScrollElement().scrollTop = 0;
      }
      setActiveProperty(null);
      setPlaceTypeQuery('');
    }, 600);
  };

  const handleOnPlaceTypeSelect = (placeTypeId) => {
    const item = cloneDeep(activeProperty);
    item.types = [placeTypeId, ...activeProperty.types];

    resetPlaceTypeSection();
    onChange(item);
    onNext(item);
  };

  useEffect(() => {
    onLoading(apiLoading);
  }, [onLoading, apiLoading]);

  return (
    <Row className="justify-content-lg-center py-10">
      <Col lg="8">
        <Alert variant="soft-primary" className="mb-5">
          <div className="d-flex">
            <div className="flex-shrink-0">
              <i className="bi-exclamation-triangle-fill" />
            </div>
            <div className="flex-grow-1 ms-2">
              <FormattedMessage
                id={`app.helpers.propertySelect.selectYourProperty${
                  activeCarouselIndex === 1 ? '.selectYourPlaceType' : ''
                }.info`}
              />
            </div>
          </div>
        </Alert>
        <Carousel
          controls={false}
          indicators={false}
          activeIndex={activeCarouselIndex}
        >
          <Carousel.Item>
            <div className="position-relative">
              <RequestResult type="error" message={apiError} className="mb-3" />
              <div>
                <InputGroup className="input-group-merge">
                  <InputGroup.Text className="input-group-prepend">
                    <i className="bi-search" />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder={formatMessage({
                      id: 'app.common.searchProperties',
                    })}
                    value={titleQuery}
                    onChange={(e) => {
                      setTitleQuery(e.target.value);
                      onSearch(e.target.value);
                    }}
                  />
                  {titleQuery.length > 0 && (
                    <InputGroup.Text
                      as="button"
                      className="input-group-append"
                      onClick={resetData}
                    >
                      <i className="bi-x-lg" />
                    </InputGroup.Text>
                  )}
                </InputGroup>
              </div>
              <div className="mt-3">
                <RequestLoading loading={apiLoading} size="lg" margin="5" />
                {!apiLoading && !apiError && apiData?.length === 0 && (
                  <RequestResult
                    type="secondary"
                    message="app.common.noResultsFound"
                  />
                )}
                {!apiLoading && !apiError && apiData?.length > 0 && (
                  <Card>
                    <Card.Body className="p-0">
                      <SimpleBar style={{ maxHeight: 365 }}>
                        <ListGroup variant="flush">
                          {apiData.map((item) => (
                            <ListGroup.Item
                              key={item.place_id}
                              onClick={() => {
                                handleOnSelect(item);
                              }}
                              action
                            >
                              <div className="d-flex w-100 align-items-center justify-content-between">
                                <div>
                                  <div className="d-block h5 text-inherit mb-1">
                                    {item.structured_formatting?.main_text}
                                  </div>
                                  <div className="d-block fs-6 text-body">
                                    {item.structured_formatting?.secondary_text}
                                  </div>
                                </div>
                                {item.place_id === value?.place_id && (
                                  <div className="ms-2">
                                    <span className="icon icon-xs icon-primary icon-circle">
                                      <i className="bi-check-lg" />
                                    </span>
                                  </div>
                                )}
                              </div>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </SimpleBar>
                    </Card.Body>
                  </Card>
                )}
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="position-relative">
              <Row>
                {activeProperty && (
                  <Col xs="12" className="mb-3">
                    <Alert variant="soft-secondary">
                      <Row className="justify-content-center justify-content-sm-between align-items-sm-center">
                        <Col sm className="mb-2 mb-md-0">
                          <div className="d-block h5 text-inherit mb-1">
                            {activeProperty.structured_formatting?.main_text}
                          </div>
                          <div className="d-block fs-6 text-body">
                            {
                              activeProperty.structured_formatting
                                ?.secondary_text
                            }
                          </div>
                        </Col>
                        <Col sm="auto" className="d-flex">
                          <Button
                            onClick={() => {
                              resetPlaceTypeSection();
                            }}
                          >
                            <FormattedMessage id="app.common.changeProperty" />
                          </Button>
                        </Col>
                      </Row>
                    </Alert>
                  </Col>
                )}
                <Col xs="12" className="mb-3">
                  <div className="position-relative">
                    <div>
                      <InputGroup className="input-group-merge">
                        <InputGroup.Text className="input-group-prepend">
                          <i className="bi-search" />
                        </InputGroup.Text>
                        <Form.Control
                          placeholder={formatMessage({
                            id: 'app.common.filterCategories',
                          })}
                          value={placeTypeQuery}
                          onChange={(e) => {
                            setPlaceTypeQuery(e.target.value);
                          }}
                        />
                        {placeTypeQuery.length > 0 && (
                          <InputGroup.Text
                            as="button"
                            className="input-group-append"
                            onClick={() => {
                              setPlaceTypeQuery('');
                            }}
                          >
                            <i className="bi-x-lg" />
                          </InputGroup.Text>
                        )}
                      </InputGroup>
                    </div>
                    <div className="mt-3">
                      <Card>
                        <Card.Body className="p-0">
                          <SimpleBar
                            ref={scrollerRef}
                            style={{ maxHeight: 268 }}
                          >
                            <ListGroup variant="flush">
                              {Constants.Google.PlaceTypes.filter(
                                (item) => item.indexOf(placeTypeQuery) > -1
                              ).map((item) => (
                                <ListGroup.Item
                                  key={item}
                                  onClick={() => {
                                    handleOnPlaceTypeSelect(item);
                                  }}
                                  action
                                >
                                  <div className="d-flex w-100 align-items-center justify-content-between">
                                    <div>
                                      <div className="d-block h5 text-inherit mb-1">
                                        <FormattedMessage
                                          id={`app.googlePlaceTypes.${item}`}
                                        />
                                      </div>
                                    </div>
                                    {item === value?.types?.[0] && (
                                      <div className="ms-2">
                                        <span className="icon icon-xs icon-primary icon-circle">
                                          <i className="bi-check-lg" />
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </ListGroup.Item>
                              ))}
                            </ListGroup>
                          </SimpleBar>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Carousel.Item>
        </Carousel>
      </Col>
    </Row>
  );
}

SelectYourPropertyStep.propTypes = {
  onNext: PropTypes.func,
  onLoading: PropTypes.func,
  value: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
};

SelectYourPropertyStep.defaultProps = {
  onNext: () => {},
  onLoading: () => {},
  value: undefined,
  onChange: () => {},
};

export default SelectYourPropertyStep;
