import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { DateRangePicker } from '../../../../components';
import Constants from '../../../../constants';
import VisitsByDay from './VisitsByDay';
import VisitsByOs from './VisitsByOs';

const dateFrom = moment().subtract(89, 'days');
const dateTo = moment();

const initialDates = [
  dateFrom.format(Constants.DateFormats.API),
  dateTo.format(Constants.DateFormats.API),
];

const VisitMetricCharts = forwardRef((props, ref) => {
  const visitsByDayRef = useRef();
  const visitsByOsRef = useRef();

  const [date, setDate] = useState(initialDates);
  const [loading, setLoading] = useState(false);

  const handleChangeDate = (d) => {
    setLoading(true);
    const nDate = [
      moment(d[0]).format(Constants.DateFormats.API),
      moment(d[1]).format(Constants.DateFormats.API),
    ];
    setDate(nDate);
    setLoading(false);
  };

  useImperativeHandle(ref, () => ({
    reload: () => {
      visitsByDayRef.current.reload();
      visitsByOsRef.current.reload();
    },
  }));

  return (
    <Card>
      <Card.Header>
        <Row className="justify-content-between align-items-center flex-grow-1">
          <Col md className="mb-3 mb-md-0">
            <Card.Title bsPrefix="card-header-title" as="h4">
              <FormattedMessage id="app.common.metrics" />
            </Card.Title>
          </Col>
          <Col xs="auto">
            <Row className="align-items-sm-center">
              <Col sm="auto" className="mb-2 mb-sm-0">
                <Row className="align-items-center gx-0">
                  <Col>
                    <span className="text-secondary me-2">
                      <FormattedMessage id="app.common.date" />:
                    </span>
                  </Col>
                  <Col xs="auto">
                    <DateRangePicker
                      type="button"
                      initialSettings={{
                        initialDates: {
                          startDate: initialDates[0],
                          endDate: initialDates[1],
                        },
                      }}
                      value={date}
                      onChange={handleChangeDate}
                      disabled={loading}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col xl="8">
            <VisitsByDay date={date} ref={visitsByDayRef} />
          </Col>
          <Col xl="4">
            <VisitsByOs date={date} ref={visitsByOsRef} />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
});

export default VisitMetricCharts;
