import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, Card, Col, ProgressBar, Row } from 'react-bootstrap';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import _, { kebabCase } from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  NoAccessInfoComponentLevel,
  PageHeader,
  RequestLoading,
  RequestResult,
  Tooltip,
} from '../../components';
import {
  RecentReviews,
  TopKeywords,
  RatingSummary,
  KeywordsSentiment,
} from './ReputationManager/partials';
import { ScoreCard } from './SeoManager/partials';
import { useAxiosQuery } from '../../hooks';
import { PropertyContext } from '../../context/PropertyContext';
import Utils from '../../utils';
import { Routes as AppRoutes } from '../../router/routeMapping';
import Constants from '../../constants';
import { Heatmap } from './LocalManager/partials';
import { AuthContext } from '../../context/AuthContext';

function ScoreCardWrapper({ activeProperty }) {
  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/detail/getData',
    preventFetch: !activeProperty?.web,
    requestType: 'get',
    params: { url: Utils.String.trimUrl(activeProperty?.web) },
  });

  return (
    <>
      {(apiLoading ||
        apiError ||
        !activeProperty?.web ||
        apiData?.point === '0') && (
        <Card className="h-100">
          <Card.Header>
            <Card.Title bsPrefix="card-header-title" as="h4">
              <FormattedMessage id="app.common.seoScore" />
              <Tooltip
                content={
                  <FormattedMessage id="app.helpers.overview.tooltips.seoScore" />
                }
              >
                <i className="bi-question-circle text-body ms-1" />
              </Tooltip>
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <RequestLoading loading={apiLoading} size="lg" margin="5" />
            <RequestResult type="error" message={apiError} />
            <RequestResult
              type="secondary"
              image={{ height: '12rem' }}
              title="emptyState.websiteDataNotCollected.title"
              message={
                !apiLoading &&
                !apiError &&
                activeProperty?.web &&
                apiData?.point === '0'
                  ? 'emptyState.websiteDataNotCollected.message'
                  : null
              }
              action={{
                type: 'button',
                variant: 'primary',
                link: AppRoutes.protected.PROPERTY_EDIT.children.PROPERTY_EDIT_SETTINGS.path.replace(
                  ':id',
                  kebabCase(activeProperty.title)
                ),
                title: 'emptyState.websiteDataNotCollected.action.title',
              }}
            />
            <RequestResult
              type="secondary"
              image={{ height: '12rem' }}
              title="emptyState.noWebsite.title"
              message={
                !activeProperty?.web ? 'emptyState.noWebsite.message' : null
              }
              action={{
                type: 'button',
                variant: 'primary',
                link: AppRoutes.protected.PROPERTY_EDIT.children.PROPERTY_EDIT_SETTINGS.path.replace(
                  ':id',
                  kebabCase(activeProperty.title)
                ),
                title: 'emptyState.noWebsite.action.title',
              }}
            />
          </Card.Body>
        </Card>
      )}
      {!apiLoading &&
        !apiError &&
        activeProperty?.web &&
        apiData?.point !== '0' && <ScoreCard value={Number(apiData?.point)} />}
    </>
  );
}

ScoreCardWrapper.propTypes = {
  activeProperty: PropTypes.objectOf(PropTypes.any).isRequired,
};

function KeywordWrapper({ activeProperty }) {
  const dateFrom = moment().subtract(89, 'days');
  const dateTo = moment();

  const initialDates = [
    dateFrom.format(Constants.DateFormats.API),
    dateTo.format(Constants.DateFormats.API),
  ];
  const [activeItem, setActiveItem] = useState();

  const {
    data: apiData,
    isFetching: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/reputation_manager/sentiment_most_used',
    preventFetch: !activeProperty?.id,
    params: {
      id: activeProperty?.id,
      date: initialDates,
    },
  });

  useEffect(() => {
    if (apiData?.reviews?.[0]?.val) {
      setActiveItem(apiData.reviews[0]);
    }
  }, [apiData]);

  return (
    <>
      {(apiLoading || apiError) && (
        <Card className="h-100">
          <Card.Body>
            <RequestLoading loading={apiLoading} size="lg" margin="5" />
            <RequestResult type="error" message={apiError} />
          </Card.Body>
        </Card>
      )}
      {!apiLoading &&
        !apiError &&
        (!apiData?.reviews || apiData?.reviews?.length === 0) && (
          <Card className="h-100">
            <Card.Header>
              <Card.Title bsPrefix="card-header-title" as="h4">
                <FormattedMessage id="app.common.sentimentInsights" />
                <Tooltip
                  content={
                    <FormattedMessage id="app.helpers.reputationManager.overview.tooltips.topKeywords" />
                  }
                >
                  <i className="bi-question-circle text-body ms-1" />
                </Tooltip>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <RequestResult
                type="secondary"
                title="emptyState.sentimentInsights.title"
                message="emptyState.sentimentInsights.message"
              />
            </Card.Body>
          </Card>
        )}
      {!apiLoading && !apiError && apiData?.reviews?.length > 0 && (
        <KeywordsSentiment
          isOverview
          loading={apiLoading}
          word={activeItem?.val}
          data={activeItem?.nums}
          date={initialDates}
          propertyId={activeProperty?.id}
        />
      )}
    </>
  );
}

KeywordWrapper.propTypes = {
  activeProperty: PropTypes.objectOf(PropTypes.any).isRequired,
};

function HeatmapWrapper({ activeProperty, hasAccess }) {
  const [activeItem, setActiveItem] = useState();

  const {
    isLoading: apiLoading,
    error: apiError,
    data: apiData,
  } = useAxiosQuery({
    url: '/geoGrid/list',
    preventFetch: !activeProperty?.id && hasAccess,
    params: {
      PropertyID: activeProperty?.id,
    },
    select: (data) => {
      const unsorted = _(data)
        .groupBy((x) => x.date)
        .value();
      const sortedKeys = Object.keys(unsorted).sort((a, b) =>
        b.localeCompare(a)
      );
      const sorted = {};
      sortedKeys.forEach((key) => {
        sorted[key] = unsorted[key];
      });
      return sorted;
    },
  });

  useEffect(() => {
    if (apiData) {
      const actDate = Object.keys(apiData)?.[0] || undefined;
      setActiveItem(apiData[actDate]?.[0]);
    }
  }, [apiData]);

  return (
    <Card className="h-100">
      <Card.Header className="card-header-content-between">
        <Card.Title bsPrefix="card-header-title" as="h4">
          <FormattedMessage id="app.common.rankingHeatmap" />
        </Card.Title>
        {!apiLoading && !apiError && activeItem && hasAccess && (
          <Badge bg="soft-primary" className="text-primary">
            <FormattedMessage id="app.common.searchTerm" />:
            <span className="ms-1">{`"${activeItem.keyword}"`}</span>
          </Badge>
        )}
      </Card.Header>
      {(apiLoading || apiError) && (
        <Card.Body>
          <RequestLoading loading={apiLoading} size="lg" margin="5" />
          <RequestResult type="error" message={apiError} />
        </Card.Body>
      )}
      {!apiLoading && !apiError && Object.keys(apiData).length === 0 && (
        <Card.Body>
          <RequestResult
            type="secondary"
            title="emptyState.rankingHeatmap.title"
            message="emptyState.rankingHeatmap.message"
          />
        </Card.Body>
      )}
      {!apiLoading &&
        !apiError &&
        Object.keys(apiData).length > 0 &&
        activeItem &&
        (hasAccess ? (
          <>
            <Heatmap
              searchTerm={activeItem.keyword}
              id={activeItem.id}
              isOverview
            />
            <Card.Footer className="card-footer text-center">
              <Link
                to={
                  AppRoutes.protected.LOCAL_MANAGER.children
                    .LOCAL_MANAGER_RANKING_HEATMAP.path
                }
              >
                <FormattedMessage id="app.common.viewAllSearchTerms" />
                <i className="bi-chevron-right ms-1" />
              </Link>
            </Card.Footer>
          </>
        ) : (
          <NoAccessInfoComponentLevel
            module={
              AppRoutes.protected.LOCAL_MANAGER.children
                .LOCAL_MANAGER_RANKING_HEATMAP.path
            }
          />
        ))}
    </Card>
  );
}

HeatmapWrapper.propTypes = {
  activeProperty: PropTypes.objectOf(PropTypes.any).isRequired,
  hasAccess: PropTypes.bool.isRequired,
};

function SmallCard({ titleKey, descKey, infoKey, children }) {
  return (
    <div>
      <div className="d-flex">
        <div className="flex-grow-1">
          <h6 className="card-subtitle mb-1">
            <FormattedMessage id={`app.common.${titleKey}`} />
            {infoKey && (
              <Tooltip
                content={
                  <FormattedMessage
                    id={`app.helpers.${infoKey}.tooltips.${titleKey}`}
                  />
                }
              >
                <i className="bi-question-circle text-body ms-1" />
              </Tooltip>
            )}
          </h6>
          {descKey && (
            <div className="card-text small mb-2">
              <FormattedMessage id={`app.common.${descKey}`} />
            </div>
          )}
        </div>
      </div>
      <div className="w-100">{children}</div>
    </div>
  );
}

SmallCard.propTypes = {
  titleKey: PropTypes.string.isRequired,
  descKey: PropTypes.string,
  infoKey: PropTypes.string,
  children: PropTypes.node.isRequired,
};

SmallCard.defaultProps = {
  descKey: null,
  infoKey: null,
};

function ListingWrapper({ activeProperty }) {
  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/reputation_manager/listingmanager',
    preventFetch: !activeProperty?.id,
    params: {
      id: activeProperty?.id,
    },
  });

  const getScoreType = (value, type) => {
    let variant = 'danger';
    let titleKey = 'bad';

    if (value > 50 && value < 80) {
      variant = 'warning';
      titleKey = 'average';
    } else if (value >= 80) {
      variant = 'success';
      titleKey = 'good';
    }

    return type === 'color' ? variant : titleKey;
  };

  return (
    <Card className="h-100">
      {!apiLoading && !apiError && !apiData?.own.total && (
        <Card.Header>
          <Card.Title bsPrefix="card-header-title" as="h4">
            <FormattedMessage id="app.common.listingManager" />
            <Tooltip
              content={
                <FormattedMessage id="app.helpers.overview.tooltips.listingManager" />
              }
            >
              <i className="bi-question-circle text-body ms-1" />
            </Tooltip>
          </Card.Title>
        </Card.Header>
      )}
      <Card.Body>
        <RequestLoading loading={apiLoading} size="lg" margin="5" />
        <RequestResult type="error" message={apiError} />
        {!apiLoading && !apiError && !apiData?.own.total && (
          <RequestResult
            type="secondary"
            title="emptyState.listingManager.title"
            message="emptyState.listingManager.message"
          />
        )}
        {!apiLoading && !apiError && !!apiData?.own?.total && (
          <Row className="col-sm-divider">
            <Col sm="6">
              <SmallCard
                titleKey="onlinePresence"
                descKey="overallAssessment"
                infoKey="listingManager"
              >
                <div className="d-flex align-items-center">
                  <div
                    className={`fs-5 me-2 text-${getScoreType(
                      apiData.own.score,
                      'color'
                    )}`}
                  >
                    <strong>{apiData.own.score}</strong>
                  </div>
                  <div className="flex-grow-1">
                    <ProgressBar
                      style={{ height: 20 }}
                      now={apiData.own.score}
                      variant={getScoreType(apiData.own.score, 'color')}
                    />
                  </div>
                  <div
                    className={`fs-3 ms-2 text-${getScoreType(
                      apiData.own.score,
                      'color'
                    )}`}
                  >
                    <strong>
                      <FormattedMessage
                        id={`app.common.${getScoreType(apiData.own.score)}`}
                      />
                    </strong>
                  </div>
                </div>
              </SmallCard>
            </Col>
            <Col sm="6">
              <SmallCard
                titleKey="listingsToFix"
                descKey="toImproveCoverage"
                infoKey="listingManager"
              >
                <Card.Title
                  as="h2"
                  className="text-inherit d-flex align-items-center"
                >
                  <span className="me-1">
                    {apiData.own.notfound + apiData.own.missing}
                  </span>
                  <span>/</span>
                  <span className="ms-1 fs-4">{apiData.own.total}</span>
                </Card.Title>
              </SmallCard>
            </Col>
          </Row>
        )}
      </Card.Body>
      <Card.Footer className="card-footer text-center">
        <Link to={AppRoutes.protected.LISTING_MANAGER.path}>
          <FormattedMessage id="app.common.viewListingManager" />
          <i className="bi-chevron-right ms-1" />
        </Link>
      </Card.Footer>
    </Card>
  );
}

ListingWrapper.propTypes = {
  activeProperty: PropTypes.objectOf(PropTypes.any).isRequired,
};

function ReviewGeneratorWrapper() {
  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/_link/clicks',
  });

  const getDiff = () => {
    const numA = apiData.last_month;
    const numB = apiData.prev_month;

    const percentageInc = (numA - numB) / numB;

    let color = 'danger';
    let dir = 'down';

    if (percentageInc > 0) {
      color = 'success';
      dir = 'up';
    }

    if (numB > 0 && percentageInc !== 0) {
      return (
        <Badge bg={`soft-${color}`} className={`text-${color}`}>
          <i className={`bi-graph-${dir}-arrow`} />
          <span className="ms-1 fw-bold">
            <FormattedNumber
              value={percentageInc}
              // eslint-disable-next-line react/style-prop-object
              style="percent"
              maximumFractionDigits={0}
            />
          </span>
        </Badge>
      );
    }

    return null;
  };

  return (
    <Card className="h-100">
      <Card.Header className="card-header-content-between">
        <Card.Title bsPrefix="card-header-title" as="h4">
          <FormattedMessage id="app.common.reviewGenerator" />
          <Tooltip
            content={
              <FormattedMessage id="app.helpers.overview.tooltips.reviewGenerator" />
            }
          >
            <i className="bi-question-circle text-body ms-1" />
          </Tooltip>
        </Card.Title>
        {!apiLoading && !apiError && apiData?.co > 0 && (
          <Badge bg="soft-primary" className="text-primary">
            <FormattedMessage id="app.common.totalLinks" />:
            <span className="ms-1">{`${apiData.co}`}</span>
          </Badge>
        )}
      </Card.Header>
      <Card.Body>
        <RequestLoading loading={apiLoading} size="lg" margin="5" />
        <RequestResult type="error" message={apiError} />
        {!apiLoading && !apiError && apiData?.co <= 0 && (
          <RequestResult
            type="secondary"
            title="emptyState.reviewGenerator.title"
            message="emptyState.reviewGenerator.message"
            action={{
              type: 'button',
              variant: 'primary',
              link: AppRoutes.protected.REPUTATION_MANAGER.children
                .REPUTATION_MANAGER_REVIEW_GENERATOR.path,
              title: 'emptyState.reviewGenerator.action.title',
            }}
          />
        )}
        {!apiLoading && !apiError && apiData?.co > 0 && (
          <Row className="col-sm-divider">
            <Col sm="4">
              <SmallCard titleKey="totalClicks">
                <Card.Title
                  as="h2"
                  className="text-inherit d-flex align-items-center"
                >
                  {apiData.total}
                </Card.Title>
              </SmallCard>
            </Col>
            <Col sm="4">
              <SmallCard titleKey="lastMonth">
                <div className="d-flex align-items-center">
                  <Card.Title
                    as="h2"
                    className="text-inherit d-flex align-items-center mb-0 me-1"
                  >
                    {apiData.last_month}
                  </Card.Title>
                  {getDiff()}
                </div>
              </SmallCard>
            </Col>
            <Col sm="4">
              <SmallCard titleKey="previousMonth">
                {apiData.prev_month === 0 ? (
                  <Badge bg="soft-secondary" className="text-secondary">
                    <FormattedMessage id="app.common.n/a" />
                  </Badge>
                ) : (
                  <Card.Title
                    as="h2"
                    className="text-inherit d-flex align-items-center"
                  >
                    {apiData.prev_month}
                  </Card.Title>
                )}
              </SmallCard>
            </Col>
          </Row>
        )}
      </Card.Body>
      {!apiLoading && !apiError && apiData?.co > 0 && (
        <Card.Footer className="card-footer text-center">
          <Link
            to={
              AppRoutes.protected.REPUTATION_MANAGER.children
                .REPUTATION_MANAGER_REVIEW_GENERATOR.path
            }
          >
            <FormattedMessage id="app.common.viewReviewGenerator" />
            <i className="bi-chevron-right ms-1" />
          </Link>
        </Card.Footer>
      )}
    </Card>
  );
}

function Overview() {
  const { activeProperty } = useContext(PropertyContext);
  const { pack } = useContext(AuthContext);

  return (
    <div className="content container">
      <PageHeader title="dashboard" />
      <Row>
        <Col lg="8" className="mb-3 mb-lg-5">
          <RatingSummary />
        </Col>
        <Col lg="4" className="mb-3 mb-lg-5">
          <ScoreCardWrapper activeProperty={activeProperty} />
        </Col>
        <Col lg="6" className="mb-3 mb-lg-5">
          <RecentReviews />
        </Col>
        <Col lg="6" className="mb-3 mb-lg-5">
          <TopKeywords />
        </Col>
        <Col lg="6" className="mb-3 mb-lg-5">
          <KeywordWrapper activeProperty={activeProperty} />
        </Col>
        <Col lg="6" className="mb-3 mb-lg-5">
          <HeatmapWrapper
            activeProperty={activeProperty}
            hasAccess={pack.usage['local-manager']['ranking-heatmap']}
          />
        </Col>
        <Col lg="6" className="mb-3 mb-lg-5">
          <ListingWrapper activeProperty={activeProperty} />
        </Col>
        <Col lg="6" className="mb-3 mb-lg-5">
          <ReviewGeneratorWrapper />
        </Col>
      </Row>
    </div>
  );
}

export default Overview;
