import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Nav,
  ListGroup,
  Card,
  Image,
  Dropdown,
  Button,
  Breadcrumb,
  ButtonGroup,
} from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { lowerCase } from 'lodash';
import FsLightbox from 'fslightbox-react';
import UnknownIcon from '../../assets/images/svg/brands/google-docs-icon.svg';
import PdfIcon from '../../assets/images/svg/brands/pdf-icon.svg';
import ImageIcon from '../../assets/images/svg/components/placeholder-img-format.svg';
import CsvIcon from '../../assets/images/svg/components/placeholder-csv-format.svg';
import Utils from '../../utils';
import { LangContext } from '../../context/LangContext';
import { useAxiosQuery, useConfirmModal, useFormModal } from '../../hooks';
import { yup } from '../../lib';
import RequestLoading from '../RequestLoading';
import RequestResult from '../RequestResult';

const viewTypes = [
  { icon: 'bi-grid', value: 'grid' },
  { icon: 'bi-view-list', value: 'list' },
];

const iconMap = {
  unknown: UnknownIcon,
  pdf: PdfIcon,
  jpg: ImageIcon,
  jpeg: ImageIcon,
  png: ImageIcon,
  gif: ImageIcon,
  bmp: ImageIcon,
  webp: ImageIcon,
  csv: CsvIcon,
};

const ligtboxAllowedTypes = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'mp4'];

function FolderMenu({ onDelete }) {
  return (
    <Dropdown.Menu className="m-0" renderOnMount>
      <Dropdown.Header>
        <FormattedMessage id="app.common.actions" />
      </Dropdown.Header>
      <Dropdown.Item onClick={onDelete}>
        <i className="bi-trash dropdown-item-icon" />
        <span>
          <FormattedMessage id="app.common.delete" />
        </span>
      </Dropdown.Item>
    </Dropdown.Menu>
  );
}

FolderMenu.propTypes = {
  onDelete: PropTypes.func,
};

FolderMenu.defaultProps = {
  onDelete: () => {},
};

function FileMenu({ onDelete, downloadUrl }) {
  return (
    <Dropdown.Menu className="m-0" renderOnMount>
      <Dropdown.Header>
        <FormattedMessage id="app.common.actions" />
      </Dropdown.Header>
      {downloadUrl && (
        <Dropdown.Item href={downloadUrl}>
          <i className="bi-download dropdown-item-icon" />
          <span>
            <FormattedMessage id="app.common.download" />
          </span>
        </Dropdown.Item>
      )}
      {onDelete && (
        <>
          <Dropdown.Divider />
          <Dropdown.Item onClick={onDelete}>
            <i className="bi-trash dropdown-item-icon" />
            <span>
              <FormattedMessage id="app.common.delete" />
            </span>
          </Dropdown.Item>
        </>
      )}
    </Dropdown.Menu>
  );
}

FileMenu.propTypes = {
  onDelete: PropTypes.func,
  downloadUrl: PropTypes.string,
};

FileMenu.defaultProps = {
  onDelete: null,
  downloadUrl: null,
};

function SmartFileManager({ paths, params }) {
  const { currentLanguage } = useContext(LangContext);
  const [viewType, setViewType] = useState(viewTypes[0].value);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [currentFolder, setCurrentFolder] = useState();
  const [foldersMap, setFoldersMap] = useState([]);
  const { formatMessage } = useIntl();
  const { confirm } = useConfirmModal({ confirmVariant: 'danger' });
  const { form } = useFormModal();

  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [previewItems, setPreviewItems] = useState([]);

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  const openLightboxOnSlide = (number) => {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  };

  const {
    data: filesData,
    isLoading: filesLoading,
    error: filesError,
  } = useAxiosQuery({
    url: paths.files.list,
    params: {
      ...params,
      ...paths.files.listParams,
      IDDirectories: currentFolder?.DirectoryID || 0,
    },
  });

  const {
    data: foldersData,
    isLoading: foldersLoading,
    error: foldersError,
  } = useAxiosQuery({
    url: paths.folders?.list,
    preventFetch: !paths.folders?.list,
    params: {
      ...params,
      ...paths.folders?.listParams,
      CDirID: currentFolder?.DirectoryID || 0,
    },
  });

  const onFolderChange = (folder, index) => {
    if (!folder) {
      setCurrentFolder(null);
      setFoldersMap([]);
      return;
    }

    const nFolders =
      index > -1 ? [...foldersMap].slice(0, index) : [...foldersMap];

    nFolders.push(folder);

    setCurrentFolder(folder);
    setFoldersMap(nFolders);
  };

  const onDeleteItem = async (type, id, title) => {
    let requestUrl = paths.folders?.delete;
    let requestParams = {
      ...params,
      ...paths.folders?.deleteParams,
      DirectoryID: id,
    };

    if (type === 'file') {
      requestUrl = paths.files?.delete;
      requestParams = { ...params, ...paths.files?.deleteParams, FileID: id };
    }

    const isSuccess = await confirm({
      message: (
        <FormattedMessage
          id="app.common.areYouSureYouWantToDeleteXY"
          values={{
            x: title,
            y: lowerCase(formatMessage({ id: `app.common.${type}` })),
          }}
        />
      ),
      requestUrl,
      requestParams,
    });

    if (isSuccess) {
      if (type === 'folder') {
        setFolders((prevFolders) =>
          prevFolders.filter((item) => item.DirectoryID !== id)
        );
      } else {
        setFiles((prevFiles) => prevFiles.filter((item) => item.FileID !== id));
      }
    }
  };

  const onCreateFolder = async () => {
    const fields = [
      {
        cols: [
          {
            key: 'folderName',
            apiKey: 'Name',
            schema: yup.string().required(),
          },
        ],
      },
    ];

    const requestParams = { ...params, ...paths.folders?.createParams };
    if (currentFolder?.DirectoryID) {
      requestParams.CDirID = currentFolder?.DirectoryID;
    }

    const formData = await form({
      title: 'newFolder',
      confirmLabel: 'create',
      requestUrl: paths.folders?.create,
      requestParams,
      fields,
      fetchOnStart: false,
    });

    if (formData) {
      setFolders((prevFolders) => [...prevFolders, formData.Result]);
    }
  };

  const onUploadItem = async () => {
    const fields = [
      {
        cols: [
          {
            key: 'file',
            type: 'dropzone',
            schema: yup.mixed().requiredFile(),
            options: {
              controller: {
                options: {
                  multiple: false,
                },
              },
            },
          },
        ],
      },
      /* {
        cols: [
          {
            key: 'file2',
            type: 'dropzoneAlt',
            schema: yup.mixed().requiredFile(),
            options: {
              controller: { options: { uploadMultiple: true, maxFiles: 5 } },
            },
          },
        ],
      }, */
    ];

    const requestParams = {
      ...params,
      ...paths.files?.createParams,
      IDDirectories: currentFolder?.DirectoryID || 0,
    };

    const formData = await form({
      size: 'lg',
      title: 'uploadFiles',
      confirmLabel: 'upload',
      requestUrl: paths.files?.create,
      requestParams,
      fields,
      fetchOnStart: false,
    });

    if (formData) {
      setFiles((prevFiles) => [...prevFiles, formData.Result]);
    }
  };

  const getFileMenu = (item) => (
    <FileMenu
      onDelete={
        paths.files?.delete
          ? () => {
              onDeleteItem('file', item.FileID, item.FileName);
            }
          : null
      }
      downloadUrl={
        paths.files?.download && `${paths.files?.download}${item.FileName}`
      }
    />
  );

  const getPreviewLink = (item, vType) => {
    const i = previewItems?.findIndex(
      (nItem) => nItem.FileName === item.FileName
    );

    const classN = vType === 'grid' ? 'stretched-link p-0' : 'text-dark p-0';

    if (i > -1) {
      return (
        <Button
          variant="link"
          onClick={() => {
            openLightboxOnSlide(i + 1);
          }}
          className={classN}
        >
          {vType === 'list' && item.FileName}
        </Button>
      );
    }

    return (
      <a
        href={`${paths.files?.preview}/${item.FileName}`}
        target="_blank"
        className={classN}
        rel="noreferrer"
      >
        {vType === 'list' && item.FileName}
      </a>
    );
  };

  useEffect(() => {
    setLoading(foldersLoading || filesLoading);
  }, [foldersLoading, filesLoading]);

  useEffect(() => {
    setError(foldersError || filesError);
  }, [foldersError, filesError]);

  useEffect(() => {
    setFolders(foldersData);
  }, [foldersData]);

  useEffect(() => {
    setFiles(filesData);
  }, [filesData]);

  useEffect(() => {
    const pItems = files?.filter((item) =>
      ligtboxAllowedTypes.includes(item.FileType)
    );
    setPreviewItems(pItems);
  }, [files]);

  return (
    <>
      <Row className="align-items-center mb-3 mb-lg-5">
        <Col sm className="mb-2 mb-sm-0">
          <Breadcrumb>
            <Breadcrumb.Item
              linkProps={{
                className: 'breadcrumb-link',
              }}
              active={!currentFolder || currentFolder?.DirectoryID === 0}
              onClick={() => {
                onFolderChange();
              }}
            >
              <i className="bi-house me-1" />
              <FormattedMessage id="app.common.rootDirectory" />
            </Breadcrumb.Item>
            {foldersMap.map((item, index) => (
              <Breadcrumb.Item
                key={item.DirectoryID}
                linkProps={{
                  className: 'breadcrumb-link',
                }}
                active={currentFolder?.DirectoryID === item.DirectoryID}
                onClick={() => {
                  if (currentFolder?.DirectoryID !== item.DirectoryID) {
                    onFolderChange(item, index);
                  }
                }}
              >
                <i className="bi-folder me-1" /> {item.Name}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </Col>
        <Col sm="auto">
          <Dropdown align="end">
            <ButtonGroup>
              <Button onClick={onUploadItem}>
                <i className="bi-cloud-arrow-up-fill me-1" />
                <FormattedMessage id="app.common.upload" />
              </Button>
              <Dropdown.Toggle />
            </ButtonGroup>
            <Dropdown.Menu className="m-0" renderOnMount>
              <Dropdown.Header>
                <FormattedMessage id="app.common.actions" />
              </Dropdown.Header>
              {paths.folders?.create && (
                <Dropdown.Item onClick={onCreateFolder}>
                  <i className="bi-folder-plus dropdown-item-icon" />
                  <span>
                    <FormattedMessage id="app.common.newFolder" />
                  </span>
                </Dropdown.Item>
              )}
              <Dropdown.Divider />
              <Dropdown.Item onClick={onUploadItem}>
                <i className="bi-file-earmark-arrow-up dropdown-item-icon" />
                <span>
                  <FormattedMessage id="app.common.uploadFiles" />
                </span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <RequestLoading loading={loading} size="lg" margin="5" />
      <RequestResult type="error" message={error} />
      {!loading && !error && (
        <>
          {folders?.length > 0 && (
            <>
              <h2 className="h4 mb-3 mb-lg-5">
                <FormattedMessage id="app.common.folders" />
              </h2>
              <Row xs="1" sm="2" md="3" lg="4" className="mb-5">
                {folders.map((item, index) => (
                  <Col
                    key={`folder_${index.toString()}`}
                    className="mb-3 mb-lg-5"
                  >
                    <Card className="card-sm card-hover-shadow h-100">
                      <Card.Body>
                        <div className="d-flex align-items-center">
                          <i className="bi-folder fs-2 text-body me-2" />

                          <h5 className="text-truncate ms-2 mb-0">
                            {item.Name}
                          </h5>
                          {paths.folders?.delete && (
                            <Dropdown align="end" className="ms-auto">
                              <Dropdown.Toggle
                                bsPrefix="btn"
                                variant="ghost-secondary"
                                size="sm"
                                className="btn-icon rounded-circle card-dropdown-btn"
                              >
                                <i className="bi-three-dots-vertical" />
                              </Dropdown.Toggle>
                              <FolderMenu
                                onDelete={() => {
                                  onDeleteItem(
                                    'folder',
                                    item.DirectoryID,
                                    item.Name
                                  );
                                }}
                              />
                            </Dropdown>
                          )}
                        </div>
                      </Card.Body>
                      <Button
                        variant="link"
                        className="stretched-link p-0 border-0"
                        onClick={() => {
                          onFolderChange(item);
                        }}
                      />
                    </Card>
                  </Col>
                ))}
              </Row>
            </>
          )}
          <Row className="align-items-center mb-3 mb-lg-5">
            <Col>
              <h2 className="h4 mb-0">
                <FormattedMessage id="app.common.files" />
              </h2>
            </Col>
            <Col xs="auto">
              <Nav
                variant="segment"
                activeKey={viewType}
                onSelect={setViewType}
              >
                {viewTypes.map((item) => (
                  <Nav.Item key={item.value}>
                    <Nav.Link eventKey={item.value}>
                      <i className={item.icon} />
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Col>
          </Row>
          {files?.length > 0 ? (
            <div>
              {viewType === 'grid' ? (
                <Row xs="1" sm="2" md="3" lg="4">
                  {files.map((item, index) => (
                    <Col
                      key={`file_${index.toString()}`}
                      className="mb-3 mb-lg-5"
                    >
                      <Card className="card-sm card-hover-shadow card-header-borderless h-100 text-center">
                        <Card.Header className="card-header-content-between border-0">
                          <span className="small">
                            {Utils.String.humanFileSize(item.FileSize)}
                          </span>
                          {(paths.files?.delete || paths.files?.download) && (
                            <Dropdown align="end">
                              <Dropdown.Toggle
                                bsPrefix="btn"
                                variant="ghost-secondary"
                                size="sm"
                                className="btn-icon rounded-circle card-dropdown-btn"
                              >
                                <i className="bi-three-dots-vertical" />
                              </Dropdown.Toggle>
                              {getFileMenu(item)}
                            </Dropdown>
                          )}
                        </Card.Header>
                        <Card.Body>
                          <Image
                            className="avatar avatar-4x3"
                            src={iconMap[item.FileType] || iconMap.unknown}
                          />
                        </Card.Body>
                        <Card.Body>
                          <Card.Title as="h5">{item.FileName}</Card.Title>
                          <p className="small" lang={currentLanguage}>
                            <FormattedMessage
                              id="app.common.createdN"
                              values={{
                                n: moment(item.DateCreated).fromNow(),
                              }}
                            />
                          </p>
                        </Card.Body>

                        {paths.files?.preview && getPreviewLink(item, viewType)}
                      </Card>
                    </Col>
                  ))}
                </Row>
              ) : (
                <ListGroup>
                  {files.map((item, index) => (
                    <ListGroup.Item key={`file_${index.toString()}`}>
                      <Row className="align-items-center">
                        <Col xs="auto">
                          <Image
                            className="avatar avatar-xs avatar-4x3"
                            src={iconMap[item.FileType] || iconMap.unknown}
                          />
                        </Col>
                        <Col>
                          <h5 className="mb-0">
                            {paths.files?.preview
                              ? getPreviewLink(item, viewType)
                              : item.FileName}
                          </h5>
                          <ul className="list-inline list-separator small text-body">
                            <li
                              className="list-inline-item"
                              lang={currentLanguage}
                            >
                              <FormattedMessage
                                id="app.common.createdN"
                                values={{
                                  n: moment(item.DateCreated).fromNow(),
                                }}
                              />
                            </li>
                            <li className="list-inline-item">
                              {Utils.String.humanFileSize(item.FileSize)}
                            </li>
                          </ul>
                        </Col>
                        {(paths.files?.delete || paths.files?.download) && (
                          <Col xs="auto">
                            <Dropdown align="end">
                              <Dropdown.Toggle
                                bsPrefix="btn"
                                variant="white"
                                size="sm"
                              >
                                <span className="d-none d-sm-inline-block me-1">
                                  <FormattedMessage id="app.common.more" />
                                </span>
                                <i className="bi-chevron-down" />
                              </Dropdown.Toggle>
                              {getFileMenu(item)}
                            </Dropdown>
                          </Col>
                        )}
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
              <FsLightbox
                exitFullscreenOnClose
                toggler={lightboxController.toggler}
                slide={lightboxController.slide}
                sources={previewItems?.map(
                  (item) => `${paths.files?.preview}/${item.FileName}`
                )}
              />
            </div>
          ) : (
            <RequestResult type="secondary" message="app.common.noFilesFound" />
          )}
        </>
      )}
    </>
  );
}

SmartFileManager.propTypes = {
  paths: PropTypes.shape({
    folders: PropTypes.shape({
      list: PropTypes.string,
      listParams: PropTypes.objectOf(PropTypes.any),
      create: PropTypes.string,
      createParams: PropTypes.objectOf(PropTypes.any),
      delete: PropTypes.string,
      deleteParams: PropTypes.objectOf(PropTypes.any),
    }),
    files: PropTypes.shape({
      list: PropTypes.string,
      listParams: PropTypes.objectOf(PropTypes.any),
      create: PropTypes.string,
      createParams: PropTypes.objectOf(PropTypes.any),
      delete: PropTypes.string,
      deleteParams: PropTypes.objectOf(PropTypes.any),
      preview: PropTypes.string,
      download: PropTypes.string,
    }).isRequired,
  }).isRequired,
  params: PropTypes.objectOf(PropTypes.any),
};
SmartFileManager.defaultProps = {
  params: {},
};

export default SmartFileManager;
