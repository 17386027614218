import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  ListGroup,
  Badge,
  Row,
  Col,
  Spinner,
  InputGroup,
  Form,
} from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import SimpleBar from 'simplebar-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { startCase, toLower } from 'lodash';
import { Nouislider, RequestLoading } from '../../../../../components';

function ListItem({ item, onMouseEnter, onMouseLeave, onChange, checked }) {
  return (
    <ListGroup.Item
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      as="label"
      action
      htmlFor={`property_checkbox_${item.place_id}`}
    >
      <div className="d-flex">
        <div className="me-2">
          <input
            className="form-check-input"
            type="checkbox"
            value={item.place_id}
            name="property_checkbox"
            id={`property_checkbox_${item.place_id}`}
            checked={checked}
            onChange={onChange}
          />
        </div>
        <div>
          <div className="d-flex align-items-center h5 text-inherit mb-1">
            {item.mapIndex && (
              <Badge pill bg={checked ? 'success' : 'danger'} className="me-1">
                {checked ? <i className="bi-check-lg" /> : item.mapIndex}
              </Badge>
            )}
            {startCase(toLower(item.name))}
          </div>
          <div className="d-block fs-6 text-body">{item.formatted_address}</div>
        </div>
      </div>
    </ListGroup.Item>
  );
}

ListItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
};

const PropertyList = forwardRef(
  (
    {
      height,
      data,
      onChange,
      value,
      onMouseEnter,
      onMouseLeave,
      hasMore,
      onLoadMore,
      max,
      radius,
      isLoading,
      isNextPageLoading,
      onSearch,
    },
    ref
  ) => {
    const { formatMessage } = useIntl();
    const [searchQuery, setSearchQuery] = useState('');

    const handleItemSelect = (e) => {
      const foundedProperty = data.find(
        (item) => item.place_id.toString() === e.target.value.toString()
      );

      if (!foundedProperty) {
        return;
      }

      const added = value.find(
        (item) => item.place_id.toString() === e.target.value.toString()
      );

      if (added) {
        const nValue = value.filter(
          (item) => item.place_id.toString() !== added.place_id.toString()
        );
        onChange([...nValue]);
        return;
      }

      if (value.length + 1 > max) {
        return;
      }

      onChange([...value, foundedProperty]);
    };

    return (
      <Card className="h-100">
        {radius && (
          <Card.Header>
            <Row className="align-items-center gx-0">
              <Col>
                <h6 className="mb-0 pe-2">
                  <FormattedMessage id="app.common.radius" />:
                </h6>
              </Col>
              <Col xs="auto">
                <div className="range-slider" style={{ width: 200 }}>
                  <Nouislider
                    className="range-slider-ui"
                    range={{
                      min: radius.min,
                      max: radius.max,
                    }}
                    start={radius.value}
                    step={radius.step}
                    tooltips={[radius.formatter(true)]}
                    format={radius.formatter()}
                    onChange={(val) => {
                      radius.onChange(val[0]);
                    }}
                    disabled={isLoading || isNextPageLoading}
                  />
                </div>
              </Col>
            </Row>
          </Card.Header>
        )}
        <Card.Header>
          <div>
            <InputGroup className="input-group-merge">
              <InputGroup.Text className="input-group-prepend">
                <i className="bi-search" />
              </InputGroup.Text>
              <Form.Control
                placeholder={formatMessage({
                  id: 'app.common.searchCompetitors',
                })}
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  onSearch(e.target.value);
                }}
              />
              {searchQuery.length > 0 && (
                <InputGroup.Text
                  as="button"
                  className="input-group-append"
                  onClick={() => {
                    setSearchQuery('');
                    onSearch('');
                  }}
                >
                  <i className="bi-x-lg" />
                </InputGroup.Text>
              )}
            </InputGroup>
          </div>
        </Card.Header>
        {isLoading ? (
          <div className="h-100">
            <RequestLoading loading={isLoading} />
          </div>
        ) : (
          <div className="h-100">
            {data.length > 0 ? (
              <Card.Body className="p-0 h-100">
                <SimpleBar
                  ref={ref}
                  scrollableNodeProps={{ id: 'scroll-node' }}
                  style={{ height }}
                >
                  <InfiniteScroll
                    scrollableTarget="scroll-node"
                    dataLength={data.length}
                    hasMore={hasMore}
                    next={onLoadMore}
                  >
                    <ListGroup variant="flush">
                      {data.map((item) => (
                        <ListItem
                          key={item.place_id}
                          item={item}
                          onMouseEnter={() => {
                            onMouseEnter(item);
                          }}
                          onMouseLeave={() => {
                            onMouseLeave();
                          }}
                          onChange={handleItemSelect}
                          checked={
                            !!value.find((p) => p.place_id === item.place_id)
                          }
                        />
                      ))}
                      {isNextPageLoading && (
                        <ListGroup.Item className="text-center p-3">
                          <>
                            <Spinner
                              animation="border"
                              size="sm"
                              className="me-1"
                            />
                            <FormattedMessage id="app.common.loading" />
                          </>
                        </ListGroup.Item>
                      )}
                    </ListGroup>
                  </InfiniteScroll>
                </SimpleBar>
              </Card.Body>
            ) : (
              <Card.Body>
                <h5 className="mb-0">
                  <FormattedMessage id="app.common.noCompetitorsFound" />
                </h5>
              </Card.Body>
            )}
          </div>
        )}
      </Card>
    );
  }
);

PropertyList.propTypes = {
  height: PropTypes.number,
  data: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.any,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  hasMore: PropTypes.bool,
  onLoadMore: PropTypes.func,
  max: PropTypes.number,
  radius: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    value: PropTypes.number,
    onChange: PropTypes.func,
    formatter: PropTypes.func,
  }),
  isLoading: PropTypes.bool,
  isNextPageLoading: PropTypes.bool,
  onSearch: PropTypes.func,
};

PropertyList.defaultProps = {
  height: 413,
  data: [],
  onChange: () => {},
  value: [],
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  hasMore: false,
  onLoadMore: () => {},
  max: undefined,
  radius: undefined,
  isLoading: false,
  isNextPageLoading: false,
  onSearch: () => {},
};

export default PropertyList;
