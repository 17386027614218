import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { SmartImage } from '../../../../../components';
import Illustration from '../../../../../assets/images/svg/illustrations/oc-unlock.svg';
import IllustrationLight from '../../../../../assets/images/svg/illustrations-light/oc-unlock.svg';
import GoogleLogo from './GoogleLogo';

const APP_TITLE = process.env.REACT_APP_SITE_TITLE || '';

function AnalyticsLogin({ onClick }) {
  return (
    <div className="py-10">
      <Row className="justify-content-sm-center text-center">
        <Col sm="6" md="4">
          <SmartImage
            className="img-fluid mb-5"
            src={Illustration}
            darkSrc={IllustrationLight}
            style={{ height: 120 }}
          />
        </Col>
      </Row>
      <Row className="justify-content-sm-center text-center">
        <Col sm="8" md="6">
          <h2 className="mb-4">
            <span className="display-5 me-2">
              <i className="bi-google" />
            </span>
            <FormattedMessage id="app.googleAnalytics.login.title" />
          </h2>
          <p className="mb-5">
            <FormattedMessage
              id="app.googleAnalytics.login.message"
              values={{ appTitle: APP_TITLE }}
            />
          </p>
          <Button variant="white" size="lg" onClick={onClick}>
            <span className="d-flex justify-content-center align-items-center">
              <GoogleLogo />
              <FormattedMessage id="app.common.signInWithGoogle" />
            </span>
          </Button>
        </Col>
      </Row>
    </div>
  );
}

AnalyticsLogin.propTypes = {
  onClick: PropTypes.func,
};

AnalyticsLogin.defaultProps = {
  onClick: () => {},
};

export default AnalyticsLogin;
