import React from 'react';
import PropTypes from 'prop-types';
import GoogleIcon from '../../../../../assets/images/svg/brands/google-icon.svg';
import { SmartImage } from '../../../../../components';

function GoogleLogo({ className }) {
  return (
    <SmartImage
      src={GoogleIcon}
      darkSrc={GoogleIcon || null}
      className={className}
    />
  );
}

GoogleLogo.propTypes = {
  className: PropTypes.string,
};

GoogleLogo.defaultProps = {
  className: 'avatar avatar-xss me-2',
};

export default GoogleLogo;
