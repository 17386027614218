import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  InputGroup,
  Image,
  Modal,
  Dropdown,
  ButtonGroup,
  Badge,
  Spinner,
} from 'react-bootstrap';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import Select from 'react-select';
import { QRCodeSVG } from 'qrcode.react';
import { capitalize, lowerCase, startCase, toLower } from 'lodash';
import { useReactToPrint } from 'react-to-print';
import { toBlob } from 'html-to-image';
import { useCopyToClipboard, useMeasure } from 'react-use';
import { useForm, Controller } from 'react-hook-form';
import { PropertyContext } from '../../../context/PropertyContext';
import {
  BrandLogo,
  Rating,
  RequestResult,
  SmartTable,
  Tooltip,
} from '../../../components';
import { ReactComponent as NFC } from '../../../assets/images/svg/components/nfc.svg';
import Utils from '../../../utils';
import { useAxiosMutation, useConfirmModal, useToast } from '../../../hooks';

const shortenedUrl = process.env.REACT_APP_SHORTENED_URL || '';

const PRINT_AREA_WIDTH = 148;
const PRINT_AREA_HEIGHT = 210;
const PRINT_AREA_ASPECT_RATIO = Math.floor(
  (PRINT_AREA_HEIGHT / PRINT_AREA_WIDTH) * 100
);

const DARK_BACKGROUND_COLOR = '#17181a';
const LIGHT_BACKGROUND_COLOR = '#FFFFFF';

const DARK_TEXT_COLOR = '#FFFFFF';
const LIGHT_TEXT_COLOR = '#000000';

const pageStyle = `
@media print {
  body {-webkit-print-color-adjust: exact;}
  #printDiv {border-raidus:0;}
}
@page {
  size: ${PRINT_AREA_WIDTH}mm ${PRINT_AREA_HEIGHT}mm;
  margin: 0mm !important;
}`;

const types = {
  PlatformsReview: {
    labelKey: 'review',
    id: 'PlatformsReview',
    urlKey: 'site_url',
    headingKey: 'reviewUsOn',
    titleKey: 'review.title',
    subtitleKey: 'review.subtitle',
    commentKey: 'review.comment',
  },
  PlatformsSocial: {
    labelKey: 'social',
    id: 'PlatformsSocial',
    urlKey: 'url',
    headingKey: 'followUsOn',
    titleKey: 'social.title',
    subtitleKey: 'social.subtitle',
    commentKey: 'social.comment',
  },
};

function ReviewDetail({ item, onClose }) {
  const { activeProperty } = useContext(PropertyContext);
  const { formatMessage } = useIntl();

  const [frameContainerRef, { height: frameContainerHeight }] = useMeasure();
  const [dynamicAreaRef, { height: dynamicAreaHeight }] = useMeasure();

  const [logoHeight, setLogoHeight] = useState(0);

  const getDefaultFieldValue = (type) =>
    formatMessage({
      id: `app.helpers.reputationManager.reviewGenerator.defaults.${
        types[types.PlatformsReview.id][`${type}Key`]
      }`,
    });

  const itemType =
    item?.type === 'review'
      ? types.PlatformsReview.id
      : types.PlatformsSocial.id;

  const {
    control,
    register,
    formState: { dirtyFields },
    setValue,
    resetField,
    watch,
    handleSubmit,
  } = useForm({
    defaultValues: item
      ? {
          brand: activeProperty.brands.find((nItem) => nItem.id === item.brand),
          type: itemType,
          platform:
            activeProperty[itemType]?.length > 0
              ? activeProperty[itemType].find(
                  (nItem) => nItem.site === item.platform
                )
              : undefined,
          title: item.title,
          subtitle: item.subtitle,
          comment: item.comment,
          nfcIsEnabled: item.show_nfc === 1,
          isDark: item.dark_version === 1,
        }
      : {
          brand:
            activeProperty.brands?.length > 0
              ? activeProperty.brands[0]
              : undefined,
          type: types.PlatformsReview.id,
          platform:
            activeProperty[types.PlatformsReview.id]?.length > 0
              ? activeProperty[types.PlatformsReview.id][0]
              : undefined,
          title: getDefaultFieldValue('title'),
          subtitle: getDefaultFieldValue('subtitle'),
          comment: getDefaultFieldValue('comment'),
        },
  });

  const watchAllFields = watch();

  const {
    isLoading: apiLoading,
    error: apiError,
    mutate: apiFetch,
  } = useAxiosMutation({
    url: '/_link/insert',
  });

  const onSumbit = (data) => {
    const {
      platform: { site_url: link, site, title: platformTitle },
      brand: { id: brand },
      type,
      title,
      subtitle,
      comment,
      isDark,
      nfcIsEnabled,
    } = data;

    apiFetch(
      {
        property_id: activeProperty?.id,
        link,
        name: `${capitalize(site)} - ${startCase(toLower(platformTitle))}`,
        brand,
        type: type === types.PlatformsReview.id ? 'review' : 'social',
        platform: site,
        title,
        subtitle,
        comment,
        show_nfc: nfcIsEnabled ? 1 : 0,
        dark_version: isDark ? 1 : 0,
      },
      {
        onSuccess: () => {
          onClose(true);
        },
      }
    );
  };

  const printComponentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    pageStyle,
  });

  const handleDownload = async () => {
    const blob = await toBlob(printComponentRef.current);
    const filename = `${formatMessage({
      id: `app.common.${types[watchAllFields.type].headingKey}`,
    })} ${watchAllFields.platform.site}.png`;
    Utils.Dom.fileDownloadFromData(blob, filename);
  };

  useEffect(() => {
    if (!item) {
      if (activeProperty[watchAllFields.type]?.length > 0) {
        setValue('platform', activeProperty[watchAllFields.type][0]);
      } else {
        setValue('platform', undefined);
      }
      resetField('title');
      resetField('subtitle');
      resetField('comment');
    }
  }, [watchAllFields.type, activeProperty, setValue, resetField, item]);

  useEffect(() => {
    setLogoHeight(frameContainerHeight - dynamicAreaHeight);
  }, [dynamicAreaHeight, frameContainerHeight]);

  return (
    <>
      <Modal.Header closeButton={!apiLoading}>
        <Modal.Title as="h4">
          {item ? (
            item.name
          ) : (
            <FormattedMessage id="app.common.createNewLink" />
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {!item && (
            <>
              <Col xs="12">
                <RequestResult
                  type="error"
                  message={apiError}
                  className="mb-5"
                />
              </Col>
              <Col lg="6">
                <Form>
                  <Row>
                    <Col xs="12" className="mb-4">
                      <Form.Label>
                        <FormattedMessage id="app.common.brands" />
                      </Form.Label>
                      <Controller
                        control={control}
                        name="brand"
                        render={({ field: { onChange, value } }) => (
                          <Select
                            options={activeProperty.brands}
                            getOptionLabel={(option) =>
                              `${capitalize(option.name)}`
                            }
                            getOptionValue={(option) => `${option.id}`}
                            className="react-select-custom-container"
                            classNamePrefix="react-select-custom"
                            isSearchable={false}
                            id={`${watchAllFields.brand?.id}_select`}
                            value={value}
                            onChange={onChange}
                            isDisabled={apiLoading}
                            styles={{
                              menu: (baseStyles) => ({
                                ...baseStyles,
                                zIndex: 99,
                              }),
                            }}
                          />
                        )}
                      />
                    </Col>
                    <Col xs="12" className="mb-4">
                      <Form.Label>
                        <FormattedMessage id="app.common.type" />
                      </Form.Label>
                      <InputGroup className="input-group-sm-vertical">
                        {Object.keys(types).map((key) => {
                          const { id, labelKey } = types[key];
                          return (
                            <Form.Control key={id} as="label">
                              <Form.Check
                                label={
                                  <FormattedMessage
                                    id={`app.common.${labelKey}`}
                                  />
                                }
                                type="radio"
                                name="type"
                                id={id}
                                value={id}
                                {...register('type')}
                                disabled={apiLoading}
                              />
                            </Form.Control>
                          );
                        })}
                      </InputGroup>
                    </Col>
                    {activeProperty[watchAllFields.type]?.length > 0 ? (
                      <>
                        <Col xs="12" className="mb-4">
                          <Form.Label
                            htmlFor={`${watchAllFields.type.id}_select`}
                          >
                            <FormattedMessage
                              id="app.common.xPlatforms"
                              values={{
                                x: (
                                  <FormattedMessage
                                    id={`app.common.${
                                      types[watchAllFields.type].labelKey
                                    }`}
                                  />
                                ),
                              }}
                            />
                          </Form.Label>
                          <Controller
                            control={control}
                            name="platform"
                            render={({ field: { onChange, value } }) => (
                              <Select
                                options={
                                  activeProperty[types[watchAllFields.type].id]
                                }
                                getOptionLabel={(option) =>
                                  `${capitalize(option.site)}`
                                }
                                getOptionValue={(option) => `${option.site}`}
                                className="react-select-custom-container"
                                classNamePrefix="react-select-custom"
                                isSearchable={false}
                                id={`${types[watchAllFields.type].id}_select`}
                                value={value}
                                onChange={onChange}
                                isDisabled={apiLoading}
                                styles={{
                                  menu: (baseStyles) => ({
                                    ...baseStyles,
                                    zIndex: 98,
                                  }),
                                }}
                              />
                            )}
                          />
                        </Col>
                        <Col xs="12" className="mb-4">
                          <div className="d-flex justify-content-between">
                            <Form.Label htmlFor="title">
                              <FormattedMessage id="app.common.title" />
                            </Form.Label>
                            {dirtyFields.title && (
                              <Button
                                variant="link"
                                size="xs"
                                onClick={() => {
                                  resetField('title');
                                }}
                                disabled={apiLoading}
                              >
                                <FormattedMessage id="app.common.returnToDefault" />
                              </Button>
                            )}
                          </div>
                          <InputGroup className="input-group-merge">
                            <Form.Control
                              as="textarea"
                              id="title"
                              style={{ resize: 'none' }}
                              {...register('title')}
                              disabled={apiLoading}
                            />
                            {watchAllFields.title?.length > 0 && (
                              <InputGroup.Text
                                as="button"
                                type="button"
                                className="input-group-append"
                                onClick={() => {
                                  setValue('title', '', {
                                    shouldDirty: true,
                                  });
                                }}
                                disabled={apiLoading}
                              >
                                <i className="bi-x-lg" />
                              </InputGroup.Text>
                            )}
                          </InputGroup>
                        </Col>
                        <Col xs="12" className="mb-4">
                          <div className="d-flex justify-content-between">
                            <Form.Label htmlFor="subtitle">
                              <FormattedMessage id="app.common.subtitle" />
                            </Form.Label>
                            {dirtyFields.subtitle && (
                              <Button
                                variant="link"
                                size="xs"
                                onClick={() => {
                                  resetField('subtitle');
                                }}
                                disabled={apiLoading}
                              >
                                <FormattedMessage id="app.common.returnToDefault" />
                              </Button>
                            )}
                          </div>
                          <InputGroup className="input-group-merge">
                            <Form.Control
                              as="textarea"
                              id="subtitle"
                              style={{ resize: 'none' }}
                              {...register('subtitle')}
                              disabled={apiLoading}
                            />
                            {watchAllFields.subtitle?.length > 0 && (
                              <InputGroup.Text
                                as="button"
                                type="button"
                                className="input-group-append"
                                onClick={() => {
                                  setValue('subtitle', '', {
                                    shouldDirty: true,
                                  });
                                }}
                                disabled={apiLoading}
                              >
                                <i className="bi-x-lg" />
                              </InputGroup.Text>
                            )}
                          </InputGroup>
                        </Col>
                        <Col xs="12" className="mb-4">
                          <div className="d-flex justify-content-between">
                            <Form.Label htmlFor="comment">
                              <FormattedMessage id="app.common.comment" />
                            </Form.Label>
                            {dirtyFields.comment && (
                              <Button
                                variant="link"
                                size="xs"
                                onClick={() => {
                                  resetField('comment');
                                }}
                                disabled={apiLoading}
                              >
                                <FormattedMessage id="app.common.returnToDefault" />
                              </Button>
                            )}
                          </div>
                          <InputGroup className="input-group-merge">
                            <Form.Control
                              as="textarea"
                              id="comment"
                              style={{ resize: 'none' }}
                              {...register('comment')}
                              disabled={apiLoading}
                            />
                            {watchAllFields.comment?.length > 0 && (
                              <InputGroup.Text
                                as="button"
                                type="button"
                                className="input-group-append"
                                onClick={() => {
                                  setValue('comment', '', {
                                    shouldDirty: true,
                                  });
                                }}
                                disabled={apiLoading}
                              >
                                <i className="bi-x-lg" />
                              </InputGroup.Text>
                            )}
                          </InputGroup>
                        </Col>
                        <Col xs="12" className="mb-4">
                          <Form.Check
                            type="switch"
                            id="nfc-switch"
                            label={
                              <FormattedMessage id="app.common.showNfcLogo" />
                            }
                            className="d-flex align-items-center"
                            {...register('nfcIsEnabled')}
                            disabled={apiLoading}
                          />
                        </Col>
                        <Col xs="12">
                          <Form.Check
                            type="switch"
                            id="theme-switch"
                            label={
                              <FormattedMessage id="app.common.darkVersion" />
                            }
                            className="d-flex align-items-center"
                            {...register('isDark')}
                            disabled={apiLoading}
                          />
                        </Col>
                      </>
                    ) : (
                      <Col xs="12">
                        <RequestResult
                          type="secondary"
                          message={formatMessage(
                            { id: 'app.common.noXPlatformsProvided' },
                            {
                              x: lowerCase(
                                formatMessage({
                                  id: `app.common.${
                                    types[watchAllFields.type].labelKey
                                  }`,
                                })
                              ),
                            }
                          )}
                        />
                      </Col>
                    )}
                  </Row>
                </Form>
              </Col>
            </>
          )}
          {watchAllFields.platform && (
            <Col lg={item ? '12' : '6'} className="mt-3 mt-lg-0">
              {item && (
                <div className="d-flex gap-2 justify-content-center mb-3">
                  <Button onClick={handlePrint}>
                    <i className="bi-printer me-1" />
                    <FormattedMessage id="app.common.print" />
                  </Button>
                  <Button variant="white" onClick={handleDownload}>
                    <i className="bi-download me-1" />
                    <FormattedMessage id="app.common.download" />
                  </Button>
                </div>
              )}
              <div className="d-flex align-items-center justify-content-center rounded overflow-hidden">
                <div
                  ref={printComponentRef}
                  id="printDiv"
                  className="position-relative"
                  style={{
                    width: '100%',
                    paddingTop: `${PRINT_AREA_ASPECT_RATIO}%`,
                    background: watchAllFields.isDark
                      ? DARK_BACKGROUND_COLOR
                      : LIGHT_BACKGROUND_COLOR,
                    color: watchAllFields.isDark
                      ? DARK_TEXT_COLOR
                      : LIGHT_TEXT_COLOR,
                  }}
                >
                  <div className="position-absolute top-0 start-0 bottom-0 end-0">
                    <div
                      ref={frameContainerRef}
                      className="d-flex flex-column align-items-center justify-content-between h-100 p-5"
                    >
                      <div className="w-100 text-center">
                        {watchAllFields.brand ? (
                          <div
                            className="d-flex align-items-center justify-content-center w-100 text-center pb-5"
                            style={{ height: logoHeight }}
                          >
                            {watchAllFields.brand?.logo_url ? (
                              <Image
                                src={watchAllFields.brand.logo_url}
                                style={{ objectFit: 'contain' }}
                                className="h-100 w-100"
                              />
                            ) : (
                              <h1 className="text-reset text-center">
                                {watchAllFields.brand.name ||
                                  activeProperty.title}
                              </h1>
                            )}
                          </div>
                        ) : (
                          <h1 className="text-reset text-center">
                            {activeProperty.title}
                          </h1>
                        )}
                      </div>
                      <div ref={dynamicAreaRef}>
                        <div className="d-flex flex-column align-items-center justify-content-center flex-fill">
                          {watchAllFields.title && (
                            <h5
                              className="text-reset text-center"
                              // eslint-disable-next-line react/no-danger
                              dangerouslySetInnerHTML={{
                                __html: watchAllFields.title.replace(
                                  /\n/g,
                                  '<br />'
                                ),
                              }}
                              style={{ fontSize: '140%' }}
                            />
                          )}

                          {watchAllFields.subtitle && (
                            <h6
                              className="mt-3 text-reset text-center"
                              // eslint-disable-next-line react/no-danger
                              dangerouslySetInnerHTML={{
                                __html: watchAllFields.subtitle.replace(
                                  /\n/g,
                                  '<br />'
                                ),
                              }}
                              style={{ fontSize: '100%' }}
                            />
                          )}

                          <div className="d-flex mt-3 mb-3">
                            {watchAllFields.nfcIsEnabled && (
                              <div
                                className="me-4"
                                style={{ width: PRINT_AREA_WIDTH }}
                              >
                                <NFC
                                  className="w-100"
                                  style={{
                                    fill: watchAllFields.isDark
                                      ? DARK_TEXT_COLOR
                                      : LIGHT_TEXT_COLOR,
                                  }}
                                />
                              </div>
                            )}
                            <div className="position-relative">
                              {!item && (
                                <>
                                  <div className="position-absolute bg-dark opacity-75 top-0 bottom-0 end-0 start-0" />
                                  <div className="position-absolute top-50 start-50 translate-middle text-white fw-bold fs-3">
                                    <FormattedMessage id="app.common.sample" />
                                  </div>
                                </>
                              )}
                              <QRCodeSVG
                                value={
                                  item
                                    ? `${shortenedUrl}/${item.shortened}`
                                    : shortenedUrl
                                }
                                size={PRINT_AREA_WIDTH}
                              />
                            </div>
                          </div>
                          {watchAllFields.comment && (
                            <h6
                              className="mt-3 text-reset text-center"
                              // eslint-disable-next-line react/no-danger
                              dangerouslySetInnerHTML={{
                                __html: watchAllFields.comment.replace(
                                  /\n/g,
                                  '<br />'
                                ),
                              }}
                              style={{ fontSize: '100%' }}
                            />
                          )}
                        </div>
                        <div className="d-flex flex-column align-items-center justify-content-center">
                          <h4
                            className="mt-3 text-reset text-center"
                            style={{ fontSize: '120%' }}
                          >
                            <FormattedMessage
                              id={`app.common.${
                                types[watchAllFields.type].headingKey
                              }`}
                            />
                          </h4>
                          <div className="d-flex flex-column align-items-center justify-content-center mt-3">
                            <BrandLogo
                              brand={watchAllFields.platform.site}
                              forceFill={
                                watchAllFields.isDark ? 'dark' : 'default'
                              }
                              size={PRINT_AREA_WIDTH * 0.5}
                            />
                            {types[watchAllFields.type].id ===
                              'PlatformsReview' && (
                              <Rating
                                initVal={5}
                                className="mt-2"
                                iconClassName="fs-1"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          )}
        </Row>
        <div className="d-flex w-100 align-items-center justify-content-end gap-2 mt-5">
          {!item && (
            <>
              <Button
                variant="secondary"
                onClick={onClose}
                disabled={apiLoading}
              >
                <FormattedMessage id="app.common.cancel" />
              </Button>
              <Button
                onClick={() => {
                  handleSubmit(onSumbit)();
                }}
                disabled={
                  apiLoading ||
                  activeProperty[watchAllFields.type]?.length === 0
                }
              >
                {apiLoading ? (
                  <>
                    <Spinner animation="border" size="sm" className="me-1" />
                    <FormattedMessage id="app.common.loading" />
                  </>
                ) : (
                  <>
                    <i className="bi-plus me-1" />
                    <FormattedMessage id="app.common.create" />
                  </>
                )}
              </Button>
            </>
          )}
        </div>
      </Modal.Body>
      {item && (
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            <FormattedMessage id="app.common.close" />
          </Button>
        </Modal.Footer>
      )}
    </>
  );
}

ReviewDetail.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  onClose: PropTypes.func,
};
ReviewDetail.defaultProps = {
  item: null,
  onClose: () => {},
};

function UrlColumn({ value }) {
  const [show, setShow] = useState(undefined);
  const [copyState, copyToClipboard] = useCopyToClipboard();
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (copyState?.value) {
      setShow(false);
      setTimeout(() => {
        setShow(undefined);
      }, 100);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    }
  }, [copyState?.value]);

  return (
    <InputGroup className="input-group-merge">
      <Form.Control defaultValue={value} readOnly />
      <InputGroup.Text
        className="input-group-append"
        style={{ cursor: 'pointer' }}
        onClick={() => {
          copyToClipboard(value);
        }}
      >
        <Tooltip
          show={show}
          placement="top"
          content={
            <FormattedMessage
              id={`app.common.${isCopied ? 'copied' : 'copyToClipboard'}`}
            />
          }
        >
          <i className={`bi-${isCopied ? 'check' : 'clipboard'}`} />
        </Tooltip>
      </InputGroup.Text>
    </InputGroup>
  );
}

UrlColumn.propTypes = {
  value: PropTypes.string,
};
UrlColumn.defaultProps = {
  value: '',
};

function ReviewGenerator() {
  const { activeProperty } = useContext(PropertyContext);
  const { formatMessage } = useIntl();

  const tableRef = useRef();

  const [detailIsVisible, setDetailIsVisible] = useState(false);
  const [activeDetailItem, setActiveDetailItem] = useState();

  const { confirm } = useConfirmModal({ confirmVariant: 'danger' });
  const { showToast } = useToast();

  const onDeleteRow = useCallback(
    async (id, title) => {
      const isSuccess = await confirm({
        message: (
          <FormattedMessage
            id="app.common.areYouSureYouWantToDeleteXY"
            values={{
              x: title,
              y: lowerCase(formatMessage({ id: 'app.common.link' })),
            }}
          />
        ),
        requestUrl: '/_link/delete',
        requestParams: { id },
      });
      if (isSuccess) {
        tableRef.current.reload();

        showToast({
          type: 'success',
          autohide: true,
          title: <FormattedMessage id="app.common.success" />,
          message: (
            <FormattedMessage
              id="app.common.theXWasDeletedSuccessfully"
              values={{
                x: lowerCase(formatMessage({ id: 'app.common.link' })),
              }}
            />
          ),
        });
      }
    },
    [confirm, formatMessage, showToast]
  );

  const columns = [
    {
      Header: <FormattedMessage id="app.common.name" />,
      accessor: 'name',
      Cell: ({ cell: { value } }) => value,
    },
    {
      Header: <FormattedMessage id="app.common.link" />,
      accessor: 'shortened',
      minWidth: 300,
      Cell: useCallback(
        ({ cell: { value } }) => (
          <UrlColumn value={`${shortenedUrl}/${value}`} />
        ),
        []
      ),
    },
    {
      Header: <FormattedMessage id="app.common.totalClicks" />,
      accessor: 'total',
      textAlign: 'end',
    },
    {
      Header: <FormattedMessage id="app.common.lastMonth" />,
      accessor: 'last_month',
      textAlign: 'end',
      Cell: useCallback(({ cell: { value }, row: { original } }) => {
        const numA = value;
        const numB = original.prev_month;

        const percentageInc = (numA - numB) / numB;

        let color = 'danger';
        let dir = 'down';

        if (percentageInc > 0) {
          color = 'success';
          dir = 'up';
        }

        return (
          <div className="d-flex w-100 align-items-start justify-content-end">
            <span className="me-1">{value}</span>
            {numB > 0 && percentageInc !== 0 && (
              <Badge bg={`soft-${color}`} className={`text-${color}`}>
                <i className={`bi-graph-${dir}-arrow`} />
                <span className="ms-1 fw-bold">
                  <FormattedNumber
                    value={percentageInc}
                    // eslint-disable-next-line react/style-prop-object
                    style="percent"
                    maximumFractionDigits={0}
                  />
                </span>
              </Badge>
            )}
          </div>
        );
      }, []),
    },
    {
      Header: <FormattedMessage id="app.common.previousMonth" />,
      accessor: 'prev_month',
      textAlign: 'end',
      Cell: useCallback(
        ({ cell: { value } }) =>
          value === 0 ? (
            <Badge bg="soft-secondary" className="text-secondary">
              <FormattedMessage id="app.common.n/a" />
            </Badge>
          ) : (
            value
          ),
        []
      ),
    },
    {
      accessor: 'id',
      textAlign: 'end',
      Cell: useCallback(
        ({ cell: { value }, row: { original } }) => (
          <Dropdown align="end">
            <ButtonGroup>
              <Button
                variant="white"
                size="sm"
                onClick={() => {
                  setActiveDetailItem(original);
                  setDetailIsVisible(true);
                }}
              >
                <i className="bi-eye me-1" />
                <FormattedMessage id="app.common.view" />
              </Button>
              <ButtonGroup>
                <Dropdown.Toggle
                  variant="white"
                  size="sm"
                  className="btn-icon dropdown-toggle-empty"
                />
              </ButtonGroup>
            </ButtonGroup>
            <Dropdown.Menu className="m-0" renderOnMount>
              <Dropdown.Header>
                <FormattedMessage id="app.common.actions" />
              </Dropdown.Header>
              <Dropdown.Item
                onClick={() => {
                  onDeleteRow(
                    value,
                    `${shortenedUrl}/${original.shortened} (${original.name})`
                  );
                }}
              >
                <i className="bi-trash dropdown-item-icon text-danger" />
                <span className="text-danger">
                  <FormattedMessage id="app.common.delete" />
                </span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
        [onDeleteRow]
      ),
    },
  ];

  return (
    <>
      <Card>
        <Card.Header className="card-header-content-md-between">
          <span />
          <Button
            onClick={() => {
              setDetailIsVisible(true);
            }}
          >
            <i className="bi-plus me-1" />
            <FormattedMessage id="app.common.createNewLink" />
          </Button>
        </Card.Header>
        <SmartTable
          ref={tableRef}
          columns={columns}
          requestUrl="/_link/list"
          requestParams={{ property_id: activeProperty?.id }}
          noDataOptions={{
            title: 'emptyState.reviewGenerator.title',
            message: 'emptyState.reviewGenerator.message',
          }}
        />
      </Card>
      <Modal
        show={detailIsVisible}
        onHide={() => {
          setDetailIsVisible(false);
        }}
        onExited={() => {
          setActiveDetailItem(null);
        }}
        // scrollable
        size={activeDetailItem ? '' : 'xl'}
        backdrop="static"
        keyboard={false}
      >
        <ReviewDetail
          item={activeDetailItem}
          onClose={(success) => {
            if (success) {
              tableRef.current.reload();
            }
            setDetailIsVisible(false);
          }}
        />
      </Modal>
    </>
  );
}

export default ReviewGenerator;
