import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  Card,
  Collapse,
  Button,
  Form,
  Badge,
  ButtonGroup,
  Dropdown,
  Modal,
  Row,
  Col,
  Spinner,
  InputGroup,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { FormattedMessage, FormattedPlural, useIntl } from 'react-intl';
import { findAll } from 'highlight-words-core';
import { debounce } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { useCopyToClipboard } from 'react-use';
import {
  BrandLogo,
  CountryFlag,
  Rating,
  RequestResult,
  ShowMoreText,
  SmartForm,
  SmartTable,
  Tooltip,
} from '../../../components';
import { yup } from '../../../lib';
import Constants from '../../../constants';
import { PropertyContext } from '../../../context/PropertyContext';
import { useAxiosQuery } from '../../../hooks';
import { languages } from '../../../context/LangContext';
import NoDataIllustration from '../../../assets/images/svg/illustrations/oc-reviewing.svg';
import NoDataIllustrationLight from '../../../assets/images/svg/illustrations-light/oc-reviewing.svg';

const supportedAILanguages = [
  'de',
  'en',
  'es',
  'fr',
  'hi',
  'pt',
  'ru',
  'tr',
  'zh',
  'bn',
  'ja',
  'id',
  'nl',
  'it',
];

const getFeel = (value) => {
  let result = Constants.Review.Types.Neutral.id;
  switch (Math.round(value)) {
    case 1:
    case 2:
      result = Constants.Review.Types.Negative.id;
      break;

    case 4:
    case 5:
      result = Constants.Review.Types.Positive.id;
      break;

    case 3:
      result = Constants.Review.Types.Neutral.id;
      break;

    default:
      result = Constants.Review.Types.Neutral.id;
      break;
  }

  return result;
};

const sourceOptionLabel = ({ id }) => (
  <div className="d-flex">
    <BrandLogo brand={id} size={20} />
  </div>
);

const compsetOptionLabel = ({ color, name }) => (
  <div className="d-flex align-items-center">
    <span className="legend-indicator" style={{ backgroundColor: color }} />
    {name}
  </div>
);

const rateOptionLabel = ({ id, label }) => (
  <div className="d-flex gap-1 align-items-center">
    <Rating initVal={id} /> {label}
  </div>
);

function rateOption({
  getStyles,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...props
}) {
  return (
    <components.Option
      {...props}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={innerProps}
    >
      <div className="d-flex justify-content-between">
        {children}
        {isSelected && <i className="bi bi-check2" />}
      </div>
    </components.Option>
  );
}

rateOption.propTypes = {
  getStyles: PropTypes.any.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isFocused: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  innerProps: PropTypes.any.isRequired,
};

const typeOptionLabel = ({ id }) => (
  <div className="d-flex">
    <Badge bg={`${Constants.Review.Types[id].color}`}>
      <i className={`${Constants.Review.Types[id].icon} fs-6 me-1`} />
      <FormattedMessage
        id={`app.common.${Constants.Review.Types[id].labelKey}`}
      />
    </Badge>
  </div>
);

const statusOptionLabel = ({ id }) => (
  <div className="d-flex align-items-center">
    <span
      className={`legend-indicator bg-${Constants.Review.Status[id].color}`}
    />
    <FormattedMessage
      id={`app.common.${Constants.Review.Status[id].labelKey}`}
    />
  </div>
);

function ValueContainer({ children, ...props }) {
  return (
    <components.ValueContainer {...props}>
      {Array.isArray(children[0]) && children[0].length > 0 ? (
        <div className="position-absolute text-nowrap">
          <FormattedPlural
            value={children[0].length}
            one={<FormattedMessage id="app.common.oneItemSelected" />}
            other={
              <FormattedMessage
                id="app.common.nItemsSelected"
                values={{ n: children[0].length }}
              />
            }
          />
        </div>
      ) : (
        children[0]
      )}
      {children[1]}
    </components.ValueContainer>
  );
}

ValueContainer.propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.objectOf(PropTypes.any),
      PropTypes.arrayOf(PropTypes.any),
    ])
  ),
};

ValueContainer.defaultProps = {
  children: [],
};

function ReviewComment({ maxLine, text, searchText }) {
  const getHighlightedText = (sText, fullText) => {
    const chunks = findAll({
      searchWords: Array.isArray(sText) ? sText : [sText],
      textToHighlight: fullText,
    });

    const highlightedText = chunks
      .map((chunk) => {
        const { end, highlight, start } = chunk;
        const t = fullText.substr(start, end - start);
        if (highlight) {
          return `<mark class="bg-primary text-white rounded px-2">${t}</mark>`;
        }
        return t;
      })
      .join('');

    return highlightedText;
  };

  return (
    <ShowMoreText
      text={getHighlightedText(searchText, text)}
      maxLine={maxLine}
      basedOn="words"
    />
  );
}
ReviewComment.propTypes = {
  maxLine: PropTypes.number,
  text: PropTypes.string,
  searchText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};
ReviewComment.defaultProps = {
  maxLine: 2,
  text: '',
  searchText: null,
};

function ReviewDetail({ item, businessName }) {
  const textAreaRef = useRef();
  const [isTyping, setIsTyping] = useState(false);
  const timer = useRef();
  const [copyState, copyToClipboard] = useCopyToClipboard();
  const [isCopied, setIsCopied] = useState(false);

  const writeIt = (txt) => {
    const speed = 50;
    let counter = 0;
    textAreaRef.current.value = '';
    if (timer.current) {
      clearTimeout(timer.current);
      setIsTyping(false);
    }
    function typeWriter() {
      if (counter < txt.length) {
        textAreaRef.current.value += txt.charAt(counter);
        counter += 1;
        setIsTyping(true);
        timer.current = setTimeout(typeWriter, speed);
      } else {
        counter = 0;
        setIsTyping(false);
      }
    }

    if (txt) {
      typeWriter();
    }
  };

  const {
    isFetching: apiLoading,
    error: apiError,
    refetch: apiFetch,
  } = useAxiosQuery({
    url: '/reputation_manager/get_ai_response',
    preventFetch: true,
    params: {
      language:
        item.lang && supportedAILanguages.find((l) => l === item.lang)
          ? item.lang
          : Object.keys(languages)[0],
      rating: item.rating ? Math.floor(item.rating) : 0,
    },
    onSuccess: (data) => {
      if (data?.reviewMessage) {
        const txt = data.reviewMessage
          .replace('{ClientName}', item.review_title)
          .replace('{BusinessName}', businessName);
        writeIt(txt);
      }
    },
  });

  useEffect(
    () => () => {
      if (timer.current) {
        clearTimeout(timer.current);
        setIsTyping(false);
      }
    },
    []
  );

  useEffect(() => {
    if (copyState?.value) {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    }
  }, [copyState?.value]);

  const feel = getFeel(item.rating);

  return (
    <>
      <Row className="justify-content-center justify-content-sm-between align-items-sm-center">
        <Col sm className="mb-2 mb-md-0">
          <BrandLogo brand={item.site} size={30} />
        </Col>
        <Col sm="auto" className="d-flex">
          <div className="me-3">
            {item.rating > 0 ? (
              <Rating initVal={item.rating} />
            ) : (
              <Badge bg="secondary">
                <FormattedMessage id="app.common.notRated" />
              </Badge>
            )}
          </div>
          <div>
            <Badge bg={`${Constants.Review.Types[feel].color}`}>
              <i className={`${Constants.Review.Types[feel].icon} fs-6 me-1`} />
              <FormattedMessage
                id={`app.common.${Constants.Review.Types[feel].labelKey}`}
              />
            </Badge>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center justify-content-sm-between align-items-sm-center mt-4">
        <Col sm className="mb-2 mb-md-0">
          <h4>
            {item.lang && Object.keys(languages)[0] !== item.lang && (
              <CountryFlag countryCode={item.lang} svg className="me-2" />
            )}
            {item.review_title}
          </h4>
        </Col>
        <Col sm="auto">
          {moment(item.date).format(Constants.DateFormats.APP.Moment.Common)}
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          {item.comment ? (
            <ReviewComment text={item.comment} maxLine={5} />
          ) : (
            <Badge bg="secondary">
              <FormattedMessage id="app.common.noReview" />
            </Badge>
          )}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <span
            className={`legend-indicator bg-${
              Constants.Review.Status[item.status].color
            }`}
          />
          <FormattedMessage
            id={`app.common.${Constants.Review.Status[item.status].labelKey}`}
          />
        </Col>
      </Row>
      {item.res?.[0] && (
        <Row className="mt-4">
          <Col xs="12">
            <div className="d-flex mt-2">
              <div className="me-2">
                <i className="bi-reply" />
              </div>
              <blockquote className="blockquote blockquote-sm">
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <h5 className="mb-0">{item.res[0].title}</h5>
                    <ul className="list-inline fs-6">
                      <li className="list-inline-item">
                        {moment(item.res[0].date).format(
                          Constants.DateFormats.APP.Moment.Common
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
                {item.res[0].comment}
              </blockquote>
            </div>
          </Col>
        </Row>
      )}
      {item.status !== Constants.Review.Status[2].id && (
        <>
          <RequestResult type="error" message={apiError} className="mt-4" />
          <Row className="mt-4">
            <Col xs="12">
              <Form.Control
                as="textarea"
                rows={3}
                ref={textAreaRef}
                readOnly={isTyping}
                disabled={apiLoading}
              />
            </Col>
          </Row>
          <Row className="justify-content-center justify-content-md-between align-items-md-center mt-4">
            <Col md className="mb-2 mb-lg-0">
              <Button
                variant="primary"
                disabled={apiLoading || isTyping}
                onClick={() => {
                  writeIt();
                  apiFetch();
                }}
              >
                {apiLoading && (
                  <>
                    <Spinner animation="border" size="sm" className="me-1" />
                    <FormattedMessage id="app.common.loading" />
                  </>
                )}
                {isTyping && (
                  <>
                    <Spinner animation="grow" size="sm" className="me-1" />
                    <FormattedMessage id="app.common.generatingAiResponse" />
                  </>
                )}
                {!apiLoading && !isTyping && (
                  <>
                    <i className="bi-robot me-1" />
                    <FormattedMessage id="app.common.generateAiResponse" />
                  </>
                )}
              </Button>
            </Col>
            <Col md="auto">
              <Button
                variant="secondary"
                disabled={apiLoading || isTyping}
                onClick={() => {
                  copyToClipboard(textAreaRef.current.value);
                }}
              >
                {isCopied ? (
                  <>
                    <i className="bi-clipboard2-check me-1" />
                    <FormattedMessage id="app.common.copied" />
                  </>
                ) : (
                  <>
                    <i className="bi-clipboard2 me-1" />
                    <FormattedMessage id="app.common.copyResponse" />
                  </>
                )}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
ReviewDetail.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  businessName: PropTypes.string,
};
ReviewDetail.defaultProps = {
  item: null,
  businessName: '',
};

function Reviews() {
  const tableRef = useRef();
  const filterFormRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filtersIsVisible, setFiltersIsVisible] = useState(false);
  const [detailIsVisible, setDetailIsVisible] = useState(false);
  const [activeDetailItem, setActiveDetailItem] = useState();
  const [filters, setFilters] = useState({});
  const { formatMessage } = useIntl();

  const { activeProperty } = useContext(PropertyContext);

  const { data: apiData } = useAxiosQuery({
    url: '/review_keywords/list',
    preventFetch: !activeProperty?.id,
    params: { hotel_id: activeProperty?.id },
  });

  // const { confirm } = useConfirmModal({ confirmVariant: 'danger' });
  // const { form } = useFormModal();
  // const { showToast } = useToast();

  /* const onActionRow = useCallback(
    async (id, type) => {
      const title = type;
      const confirmLabel = type;
      const requestUrl = '/reviews/list';
      const requestParams = { id };
      let fetchOnStart;
      let fields;
      let message;

      switch (type) {
        case 'share':
          fields = [
            {
              cols: [
                {
                  key: 'email',
                  schema: yup.string().email().required(),
                },
              ],
            },
          ];
          break;

        case 'addToFavorites':
          message = 'something something...';
          fetchOnStart = true;
          break;

        default:
          break;
      }

      const isSuccess = await form({
        title,
        confirmLabel,
        requestUrl,
        requestParams,
        fields,
        message,
        fetchOnStart,
      });

      if (isSuccess) {
        tableRef.current.reload();

        if (type === 'addToFavorites') {
          showToast({
            type: 'success',
            autohide: true,
            title: 'x1',
            message: 'y2',
          });
        }
      }
    },
    [form, showToast]
  ); */

  /* const onDeleteRow = useCallback(
    async (id, title) => {
      const isSuccess = await confirm({
        message: (
          <FormattedMessage
            id="app.common.areYouSureYouWantToDeleteXY"
            values={{
              x: title,
              y: lowerCase(formatMessage({ id: 'app.common.review' })),
            }}
          />
        ),
        requestUrl: '/reviews/list',
        requestParams: { id },
      });
      if (isSuccess) {
        tableRef.current.reload();
      }
    },
    [confirm, formatMessage]
  ); */

  const columns = [
    {
      Header: <FormattedMessage id="app.common.site" />,
      accessor: 'site',
      Cell: useCallback(
        ({ cell: { value } }) => <BrandLogo brand={value} size={20} />,
        []
      ),
    },
    {
      Header: <FormattedMessage id="app.common.property" />,
      accessor: 'PropertyID',
      wrap: false,
      minWidth: '137px',
      Cell: useCallback(
        ({
          cell: {
            row: { original },
          },
        }) => (
          <div className="text-center">
            {original.PropertyID === activeProperty?.id ? (
              <Tooltip content={original.title}>
                <Badge bg="primary">
                  <i className="bi-check-circle-fill me-1" />
                  <FormattedMessage id="app.common.myProperty" />
                </Badge>
              </Tooltip>
            ) : (
              <Tooltip content={original.PropertyTitle}>
                <Badge bg="secondary">
                  <i className="bi-arrow-up-right-circle-fill me-1" />
                  <FormattedMessage id="app.common.competitor" />
                </Badge>
              </Tooltip>
            )}
          </div>
        ),
        [activeProperty?.id]
      ),
    },
    {
      Header: <FormattedMessage id="app.common.review" />,
      accessor: 'comment',
      wrap: true,
      minWidth: '30%',
      disableNullControl: true,
      Cell: useCallback(
        ({ cell: { value } }) =>
          value ? (
            <ReviewComment
              text={value}
              searchText={
                filters?.search || apiData?.map((item) => item.keyword) || null
              }
            />
          ) : (
            <Badge bg="secondary">
              <FormattedMessage id="app.common.noReview" />
            </Badge>
          ),
        [filters?.search, apiData]
      ),
    },
    {
      Header: <FormattedMessage id="app.common.reviewer" />,
      accessor: 'review_title',
      Cell: useCallback(
        ({
          cell: {
            value,
            row: { original },
          },
        }) => (
          <>
            {original.lang && Object.keys(languages)[0] !== original.lang && (
              <CountryFlag countryCode={original.lang} svg className="me-2" />
            )}
            {value}
          </>
        ),
        []
      ),
    },
    {
      Header: <FormattedMessage id="app.common.date" />,
      accessor: 'date',
      Cell: ({ cell: { value } }) =>
        moment(value).format(Constants.DateFormats.APP.Moment.Common),
    },
    {
      Header: <FormattedMessage id="app.common.rating" />,
      accessor: 'rating',
      disableNullControl: true,
      Cell: useCallback(
        ({ cell: { value } }) =>
          value > 0 ? (
            <Rating initVal={value} />
          ) : (
            <Badge bg="secondary">
              <FormattedMessage id="app.common.notRated" />
            </Badge>
          ),
        []
      ),
    },
    {
      Header: <FormattedMessage id="app.common.type" />,
      accessor: 'feel',
      Cell: useCallback(
        ({
          cell: {
            row: { original },
          },
        }) => {
          const val = getFeel(original.rating);

          return (
            <Badge bg={`${Constants.Review.Types[val].color}`}>
              <i className={`${Constants.Review.Types[val].icon} fs-6 me-1`} />
              <FormattedMessage
                id={`app.common.${Constants.Review.Types[val].labelKey}`}
              />
            </Badge>
          );
        },
        []
      ),
    },
    {
      Header: <FormattedMessage id="app.common.status" />,
      accessor: 'status',
      Cell: useCallback(
        ({ cell: { value } }) => (
          <>
            <span
              className={`legend-indicator bg-${Constants.Review.Status[value].color}`}
            />
            <FormattedMessage
              id={`app.common.${Constants.Review.Status[value].labelKey}`}
            />
          </>
        ),
        []
      ),
    },
    {
      accessor: 'id',
      Cell: useCallback(
        ({
          cell: {
            row: { original },
          },
        }) =>
          original.status !== Constants.Review.Status[2].id ? (
            <Dropdown align="end">
              <ButtonGroup>
                <Button
                  variant="white"
                  size="sm"
                  onClick={() => {
                    setActiveDetailItem(original);
                    setDetailIsVisible(true);
                  }}
                >
                  <i className="bi-eye me-1" />
                  <FormattedMessage id="app.common.view" />
                </Button>
                <ButtonGroup>
                  <Dropdown.Toggle
                    variant="white"
                    size="sm"
                    className="btn-icon dropdown-toggle-empty"
                  />
                </ButtonGroup>
              </ButtonGroup>
              <Dropdown.Menu className="m-0" renderOnMount>
                <Dropdown.Header>
                  <FormattedMessage id="app.common.actions" />
                </Dropdown.Header>
                <Dropdown.Item
                  onClick={() => {
                    setActiveDetailItem(original);
                    setDetailIsVisible(true);
                  }}
                >
                  <i className="bi-robot dropdown-item-icon" />
                  <span>
                    <FormattedMessage id="app.common.generateAiResponse" />
                  </span>
                </Dropdown.Item>
                {/* <Dropdown.Item
                  onClick={() => {
                    onActionRow(value, 'share');
                  }}
                >
                  <i className="bi-share dropdown-item-icon" />
                  <span>
                    <FormattedMessage id="app.common.share" />
                  </span>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    onActionRow(value, 'addToFavorites');
                  }}
                >
                  <i className="bi-star dropdown-item-icon" />
                  <span>
                    <FormattedMessage id="app.common.addToFavorites" />
                  </span>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  onClick={() => {
                    onDeleteRow(value, original.review_title);
                  }}
                >
                  <i className="bi-trash dropdown-item-icon" />
                  <span>
                    <FormattedMessage id="app.common.delete" />
                  </span>
                </Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <Button
              variant="white"
              size="sm"
              onClick={() => {
                setActiveDetailItem(original);
                setDetailIsVisible(true);
              }}
            >
              <i className="bi-eye me-1" />
              <FormattedMessage id="app.common.view" />
            </Button>
          ),
        []
      ),
    },
  ];

  const rates = useMemo(
    () =>
      Array(6)
        .fill(0)
        .map((x, i) => ({
          id: 5 - i,
          label:
            i === 5 ? (
              <FormattedMessage id="app.common.notRated" />
            ) : (
              <FormattedPlural
                value={5 - i}
                one={<FormattedMessage id="app.common.star" />}
                other={<FormattedMessage id="app.common.stars" />}
              />
            ),
        })),
    []
  );

  const filterFields = useMemo(
    () => [
      {
        cols: [
          {
            key: 'sources',
            apiKey: 'site',
            type: 'react-select',
            options: {
              controller: {
                props: {
                  options: activeProperty?.PlatformsReview
                    ? Object.values(Constants.Review.Sites).filter((item) =>
                        activeProperty?.PlatformsReview.map(
                          (nItem) => nItem.site
                        ).includes(item.id)
                      )
                    : Object.values(Constants.Review.Sites),
                  getOptionValue: (option) => `${option.id}`,
                  formatOptionLabel: sourceOptionLabel,
                  isClearable: true,
                  isMulti: true,
                },
              },
            },
            schema: yup.array().nullable(),
          },
          {
            key: 'compset',
            apiKey: 'compsetID',
            type: 'react-select',
            options: {
              controller: {
                props: {
                  options: activeProperty?.groups,
                  getOptionValue: (option) => `${option.id}`,
                  formatOptionLabel: compsetOptionLabel,
                  isClearable: true,
                  isMulti: false,
                  isSearchable: false,
                },
              },
            },
            schema: yup.string().nullable(),
          },
          {
            key: 'dateRange',
            apiKey: 'date',
            type: 'dateRangePicker',
            schema: yup.array().min(2).nullable(),
          },
          /* {
            key: 'dateRange',
            apiKey: 'date',
            type: 'flatpickr',
            options: {
              controller: {
                options: { mode: 'range' },
              },
            },
            schema: yup.array().min(2).nullable(),
          }, */
        ],
      },
      {
        cols: [
          {
            key: 'rates',
            apiKey: 'rating',
            type: 'react-select',
            options: {
              controller: {
                props: {
                  options: rates,
                  getOptionValue: (option) => `${option.id}`,
                  formatOptionLabel: rateOptionLabel,
                  components: { ValueContainer, Option: rateOption },
                  hideSelectedOptions: false,
                  isClearable: true,
                  isMulti: true,
                  isSearchable: false,
                },
              },
            },
            schema: yup.array().nullable(),
          },
          {
            key: 'type',
            apiKey: 'feel',
            type: 'react-select',
            options: {
              controller: {
                props: {
                  options: Object.values(Constants.Review.Types),
                  getOptionValue: (option) => `${option.id}`,
                  formatOptionLabel: typeOptionLabel,
                  isClearable: true,
                  isMulti: false,
                  isSearchable: false,
                },
              },
            },
            schema: yup.string().nullable(),
          },
          {
            key: 'status',
            apiKey: 'status',
            type: 'react-select',
            options: {
              controller: {
                props: {
                  options: Object.values(Constants.Review.Status),
                  getOptionValue: (option) => `${option.id}`,
                  formatOptionLabel: statusOptionLabel,
                  isClearable: true,
                  isMulti: false,
                  isSearchable: false,
                },
              },
            },
            schema: yup.string().nullable(),
          },
        ],
      },
    ],
    [rates, activeProperty?.groups, activeProperty?.PlatformsReview]
  );

  const parseSearchParams = useCallback(
    (isAPI) => {
      const getVal = (val, key) => {
        if (isAPI) {
          return val;
        }
        if (key === 'dateRange') {
          return moment(val).toDate();
        }
        return val;
      };

      const params = {};
      searchParams.forEach((value, key) => {
        let isArray = false;
        filterFields.forEach((row) => {
          row.cols.forEach((col) => {
            if (
              col.key === key &&
              (col.options?.controller?.props?.isMulti ||
                col.key === 'dateRange')
            ) {
              isArray = true;
            }
          });
        });

        if (!params[key]) {
          params[key] = !isArray ? getVal(value, key) : [getVal(value, key)];
        } else {
          params[key] = [
            ...(Array.isArray(params[key]) ? params[key] : [params[key]]),
            getVal(value, key),
          ];
        }
      });
      return params;
    },
    [searchParams, filterFields]
  );

  const handleSearch = (text) => {
    const params = parseSearchParams(true);
    if (text) {
      params.search = text;
    } else {
      delete params.search;
    }
    setSearchParams(params);
  };

  const onSearch = debounce((text) => {
    handleSearch(text);
  }, 500);

  useEffect(() => {
    const params = parseSearchParams(true);
    const nParams = params.search ? { search: params.search } : {};
    filterFields.forEach((row) => {
      row.cols.forEach((col) => {
        if (params[col.key]) {
          if (col.key === 'type') {
            let val = 0;
            switch (params[col.key]) {
              case Constants.Review.Types.Negative.id:
                val = [1, 2];
                break;

              case Constants.Review.Types.Positive.id:
                val = [4, 5];
                break;

              case Constants.Review.Types.Neutral.id:
                val = [3];
                break;

              default:
                val = 0;
                break;
            }
            nParams.rating = val;
          } else {
            nParams[col.apiKey || col.key] = params[col.key];
          }
        }
      });
    });
    setFilters(nParams);
  }, [parseSearchParams, filterFields]);

  useEffect(() => {
    const params = parseSearchParams();
    if (params.search) {
      delete params.search;
    }
    filterFormRef.current.reset(params);
  }, [parseSearchParams]);

  return (
    <>
      <Card>
        <Card.Header className="card-header-content-md-between">
          <div className="mb-2 mb-md-0">
            <InputGroup className="input-group-merge input-group-borderless">
              <InputGroup.Text className="input-group-prepend">
                <i className="bi-search" />
              </InputGroup.Text>
              <Form.Control
                type="search"
                placeholder={formatMessage({ id: 'app.common.searchReviews' })}
                defaultValue={parseSearchParams().search || ''}
                onChange={(e) => {
                  if (e.target.value) {
                    onSearch(e.target.value);
                  } else {
                    handleSearch(e.target.value);
                  }
                }}
              />
            </InputGroup>
          </div>

          <div className="d-grid d-sm-flex align-items-sm-center gap-2">
            <div id="datatableCounterInfo" style={{ display: 'none' }}>
              <div className="d-flex align-items-center">
                <span className="fs-5 me-3">
                  <span id="datatableCounter">0</span> Selected
                </span>

                <a className="btn btn-outline-danger btn-sm" href="#!">
                  <i className="bi-trash" /> Delete
                </a>
              </div>
            </div>

            <Button
              variant="white"
              size="sm"
              className="dropdown-toggle"
              onClick={() => {
                setFiltersIsVisible(!filtersIsVisible);
              }}
            >
              <i className="bi-filter me-1" />
              <FormattedMessage id="app.common.filters" />
              {Object.keys(filters).filter((key) => key !== 'search').length >
                0 && (
                <Badge bg="soft-dark" className="text-dark rounded-circle ms-1">
                  {
                    Object.keys(filters).filter((key) => key !== 'search')
                      .length
                  }
                </Badge>
              )}
            </Button>
          </div>
        </Card.Header>

        <Collapse in={filtersIsVisible}>
          <div id="filter-collapse">
            <Card.Body>
              <SmartForm
                ref={filterFormRef}
                fields={filterFields}
                submitButtonText="applyFilters"
                clearButtonText="clearFilters"
                isFilterForm
                disableApiKey
                // defaultValues={parseSearchParams()}
                /* defaultValues={{
                sources: [
                  { id: 'expedia', label: 'Expedia', color: '#ff0000' },
                  { id: 'facebook', label: 'Facebook', color: '#ff0000' },
                ],
              }} */
                /* defaultValues={{
                dateRange: [
                  new Date(),
                  moment(new Date()).add('10', 'days').toDate(),
                ],
                dateRange2: [
                  new Date(),
                  moment(new Date()).add('5', 'days').toDate(),
                ],
              }} */
                onSubmit={(formData) => {
                  const params = {};
                  if (formData) {
                    Object.keys(formData).forEach((key) => {
                      if (formData[key]) {
                        if (key === 'dateRange') {
                          params[key] = formData[key].map((item) =>
                            moment(item).format(Constants.DateFormats.API)
                          );
                        } else {
                          params[key] = formData[key];
                        }
                      }
                    });
                  }

                  if (parseSearchParams().search) {
                    params.search = parseSearchParams().search;
                  }

                  setSearchParams(params);
                }}
              />
            </Card.Body>
          </div>
        </Collapse>

        {activeProperty && (
          <SmartTable
            ref={tableRef}
            columns={columns}
            requestUrl="/reviews/list"
            requestParams={{ id: activeProperty?.id }}
            filters={filters}
            hoverable
            noDataOptions={{
              title: 'emptyState.reviews.title',
              message: 'emptyState.reviews.message',
              image: {
                src: NoDataIllustration,
                darkSrc: NoDataIllustrationLight,
              },
            }}
          />
        )}
      </Card>
      <Modal
        show={detailIsVisible}
        onHide={() => {
          setDetailIsVisible(false);
        }}
        onExited={() => {
          setActiveDetailItem(null);
        }}
        scrollable
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title as="h4">
            <FormattedMessage id="app.common.reviewDetails" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReviewDetail
            item={activeDetailItem}
            businessName={activeProperty?.title}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Reviews;
