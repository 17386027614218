import React, { useContext } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Row, Col, Card, ProgressBar, Dropdown } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { lowerCase } from 'lodash';
import { PropertyContext } from '../../../../context/PropertyContext';
import { yup } from '../../../../lib';
import { useConfirmModal, useFormModal } from '../../../../hooks';
import AddNewImage from '../../../../assets/images/svg/illustrations/oc-improve-signup-experience.svg';
import AddNewImageLight from '../../../../assets/images/svg/illustrations-light/oc-improve-signup-experience.svg';
import { ShowMoreText, SmartImage } from '../../../../components';

function Brands() {
  const { formatMessage } = useIntl();
  const { property } = useOutletContext();
  const { updateProperty } = useContext(PropertyContext);

  const { confirm } = useConfirmModal({ confirmVariant: 'danger' });
  const { form } = useFormModal();

  const onDeleteItem = async (item) => {
    const isSuccess = await confirm({
      message: (
        <FormattedMessage
          id="app.common.areYouSureYouWantToDeleteXY"
          values={{
            x: item.name,
            y: lowerCase(formatMessage({ id: 'app.common.brand' })),
          }}
        />
      ),
      requestUrl: '/brands/delete',
      requestParams: { id: item.id },
    });

    if (isSuccess) {
      const nProperty = { ...property };
      nProperty.brands = nProperty.brands.filter(
        (brand) => brand.id !== item.id
      );

      updateProperty(nProperty);
    }
  };

  const fields = [
    {
      cols: [
        {
          key: 'name',
          schema: yup.string().required(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'description',
          type: 'textarea',
          schema: yup.string(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'logo',
          type: 'image',
          schema: yup.mixed(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'firstColor',
          apiKey: 'color_main',
          type: 'color',
          schema: yup.string(),
        },
        {
          key: 'secondColor',
          apiKey: 'color_subheading',
          type: 'color',
          schema: yup.string(),
        },
        {
          key: 'thirdColor',
          apiKey: 'color_description',
          type: 'color',
          schema: yup.string(),
        },
      ],
    },
  ];

  const onAddOrEditItem = async (item) => {
    const requestUrl = item ? '/brands/update' : '/brands/insert';
    const requestParams = item ? { id: item.id } : { property_id: property.id };
    const defaultValues = item
      ? {
          name: item.name || '',
          description: item.description || '',
          logo: item?.logo_url || '',
          firstColor: item.color_main || '#000000',
          secondColor: item.color_subheading || '#000000',
          thirdColor: item.color_description || '#000000',
        }
      : {};
    const formData = await form({
      size: 'lg',
      title: item ? 'editBrand' : 'addNewBrand',
      confirmLabel: item ? 'save' : 'create',
      requestUrl,
      requestParams,
      defaultValues,
      fields,
      fetchOnStart: false,
    });

    if (formData) {
      const nProperty = { ...property };
      const founded = nProperty.brands.find((brand) => brand.id === item?.id);
      if (founded) {
        nProperty.brands = nProperty.brands.map((brand) =>
          brand.id === item?.id ? formData.Result : brand
        );
      } else {
        nProperty.brands.push(formData.Result);
      }

      updateProperty(nProperty);
    }
  };

  return (
    <div>
      <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 mb-5">
        <Col className="mb-3 mb-lg-5">
          <Card
            onClick={() => {
              onAddOrEditItem();
            }}
            className="card-dashed shadow-none card-centered card-sm h-100"
            style={{ cursor: 'pointer' }}
          >
            <Card.Body className="card-dashed-body py-8">
              <SmartImage
                className="avatar avatar-lg avatar-4x3 mb-2"
                src={AddNewImage}
                darkSrc={AddNewImageLight}
              />
              <span>
                <i className="bi-plus me-1" />
                <FormattedMessage id="app.common.addNewBrand" />
              </span>
            </Card.Body>
          </Card>
        </Col>
        {property.brands.map((item, index) => (
          <Col key={`${index.toString()}`} className="mb-3 mb-lg-5">
            <Card className="card-sm card-hover-shadow h-100 text-center">
              <Dropdown align="end" className="card-pinned-top-end">
                <Dropdown.Toggle
                  bsPrefix="btn"
                  variant="ghost-secondary"
                  size="sm"
                  className="btn-icon rounded-circle card-dropdown-btn"
                >
                  <i className="bi-three-dots-vertical" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      onAddOrEditItem(item);
                    }}
                  >
                    <i className="bi-pencil dropdown-item-icon" />
                    <span>
                      <FormattedMessage id="app.common.edit" />
                    </span>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    onClick={() => {
                      onDeleteItem(item);
                    }}
                  >
                    <i className="bi-trash dropdown-item-icon" />
                    <span>
                      <FormattedMessage id="app.common.delete" />
                    </span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div className="d-flex flex-column h-100">
                <Card.Img
                  variant="top"
                  className="p-2"
                  src={item.logo_url}
                  style={{ height: 200, objectFit: 'contain' }}
                />
                <Card.Footer className="d-flex flex-column border-0 w-100 flex-fill">
                  <div className="flex-grow-1 mb-3">
                    <h5 className="mb-1">{item.name}</h5>
                    {item.description ? (
                      <div className="fs-6 text-muted mb-3">
                        <ShowMoreText
                          text={item.description}
                          maxLine={3}
                          basedOn="words"
                          hideButton
                        />
                      </div>
                    ) : (
                      <span className="small">
                        <FormattedMessage id="app.common.noDescription" />
                      </span>
                    )}
                  </div>
                  <ProgressBar style={{ height: 30 }}>
                    <ProgressBar
                      now={33}
                      style={{ backgroundColor: item.color_main }}
                    />
                    <ProgressBar
                      now={34}
                      className="border-start border-end"
                      style={{ backgroundColor: item.color_subheading }}
                    />
                    <ProgressBar
                      now={33}
                      style={{ backgroundColor: item.color_description }}
                    />
                  </ProgressBar>
                </Card.Footer>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default Brands;
