import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || '',
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || '',
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '',
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '',
  appId: process.env.REACT_APP_FIREBASE_APP_ID || '',
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const requestForToken = () =>
  Notification.requestPermission().then(async (permission) => {
    if (permission === 'granted') {
      return getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY || '',
      })
        .then((currentToken) => {
          if (currentToken) {
            return currentToken;
          }

          throw new Error('generateToken');
        })
        .catch(() => {
          throw new Error('receiveToken');
        });
    }
    throw new Error('userPermission');
  });

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
