import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useGoogleLogin, googleLogout } from '@react-oauth/google';
import { useCookie } from 'react-use';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  DateRangePicker,
  RequestLoading,
  RequestResult,
} from '../../../components';
import Constants from '../../../constants';
import { Google } from './partials';

const GA_SCOPE =
  'https://www.googleapis.com/auth/analytics.readonly https://www.googleapis.com/auth/webmasters.readonly';

const dateFrom = moment().subtract(89, 'days');
const dateTo = moment();

const initialDates = [
  dateFrom.format(Constants.DateFormats.API),
  dateTo.format(Constants.DateFormats.API),
];

function GoogleAnalytics() {
  const { formatMessage } = useIntl();
  const [credential, updateCredential, deleteCredential] = useCookie('g-user');
  const [viewId, setViewId] = useState();
  const [authorizeCalled, setAuthorizeCalled] = useState(false);
  const [date, setDate] = useState(initialDates);
  const [authorized, setAuthorized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const signOut = useCallback(() => {
    deleteCredential();
    googleLogout();
    setAuthorizeCalled(false);
    setAuthorized(false);
    setViewId(null);
  }, [deleteCredential]);

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      updateCredential(JSON.stringify(tokenResponse));
    },
    onError: () => {
      deleteCredential();
      setError('app.common.somethingWentWrong');
    },
    scope: GA_SCOPE,
  });

  const isOAuthTokenValid = async (token) => {
    const BASE_API = 'https://www.googleapis.com/oauth2/v1/tokeninfo';
    return new Promise((resolve, reject) => {
      fetch(`${BASE_API}?access_token=${token}`, {
        mode: 'cors',
      })
        .then((response) => response.json())
        .then(({ expires_in: timeout = 0 }) => {
          if (timeout > 0) resolve('Token is valid');
          reject(new Error('Token has expired'));
        });
    });
  };

  const handleChangeDate = (d) => {
    const nDate = [
      moment(d[0]).format(Constants.DateFormats.API),
      moment(d[1]).format(Constants.DateFormats.API),
    ];
    setDate(nDate);
  };

  useEffect(() => {
    if (credential && !error && !authorizeCalled) {
      const credObj = JSON.parse(credential);
      const accessToken = credObj?.access_token;

      if (!accessToken) {
        return;
      }

      const checkToken = async () => {
        try {
          await isOAuthTokenValid(accessToken);
          setAuthorized(true);
        } catch {
          signOut();
        }
      };

      checkToken();
      setAuthorizeCalled(true);
      setLoading(false);
    }
  }, [credential, authorizeCalled, error, signOut]);

  return (
    <div>
      <RequestLoading loading={loading} size="lg" margin="5" />
      <RequestResult type="error" message={error} />
      {!loading && !error && (
        <div>
          {authorized ? (
            <>
              <Row className="justify-content-between align-items-center flex-grow-1">
                <Col md className="mb-3 mb-md-0">
                  <Button
                    variant="white"
                    size="lg"
                    onClick={() => {
                      signOut();
                    }}
                  >
                    <span className="d-flex justify-content-center align-items-center">
                      <Google.Logo />
                      <FormattedMessage id="app.common.signOutFromGoogle" />
                    </span>
                  </Button>
                </Col>
                <Col xs="auto">
                  <Row className="align-items-sm-center">
                    <Col sm="auto" className="mb-2 mb-sm-0">
                      <Row className="align-items-center gx-0">
                        <Col>
                          <span className="text-secondary me-2">
                            <FormattedMessage id="app.common.date" />:
                          </span>
                        </Col>
                        <Col xs="auto">
                          <DateRangePicker
                            type="button"
                            initialSettings={{
                              initialDates: {
                                startDate: initialDates[0],
                                endDate: initialDates[1],
                              },
                            }}
                            value={date}
                            onChange={handleChangeDate}
                            disabled={!!(loading || error)}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <hr />
              {credential && (
                <Google.AnalyticsAccountSelect
                  token={JSON.parse(credential)?.access_token}
                  onChange={(view) => {
                    if (!view) {
                      setViewId(null);
                      return;
                    }
                    if (`ga:${viewId}` !== view?.id) {
                      setViewId(`ga:${view.id}`);
                    }
                  }}
                />
              )}
              {viewId && (
                <>
                  <hr />
                  <Row>
                    <Col lg="6" className="mb-3 mb-lg-5">
                      <Google.AnalyticsCard
                        token={JSON.parse(credential)?.access_token}
                        type="line"
                        title={formatMessage({
                          id: 'app.googleAnalytics.sessions',
                        })}
                        query={{
                          'start-date': date[0],
                          'end-date': date[1],
                          dimensions: 'ga:date',
                          metrics: 'ga:sessions',
                          ids: viewId,
                        }}
                      />
                    </Col>
                    <Col lg="6" className="mb-3 mb-lg-5">
                      <Google.AnalyticsCard
                        token={JSON.parse(credential)?.access_token}
                        type="line"
                        title={formatMessage({
                          id: 'app.googleAnalytics.pageviews',
                        })}
                        query={{
                          'start-date': date[0],
                          'end-date': date[1],
                          dimensions: 'ga:date',
                          metrics: 'ga:pageviews',
                          ids: viewId,
                        }}
                      />
                    </Col>
                    <Col lg="6" className="mb-3 mb-lg-5">
                      <Google.AnalyticsCard
                        token={JSON.parse(credential)?.access_token}
                        type="doughnut"
                        title={formatMessage({
                          id: 'app.googleAnalytics.newVsReturningVisitors',
                        })}
                        query={{
                          'start-date': date[0],
                          'end-date': date[1],
                          'max-results': 10,
                          dimensions: 'ga:userType',
                          metrics: 'ga:sessions',
                          ids: viewId,
                        }}
                      />
                    </Col>
                    <Col lg="6" className="mb-3 mb-lg-5">
                      <Google.AnalyticsCard
                        token={JSON.parse(credential)?.access_token}
                        type="doughnut"
                        title={formatMessage({
                          id: 'app.googleAnalytics.deviceBreakdown',
                        })}
                        query={{
                          'start-date': date[0],
                          'end-date': date[1],
                          'max-results': 10,
                          dimensions: 'ga:deviceCategory',
                          metrics: 'ga:sessions',
                          ids: viewId,
                        }}
                      />
                    </Col>
                    <Col lg="6" className="mb-3 mb-lg-5">
                      <Google.AnalyticsCard
                        token={JSON.parse(credential)?.access_token}
                        type="table"
                        title={formatMessage(
                          {
                            id: 'app.googleAnalytics.topNCountries',
                          },
                          { n: 10 }
                        )}
                        query={{
                          'start-date': date[0],
                          'end-date': date[1],
                          'max-results': 10,
                          dimensions: 'ga:country',
                          metrics: 'ga:sessions',
                          sort: '-ga:sessions',
                          ids: viewId,
                        }}
                      />
                    </Col>
                    <Col lg="6" className="mb-3 mb-lg-5">
                      <Google.AnalyticsCard
                        token={JSON.parse(credential)?.access_token}
                        type="table"
                        title={formatMessage(
                          {
                            id: 'app.googleAnalytics.topNReferrals',
                          },
                          { n: 10 }
                        )}
                        query={{
                          'start-date': date[0],
                          'end-date': date[1],
                          'max-results': 10,
                          dimensions: 'ga:source',
                          metrics: 'ga:pageviews,ga:sessionDuration,ga:exits',
                          filters: 'ga:medium==referral',
                          sort: '-ga:pageviews',
                          ids: viewId,
                        }}
                      />
                    </Col>
                    <Col xs="12" className="mb-3 mb-lg-5">
                      <Google.AnalyticsCard
                        token={JSON.parse(credential)?.access_token}
                        type="table"
                        title={formatMessage({
                          id: 'app.googleAnalytics.topPostOrPages',
                        })}
                        query={{
                          'start-date': date[0],
                          'end-date': date[1],
                          'max-results': 10,
                          dimensions: 'ga:pagePath',
                          metrics:
                            'ga:pageviews,ga:uniquePageviews,ga:timeOnPage,ga:bounces,ga:entrances,ga:exits',
                          sort: '-ga:pageviews',
                          ids: viewId,
                        }}
                      />
                    </Col>
                    <Col xs="12" className="mb-3 mb-lg-5">
                      <Google.AnalyticsCard
                        token={JSON.parse(credential)?.access_token}
                        type="table"
                        title={formatMessage({
                          id: 'app.googleAnalytics.topLandingPages',
                        })}
                        query={{
                          'start-date': date[0],
                          'end-date': date[1],
                          'max-results': 10,
                          dimensions: 'ga:landingPagePath',
                          metrics: 'ga:entrances,ga:bounces',
                          sort: '-ga:entrances',
                          ids: viewId,
                        }}
                      />
                    </Col>
                    <Col xs="12" className="mb-3 mb-lg-5">
                      <Google.AnalyticsCard
                        token={JSON.parse(credential)?.access_token}
                        type="table"
                        title={formatMessage({
                          id: 'app.googleAnalytics.topExitPages',
                        })}
                        query={{
                          'start-date': date[0],
                          'end-date': date[1],
                          'max-results': 10,
                          dimensions: 'ga:exitPagePath',
                          metrics: 'ga:exits,ga:pageviews',
                          sort: '-ga:exits',
                          ids: viewId,
                        }}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </>
          ) : (
            <Google.AnalyticsLogin
              onClick={() => {
                login();
              }}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default GoogleAnalytics;
