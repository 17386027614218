import moment from 'moment';

export const getDaysBetweenDates = (startDate, endDate) => {
  const now = moment(startDate).clone();
  const end = moment(endDate).clone();
  const dates = [];

  while (now.isSameOrBefore(end)) {
    dates.push(now.toDate());
    now.add(1, 'days');
  }
  return dates;
};

export const isToday = (date) => {
  const today = moment().clone().startOf('day');
  return moment(date).isSame(today, 'd');
};

export const isYesterday = (date) => {
  const yesterday = moment().clone().subtract(1, 'days').startOf('day');
  return moment(date).isSame(yesterday, 'd');
};

export const isWithinAWeek = (date) => {
  const aWeekOld = moment().clone().subtract(7, 'days').startOf('day');
  return moment(date).isAfter(aWeekOld, 'd');
};
