import * as Colors from './colors';
import Brands from './brands';
import Countries from './countries';
import AuthVendors from './authVendors';
import YupLocale from './yupLocale';
import * as DateFormats from './dateFormats';
import * as Review from './review';
import * as Social from './social';
import States from './states';
import * as User from './user';
import * as Billing from './billing';
import * as Google from './google';

const Constants = {
  Colors,
  Brands,
  Countries,
  AuthVendors,
  YupLocale,
  DateFormats,
  Review,
  Social,
  States,
  User,
  Billing,
  Google,
};

export default Constants;
