import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  ListGroup,
  Row,
  Spinner,
  Form,
  Card,
  Nav,
  Dropdown,
  ButtonGroup,
  Button,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Select from 'react-select';
import { useAxiosMutation, useAxiosQuery } from '../../../hooks';
import {
  PageHeader,
  Pagination,
  RequestLoading,
  RequestResult,
  SmartImage,
} from '../../../components';
import Utils from '../../../utils';
import { protectedAreaBasePath } from '../../../router/routeMapping';
import Illustration from '../../../assets/images/svg/illustrations/oc-megaphone.svg';
import IllustrationLight from '../../../assets/images/svg/illustrations-light/oc-megaphone.svg';

const PER_PAGE_VALUES = [10, 15, 20, 25];

const types = [
  { label: 'all', value: 'all' },
  { label: 'read', value: '1' },
  { label: 'unread', value: '0' },
];

function MessageListItem({ item, onChange, onMouseDown }) {
  const [checked, setChecked] = useState(!item.is_Read);

  const { isLoading: apiLoading, mutate: apiFetch } = useAxiosMutation({
    url: '/notifications/update',
  });

  const markAs = (isRead) => {
    apiFetch(
      { NotificationID: item.NotificationID, is_Read: isRead ? 0 : 1 },
      {
        onSuccess: (data) => {
          if (data) {
            setChecked(!checked);
            onChange(!checked ? 0 : 1);
          }
        },
      }
    );
  };

  return (
    <ListGroup.Item className="form-check-select">
      <Row>
        <Col xs="auto">
          <div className="d-flex align-items-center">
            {apiLoading ? (
              <div style={{ minWidth: 24 }}>
                <Spinner animation="border" size="xs" />
              </div>
            ) : (
              <Form.Check>
                <Form.Check.Input
                  checked={checked}
                  onChange={() => {
                    markAs(!checked);
                  }}
                />
                <Form.Check.Label />
                <span className="form-check-stretched-bg" />
              </Form.Check>
            )}
          </div>
        </Col>
        <Col className="ms-n2">
          <h5 className="mb-1">
            {item.Title ? (
              <FormattedMessage
                id={`app.notifications.${item.Title}`}
                values={{ n: item.ValueNew }}
                defaultMessage={item.Title}
              />
            ) : (
              <FormattedMessage id="app.common.noTitle" />
            )}
          </h5>
          <p className="text-body small">
            {item.Description ? (
              <FormattedMessage
                id={`app.notifications.${item.Description}`}
                defaultMessage={item.Description}
              />
            ) : (
              <FormattedMessage id="app.common.noDescription" />
            )}
          </p>
        </Col>
        <Col xs="auto" as="span" className="text-muted text-cap">
          {moment(item.Date).fromNow(true)}
        </Col>
      </Row>
      {item.URL && (
        <Link
          className="stretched-link"
          to={
            Utils.String.checkUrlProtocol(item.URL)
              ? item.URL
              : `${protectedAreaBasePath}${item.URL}`
          }
          target={
            Utils.String.checkUrlProtocol(item.URL) ? '_blank' : undefined
          }
          rel={
            Utils.String.checkUrlProtocol(item.URL)
              ? 'nofollow noreferrer'
              : undefined
          }
          onMouseDown={() => {
            markAs(false);
            onMouseDown();
          }}
        />
      )}
    </ListGroup.Item>
  );
}

MessageListItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func,
  onMouseDown: PropTypes.func,
};

MessageListItem.defaultProps = {
  onChange: () => {},
  onMouseDown: () => {},
};

function Notifications() {
  const [type, setType] = useState(types[0].value);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(PER_PAGE_VALUES[0]);

  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [listData, setListData] = useState([]);

  const {
    data: apiData,
    isFetching: listLoading,
    error: listError,
    refetch: listFetch,
  } = useAxiosQuery({
    url: '/notifications/List',
    dataWithRoot: true,
    params: { page: currentPage, quantity: itemPerPage, read: type },
    keepPreviousData: true,
    onSuccess: (data) => {
      if (data) {
        setListData(data.rows);
        setUnreadMessagesCount(data.not_read);
      }
    },
  });

  const {
    isLoading: updateLoading,
    error: updateError,
    mutate: updateFetch,
  } = useAxiosMutation({
    url: '/notifications/update_status',
  });

  const markAsAllReadOrUnread = (status) => {
    const NotificationID = listData.map((item) => item.NotificationID);
    updateFetch(
      { NotificationID, is_Read: status },
      {
        onSuccess: (data) => {
          if (data) {
            listFetch();
          }
        },
      }
    );
  };

  const readStatusChanged = (id, status) => {
    const nData = [...listData].map((item) => {
      const nItem = item;
      if (nItem.NotificationID === id) {
        nItem.is_Read = status;
      }
      return nItem;
    });
    setListData(nData);
    setUnreadMessagesCount(nData.filter((item) => item.is_Read === 0).length);
  };

  const onPageChange = (pageNumber) => {
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber);
    }
  };

  const onPerPageChange = (perPage) => {
    if (perPage !== itemPerPage) {
      setCurrentPage(1);
      setItemPerPage(perPage);
    }
  };

  const onTypeChange = (t) => {
    if (t !== type) {
      setCurrentPage(1);
      setType(t);
    }
  };

  useEffect(() => {
    setLoading(listLoading || updateLoading);
  }, [listLoading, updateLoading]);

  useEffect(() => {
    setError(listError || updateError);
  }, [listError, updateError]);

  return (
    <div className="content container">
      <PageHeader title="notifications">
        <Dropdown align="end">
          <ButtonGroup>
            <Button size="sm" onClick={() => markAsAllReadOrUnread(1)}>
              <i className="bi-check2-all me-2" />
              <span>
                <FormattedMessage id="app.common.markAllAsRead" />
              </span>
            </Button>
            <ButtonGroup>
              <Dropdown.Toggle
                size="sm"
                className="btn-icon dropdown-toggle-empty"
              />
            </ButtonGroup>
          </ButtonGroup>
          <Dropdown.Menu className="m-0" renderOnMount>
            <Dropdown.Item onClick={() => markAsAllReadOrUnread(0)}>
              <i className="bi-app-indicator dropdown-item-icon" />
              <span>
                <FormattedMessage id="app.common.markAllAsUnread" />
              </span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </PageHeader>
      <Card>
        <Card.Header>
          <Row className="justify-content-between align-items-center flex-grow-1">
            <Col md className="mb-3 mb-md-0 mb-lg-3 mb-xl-0">
              <Card.Title bsPrefix="card-header-title" as="h4">
                <FormattedMessage id="app.common.messages" />
                {unreadMessagesCount > 0 && (
                  <span className="ms-1">{`(${unreadMessagesCount})`}</span>
                )}
              </Card.Title>
            </Col>
            <Col xs="auto">
              <Row className="align-items-sm-center">
                <Col sm="auto" className="mb-2 mb-md-0">
                  <Nav
                    variant="segment"
                    activeKey={type}
                    onSelect={onTypeChange}
                  >
                    {types.map((item) => (
                      <Nav.Item key={item.value}>
                        <Nav.Link eventKey={item.value}>
                          <FormattedMessage id={`app.common.${item.label}`} />
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Header>
        {(loading ||
          error ||
          (!loading && !error && listData.length === 0)) && (
          <Card.Body>
            <RequestLoading loading={loading} size="lg" margin="5" />
            <RequestResult type="error" message={error} />

            {!loading && !error && listData.length === 0 && (
              <RequestResult type="secondary" message="app.common.noMessages" />
            )}
          </Card.Body>
        )}

        <RequestLoading loading={loading} />
        <RequestResult type="error" message={error} className="m-2" />
        {!loading && !error && listData.length === 0 && (
          <div className="card-body text-center">
            <div className="mb-3">
              <SmartImage
                className="avatar avatar-xl"
                src={Illustration}
                darkSrc={IllustrationLight}
              />
            </div>
            <p>
              <FormattedMessage id="app.common.noMessages" />
            </p>
          </div>
        )}
        {!loading && !error && listData?.length > 0 && (
          <ListGroup variant="flush">
            {listData
              // .sort((a, b) => a.is_Read - b.is_Read)
              .map((item) => (
                <MessageListItem
                  key={item.NotificationID}
                  item={item}
                  onChange={(status) => {
                    readStatusChanged(item.NotificationID, status);
                  }}
                />
              ))}
          </ListGroup>
        )}
        <Card.Footer>
          <Row className="justify-content-center justify-content-sm-between align-items-sm-center">
            <Col className="col-sm mb-2 mb-sm-0">
              <div className="d-flex justify-content-center justify-content-sm-start align-items-center">
                <span className="me-2">
                  <FormattedMessage id="app.common.showing" />:
                </span>

                <Select
                  menuPlacement="top"
                  options={PER_PAGE_VALUES.map((x) => ({
                    value: x,
                    label: x,
                  }))}
                  defaultValue={{
                    value: PER_PAGE_VALUES[0],
                    label: PER_PAGE_VALUES[0],
                  }}
                  value={{ value: itemPerPage, label: itemPerPage }}
                  onChange={(item) => {
                    onPerPageChange(item.value);
                  }}
                  className="react-select-custom-container"
                  classNamePrefix="react-select-custom"
                  isSearchable={false}
                />

                <span className="text-secondary mx-2">
                  <FormattedMessage id="app.common.of" />
                </span>
                {apiData?.count}
              </div>
            </Col>

            <Col className="col-sm-auto">
              <div className="d-flex justify-content-center justify-content-sm-end">
                <Pagination
                  currentPage={currentPage}
                  totalRows={apiData?.count}
                  pageChangeHandler={onPageChange}
                  rowsPerPage={itemPerPage}
                />
              </div>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </div>
  );
}

export default Notifications;
