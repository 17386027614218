import React, { useContext, useState, useEffect } from 'react';
import { Row, Col, Dropdown, Button, Nav } from 'react-bootstrap';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import {
  DateRangePicker,
  PageHeader,
  RequestLoading,
  RequestResult,
} from '../../../components';
import { useAxiosQuery } from '../../../hooks';
import { PropertyContext } from '../../../context/PropertyContext';
import Constants from '../../../constants';

import { CalendarView, GraphView, ListView } from './partials';

const viewTypes = [
  { icon: 'bi-calendar-week', value: 'calendar' },
  { icon: 'bi-graph-up', value: 'graph' },
  { icon: 'bi-card-list', value: 'list' },
];

const dateFrom = moment().subtract(89, 'days');
const dateTo = moment();

const initialDates = [
  dateFrom.format(Constants.DateFormats.API),
  dateTo.format(Constants.DateFormats.API),
];

function RateManager() {
  const [viewType, setViewType] = useState(viewTypes[0].value);
  const [date, setDate] = useState(initialDates);

  const { activeProperty } = useContext(PropertyContext);
  const [activeGroupId, setActiveGroupId] = useState(
    activeProperty?.groups[0].id
  );

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/calendar_price/get_by_title',
    preventFetch: !activeProperty?.id || !activeGroupId,
    params: {
      property_id: activeProperty?.id,
      platform: 'booking',
      date,
      compsetID: activeGroupId,
    },
  });

  const handleChangeDate = (d) => {
    const nDate = [
      moment(d[0]).format(Constants.DateFormats.API),
      moment(d[1]).format(Constants.DateFormats.API),
    ];
    setDate(nDate);
  };

  const handleChangeGroup = (id) => {
    setActiveGroupId(id);
  };

  const renderActiveGroup = () => {
    const foundedGroup = activeProperty?.groups.find(
      (group) => Number(group.id) === Number(activeGroupId)
    );

    return (
      <>
        <span
          className="legend-indicator me-2"
          style={{ backgroundColor: foundedGroup?.color }}
        />
        {foundedGroup?.name}
      </>
    );
  };

  useEffect(() => {
    setActiveGroupId(activeProperty?.groups[0].id);
  }, [activeProperty]);

  return (
    <div className="content container">
      <PageHeader
        className="d-block"
        title="rateManager"
        breadcrumbData={{
          current: 'rateManager',
        }}
      >
        <Row className="align-items-lg-center">
          <Col sm="auto" className="mb-2 mb-lg-0">
            <Row className="align-items-center gx-0">
              <Col>
                <span className="text-secondary me-2">
                  <FormattedMessage id="app.common.date" />:
                </span>
              </Col>
              <Col xs="auto">
                <DateRangePicker
                  type="button"
                  initialSettings={{
                    initialDates: {
                      startDate: initialDates[0],
                      endDate: initialDates[1],
                    },
                  }}
                  value={date}
                  onChange={handleChangeDate}
                  disabled={apiLoading}
                />
              </Col>
            </Row>
          </Col>
          <Col sm="auto" className="mb-2 mb-lg-0">
            <Row className="align-items-center gx-0">
              <Col>
                <span className="text-secondary me-2">
                  <FormattedMessage id="app.common.compset" />:
                </span>
              </Col>
              <Col xs="auto">
                <Dropdown align="end" onSelect={handleChangeGroup}>
                  <Dropdown.Toggle
                    as={Button}
                    size="sm"
                    variant="white"
                    disabled={apiLoading}
                  >
                    {renderActiveGroup()}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="m-0" renderOnMount>
                    {activeProperty?.groups.map((group) => (
                      <Dropdown.Item
                        key={group.id}
                        eventKey={group.id}
                        className="d-flex align-items-center"
                      >
                        <span
                          className="legend-indicator me-2"
                          style={{ backgroundColor: group.color }}
                        />
                        {group.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
          </Col>
          <Col sm="auto" className="mb-2 mb-lg-0">
            <Row className="align-items-center gx-0">
              <Col>
                <span className="text-secondary me-2">
                  <FormattedMessage id="app.common.viewType" />:
                </span>
              </Col>
              <Col xs="auto">
                <Nav
                  variant="segment"
                  activeKey={viewType}
                  onSelect={setViewType}
                >
                  {viewTypes.map((item) => (
                    <Nav.Item key={item.value}>
                      <Nav.Link eventKey={item.value}>
                        <i className={`bi ${item.icon}`} />
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>
            </Row>
          </Col>
        </Row>
      </PageHeader>

      <RequestLoading loading={apiLoading} size="lg" margin="5" />
      <RequestResult type="error" message={apiError} />

      {!apiLoading && !apiError && apiData && date && (
        <>
          {viewType === 'calendar' && (
            <CalendarView data={apiData} date={date} />
          )}
          {viewType === 'graph' && <GraphView data={apiData} date={date} />}
          {viewType === 'list' && <ListView data={apiData} date={date} />}
        </>
      )}
    </div>
  );
}

export default RateManager;
