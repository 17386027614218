import React, { forwardRef, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { RequestLoading, RequestResult } from '../../../../../components';
import { useAxiosMutation } from '../../../../../hooks';
import Constants from '../../../../../constants';

const FinalSaveModal = forwardRef(({ onSuccess, wizardData }, ref) => {
  const [show, setShow] = useState(false);
  const {
    isLoading: apiLoading,
    error: apiError,
    mutate: apiFetch,
  } = useAxiosMutation({
    url: '/places/add_rels',
  });

  const getSocialDefaults = () => {
    const newObj = Object.entries(Constants.Social.Sites).reduce(
      (obj, [key]) => {
        const nObj = obj;
        if (wizardData.branding?.[key]) {
          nObj[key] = wizardData.branding[key];
        }
        return nObj;
      },
      {}
    );

    return newObj;
  };

  const fetch = () => {
    const formData = {
      id: wizardData.myProperty.place_id,
      ids: wizardData.myCompetitors.map((item) => item.place_id) || [],
      name: wizardData.branding.name,
      description: wizardData.branding.description,
      logo: wizardData.branding.logo,
      color_main: wizardData.branding.firstColor,
      color_subheading: wizardData.branding.secondColor,
      color_description: wizardData.branding.thirdColor,
      keywords: wizardData.keywords.map((item) => item.long_tail) || [],
      website: wizardData.branding.website,
      ...getSocialDefaults(),
    };

    apiFetch(formData, {
      onSuccess,
    });
  };

  useImperativeHandle(ref, () => ({
    fetch: () => {
      setShow(true);
      fetch();
    },
    close: () => {
      setShow(false);
    },
  }));

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      size="sm"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title as="h5">
          <FormattedMessage id="app.common.creatingProperty" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <RequestLoading loading={apiLoading} />
        <RequestResult type="error" message={apiError} />
      </Modal.Body>

      {apiError && (
        <Modal.Footer>
          <Button
            onClick={() => {
              setShow(false);
            }}
            variant="white"
          >
            <FormattedMessage id="app.common.cancel" />
          </Button>
          <Button onClick={fetch} variant="danger">
            <i className="bi-exclamation-triangle me-1" />
            <FormattedMessage id="app.common.tryAgain" />
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
});

FinalSaveModal.propTypes = {
  onSuccess: PropTypes.func,
  wizardData: PropTypes.objectOf(PropTypes.any),
};

FinalSaveModal.defaultProps = {
  onSuccess: () => {},
  wizardData: {},
};

export default FinalSaveModal;
