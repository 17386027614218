import AnalyticsCard from './AnalyticsCard';
import AnalyticsLogin from './AnalyticsLogin';
import AnalyticsAccountSelect from './AnalyticsAccountSelect';
import SearchConsoleCard from './SearchConsoleCard';
import GoogleLogo from './GoogleLogo';

const Google = {
  AnalyticsAccountSelect,
  AnalyticsCard,
  AnalyticsLogin,
  SearchConsoleCard,
  Logo: GoogleLogo,
};

export default Google;
