import React from 'react';

function ListView() {
  return (
    <div>
      <div>List View</div>
    </div>
  );
}

export default ListView;
