import React, { createContext, useMemo, useReducer, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { useAxiosQuery } from '../hooks';
import { RequestLoading, RequestResult } from '../components';

const PropertyContext = createContext();

const initialState = {
  activeProperty: null,
  properties: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_PROPERTIES':
      return {
        ...state,
        properties: action.payload.properties,
      };
    case 'UPDATE_ACTIVE_PROPERTY':
      localStorage.setItem('activePropertyId', action.payload.property.id);
      return {
        ...state,
        activeProperty: action.payload.property,
      };

    case 'ADD_PROPERTY':
      localStorage.setItem('activePropertyId', action.payload.property.id);
      return {
        ...state,
        properties: [...state.properties, action.payload.property],
        activeProperty: action.payload.property,
      };
    case 'REMOVE_PROPERTY': {
      const nProperties = state.properties.filter(
        (item) => item.id !== action.payload.propertyId
      );
      let nActiveProperty = state.activeProperty;
      if (
        state.activeProperty.id === action.payload.propertyId &&
        nProperties.length > 0
      ) {
        [nActiveProperty] = nProperties;
      }
      if (nProperties.length === 0) {
        nActiveProperty = null;
      }
      return {
        ...state,
        properties: [...nProperties],
        activeProperty: nActiveProperty,
      };
    }
    case 'UPDATE_PROPERTY': {
      const nProperties = state.properties.map((property) => {
        if (property.id === action.payload.property.id) {
          return action.payload.property;
        }
        return property;
      });
      let nActiveProperty = state.activeProperty;
      if (state.activeProperty.id === action.payload.property.id) {
        nActiveProperty = action.payload.property;
      }
      return {
        ...state,
        properties: [...nProperties],
        activeProperty: nActiveProperty,
      };
    }
    default:
      return state;
  }
};

function PropertyProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const updateProperties = useCallback(
    (properties) => {
      dispatch({
        type: 'UPDATE_PROPERTIES',
        payload: {
          properties,
        },
      });
    },
    [dispatch]
  );

  const updateActiveProperty = useCallback(
    (property) => {
      dispatch({
        type: 'UPDATE_ACTIVE_PROPERTY',
        payload: {
          property,
        },
      });
    },
    [dispatch]
  );

  const addProperty = useCallback(
    (property) => {
      dispatch({
        type: 'ADD_PROPERTY',
        payload: {
          property,
        },
      });
    },
    [dispatch]
  );

  const removeProperty = useCallback(
    (propertyId) => {
      dispatch({
        type: 'REMOVE_PROPERTY',
        payload: {
          propertyId,
        },
      });
    },
    [dispatch]
  );

  const updateProperty = useCallback(
    (property) => {
      dispatch({
        type: 'UPDATE_PROPERTY',
        payload: {
          property,
        },
      });
    },
    [dispatch]
  );

  const {
    isLoading: apiLoading,
    error: apiError,
    refetch: apiRefetch,
  } = useAxiosQuery({
    url: '/properties/list',
    onSuccess: (data) => {
      updateProperties(data);
      if (data.length > 0) {
        let savedActivePropertyId;
        try {
          savedActivePropertyId = JSON.parse(
            localStorage.getItem('activePropertyId') || null
          );
        } catch (e) {
          savedActivePropertyId = null;
        }

        let savedActiveProperty;
        if (savedActivePropertyId) {
          savedActiveProperty = data.find(
            (nItem) => nItem.id === savedActivePropertyId
          );
        }
        updateActiveProperty(savedActiveProperty || data[0]);
      }
    },
  });
  const value = useMemo(
    () => ({
      activeProperty: state.activeProperty,
      updateActiveProperty,
      properties: state.properties,
      updateProperties,
      addProperty,
      removeProperty,
      updateProperty,
      fetchProperties: apiRefetch,
    }),
    [
      state,
      updateActiveProperty,
      updateProperties,
      addProperty,
      removeProperty,
      updateProperty,
      apiRefetch,
    ]
  );

  return (
    <PropertyContext.Provider value={value}>
      {(apiLoading || apiError) && (
        <Row className="justify-content-center my-5">
          <Col lg="8">
            <RequestLoading loading={apiLoading} size="lg" margin="5" />
            <RequestResult type="error" message={apiError} image />
          </Col>
        </Row>
      )}

      {!apiLoading && !apiError && children}
    </PropertyContext.Provider>
  );
}

PropertyProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { PropertyContext, PropertyProvider };
