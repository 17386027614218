export const getMedian = (values) => {
  values.sort((a, b) => a - b);
  const half = Math.floor(values.length / 2);

  if (values.length % 2) return values[half];
  return (values[half - 1] + values[half]) / 2.0;
};

export const padArrayToMultiple = (
  arr,
  multiple,
  emptyObject,
  incrementKey
) => {
  const currentLength = arr.length;
  const missingElements = multiple - (currentLength % multiple);
  const missingObject = emptyObject || null;

  if (missingElements !== multiple) {
    for (let i = 0; i < missingElements; i += 1) {
      arr.push(
        incrementKey && missingObject[incrementKey]
          ? {
              ...missingObject,
              [incrementKey]: `${missingObject[incrementKey]}${i}`,
            }
          : missingObject
      );
    }
  }

  return arr;
};
