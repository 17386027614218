import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, Button } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import SmartImage from './SmartImage';
import { Routes as AppRoutes } from '../router/routeMapping';
import Illustration from '../assets/images/svg/illustrations/oc-project-development.svg';
import IllustrationLight from '../assets/images/svg/illustrations-light/oc-project-development.svg';
import Utils from '../utils';

const upgradeInfoLink = process.env.REACT_APP_SUBSCRIPTION_INFO_LINK || '';

let upgradeInfoLinkIsExternal = false;

if (Utils.String.checkUrlProtocol(upgradeInfoLink)) {
  upgradeInfoLinkIsExternal = true;
}

const onImageError = (e, theme) => {
  e.target.src = theme === 'dark' ? IllustrationLight : Illustration;
};

function NoAccessInfoComponentLevel({ module, className, src, darkSrc }) {
  const { formatMessage } = useIntl();
  const [imageError, setImageError] = useState(false);

  let parentPage;
  let activePage;
  Object.values(AppRoutes.protected).forEach((item) => {
    if (item.path === module) {
      activePage = item;
      return;
    }
    if (item.children) {
      Object.values(item.children).forEach((childItem) => {
        if (childItem.path === module) {
          activePage = childItem;
          parentPage = item;
        }
      });
    }
  });

  let defaultSrc = `/assets/pack-lock/${
    parentPage ? `${parentPage.title}/` : ''
  }${activePage.title}-light.png`;

  let defaultDarkSrc = `/assets/pack-lock/${
    parentPage ? `${parentPage.title}/` : ''
  }${activePage.title}-dark.png`;

  if (src) {
    defaultSrc = src;
    defaultDarkSrc = darkSrc;
  }

  if (darkSrc) {
    defaultDarkSrc = darkSrc;
  }

  useEffect(() => {
    setImageError(false);
  }, [module]);

  return (
    <div
      className={`position-relative w-100 h-100 d-flex align-items-center justify-content-center ${className}`}
    >
      <div className="position-absolute w-100 h-100">
        <SmartImage
          className={`w-100 h-100 ${imageError ? '' : ''}`}
          style={{ objectFit: 'cover' }}
          src={defaultSrc}
          darkSrc={defaultDarkSrc}
          onError={(e, theme) => {
            setImageError(true);
            onImageError(e, theme);
          }}
        />
      </div>
      <div
        className="position-absolute w-100 h-100 bg-light"
        style={{ opacity: 0.5 }}
      />

      <div className="position-relative">
        <div className="pt-5 pb-10">
          <div className="text-center">
            <div className="h1">
              <Badge pill className="text-uppercase">
                <i className="bi-patch-check-fill me-1" />
                <FormattedMessage id="app.common.pro" />
              </Badge>
            </div>
            <div className="my-4">
              <h2>
                {parentPage?.title && (
                  <>
                    <FormattedMessage id={`app.common.${parentPage.title}`} />
                    <span className="mx-2">/</span>
                  </>
                )}
                {activePage?.title && (
                  <FormattedMessage id={`app.common.${activePage.title}`} />
                )}
              </h2>
            </div>
            {activePage?.title && (
              <p>
                <FormattedMessage
                  id={`app.packPromo.${
                    parentPage ? `${parentPage.title}.` : ''
                  }${activePage.title}.upgradeMessage`}
                  defaultMessage={formatMessage({
                    id: 'app.packPromo.default.upgradeMessage',
                  })}
                />
                {upgradeInfoLink && (
                  <Link
                    className="link ms-1"
                    to={`${upgradeInfoLink}/#${
                      parentPage ? `${parentPage.title}_` : ''
                    }${activePage.title}`}
                    target={upgradeInfoLinkIsExternal ? '_blank' : undefined}
                    rel={
                      upgradeInfoLinkIsExternal
                        ? 'nofollow noreferrer'
                        : undefined
                    }
                  >
                    <FormattedMessage id="app.common.learnMore" />
                    <i className="bi-chevron-right ms-1" />
                  </Link>
                )}
              </p>
            )}
            <Button
              as={Link}
              to={AppRoutes.protected.ACCOUNT.children.SUBSCRIPTION_PLANS.path}
            >
              <i className="bi-arrow-up-circle me-1" />
              <FormattedMessage id="app.common.upgradePlan" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

NoAccessInfoComponentLevel.propTypes = {
  module: PropTypes.string.isRequired,
  className: PropTypes.string,
  src: PropTypes.string,
  darkSrc: PropTypes.string,
};

NoAccessInfoComponentLevel.defaultProps = {
  className: 'overflow-hidden rounded-bottom',
  src: null,
  darkSrc: null,
};

export default NoAccessInfoComponentLevel;
