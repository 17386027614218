import Brands from './brands';

export const Sites = Object.keys(Brands)
  .filter((key) => Brands[key].types.includes('social'))
  .reduce(
    (obj, key) =>
      Object.assign(obj, {
        [key]: Brands[key],
      }),
    {}
  );
