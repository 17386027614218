import { React } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import Constants from '../../../../../constants';

function SearchConsoleTable({ queryDimension, data }) {
  const columnHeaders = [queryDimension, 'ctr', 'position'];

  const humanReadableFirstKey = (value) => {
    let val = value;
    if (queryDimension === 'country') {
      const founded = Constants.Countries.find(
        (item) => item.let3.toLowerCase() === value.toLowerCase()
      );
      if (founded) {
        val = founded.name;
      }
    } else if (queryDimension === 'date') {
      val = moment(val).format(Constants.DateFormats.APP.Moment.Common);
    } else if (queryDimension === 'page') {
      val = (
        <a
          href={val}
          target="_blank"
          rel="nofollow noreferrer"
          className="link-success text-wrap"
        >
          {val}
        </a>
      );
    }

    return val;
  };

  const humanReadableValue = (type, value) => {
    let val = value;
    if (type === 'ctr') {
      val = `${(val * 100).toFixed(1)}%`;
    } else if (type === 'position') {
      val = (Math.round((val + Number.EPSILON) * 100) / 100).toFixed(1);
    }

    return val;
  };

  const sortedRows =
    queryDimension === 'date'
      ? [...data.rows].reverse().slice(0, 10)
      : data.rows;

  return (
    <Table
      responsive
      borderless
      className="table-thead-bordered table-nowrap table-align-middle card-table"
    >
      <thead className="thead-light">
        <tr>
          {columnHeaders.map((columnHeader, index) => (
            <th
              key={columnHeader}
              className={index > 0 ? 'text-end' : ''}
              style={{ width: index > 0 ? '10%' : '70%' }}
            >
              <FormattedMessage id={`app.common.${columnHeader}`} />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {sortedRows.map((row, rowIndex) => (
          <tr key={`row_${rowIndex.toString()}`}>
            {columnHeaders.map((columnHeader, index) => (
              <td
                key={`row_${rowIndex.toString()}_col_${columnHeader}_${index.toString()}`}
                className={index > 0 ? 'text-end' : ''}
              >
                {index > 0
                  ? humanReadableValue(columnHeader, row[columnHeader])
                  : humanReadableFirstKey(row.keys?.[0])}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

SearchConsoleTable.propTypes = {
  queryDimension: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

export default SearchConsoleTable;
