import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Card } from 'react-bootstrap';
import { DoughnutChart } from '../../../../components/Charts';
import Utils from '../../../../utils';
import { ThemeContext } from '../../../../context/ThemeContext';

const options = {
  rotation: -120,
  circumference: 240,
  layout: {
    padding: {
      left: 10,
      right: 10,
      top: 10,
      bottom: 10,
    },
  },
  plugins: {
    tooltip: {
      enabled: false,
    },
    legend: {
      display: false,
    },
  },
};

function ScoreCard({ value }) {
  const { theme } = useContext(ThemeContext);

  const getColor = () => {
    let color = Utils.Chart.dangerColor;

    if (value >= 50 && value < 85) {
      color = Utils.Chart.warningColor;
    } else if (value >= 85) {
      color = Utils.Chart.successColor;
    }

    return color;
  };

  const backgroundColor = getColor();

  return (
    <Card className="h-100">
      <Card.Header>
        <Card.Title bsPrefix="card-header-title" as="h4">
          <FormattedMessage id="app.common.seoScore" />
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <div className="h-100 d-flex flex-column justify-content-center align-items-center text-center">
          <div className="position-relative w-100">
            <DoughnutChart
              data={{
                labels: ['', ''],
                datasets: [
                  {
                    backgroundColor: [
                      backgroundColor,
                      theme === 'dark'
                        ? Utils.Chart.lightGridColor
                        : Utils.Chart.darkGridColor,
                    ],
                    data: [value, 100 - value],
                    borderWidth: 4,
                    borderColor:
                      theme === 'dark'
                        ? Utils.Chart.darkBgColor
                        : Utils.Chart.lightBgColor,
                  },
                ],
              }}
              options={options}
            />
            <div className="chartjs-doughnut-custom-stat">
              <span className="h1 display-1">{value}</span>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

ScoreCard.propTypes = {
  value: PropTypes.number.isRequired,
};

export default ScoreCard;
