import React, { useContext, useState, useEffect } from 'react';
import {
  Card,
  Col,
  Row,
  Dropdown,
  Button,
  Table,
  Badge,
  ProgressBar,
} from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAxiosQuery } from '../../../hooks';
import { PropertyContext } from '../../../context/PropertyContext';
import { RequestLoading, RequestResult, Tooltip } from '../../../components';
import { isArray } from '../../../utils/object';
import Constants from '../../../constants';
import { Leaderboard, TrendAnalysis } from './partials';

const columns = [
  {
    titleKey: 'name',
    id: 'name',
    align: 'text-start',
  },
  {
    titleKey: 'totalReviews',
    id: 'total',
    section: 'nums',
  },
  {
    titleKey: 'responseRate',
    id: 'response_rate',
    section: 'nums',
    disableNullControl: true,
  },
  {
    titleKey: 'averageRate',
    id: 'avg',
    section: 'nums',
  },
  {
    titleKey: 'latestReviews',
    id: 'last5',
  },
];

function CompetitorsReport() {
  const { formatMessage } = useIntl();
  const { activeProperty } = useContext(PropertyContext);
  const [activeGroupId, setActiveGroupId] = useState(
    activeProperty?.groups[0].id
  );

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/reputation_manager/average_rating',
    preventFetch:
      !activeProperty?.id ||
      !activeGroupId ||
      !activeProperty?.groups.find(
        (group) => Number(group.id) === Number(activeGroupId)
      ),
    params: {
      id: activeProperty?.id,
      compsetId: activeGroupId,
    },
  });

  const handleChangeGroup = (id) => {
    setActiveGroupId(id);
  };

  const renderActiveGroup = () => {
    const foundedGroup = activeProperty?.groups.find(
      (group) => Number(group.id) === Number(activeGroupId)
    );

    return (
      <>
        <span
          className="legend-indicator me-2"
          style={{ backgroundColor: foundedGroup?.color }}
        />
        {foundedGroup?.name}
      </>
    );
  };

  const columnParser = (id, section, row, isOwn, disableNullControl) => {
    const value = section ? row[section][id] : row[id];

    if (
      !disableNullControl &&
      (!value || (isArray(value) && value.length === 0))
    ) {
      return formatMessage({ id: 'app.common.n/a' });
    }

    const getProgressVariant = (v) => {
      let bg = 'danger';
      if (v >= 70) {
        bg = 'success';
      } else if (v >= 50 && v < 70) {
        bg = 'info';
      } else if (v >= 30 && v < 50) {
        bg = 'info';
      }

      return bg;
    };

    const getRateType = (v) => {
      let result = Constants.Review.Types.Neutral;
      switch (Math.round(v)) {
        case 1:
        case 2:
          result = Constants.Review.Types.Negative;
          break;

        case 4:
        case 5:
          result = Constants.Review.Types.Positive;
          break;

        case 3:
          result = Constants.Review.Types.Neutral;
          break;

        default:
          result = Constants.Review.Types.Neutral;
          break;
      }

      return result;
    };

    let result;
    switch (id) {
      case 'name':
        result = (
          <div className="d-flex flex-column">
            <div className="d-flex align-items-center">
              <b>{value}</b>
              {isOwn && (
                <Badge bg="primary" className="ms-2">
                  <i className="bi-check-circle-fill me-1" />
                  <FormattedMessage id="app.common.myProperty" />
                </Badge>
              )}
            </div>
            <div className="mt-2 text-wrap">{row.formatted_address}</div>
          </div>
        );
        break;

      case 'total':
        result = (
          <div className="d-flex justify-content-end">
            <Badge bg="primary" className="">
              <FormattedMessage id="app.common.total" />: {value}
            </Badge>
            <Badge bg="soft-success" className="text-success ms-1">
              <i className="bi-emoji-smile me-1" />
              {row.nums.positive}
            </Badge>
            <Badge bg="soft-primary" className="text-primary ms-1">
              <i className="bi-emoji-neutral me-1" />
              {row.nums.neutral}
            </Badge>
            <Badge bg="soft-danger" className="text-danger ms-1">
              <i className="bi-emoji-angry me-1" />
              {row.nums.negative}
            </Badge>
          </div>
        );
        break;

      case 'avg':
        result = (
          <div className="d-flex justify-content-end">
            <Badge
              bg={`soft-${getRateType(value).color}`}
              className={`text-${getRateType(value).color}`}
            >
              <i className={`${getRateType(value).icon} me-1`} />
              {value.toFixed(2)}
            </Badge>
          </div>
        );
        break;

      case 'response_rate':
        result = (
          <div className="d-flex align-items-center justify-content-end">
            <ProgressBar
              className="table-progress"
              variant={getProgressVariant(value)}
              now={value}
            />
            <span className="mb-0 ms-2">
              <small>{`${value.toFixed(2)}%`}</small>
            </span>
          </div>
        );
        break;

      case 'last5':
        result = (
          <div className="d-flex justify-content-end">
            {value.map((item, index) => {
              const rate = getRateType(item);
              return (
                <Badge
                  key={`rate_${index.toString()}`}
                  pill
                  bg={rate.color}
                  className="ms-1"
                >
                  <i className={`${rate.icon} me-1`} />
                  {Math.round(item)}
                </Badge>
              );
            })}
          </div>
        );
        break;

      default:
        result = value;
        break;
    }

    return result;
  };

  useEffect(() => {
    setActiveGroupId(activeProperty?.groups[0].id);
  }, [activeProperty]);

  return (
    <>
      <Row className="mb-3 mb-lg-5">
        <Col xs="auto" className="ms-auto">
          <Dropdown align="end" onSelect={handleChangeGroup}>
            <Dropdown.Toggle
              as={Button}
              size="sm"
              variant="white"
              disabled={apiLoading}
            >
              {renderActiveGroup()}
            </Dropdown.Toggle>
            <Dropdown.Menu className="m-0" renderOnMount>
              {activeProperty?.groups.map((group) => (
                <Dropdown.Item
                  key={group.id}
                  eventKey={group.id}
                  className="d-flex align-items-center"
                >
                  <span
                    className="legend-indicator me-2"
                    style={{ backgroundColor: group.color }}
                  />
                  {group.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row>
        <Col xs="12" className="mb-3 mb-lg-5">
          <Card className="h-100">
            <Card.Header>
              <Row className="justify-content-between align-items-center flex-grow-1">
                <Col md className="mb-3 mb-md-0 mb-lg-3 mb-xl-0">
                  <Card.Title bsPrefix="card-header-title" as="h4">
                    <FormattedMessage id="app.common.competitorsReport" />
                    <Tooltip
                      content={
                        <FormattedMessage id="app.helpers.reputationManager.competitorsReport.tooltips.competitorsReport" />
                      }
                    >
                      <i className="bi-question-circle text-body ms-1" />
                    </Tooltip>
                  </Card.Title>
                </Col>
                {/* <Col xs="auto">
                <Row className="align-items-sm-center">
                  <Col sm="auto" className="mb-2 mb-md-0">
                    <Dropdown align="end" onSelect={handleChangeGroup}>
                      <Dropdown.Toggle
                        as={Button}
                        size="sm"
                        variant="white"
                        disabled={apiLoading}
                      >
                        {renderActiveGroup()}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="m-0" renderOnMount>
                        {activeProperty?.groups.map((group) => (
                          <Dropdown.Item
                            key={group.id}
                            eventKey={group.id}
                            className="d-flex align-items-center"
                          >
                            <span
                              className="legend-indicator me-2"
                              style={{ backgroundColor: group.color }}
                            />
                            {group.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
                        </Col> */}
              </Row>
            </Card.Header>
            <RequestLoading loading={apiLoading} size="lg" margin="5" />
            {!apiLoading && apiError && (
              <Card.Body>
                <RequestResult type="error" message={apiError} />
              </Card.Body>
            )}

            {!apiLoading && !apiError && !apiData?.own?.nums?.total && (
              <Card.Body>
                <RequestResult
                  type="secondary"
                  title="emptyState.competitorsReport.title"
                  message="emptyState.competitorsReport.message"
                  image
                />
              </Card.Body>
            )}

            {!apiLoading && !apiError && !!apiData?.own?.nums?.total && (
              <Table
                responsive
                className="table-thead-bordered table-nowrap table-align-middle card-table"
              >
                <thead className="thead-light">
                  <tr>
                    {columns.map(({ id, titleKey, align }) => (
                      <th key={id} className={align || 'text-end'}>
                        <FormattedMessage id={`app.common.${titleKey}`} />
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {columns.map(
                      ({ id, section, disableNullControl, align }) => (
                        <td
                          key={`row_own_col_${id}`}
                          className={align || 'text-end'}
                        >
                          {columnParser(
                            id,
                            section,
                            apiData?.own,
                            true,
                            disableNullControl
                          )}
                        </td>
                      )
                    )}
                  </tr>
                  {apiData.compe?.map((row, rowIndex) => (
                    <tr key={`row_${rowIndex.toString()}`}>
                      {columns.map(
                        ({ id, section, disableNullControl, align }, index) => (
                          <td
                            key={`row_${rowIndex.toString()}_col_${id}_${index.toString()}`}
                            className={align || 'text-end'}
                          >
                            {columnParser(
                              id,
                              section,
                              row,
                              false,
                              disableNullControl
                            )}
                          </td>
                        )
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Card>
        </Col>
        <Col xl="8" className="mb-3 mb-lg-5">
          <TrendAnalysis initialGroupId={activeGroupId} />
        </Col>
        <Col xl="4" className="mb-3 mb-lg-5">
          <Leaderboard initialGroupId={activeGroupId} />
        </Col>
      </Row>
    </>
  );
}

export default CompetitorsReport;
