import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FormattedHtml } from '../../../../../components';
// import { RequestResult } from '../../../../../components';

const API_SERVER_IP = process.env.REACT_APP_BASE_IP || '';
const APP_TITLE = process.env.REACT_APP_SITE_TITLE || '';

function WarningMessage() {
  const getIpString = () => (
    <strong className="text-primary fs-4"> {API_SERVER_IP} </strong>
  );
  const getLink = (tag, val) => {
    let link = '';
    switch (tag) {
      case 'a_cloudflare':
        link = 'https://dash.cloudflare.com/';
        break;

      case 'a_parameters':
        link =
          'https://developers.cloudflare.com/waf/tools/ip-access-rules/parameters/';
        break;

      case 'a_actions':
        link =
          'https://developers.cloudflare.com/waf/tools/ip-access-rules/actions/';
        break;

      default:
        link = '';
        break;
    }

    return (
      <a
        href={link}
        target="_blank"
        rel="nofollow noreferrer"
        className="link-primary"
      >
        {val}
        <i className="bi-box-arrow-up-right ms-1" />
      </a>
    );
  };

  return (
    <Row className="justify-content-center">
      <Col xl="8" className="align-self-center">
        <Card>
          <Card.Body>
            <FormattedHtml
              id="warnings.cloudflare.integrationMessage"
              values={{
                ip: getIpString(),
                appTitle: APP_TITLE,
                a_cloudflare: (v) => getLink('a_cloudflare', v),
                a_parameters: (v) => getLink('a_parameters', v),
                a_actions: (v) => getLink('a_actions', v),
              }}
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default WarningMessage;
