import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { useGoogleSearchConsoleApi } from '../../../../../hooks/Google';
import { RequestLoading, RequestResult } from '../../../../../components';
import SearchConsoleChart from './SearchConsoleChart';
import SearchConsoleTable from './SearchConsoleTable';

function SearchConsoleCard({ type, title, query, siteUrl, token }) {
  const [lastQuery, setLastQuery] = useState();
  const [lastSiteUrl, setLastSiteUrl] = useState();
  const [apiData, setApiData] = useState();
  const [error, setError] = useState();
  const [hasData, setHasData] = useState(false);
  const { apiLoading, apiError, apiFetch } = useGoogleSearchConsoleApi(token, {
    onComplete: (data) => {
      if (data.error) {
        setApiData(null);
        setHasData(false);
        setError(data.error.message);
        return;
      }

      setApiData(data);
      if (data?.rows?.length > 0) {
        setHasData(true);
      }
    },
  });

  useEffect(() => {
    if (query !== lastQuery || siteUrl !== lastSiteUrl) {
      setApiData(null);
      setHasData(false);
      setLastQuery(query);
      setLastSiteUrl(siteUrl);
      apiFetch(siteUrl, query);
    }
  }, [apiFetch, query, lastQuery, siteUrl, lastSiteUrl]);

  useEffect(() => {
    setError(apiError);
  }, [apiError]);

  return (
    <Card className="h-100">
      <Card.Header>
        <Card.Title>{title}</Card.Title>
      </Card.Header>
      <Card.Body
        className={
          type === 'table' && apiData && hasData && !apiError ? 'p-0' : ''
        }
      >
        <RequestLoading loading={apiLoading} size="lg" margin="5" />
        {!apiLoading && error && (
          <RequestResult
            type="error"
            message={error || 'app.common.somethingWentWrong'}
          />
        )}
        {!apiLoading && apiData && !hasData && !error && (
          <RequestResult type="secondary" message="app.common.noData" />
        )}
        {!apiLoading && apiData && hasData && !error && (
          <>
            {type === 'table' && (
              <SearchConsoleTable
                data={apiData}
                queryDimension={query?.dimensions?.[0]}
              />
            )}
            {type === 'chart' && <SearchConsoleChart data={apiData} />}
          </>
        )}
      </Card.Body>
    </Card>
  );
}

SearchConsoleCard.propTypes = {
  type: PropTypes.oneOf(['table', 'chart']).isRequired,
  title: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  siteUrl: PropTypes.string.isRequired,
  query: PropTypes.object.isRequired,
};

export default SearchConsoleCard;
