import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

function AnalyticsTable({ data }) {
  return (
    <Table
      responsive
      borderless
      className="table-thead-bordered table-nowrap table-align-middle card-table"
    >
      <thead className="thead-light">
        <tr>
          {data.columnHeaders.map(({ name, dataType }) => (
            <th key={name} className={dataType !== 'STRING' ? 'text-end' : ''}>
              <FormattedMessage
                id={`app.googleAnalytics.${name.replace('ga:', '')}`}
              />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.rows.map((row, rowIndex) => (
          <tr key={`row_${rowIndex.toString()}`}>
            {data.columnHeaders.map(({ name, dataType }, index) => (
              <td
                key={`row_${rowIndex.toString()}_col_${name}_${index.toString()}`}
                className={dataType !== 'STRING' ? 'text-end' : ''}
              >
                {row[index]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

AnalyticsTable.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AnalyticsTable;
