/* eslint-disable no-nested-ternary */

import React, { useState, useRef, useEffect } from 'react';

import PropTypes from 'prop-types';

import { Nav } from 'react-bootstrap';

import { Link, useMatch, useMatches } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// import { useWindowSize } from 'react-use';

function SubDropdown({ submenus, show, level }) {
  return (
    <div
      className="hs-sub-menu dropdown-menu navbar-dropdown-menu-borderless"
      style={{ minWidth: '14rem', display: show ? 'block' : 'none' }}
    >
      {submenus.map((submenu, index) => (
        <SubMenuItem
          item={submenu}
          key={`${index.toString()}`}
          level={level + 1}
        />
      ))}
    </div>
  );
}

const MenuItemProps = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string,
  icon: PropTypes.string,
};

const MenuItemShape = {
  ...MenuItemProps,
  submenu: PropTypes.arrayOf(PropTypes.shape(MenuItemProps)),
};

SubDropdown.propTypes = {
  submenus: PropTypes.arrayOf(PropTypes.shape(MenuItemProps)).isRequired,
  show: PropTypes.bool.isRequired,
  level: PropTypes.number.isRequired,
};

function SubMenuItem({ item, level }) {
  // const windowSize = useWindowSize();
  const matches = useMatches();
  const initialShow = !!(
    level === 0 &&
    window.innerWidth <= 960 &&
    matches?.find((m) => m.pathname === item.href)
  );

  const match = useMatch({ path: item.href || '' });
  const [showDropdown, setShowDropdown] = useState(initialShow);

  const ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (
        window.innerWidth > 960 &&
        showDropdown &&
        ref.current &&
        !ref.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };
    document.addEventListener('mousedown', handler);
    // document.addEventListener('touchstart', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
      // document.removeEventListener('touchstart', handler);
    };
  }, [showDropdown]);

  const onMouseEnter = () => {
    if (window.innerWidth > 960) {
      setShowDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth > 960) {
      setShowDropdown(false);
    }
  };

  const handleOnTouchStart = () => {
    if (window.innerWidth <= 960) {
      setShowDropdown(!showDropdown);
    }
  };

  let as = 'li';
  if (level > 0) {
    as = item.submenu ? 'div' : Link;
  }

  return (
    <Nav.Item
      as={as}
      role="button"
      className={`${item.submenu ? 'hs-has-sub-menu' : ''} ${
        item.submenu && showDropdown ? 'hs-sub-menu-opened' : ''
      } ${
        matches?.find((m) => m.pathname === item.href) || match ? 'active' : ''
      }`}
      bsPrefix={level > 0 && !item.submenu ? 'dropdown-item' : undefined}
      to={item.href || ''}
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={() => {
        if (level > 0) {
          onMouseLeave();
        }
      }}
    >
      {!item.submenu ? (
        level === 0 ? (
          <Nav.Link as={Link} to={item.href} active={!!match}>
            {item.icon && <i className={`${item.icon} dropdown-item-icon`} />}
            <FormattedMessage
              id={`app.common.${item.title}`}
              defaultMessage={item.title}
            />
          </Nav.Link>
        ) : (
          <>
            {item.icon && <i className={`${item.icon} dropdown-item-icon`} />}
            <FormattedMessage
              id={`app.common.${item.title}`}
              defaultMessage={item.title}
            />
          </>
        )
      ) : (
        <>
          <Nav.Link
            className="hs-mega-menu-invoker dropdown-toggle"
            bsPrefix={level > 0 && 'dropdown-item'}
            active={initialShow || !!match}
            onTouchStart={handleOnTouchStart}
          >
            {item.icon && <i className={`${item.icon} dropdown-item-icon`} />}
            <FormattedMessage
              id={`app.common.${item.title}`}
              defaultMessage={item.title}
            />
          </Nav.Link>
          <SubDropdown
            submenus={item.submenu}
            show={showDropdown}
            level={level}
          />
        </>
      )}
    </Nav.Item>
  );
}
SubMenuItem.propTypes = {
  item: PropTypes.shape(MenuItemShape).isRequired,
  level: PropTypes.number.isRequired,
};

function NavbarNavMenu({ menuData }) {
  return (
    <Nav as="ul" className="d-flex flex-wrap">
      {menuData.map((menu, index) => {
        const level = 0;
        return (
          <SubMenuItem item={menu} key={`${index.toString()}`} level={level} />
        );
      })}
    </Nav>
  );
}

NavbarNavMenu.propTypes = {
  menuData: PropTypes.arrayOf(PropTypes.shape(MenuItemShape)).isRequired,
};

export default NavbarNavMenu;
