import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Button,
  Card,
  Col,
  Dropdown,
  Row,
  Table,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useAxiosQuery } from '../../../../hooks';
import { RequestLoading, RequestResult } from '../../../../components';
import { PropertyContext } from '../../../../context/PropertyContext';

const columns = [
  {
    titleKey: 'rank',
    id: 'rank',
    align: 'text-center',
  },
  {
    titleKey: 'name',
    id: 'name',
    align: 'text-start',
  },
  {
    titleKey: 'averageRate',
    id: 'avg',
  },
];

function Leaderboard({ initialGroupId }) {
  const { activeProperty } = useContext(PropertyContext);

  const [activeGroupId, setActiveGroupId] = useState(
    initialGroupId || activeProperty?.groups[0].id
  );

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/reputation_manager/get_leaderboard',
    preventFetch: !activeProperty?.id || !activeGroupId,
    params: {
      id: activeProperty?.id,
      compsetId: activeGroupId,
    },
  });

  const handleChangeGroup = (id) => {
    setActiveGroupId(id);
  };

  const renderActiveGroup = () => {
    const foundedGroup = activeProperty?.groups.find(
      (group) => Number(group.id) === Number(activeGroupId)
    );

    return (
      <>
        <span
          className="legend-indicator me-2"
          style={{ backgroundColor: foundedGroup?.color }}
        />
        {foundedGroup?.name}
      </>
    );
  };

  const columnParser = (id, row, index) => {
    const value = row[id];

    if (id !== 'rank' && !value) {
      return (
        <Badge bg="soft-secondary" className="text-secondary">
          <FormattedMessage id="app.common.n/a" />
        </Badge>
      );
    }

    const ranks = ['🥇', '🥈', '🥉'];

    let result;
    switch (id) {
      case 'rank':
        result = (
          <span
            className={`${ranks[index] ? 'fs-3' : ''} ${
              row.own ? 'text-primary fw-bold' : ''
            }`}
          >
            {ranks[index] || index + 1}
          </span>
        );
        break;
      case 'name':
        result = (
          <div
            className={`d-flex flex-wrap align-items-start ${
              row.own ? 'text-primary' : ''
            }`}
          >
            <b className={row.own ? 'me-2 mb-1' : ''}>{value}</b>
            {row.own && (
              <Badge bg="primary">
                <i className="bi-check-circle-fill me-1" />
                <FormattedMessage id="app.common.myProperty" />
              </Badge>
            )}
          </div>
        );
        break;

      case 'avg':
        result = (
          <span className={row.own ? 'text-primary fw-bold' : ''}>
            {value.toFixed(2)}
          </span>
        );
        break;

      default:
        result = value;
        break;
    }

    return result;
  };

  useEffect(() => {}, [activeProperty, initialGroupId]);

  return (
    <Card className="h-100">
      <Card.Header>
        <Row className="justify-content-between align-items-center flex-grow-1">
          <Col md className="mb-3 mb-md-0 mb-lg-3 mb-xl-0">
            <Card.Title bsPrefix="card-header-title" as="h4">
              <FormattedMessage id="app.common.leaderboard" />
            </Card.Title>
          </Col>
          {!initialGroupId && (
            <Col xs="auto">
              <Row className="align-items-sm-center">
                <Col sm="auto" className="mb-2 mb-md-0">
                  <Dropdown align="end" onSelect={handleChangeGroup}>
                    <Dropdown.Toggle
                      as={Button}
                      size="sm"
                      variant="white"
                      disabled={apiLoading}
                    >
                      {renderActiveGroup()}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="m-0" renderOnMount>
                      {activeProperty?.groups.map((group) => (
                        <Dropdown.Item
                          key={group.id}
                          eventKey={group.id}
                          className="d-flex align-items-center"
                        >
                          <span
                            className="legend-indicator me-2"
                            style={{ backgroundColor: group.color }}
                          />
                          {group.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Card.Header>
      <Card.Body className="p-0">
        <RequestLoading loading={apiLoading} margin="5" />
        <RequestResult type="error" message={apiError} />
        {!apiLoading && !apiError && Object.keys(apiData)?.length === 0 && (
          <RequestResult
            type="secondary"
            title="emptyState.trendAnalysis.title"
            message="emptyState.trendAnalysis.message"
            image
          />
        )}
        {!apiLoading && !apiError && Object.keys(apiData)?.length > 0 && (
          <Table
            responsive
            className="table-thead-bordered table-align-middle card-table"
          >
            <thead className="thead-light">
              <tr>
                {columns.map(({ id, titleKey, align }) => (
                  <th key={id} className={align || 'text-end'}>
                    <FormattedMessage id={`app.common.${titleKey}`} />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {apiData.map((row, rowIndex) => (
                <tr key={`row_${rowIndex.toString()}`}>
                  {columns.map(({ id, align }, index) => (
                    <td
                      key={`row_${rowIndex.toString()}_col_${id}_${index.toString()}`}
                      className={align || 'text-end'}
                    >
                      {columnParser(id, row, rowIndex)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Card.Body>
    </Card>
  );
}

Leaderboard.propTypes = {
  initialGroupId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Leaderboard.defaultProps = {
  initialGroupId: undefined,
};

export default Leaderboard;
