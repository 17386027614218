import React, { useContext } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { PageHeader } from '../../../components';
import { PropertyContext } from '../../../context/PropertyContext';
import Constants from '../../../constants';
import { TabPaneItem, TabPaneItemEmpty } from './partials';

function SocialSphere() {
  const { activeProperty } = useContext(PropertyContext);

  const renderMenuItem = (item) => (
    <Nav.Item key={item.id}>
      <Nav.Link as="button" eventKey={item.id}>
        <i className={`${item.icon} me-2`} />
        {item.label}
      </Nav.Link>
    </Nav.Item>
  );

  const getSortedSites = () => {
    const data =
      activeProperty.PlatformsSocial?.length > 0
        ? activeProperty.PlatformsSocial
        : [];
    const founded = Object.values(Constants.Social.Sites).filter((o1) =>
      data.some((o2) => o1.id === o2.site)
    );
    const others = Object.values(Constants.Social.Sites).filter(
      (o1) => !data.some((o2) => o1.id === o2.site)
    );
    return [...founded, ...others];
  };

  const sortedSites = getSortedSites();

  return (
    <div className="content container">
      <Tab.Container defaultActiveKey={sortedSites[0].id}>
        <PageHeader
          className="d-block"
          title="socialSphere"
          menuData={sortedSites}
          renderMenuItem={renderMenuItem}
          breadcrumbData={{
            current: 'socialSphere',
          }}
        />

        <div>
          <Tab.Content>
            {sortedSites.map((item) => {
              const founded = activeProperty.PlatformsSocial?.find(
                (nItem) => nItem.site === item.id
              );
              if (founded) {
                return (
                  <Tab.Pane eventKey={item.id} key={item.id}>
                    <TabPaneItem item={founded} property={activeProperty} />
                  </Tab.Pane>
                );
              }
              return (
                <Tab.Pane eventKey={item.id} key={item.id}>
                  <TabPaneItemEmpty item={item} property={activeProperty} />
                </Tab.Pane>
              );
            })}
          </Tab.Content>
        </div>
      </Tab.Container>
    </div>
  );
}

export default SocialSphere;
