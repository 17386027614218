const Brands = {
  airbnb: {
    id: 'airbnb',
    label: 'Airbnb',
    color: '#ff5a5f',
    types: ['review'],
    categories: ['lodging'],
  },
  booking: {
    id: 'booking',
    label: 'Booking.com',
    color: '#253d7a',
    types: ['review'],
    categories: ['lodging'],
  },
  expedia: {
    id: 'expedia',
    label: 'Expedia',
    color: '#fddb32',
    types: ['review'],
    categories: ['lodging'],
  },
  facebook: {
    id: 'facebook',
    label: 'Facebook',
    color: '#3b5998',
    icon: 'bi-facebook',
    url: 'https://www.facebook.com',
    types: ['social'],
  },
  google: {
    id: 'google',
    label: 'Google',
    color: '#4285f4',
    types: ['review'],
  },
  hotels: {
    id: 'hotels',
    label: 'Hotels.com',
    color: '#ef3346',
    types: ['review'],
  },
  tripadvisor: {
    id: 'tripadvisor',
    label: 'Trip Advisor',
    color: '#00af87',
    types: ['review'],
    categories: ['lodging'],
  },
  trustpilot: {
    id: 'trustpilot',
    label: 'Trust Pilot',
    color: '#00b67a',
    types: ['review'],
    categories: ['all'],
  },
  yelp: {
    id: 'yelp',
    label: 'Yelp',
    color: '#ff1a1a',
    types: ['review'],
    categories: ['all'],
  },
  yahoo: { id: 'yahoo', label: 'Yahoo', color: '#5f01d1', types: ['review'] },
  thumbtack: {
    id: 'thumbtack',
    label: 'Thumbtack',
    color: '#009fd9',
    types: ['review'],
    categories: ['plumber', 'electrician', 'locksmith', 'painter'],
  },
  angi: {
    id: 'angi',
    label: 'Angi',
    color: '#ff6153',
    types: ['review'],
    categories: ['plumber', 'electrician', 'locksmith', 'painter'],
  },
  realtor: {
    id: 'realtor',
    label: 'Realtor',
    color: '#d92228',
    types: ['review'],
    categories: ['real_estate_agency'],
  },
  zillow: {
    id: 'zillow',
    label: 'Zillow',
    color: '#006aff',
    types: ['review'],
    categories: ['real_estate_agency'],
  },
  lawyers: {
    id: 'lawyers',
    label: 'Lawyers.com',
    color: '#009ed7',
    types: ['review'],
    categories: ['lawyer'],
  },
  martindale: {
    id: 'martindale',
    label: 'Martindale',
    color: '#88090c',
    types: ['review'],
    categories: ['lawyer'],
  },
  healthgrades: {
    id: 'healthgrades',
    label: 'Healthgrades',
    color: '#0202ea',
    types: ['review'],
    categories: ['doctor', 'dentist'],
  },
  cargurus: {
    id: 'cargurus',
    label: 'CarGurus',
    color: '#00a0dd',
    types: ['review'],
    categories: ['car_dealer'],
  },
  cars: {
    id: 'cars',
    label: 'Cars.com',
    color: '#65299d',
    types: ['review'],
    categories: ['car_dealer'],
  },
  pinterest: {
    id: 'pinterest',
    label: 'Pinterest',
    color: '#e60023',
    icon: 'bi-pinterest',
    url: 'https://www.pinterest.com',
    types: ['social'],
  },
  twitter: {
    id: 'twitter',
    label: 'Twitter',
    color: '#1DA1F2',
    icon: 'bi-twitter',
    url: 'https://www.twitter.com',
    types: ['social'],
  },
  instagram: {
    id: 'instagram',
    label: 'Instagram',
    color: '#833AB4',
    icon: 'bi-instagram',
    url: 'https://www.instagram.com',
    types: ['social'],
  },
  tiktok: {
    id: 'tiktok',
    label: 'TikTok',
    color: '#fe2c55',
    icon: 'bi-tiktok',
    url: 'https://www.tiktok.com',
    types: ['social'],
  },
  youtube: {
    id: 'youtube',
    label: 'YouTube',
    color: '#FF0000',
    icon: 'bi-youtube',
    url: 'https://www.youtube.com',
    types: ['social'],
  },
  linkedin: {
    id: 'linkedin',
    label: 'LinkedIn',
    color: '#0e76a8',
    icon: 'bi-linkedin',
    url: 'https://www.linkedin.com',
    types: ['social'],
  },
};

export default Brands;
