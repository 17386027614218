import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, Dropdown, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { ThemeContext } from '../../../../context/ThemeContext';
import { PropertyContext } from '../../../../context/PropertyContext';
import { useAxiosQuery } from '../../../../hooks';
import {
  BrandIcon,
  DateRangePicker,
  RequestLoading,
  RequestResult,
  Tooltip,
} from '../../../../components';
import { BarChart, LineChart } from '../../../../components/Charts';
import Utils from '../../../../utils';
import Constants from '../../../../constants';

const isBar = false;

const options = {
  // backend report charts, test
  /* animation: {
    onComplete(e) {
      const dataURL = e.chart.canvas.toDataURL('image/png');
      window.document.write(`<img src="${dataURL}"/>`);
    },
  }, */
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'day',
        displayFormats: {
          day: Constants.DateFormats.APP.Moment.Common,
        },
      },
    },
    y: {
      min: 0,
      max: 5,
      ticks: {
        precision: 0,
        stepSize: 1,
        callback: (value) => value,
      },
    },
  },
};

const dateFrom = moment().subtract(89, 'days');
const dateTo = moment();

const initialDates = [
  dateFrom.format(Constants.DateFormats.API),
  dateTo.format(Constants.DateFormats.API),
];

function TrendAnalysis({ initialGroupId }) {
  const { theme } = useContext(ThemeContext);
  const { activeProperty } = useContext(PropertyContext);
  const [activeGroupId, setActiveGroupId] = useState(
    initialGroupId || activeProperty?.groups[0].id
  );

  const [activeSite, setActiveSite] = useState(null);
  const [date, setDate] = useState(initialDates);

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/reputation_manager/list',
    preventFetch: !activeProperty?.id || !activeGroupId,
    params: {
      id: activeProperty?.id,
      date,
      compsetId: activeGroupId,
    },
    select: (data) => {
      const orderedKeys = Object.keys(data).sort((a, b) => a.localeCompare(b));
      const formatted = orderedKeys.reduce((obj, key) => {
        const nObj = obj;
        nObj[key] = data[key];
        return nObj;
      }, {});

      return formatted;
    },
  });

  /* const getDataset = (data, type) => {
    const dates = Utils.Date.getDaysBetweenDates(date[0], date[1]).map((item) =>
      moment(item).format(Constants.DateFormats.API)
    );

    return {
      labels: dates.map((item) =>
        moment(item).format(Constants.DateFormats.APP.Moment.Common)
      ),
      datasets: [
        {
          data: data.list[type],
          // label: 'xxx',
          ...Utils.Chart.getLineChartOptions(
            0,
            // { borderWidth: 4, pointRadius: 8, pointHoverRadius: 8 },
            theme
          ),
        },
      ],
    };
  }; */

  const getDataset = (data, type) => {
    const dates = Utils.Date.getDaysBetweenDates(date[0], date[1]).map((item) =>
      moment(item).format(Constants.DateFormats.API)
    );

    const compsData = [];
    const getChartOptions = isBar
      ? Utils.Chart.getBarChartOptions
      : Utils.Chart.getLineChartOptions;

    data[type].compe.forEach((item, index) => {
      if (item.rates.list) {
        compsData.push({
          data: Object.values(item.rates.list)[0].map((x) => x.toFixed(1)),
          label: item.title,
          ...getChartOptions(index + 1, theme),
        });
      }
    });

    if (Object.keys(data[type].own).length > 0) {
      return {
        labels: dates,
        datasets: [
          {
            data: Object.values(data[type].own.rates.list)[0].map((x) =>
              x.toFixed(1)
            ),
            label: data[type].own.title,
            ...getChartOptions(0, theme),
          },
          ...compsData,
        ],
      };
    }

    return {
      labels: dates,
      datasets: compsData,
    };
  };

  const handleChangeDate = (d) => {
    const nDate = [
      moment(d[0]).format(Constants.DateFormats.API),
      moment(d[1]).format(Constants.DateFormats.API),
    ];
    setDate(nDate);
  };

  const handleChangeGroup = (id) => {
    setActiveGroupId(id);
  };

  const renderActiveGroup = () => {
    const foundedGroup = activeProperty?.groups.find(
      (group) => Number(group.id) === Number(activeGroupId)
    );

    return (
      <>
        <span
          className="legend-indicator me-2"
          style={{ backgroundColor: foundedGroup?.color }}
        />
        {foundedGroup?.name}
      </>
    );
  };

  useEffect(() => {
    setActiveGroupId(initialGroupId || activeProperty?.groups[0].id);
  }, [activeProperty, initialGroupId]);

  return (
    <Card className="h-100">
      <Card.Header>
        <Row className="justify-content-between align-items-center flex-grow-1">
          <Col md className="mb-3 mb-md-0 mb-lg-3 mb-xl-0">
            <Card.Title bsPrefix="card-header-title" as="h4">
              <FormattedMessage id="app.common.trendAnalysis" />
              <Tooltip
                content={
                  <FormattedMessage id="app.helpers.reputationManager.competitorsReport.tooltips.trendAnalysis" />
                }
              >
                <i className="bi-question-circle text-body ms-1" />
              </Tooltip>
            </Card.Title>
          </Col>
          <Col xs="auto">
            <Row className="align-items-sm-center">
              <Col sm="auto" className="mb-2 mb-md-0">
                <DateRangePicker
                  type="button"
                  initialSettings={{
                    initialDates: {
                      startDate: initialDates[0],
                      endDate: initialDates[1],
                    },
                  }}
                  value={date}
                  onChange={handleChangeDate}
                  disabled={apiLoading}
                />
              </Col>
              {!initialGroupId && (
                <Col sm="auto" className="mb-2 mb-md-0">
                  <Dropdown align="end" onSelect={handleChangeGroup}>
                    <Dropdown.Toggle
                      as={Button}
                      size="sm"
                      variant="white"
                      disabled={apiLoading}
                    >
                      {renderActiveGroup()}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="m-0" renderOnMount>
                      {activeProperty?.groups.map((group) => (
                        <Dropdown.Item
                          key={group.id}
                          eventKey={group.id}
                          className="d-flex align-items-center"
                        >
                          <span
                            className="legend-indicator me-2"
                            style={{ backgroundColor: group.color }}
                          />
                          {group.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              )}
              {!apiLoading && !apiError && Object.keys(apiData)?.length > 0 && (
                <Col sm="auto" className="mb-2 mb-sm-0">
                  <Dropdown align="end" onSelect={setActiveSite}>
                    <Dropdown.Toggle as={Button} size="sm" variant="white">
                      <BrandIcon
                        brand={activeSite || Object.keys(apiData)[0]}
                        className="me-1"
                      />
                      {
                        Constants.Review.Sites[
                          `${activeSite || Object.keys(apiData)[0]}`
                        ].label
                      }
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="m-0" renderOnMount>
                      {Object.keys(apiData).map((key) => (
                        <Dropdown.Item
                          key={key}
                          eventKey={key}
                          className="d-flex"
                        >
                          <BrandIcon brand={key} className="me-1" />
                          {Constants.Review.Sites[key].label}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <RequestLoading loading={apiLoading} margin="5" />
        <RequestResult type="error" message={apiError} />
        {!apiLoading && !apiError && Object.keys(apiData)?.length === 0 && (
          <RequestResult
            type="secondary"
            title="emptyState.trendAnalysis.title"
            message="emptyState.trendAnalysis.message"
            image
          />
        )}
        {!apiLoading && !apiError && Object.keys(apiData)?.length > 0 && (
          <div className="h-100 d-flex flex-column justify-content-center align-items-center">
            {isBar ? (
              <BarChart
                data={getDataset(
                  apiData,
                  activeSite || Object.keys(apiData)[0]
                )}
                options={options}
              />
            ) : (
              <LineChart
                data={getDataset(
                  apiData,
                  activeSite || Object.keys(apiData)[0]
                )}
                options={options}
              />
            )}
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

TrendAnalysis.propTypes = {
  initialGroupId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

TrendAnalysis.defaultProps = {
  initialGroupId: undefined,
};

export default TrendAnalysis;
