import React, { useContext } from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import {
  Routes as AppRoutes,
  authBasePath,
  protectedAreaBasePath,
} from './routeMapping';
import { Auth } from '../layouts';
import Constants from '../constants';

function AuthRoute() {
  const location = useLocation();

  const { isAuthenticated, role } = useContext(AuthContext);

  const dashboard =
    role === Constants.User.Roles.Admin.id ? protectedAreaBasePath : null;

  const to =
    location?.state?.from?.pathname &&
    location.state.from.pathname !== AppRoutes.auth.LOGIN.path
      ? location.state.from.pathname
      : dashboard;

  if (isAuthenticated) {
    return (
      <Navigate
        to={to}
        state={
          to !== dashboard ? { fromLogin: location?.state?.from } : undefined
        }
        replace
      />
    );
  }

  return (
    <Auth>
      {location.pathname === authBasePath ? (
        <Navigate to={AppRoutes.auth.LOGIN.path} replace />
      ) : (
        <Outlet />
      )}
    </Auth>
  );
}

export default AuthRoute;
