import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card, Button, Spinner } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { lowerCase } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useAxiosMutation, useConfirmModal } from '../../../../../hooks';
import { PropertyContext } from '../../../../../context/PropertyContext';
import { protectedAreaBasePath } from '../../../../../router/routeMapping';

function DeleteProperty({ property }) {
  const navigate = useNavigate();
  const { removeProperty } = useContext(PropertyContext);

  const { formatMessage } = useIntl();
  const { confirm } = useConfirmModal({ confirmVariant: 'danger' });
  const {
    isLoading: apiLoading,
    error: apiError,
    mutate: apiFetch,
  } = useAxiosMutation({
    url: '/places/reset',
    // params: { xid: property.id },
  });

  const handleOnDelete = async () => {
    const isSuccess = await confirm({
      message: (
        <FormattedMessage
          id="app.common.areYouSureYouWantToDeleteXY"
          values={{
            x: property.title,
            y: lowerCase(formatMessage({ id: 'app.common.property' })),
          }}
        />
      ),
    });
    if (isSuccess) {
      apiFetch(
        { id: property.id },
        {
          onSuccess: () => {
            removeProperty(property.id);
            navigate(protectedAreaBasePath, { replace: true });
          },
        }
      );
    }
  };

  return (
    <>
      <Card.Text>
        <FormattedMessage id="app.helpers.properties.settings.deleteInfo" />
      </Card.Text>
      <div className="d-flex justify-content-end">
        <Button disabled={apiLoading} variant="danger" onClick={handleOnDelete}>
          {apiLoading ? (
            <>
              <Spinner animation="border" size="xs" className="me-1" />
              <FormattedMessage id="app.common.loading" />
            </>
          ) : (
            <span>
              {apiError ? (
                <>
                  <i className="bi-exclamation-triangle me-1" />
                  <FormattedMessage id="app.common.tryAgain" />
                </>
              ) : (
                <>
                  <i className="bi-trash me-1" />
                  <FormattedMessage id="app.common.delete" />
                </>
              )}
            </span>
          )}
        </Button>
      </div>
    </>
  );
}

DeleteProperty.propTypes = {
  property: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default DeleteProperty;
