import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Constants from '../../../../../constants';
import Utils from '../../../../../utils';
import { LineChart } from '../../../../../components/Charts';
import { ThemeContext } from '../../../../../context/ThemeContext';
import { ChartJS } from '../../../../../lib/chartjs';

const defaultLegendClickHandler = ChartJS.defaults.plugins.legend.onClick;

const yAxisItems = [
  { id: 'clicks', labelKey: 'clicks' },
  { id: 'impressions', labelKey: 'impressions' },
  { id: 'ctr', labelKey: 'ctr' },
  { id: 'position', labelKey: 'position' },
];

const options = {
  plugins: {
    legend: {
      display: true,
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          let label = context.dataset.label || '';

          if (label) {
            label += ': ';
          }

          if (context.parsed.y !== null) {
            const prefix = '';
            const postfix = context.dataset.yAxisID === 'ctr' ? '%' : '';

            label += `${prefix}${context.parsed.y}${postfix}`;
          }
          return label;
        },
      },
    },
  },
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'day',
        displayFormats: {
          day: Constants.DateFormats.APP.Moment.Common,
        },
      },
    },
  },
};

function SearchConsoleChart({ data }) {
  const { theme } = useContext(ThemeContext);
  const { formatMessage } = useIntl();

  const getDataset = () => {
    const res = {
      labels: data.rows.map((item) => item.keys[0]),
      datasets: yAxisItems.map((yAxis, index) => ({
        data: data.rows.map((item) => {
          let val = item[yAxis.id];
          if (yAxis.id === 'ctr') {
            val = (item[yAxis.id] * 100).toFixed(1);
          } else if (yAxis.id === 'position') {
            val = (Math.round((val + Number.EPSILON) * 100) / 100).toFixed(1);
          }

          return val;
        }),
        label: formatMessage({ id: `app.common.${yAxis.labelKey}` }),
        yAxisID: yAxis.id,
        hidden: index > 1,
        ...Utils.Chart.getLineChartOptions(index, theme),
      })),
    };

    return res;
  };

  const onLegendClick = (event, legendItem, legend) => {
    const ci = legend.chart;

    defaultLegendClickHandler(event, legendItem, legend);

    Object.values(ci.scales).forEach((scale) => {
      const nScale = scale;
      const { id } = nScale;

      if (id !== 'x' && id !== 'y') {
        nScale.options.display = false;
        nScale.options.position = 'left';
      }
    });

    const visibleMetas = ci.getSortedVisibleDatasetMetas();
    if (visibleMetas.length <= 2) {
      visibleMetas.forEach((meta, index) => {
        const nMeta = meta;
        nMeta.yScale.options.display = true;
        nMeta.yScale.options.position = index % 2 === 0 ? 'left' : 'right';
      });
    }

    ci.update();
  };

  const getOptions = () => {
    const defaultOptions = options;
    // const refOptions = Utils.Chart.getLineChartDefaultOptions({}, t);

    yAxisItems.forEach((item, index) => {
      const o = {
        position: index % 2 === 0 ? 'left' : 'right',
        display: 'auto',
        reverse: item.id === 'position',
        title: {
          display: true,
          text: formatMessage({ id: `app.common.${item.labelKey}` }),
        },
        grid: {
          display: false,
        },
      };

      if (item.id === 'ctr') {
        o.ticks = {
          callback: (value) => `${value}%`,
        };
      }

      defaultOptions.scales[item.id] = o;
    });

    defaultOptions.scales.y = {
      ticks: {
        display: false,
      },
      grid: {
        display: true,
      },
    };

    defaultOptions.plugins.legend.onClick = onLegendClick;
    return defaultOptions;
  };

  return (
    <div className="h-100 d-flex flex-column justify-content-center align-items-center">
      <LineChart data={getDataset()} options={getOptions()} />
    </div>
  );
}

SearchConsoleChart.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SearchConsoleChart;
