import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Row, Col, Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import ReactSelect from 'react-select';
import { useAxiosQuery } from '../../../hooks';
import { yup } from '../../../lib';
import {
  FormFilePicker,
  RequestLoading,
  RequestResult,
  SmartForm,
} from '../../../components';

const paramsByIdResult = {
  Status: 'success',
  status: true,
  Title: 'Success',
  Message: 'op_success',
  Result: {
    rows: [
      {
        id: 31,
        name: 'Background Color',
        parameter: 'Background',
        parameter_type: 'color',
        default_value: '#A26D6D',
        video_template_id: 3,
      },
      {
        id: 32,
        name: 'Bottom Shape Color',
        parameter: 'BottomShapeColor',
        parameter_type: 'color',
        default_value: '#A26D6D',
        video_template_id: 3,
      },
      {
        id: 33,
        name: 'Text Color',
        parameter: 'TextColor',
        parameter_type: 'color',
        default_value: '#FFFFFF',
        video_template_id: 3,
      },
      {
        id: 34,
        name: 'Hexagon Color',
        parameter: 'HexagonColor',
        parameter_type: 'color',
        default_value: '#FF0000',
        video_template_id: 3,
      },
      {
        id: 35,
        name: 'Hexagon Text Color',
        parameter: 'HexagonTextColor',
        parameter_type: 'color',
        default_value: '#ffffff',
        video_template_id: 3,
      },
      {
        id: 36,
        name: 'Top Shape Color',
        parameter: 'TopShapeColor',
        parameter_type: 'color',
        default_value: '#ffffff',
        video_template_id: 3,
      },
      {
        id: 37,
        name: 'Slogan',
        parameter: 'Slogan',
        parameter_type: 'text',
        default_value: 'lorem ipsum dolor sit amet',
        video_template_id: 3,
      },
      {
        id: 38,
        name: 'Line 1',
        parameter: 'Text1',
        parameter_type: 'text',
        default_value: 'lorem ipsum dolor sit amet',
        video_template_id: 3,
      },
      {
        id: 39,
        name: 'Line 2',
        parameter: 'Text2',
        parameter_type: 'text',
        default_value: 'lorem ipsum dolor sit amet',
        video_template_id: 3,
      },
      {
        id: 40,
        name: 'Line 3',
        parameter: 'Text3',
        parameter_type: 'text',
        default_value: 'lorem ipsum dolor sit amet',
        video_template_id: 3,
      },
      {
        id: 41,
        name: 'Hexagon Text',
        parameter: 'HexagonText',
        parameter_type: 'text',
        default_value: 'lorem ipsum dolor sit amet',
        video_template_id: 3,
      },
      {
        id: 42,
        name: 'Website URL',
        parameter: 'WebURL',
        parameter_type: 'text',
        default_value: 'www.yoursite.com',
        video_template_id: 3,
      },
      {
        id: 43,
        name: 'Image or video 1',
        parameter: 'File1',
        parameter_type: 'file',
        default_value: '',
        video_template_id: 3,
      },
      {
        id: 44,
        name: 'Image or video 2',
        parameter: 'File2',
        parameter_type: 'file',
        default_value: '',
        video_template_id: 3,
      },
      {
        id: 45,
        name: 'Image or video 3',
        parameter: 'File3',
        parameter_type: 'file',
        default_value: '',
        video_template_id: 3,
      },
    ],
    count: 15,
  },
};

function ModalForm({ activeItem, onModalHide }) {
  const [activeTemp, setActiveTemp] = useState();
  const [formFields, setFormFields] = useState([]);
  const [formDefaultValues, setFormDefaultValues] = useState({});

  const {
    data: apiTempsData,
    isLoading: apiTempsLoading,
    error: apiTempsError,
  } = useAxiosQuery({
    url: '/properties/list',
    params: {
      quantity: 99999999,
    },
    onSuccess: (data) => {
      console.log('onSuccess:', data);
      if (data.length > 0) {
        setActiveTemp(data[0]);
      }
    },
  });

  const { isLoading: apiFieldsLoading, error: apiFieldsError } = useAxiosQuery({
    url: '/properties/list',
    // url: 'params_by_id',
    preventFetch: !activeTemp,
    params: { tempId: activeTemp?.id },
    onSuccess: (data) => {
      console.log(data);

      const fields = [];
      const defaultValues = {};
      let fieldCounter = 0;
      let rowCounter = 0;
      let filesCount = 0;

      paramsByIdResult.Result.rows.forEach((item) => {
        if (item.parameter_type === 'file') {
          filesCount += 1;
        } else {
          if (fieldCounter % 3 === 0) {
            fields.push({
              cols: [],
            });
          }

          fields[rowCounter].cols.push({
            key: item.name,
            apiKey: item.parameter,
            type: item.parameter_type,
            schema: yup.string().required(),
          });

          defaultValues[item.parameter] = item.default_value;

          fieldCounter += 1;
          if (fieldCounter % 3 === 0) {
            rowCounter += 1;
          }
        }
      });

      if (filesCount > 0) {
        fields.push({
          cols: [
            {
              key: 'files',
              apiKey: 'files',
              type: 'filePicker',
              options: {
                controller: {
                  props: {
                    requestUrl: '/properties/list',
                    requestParams: {},
                    getFileUrl: (item) => item.ImageURL,
                    getFileId: (item) => item.ImageURL,
                    isClearable: true,
                    isSearchable: true,
                    isMulti: filesCount > 1,
                    maxFiles: filesCount > 1 ? filesCount : true, // or false
                  },
                },
              },
              schema:
                filesCount > 1
                  ? yup.array().min(filesCount).required()
                  : yup.string().required(),
            },
          ],
        });
        // defaultValues.files = [21, 22];
        // defaultValues.files = 23;
      }

      setFormFields(fields);
      setFormDefaultValues(defaultValues);
    },
  });

  return (
    <>
      <Modal.Body className="pb-0">
        <Row>
          <Col xs="12">
            <RequestLoading loading={apiTempsLoading} size="lg" margin="5" />
            <RequestResult
              type="error"
              message={apiTempsError || apiFieldsError}
            />
          </Col>
          {!apiTempsLoading && !apiTempsError && apiTempsData?.length > 0 && (
            <>
              <Col xs="12" className="mb-4">
                <Form.Label>
                  <FormattedMessage id="app.common.templates" />
                </Form.Label>
                <div className="form-control p-0 m-0 bg-transparent">
                  <FormFilePicker
                    files={apiTempsData}
                    isSearchable
                    isMulti={false}
                    getFileUrl={(item) => item.thumbnail}
                    getFileId={(item) => item.id}
                    getFileSearchField={(item) => item.title}
                    value={activeTemp?.id}
                    onChange={(id) => {
                      setActiveTemp(
                        apiTempsData.find((item) => item.id === id)
                      );
                    }}
                  />
                </div>
              </Col>
              <Col xs="12" className="mb-4">
                <Form.Label>
                  <FormattedMessage id="app.common.templates" />
                </Form.Label>
                <ReactSelect
                  options={apiTempsData}
                  getOptionLabel={(option) => `${option.title}`}
                  getOptionValue={(option) => `${option.id}`}
                  className="react-select-custom-container"
                  classNamePrefix="react-select-custom"
                  value={activeTemp}
                  onChange={setActiveTemp}
                />
              </Col>
            </>
          )}
          <Col xs="12">
            <RequestLoading loading={apiFieldsLoading} size="lg" margin="5" />
          </Col>
        </Row>
      </Modal.Body>
      {!apiTempsLoading && !apiTempsError && apiTempsData?.length > 0 && (
        <div>
          {!apiFieldsLoading && !apiFieldsError && formFields?.length > 0 && (
            <SmartForm
              wrapperClass="pt-0"
              isModal
              onModalHide={onModalHide}
              fields={formFields}
              defaultValues={formDefaultValues}
              requestUrl="/xxx"
              requestParams={{ itemId: activeItem?.id }}
              onRequestSuccess={(data) => {
                console.log('onRequestSuccess:', data);
                onModalHide(data);
              }}
            />
          )}
        </div>
      )}
    </>
  );
}

ModalForm.propTypes = {
  activeItem: PropTypes.objectOf(PropTypes.any),
  onModalHide: PropTypes.func,
};

ModalForm.defaultProps = {
  activeItem: null,
  onModalHide: () => {},
};

function ServerForm() {
  const [activeItem, setActiveItem] = useState();
  const [formModalIsVisible, setFormModalIsVisible] = useState(false);

  return (
    <div>
      <Button
        onClick={() => {
          setActiveItem({ id: 1 });
          setFormModalIsVisible(true);
        }}
      >
        <FormattedMessage id="app.common.openModal" />
      </Button>

      <Modal
        show={formModalIsVisible && activeItem}
        onHide={() => {
          setFormModalIsVisible(false);
        }}
        onExited={() => {
          setActiveItem(null);
        }}
        size="xl"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h4">
            <FormattedMessage id="app.common.text" />
            {activeItem?.id}
          </Modal.Title>
        </Modal.Header>
        <ModalForm
          activeItem={activeItem}
          onModalHide={(data) => {
            setFormModalIsVisible(false);
            if (data) {
              console.log('modal closed with data:', data);
            }
          }}
        />
      </Modal>
    </div>
  );
}

export default ServerForm;
