import React, { useEffect, useState } from 'react';
import { Badge, Button } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import SmartImage from './SmartImage';
import { Routes as AppRoutes } from '../router/routeMapping';
import Illustration from '../assets/images/svg/illustrations/oc-project-development.svg';
import IllustrationLight from '../assets/images/svg/illustrations-light/oc-project-development.svg';
import Utils from '../utils';

const upgradeInfoLink = process.env.REACT_APP_SUBSCRIPTION_INFO_LINK || '';

let upgradeInfoLinkIsExternal = false;
if (Utils.String.checkUrlProtocol(upgradeInfoLink)) {
  upgradeInfoLinkIsExternal = true;
}

const onImageError = (e, theme) => {
  e.target.src = theme === 'dark' ? IllustrationLight : Illustration;
};

function NoAccessInfo() {
  const location = useLocation();
  const { formatMessage } = useIntl();
  const [imageError, setImageError] = useState(false);

  let parentPage;
  let activePage;
  Object.values(AppRoutes.protected).forEach((item) => {
    if (item.path === location.pathname) {
      activePage = item;
      return;
    }
    if (item.children) {
      Object.values(item.children).forEach((childItem) => {
        if (childItem.path === location.pathname) {
          activePage = childItem;
          parentPage = item;
        }
      });
    }
  });

  useEffect(() => {
    setImageError(false);
  }, [location]);

  return (
    <div className="pt-5 pb-10">
      <div className="text-center">
        <div className="h1">
          <Badge pill className="text-uppercase">
            <i className="bi-patch-check-fill me-1" />
            <FormattedMessage id="app.common.pro" />
          </Badge>
        </div>
        <div className="my-4">
          <h2>
            {parentPage?.title && (
              <>
                <FormattedMessage id={`app.common.${parentPage.title}`} />
                <span className="mx-2">/</span>
              </>
            )}
            {activePage?.title && (
              <FormattedMessage id={`app.common.${activePage.title}`} />
            )}
          </h2>
        </div>
        <div className="w-lg-75 mx-lg-auto mb-5">
          <figure className="device-browser device-browser-lg">
            <div className="device-browser-header">
              <div className="device-browser-header-btn-list">
                <span className="device-browser-header-btn-list-btn" />
                <span className="device-browser-header-btn-list-btn" />
                <span className="device-browser-header-btn-list-btn" />
              </div>
              <div className="device-browser-header-browser-bar">
                {activePage?.title &&
                  `https://${window.location.hostname}${activePage.path}`}
              </div>
            </div>

            <div className="device-browser-frame">
              {activePage?.title && (
                <SmartImage
                  className={`device-browser-img ${imageError ? 'p-5' : ''}`}
                  src={`/assets/pack-info/${
                    parentPage ? `${parentPage.title}/` : ''
                  }${activePage.title}-light.png`}
                  darkSrc={`/assets/pack-info/${
                    parentPage ? `${parentPage.title}/` : ''
                  }${activePage.title}-dark.png`}
                  onError={(e, theme) => {
                    setImageError(true);
                    onImageError(e, theme);
                  }}
                />
              )}
            </div>
          </figure>
        </div>
        {activePage?.title && (
          <p>
            <FormattedMessage
              id={`app.packPromo.${parentPage ? `${parentPage.title}.` : ''}${
                activePage.title
              }.upgradeMessage`}
              defaultMessage={formatMessage({
                id: 'app.packPromo.default.upgradeMessage',
              })}
            />
            {upgradeInfoLink && (
              <Link
                className="link ms-1"
                to={`${upgradeInfoLink}?page=${activePage.title}${
                  parentPage ? `&parentPage=${parentPage.title}` : ''
                }`}
                target={upgradeInfoLinkIsExternal ? '_blank' : undefined}
                rel={
                  upgradeInfoLinkIsExternal ? 'nofollow noreferrer' : undefined
                }
              >
                <FormattedMessage id="app.common.learnMore" />
                <i className="bi-chevron-right ms-1" />
              </Link>
            )}
          </p>
        )}
        <Button
          as={Link}
          to={AppRoutes.protected.ACCOUNT.children.SUBSCRIPTION_PLANS.path}
        >
          <i className="bi-arrow-up-circle me-1" />
          <FormattedMessage id="app.common.upgradePlan" />
        </Button>
      </div>
    </div>
  );
}

export default NoAccessInfo;
