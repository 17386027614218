import { useCallback, useState } from 'react';
import { qs } from '../../lib';

const BASE_API = 'https://content.googleapis.com/analytics/v3';

export const useGoogleManagementApi = (token, options) => {
  const onComplete = options?.onComplete || null;

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = useCallback(
    async (path, query = {}) => {
      setLoading(true);
      const q = qs.stringify(query);
      try {
        const response = await fetch(`${BASE_API}${path}${q}`, {
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          }),
        });

        const result = await response.json();
        setData(result);
        if (onComplete) {
          onComplete(result);
        }
      } catch (e) {
        setError(e);
      }
      setLoading(false);
    },
    [token, onComplete]
  );

  return {
    apiData: data,
    apiLoading: loading,
    apiError: error,
    apiFetch: fetchData,
  };
};

export default useGoogleManagementApi;
