import React, { forwardRef, useContext, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { RequestLoading, RequestResult, Tooltip } from '../../../../components';
import { PropertyContext } from '../../../../context/PropertyContext';
import { useAxiosQuery } from '../../../../hooks';
import Constants from '../../../../constants';
import { DoughnutChart } from '../../../../components/Charts';

const options = {
  layout: {
    padding: {
      left: 10,
      right: 10,
      top: 10,
      bottom: 10,
    },
  },
  plugins: {
    tooltip: {
      enabled: false,
    },
    legend: {
      labels: {
        generateLabels: (chart) => {
          const { data } = chart;
          if (data.labels.length && data.datasets.length) {
            const {
              labels: { pointStyle },
            } = chart.legend.options;

            const total = chart.data.datasets[0].data.reduce(
              (acc, obj) => acc + obj,
              0
            );

            return data.labels.map((label, i) => {
              const meta = chart.getDatasetMeta(0);
              const style = meta.controller.getStyle(i);

              return {
                text: `${label} - ${
                  chart.data.datasets[0].data[i]
                } (${Math.round(
                  (chart.data.datasets[0].data[i] * 100) / total
                )}%)`,
                fillStyle: style.backgroundColor,
                strokeStyle: style.borderColor,
                lineWidth: style.borderWidth,
                pointStyle,
                hidden: !chart.getDataVisibility(i),

                index: i,
              };
            });
          }
          return [];
        },
      },
    },
    datalabels: {
      padding: {
        left: 8,
        right: 8,
        top: 4,
        bottom: 4,
      },
      display: 'auto',
      anchor: 'center',
      formatter: (value, ctx) => {
        // const label = ctx.chart.data.labels[ctx.dataIndex];
        const dataArr = ctx.chart.data.datasets[0].data;
        const total = dataArr.reduce((acc, obj) => acc + obj, 0);
        return `${value} (${Math.round((value * 100) / total)}%)`;
      },
    },
  },
};

const VisitsByOs = forwardRef(({ date }, ref) => {
  const { activeProperty } = useContext(PropertyContext);

  const {
    data: apiData,
    isFetching: apiLoading,
    error: apiError,
    refetch,
  } = useAxiosQuery({
    url: '/_link/list_by_os',
    preventFetch: !date || !activeProperty?.id,
    params: {
      // id: activeProperty?.id,
      // date,
    },
  });

  const getData = () => {
    const datasetObj = {
      backgroundColor: [],
      data: [],
      borderWidth: 0,
      datalabels: { color: [] },
    };

    const labels = [];
    apiData.forEach((item, index) => {
      datasetObj.data.push(item.co);
      datasetObj.datalabels.color.push(Constants.Colors.Palette[index]);
      datasetObj.backgroundColor.push(Constants.Colors.Palette[index]);
      labels.push(item.label);
    });
    return { labels, datasets: [datasetObj] };
  };

  useImperativeHandle(ref, () => ({
    reload: refetch,
  }));

  return (
    <div>
      <h4 className="mb-4">
        <FormattedMessage id="app.common.visitsByOs" />
        <Tooltip
          content={
            <FormattedMessage id="app.helpers.reputationManager.visitMetrics.tooltips.visitsByOs" />
          }
        >
          <i className="bi-question-circle text-body ms-1" />
        </Tooltip>
      </h4>
      <RequestLoading loading={apiLoading} margin="5" />
      <RequestResult type="error" message={apiError} />
      {!apiLoading && !apiError && apiData?.length === 0 && (
        <RequestResult
          type="secondary"
          title="emptyState.visitsByOs.title"
          message="emptyState.visitsByOs.message"
        />
      )}
      {!apiLoading && !apiError && apiData?.length > 0 && (
        <div className="h-100 w-100 d-flex flex-column justify-content-center align-items-center">
          <DoughnutChart
            data={getData()}
            options={options}
            plugins={{ datalabels: true }}
          />
        </div>
      )}
    </div>
  );
});

VisitsByOs.propTypes = {
  date: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default VisitsByOs;
