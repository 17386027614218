import React, { useContext, useState } from 'react';
import { Badge, Button, Card, Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import moment from 'moment';
import ReactSelect from 'react-select';
import { useAxiosQuery } from '../../../hooks';
import { RequestLoading, RequestResult, Tooltip } from '../../../components';
import Constants from '../../../constants';
import { PropertyContext } from '../../../context/PropertyContext';
import NoDataIllustration from '../../../assets/images/svg/illustrations/oc-work-balance.svg';
import NoDataIllustrationLight from '../../../assets/images/svg/illustrations-light/oc-work-balance.svg';
import { Heatmap } from './partials';

function RankingHeatmap() {
  const { activeProperty } = useContext(PropertyContext);
  const [activeDateIndex, setActiveDateIndex] = useState(0);
  const [activeDate, setActiveDate] = useState();
  const [activeItem, setActiveItem] = useState();

  const {
    isLoading: apiLoading,
    error: apiError,
    data: apiData,
  } = useAxiosQuery({
    url: '/geoGrid/list',
    preventFetch: !activeProperty?.id,
    params: {
      PropertyID: activeProperty?.id,
    },
    select: (data) => {
      const unsorted = _(data)
        .groupBy((x) => x.date)
        .value();
      const sortedKeys = Object.keys(unsorted).sort((a, b) =>
        b.localeCompare(a)
      );
      const sorted = {};
      sortedKeys.forEach((key) => {
        sorted[key] = unsorted[key];
      });
      return sorted;
    },
    onSuccess: (data) => {
      const actDate = Object.keys(data)?.[0] || undefined;
      setActiveDate(actDate);
      setActiveItem(data[actDate]?.[0]);
    },
  });

  return (
    <div>
      <RequestLoading loading={apiLoading} size="lg" margin="5" />
      <RequestResult type="error" message={apiError} />
      {!apiLoading && !apiError && Object.keys(apiData).length === 0 && (
        <RequestResult
          type="secondary"
          title="emptyState.rankingHeatmap.title"
          message="emptyState.rankingHeatmap.message"
          image={{
            withBorder: true,
            src: NoDataIllustration,
            darkSrc: NoDataIllustrationLight,
          }}
        />
      )}
      {!apiLoading && !apiError && apiData?.[activeDate] && (
        <Row>
          <Col xs="12" className="mb-3 mb-lg-5">
            <Card className="h-100">
              <Card.Header>
                <Row className="justify-content-between align-items-center flex-grow-1">
                  <Col md className="mb-3 mb-md-0 mb-lg-3 mb-xl-0">
                    <Card.Title bsPrefix="card-header-title" as="h4">
                      <FormattedMessage id="app.common.searchTerms" />
                      <Tooltip
                        content={
                          <FormattedMessage id="app.helpers.localManager.rankingHeatmap.tooltips.searchTerms" />
                        }
                      >
                        <i className="bi-question-circle text-body ms-1" />
                      </Tooltip>
                    </Card.Title>
                  </Col>
                  <Col xs="auto">
                    <Row className="align-items-sm-center">
                      <Col sm="auto" className="mb-2 mb-md-0">
                        <div className="d-flex align-items-center">
                          <Button
                            variant="outline-secondary"
                            size="sm"
                            className="btn-icon"
                            onClick={() => {
                              let nextIndex = activeDateIndex - 1;
                              if (nextIndex < 0) {
                                nextIndex = Object.keys(apiData).length - 1;
                              }
                              setActiveDateIndex(nextIndex);
                              setActiveDate(Object.keys(apiData)[nextIndex]);
                              setActiveItem(
                                apiData[Object.keys(apiData)[nextIndex]]?.[0]
                              );
                            }}
                          >
                            <i className="bi-chevron-left" />
                          </Button>
                          <ReactSelect
                            options={Object.keys(apiData).map((key) => ({
                              label: moment(key).format(
                                Constants.DateFormats.APP.Moment.Common
                              ),
                              value: key,
                            }))}
                            value={{
                              label: moment(activeDate).format(
                                Constants.DateFormats.APP.Moment.Common
                              ),
                              value: activeDate,
                            }}
                            onChange={(opt) => {
                              const nextIndex = Object.keys(apiData).indexOf(
                                opt.value
                              );
                              setActiveDateIndex(nextIndex);
                              setActiveDate(opt.value);
                              setActiveItem(apiData[opt.value]?.[0]);
                            }}
                            className="react-select-custom-container mx-1"
                            classNamePrefix="react-select-custom"
                            isClearable={false}
                            isSearchable={false}
                          />
                          <Button
                            variant="outline-secondary"
                            size="sm"
                            className="btn-icon"
                            onClick={() => {
                              let nextIndex = activeDateIndex + 1;
                              if (nextIndex > Object.keys(apiData).length - 1) {
                                nextIndex = 0;
                              }
                              setActiveDateIndex(nextIndex);
                              setActiveDate(Object.keys(apiData)[nextIndex]);
                              setActiveItem(
                                apiData[Object.keys(apiData)[nextIndex]]?.[0]
                              );
                            }}
                          >
                            <i className="bi-chevron-right" />
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                {apiData[activeDate].map((item, index) => {
                  const nullValue = 99999;
                  const rate = item.avg_rate || nullValue;

                  const palette = Constants.Colors.RatePalette;
                  const colorIndex = Math.floor(rate) - 1;
                  const colorItem =
                    colorIndex > palette.length - 1
                      ? palette[palette.length - 1]
                      : palette[colorIndex];

                  const { color, textColor } = colorItem;

                  return (
                    <Button
                      key={`keyword_${index.toString()}`}
                      variant=""
                      size="sm"
                      className="me-2 mb-2 rounded-pill py-1 position-relative"
                      style={{
                        backgroundColor: color,
                        borderColor: color,
                        color: textColor,
                      }}
                      onClick={() => {
                        setActiveItem(item);
                      }}
                    >
                      {item.keyword}
                      <Badge bg="dark" className="fw-normal ms-2">
                        {rate === nullValue ? (
                          <FormattedMessage id="app.common.n/a" />
                        ) : (
                          rate.toFixed(2)
                        )}
                      </Badge>
                      {activeItem?.id === item.id && (
                        <div className="position-absolute top-0 start-100 translate-middle">
                          <Badge bg="dark" pill>
                            <i className="bi-check-lg" />
                          </Badge>
                        </div>
                      )}
                    </Button>
                  );
                })}
              </Card.Body>
            </Card>
          </Col>
          {activeItem && (
            <Col xs="12">
              <Heatmap searchTerm={activeItem.keyword} id={activeItem.id} />
            </Col>
          )}
        </Row>
      )}
    </div>
  );
}

export default RankingHeatmap;
