import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Badge, Card } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { capitalize, lowerCase } from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useAxiosQuery } from '../../../../hooks';
import { ThemeContext } from '../../../../context/ThemeContext';
import Utils from '../../../../utils';
import Constants from '../../../../constants';
import { RequestLoading, RequestResult, Tooltip } from '../../../../components';
import { LineChart } from '../../../../components/Charts';
import { Routes as AppRoutes } from '../../../../router/routeMapping';

function Chart({ loading, word, propertyId, date }) {
  const { theme } = useContext(ThemeContext);
  const { formatMessage } = useIntl();

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/reputation_manager/avg_word_usage',
    preventFetch: !propertyId || !date || !word,
    params: {
      word,
      id: propertyId,
      date,
    },
  });

  const getOptions = () => {
    const options = {
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
          min: 0,
          max: 5,
          ticks: {
            beginAtZero: true,
            stepSize: 1,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          displayColors: false,
          callbacks: {
            label: (context) => {
              let label = context.dataset.label || '';

              if (context.parsed.y === 0) {
                label += `: ${formatMessage({ id: 'app.common.n/a' })}`;
                return label;
              }

              if (label) {
                label += ': ';
              }

              if (context.parsed.y !== null) {
                const prefix =
                  context.chart.config?.options?.plugins?.tooltip?.prefix || '';
                const postfix =
                  context.chart.config?.options?.plugins?.tooltip?.postfix ||
                  '';

                label += `${prefix}${context.parsed.y}${postfix}`;
              }
              return label;
            },
          },
        },
      },
    };

    return options;
  };

  const getDataset = (data) => {
    const dates = Utils.Date.getDaysBetweenDates(date[0], date[1]).map((item) =>
      moment(item).format(Constants.DateFormats.API)
    );

    const skipped = (ctx, value) =>
      ctx.p0.skip || ctx.p1.skip ? value : undefined;
    const zero = (ctx, value) =>
      ctx.p0.parsed.y === ctx.p1.parsed.y ? value : undefined;

    return {
      labels: dates,
      datasets: [
        {
          data: data.list.map((x) => x.toFixed(2)),
          label: word,
          ...Utils.Chart.getLineChartOptions(0, theme, {
            backgroundColor: 'transparent',
            usePointStyle: false,
            borderColor: (context) => {
              const { ctx } = context.chart;
              const gradient = ctx.createLinearGradient(0, 0, 0, 200);
              gradient.addColorStop(0, Utils.Chart.successColor);
              gradient.addColorStop(0.4, Utils.Chart.successColor);
              gradient.addColorStop(0.5, Utils.Chart.primaryColor);
              gradient.addColorStop(0.6, Utils.Chart.dangerColor);
              gradient.addColorStop(1, Utils.Chart.dangerColor);
              return gradient;
            },
            borderWidth: 2,
            pointRadius: 2,
            pointHoverRadius: 2,
            pointBackgroundColor: (ctx) => {
              let color = '#91989e';
              if (ctx.parsed.y > 0) {
                color = Utils.Chart.dangerColor;
              }
              if (ctx.parsed.y > 2) {
                color = Utils.Chart.primaryColor;
              }
              if (ctx.parsed.y > 3) {
                color = Utils.Chart.successColor;
              }

              return color;
            },
            segment: {
              borderColor: (ctx) =>
                skipped(ctx, '#91989e') || zero(ctx, '#91989e'),
              borderDash: (ctx) => skipped(ctx, [4, 6]),
            },
            spanGaps: true,
          }),
        },
      ],
    };
  };

  if (loading) {
    return (
      <Card.Body>
        <RequestLoading loading={loading} margin="5" />
      </Card.Body>
    );
  }

  return (
    <>
      <hr className="my-3" />
      <Card.Body>
        <h6 className="card-subtitle mb-3">
          <FormattedMessage
            id="app.common.sentimentOverTimeForWord"
            values={{
              word,
            }}
          />
        </h6>
        <RequestLoading loading={apiLoading} margin="5" />
        <RequestResult type="error" message={apiError} />
        {!apiLoading && !apiError && apiData?.list.length === 0 && (
          <RequestResult type="secondary" message="app.common.noData" />
        )}
        {!apiLoading && !apiError && apiData?.list.length > 0 && (
          <div className="d-flex flex-column">
            <div className="text-success small">
              <FormattedMessage id="app.common.positive" />
            </div>
            <hr className="my-3" />
            <div className="h-100 d-flex flex-column justify-content-center align-items-center">
              <LineChart
                data={getDataset(apiData)}
                options={getOptions()}
                height="15rem"
              />
            </div>
            <hr className="my-3" />
            <div className="text-danger small">
              <FormattedMessage id="app.common.negative" />
            </div>
          </div>
        )}
      </Card.Body>
    </>
  );
}

Chart.propTypes = {
  loading: PropTypes.bool,
  word: PropTypes.string,
  propertyId: PropTypes.number,
  date: PropTypes.arrayOf(PropTypes.string),
};

Chart.defaultProps = {
  loading: false,
  word: '',
  propertyId: null,
  date: null,
};

function Sentiment({ loading, data, word, isOverview }) {
  if (loading) {
    return (
      <Card.Body>
        <RequestLoading loading={loading} margin="5" />
      </Card.Body>
    );
  }

  const mentionDiff =
    data && Object.keys(data).length > 0
      ? data[1].nums.mention - data[0].nums.mention
      : 0;
  let mentionDir = null;
  let mentionColor = null;
  if (mentionDiff > 0) {
    mentionDir = 'up';
    mentionColor = 'success';
  } else if (mentionDiff < 0) {
    mentionDir = 'down';
    mentionColor = 'danger';
  }

  return (
    <Card.Body>
      {data && Object.keys(data).length > 0 && (
        <div>
          <div
            className={`row ${
              !isOverview ? 'col-lg-divider gx-lg-6' : 'col-xl-divider gx-xl-6'
            }`}
          >
            <div className={!isOverview ? 'col-lg-6' : 'col-xl-6'}>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h6 className="card-subtitle mb-3">
                    <FormattedMessage id="app.common.sentiment" />
                    <Tooltip
                      content={
                        <FormattedMessage id="app.helpers.reputationManager.sentimentInsights.tooltips.sentiment" />
                      }
                    >
                      <i className="bi-question-circle text-body ms-1" />
                    </Tooltip>
                  </h6>
                  <h1 className="card-title">{capitalize(word)}</h1>

                  <div className="d-flex align-items-center flex-wrap">
                    {Object.values(Constants.Review.Types).map(
                      (item, index) => (
                        <Badge
                          key={item.id}
                          bg={`soft-${item.color}`}
                          className={`mb-2 text-${item.color} ${
                            index < Object.keys(Constants.Review.Types).length
                              ? 'me-2'
                              : ''
                          }`}
                        >
                          <i className={`${item.icon} me-1`} />
                          <span className="me-1">
                            <FormattedMessage
                              id={`app.common.${item.labelKey}`}
                            />
                          </span>
                          {`${Math.round(
                            (100 * data[1].nums[lowerCase(item.id)]) /
                              data[1].nums.total
                          )}%`}
                        </Badge>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className={!isOverview ? 'col-lg-3' : 'col-xl-3'}>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h6 className="card-subtitle mb-3">
                    <FormattedMessage id="app.common.mentions" />
                  </h6>
                  <div className="d-flex align-items-end">
                    <h1 className="card-title me-1 mb-0">
                      {data[1].nums.mention}
                    </h1>
                    {mentionDiff !== 0 ? (
                      <span
                        className={mentionColor ? `text-${mentionColor}` : ''}
                      >
                        {mentionDir && (
                          <i className={`bi-arrow-${mentionDir}-short`} />
                        )}
                        {Math.abs(mentionDiff)}
                      </span>
                    ) : (
                      <i className="bi-arrow-left-right small" />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className={!isOverview ? 'col-lg-3' : 'col-xl-3'}>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h6 className="card-subtitle mb-3">
                    <FormattedMessage id="app.common.reviews" />
                  </h6>
                  <div className="d-flex align-items-end">
                    <h1 className="card-title me-1 mb-0">
                      {data[1].nums.total}
                    </h1>
                    <span className="mx-2">/</span>
                    <span>{`${data[1].nums.avg.toFixed(2)}%`}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Card.Body>
  );
}

Sentiment.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.objectOf(PropTypes.any),
  word: PropTypes.string,
  isOverview: PropTypes.bool,
};

Sentiment.defaultProps = {
  loading: false,
  data: null,
  word: '',
  isOverview: false,
};

function KeywordsSentiment({
  isOverview,
  loading,
  word,
  date,
  data,
  propertyId,
}) {
  return (
    <Card className="h-100">
      <Sentiment
        loading={loading}
        word={word}
        data={data}
        isOverview={isOverview}
      />

      <Chart
        loading={loading}
        word={word}
        date={date}
        propertyId={propertyId}
      />
      {isOverview && (
        <Card.Footer className="card-footer text-center">
          <Link
            to={
              AppRoutes.protected.REPUTATION_MANAGER.children
                .REPUTATION_MANAGER_SENTIMENT_INSIGHTS.path
            }
          >
            <FormattedMessage id="app.common.viewTopKeywords" />
            <i className="bi-chevron-right ms-1" />
          </Link>
        </Card.Footer>
      )}
    </Card>
  );
}

KeywordsSentiment.propTypes = {
  isOverview: PropTypes.bool,
  loading: PropTypes.bool,
  data: PropTypes.objectOf(PropTypes.any),
  word: PropTypes.string,
  propertyId: PropTypes.number,
  date: PropTypes.arrayOf(PropTypes.string),
};

KeywordsSentiment.defaultProps = {
  isOverview: false,
  loading: false,
  data: null,
  word: null,
  propertyId: null,
  date: null,
};

export default KeywordsSentiment;
