/* eslint-disable react/no-unstable-nested-components */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Form, Table } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import Constants from '../../../../../constants';

function IndeterminateCheckbox({ indeterminate, className, ...rest }) {
  const ref = useRef();

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return (
    <Form.Check.Input
      type="checkbox"
      ref={ref}
      className={`${className} cursor-pointer`}
      {...rest}
    />
  );
}

IndeterminateCheckbox.propTypes = {
  indeterminate: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  className: PropTypes.string,
};
IndeterminateCheckbox.defaultProps = {
  indeterminate: undefined,
  className: '',
};

const KeywordTable = forwardRef(
  (
    {
      isSelectAllowed,
      isSelectAllAllowed,
      data,
      height,
      rowIdKey,
      onChange,
      defaultValue,
      max,
      selectedItemsCount,
    },
    ref
  ) => {
    const { formatMessage } = useIntl();
    const scrollerRef = useRef();

    const [columnVisibility, setColumnVisibility] = useState({});
    const [sorting, setSorting] = useState([{ id: 'volume', desc: true }]);
    const [rowSelection, setRowSelection] = useState({});

    const getColor = (v) => {
      const palette = [...Constants.Colors.RatePalette].reverse();
      const colorIndex = 10 - Math.round(v / 10);
      const colorItem =
        colorIndex > palette.length - 1
          ? palette[palette.length - 1]
          : palette[colorIndex];

      const { color } = colorItem;
      return color;
    };

    const defaultColumns = useMemo(
      () =>
        isSelectAllowed
          ? [
              {
                id: 'select',
                header: isSelectAllAllowed
                  ? ({ table }) => (
                      <IndeterminateCheckbox
                        {...{
                          checked: table.getIsAllRowsSelected(),
                          indeterminate: table.getIsSomeRowsSelected(),
                          onChange: table.getToggleAllRowsSelectedHandler(),
                        }}
                      />
                    )
                  : undefined,
                cell: ({ row }) => (
                  <IndeterminateCheckbox
                    {...{
                      checked: row.getIsSelected(),
                      disabled: !row.getCanSelect(),
                      indeterminate: row.getIsSomeSelected(),
                      onChange: row.getToggleSelectedHandler(),
                    }}
                  />
                ),
              },
            ]
          : [],
      [isSelectAllowed, isSelectAllAllowed]
    );

    const columnsMemo = useMemo(
      () => [
        ...defaultColumns,
        {
          header: <FormattedMessage id="app.common.keyword" />,
          accessorKey: 'long_tail',
        },
        {
          header: <FormattedMessage id="app.common.volume" />,
          accessorKey: 'volume',
          textAlign: 'end',
          cell: (info) => <FormattedNumber value={info.getValue()} />,
        },
        {
          header: formatMessage({
            id: 'app.common.keywordDifficulty',
          })?.replace(/[^A-Z]/g, ''),
          accessorKey: 'difficulty',
          textAlign: 'end',
          cell: (info) => (
            <>
              {info.getValue()}
              <span
                className="legend-indicator ms-1"
                style={{
                  width: '0.75rem',
                  height: '0.75rem',
                  backgroundColor: getColor(info.getValue()),
                }}
              />
            </>
          ),
        },
        {
          header: formatMessage({ id: 'app.common.costPerClick' })?.replace(
            /[^A-Z]/g,
            ''
          ),
          accessorKey: 'CPC',
          textAlign: 'end',
        },
        {
          header: `${formatMessage({
            id: 'app.common.competitiveDensity',
          })?.substring(0, 4)}.`,
          accessorKey: 'competitive_density',
          textAlign: 'end',
        },
        {
          header: <FormattedMessage id="app.common.results" />,
          accessorKey: 'number_of_result',
          textAlign: 'end',
          cell: (info) => (
            <FormattedNumber value={info.getValue()} notation="compact" />
          ),
        },
        {
          header: <FormattedMessage id="app.common.results" />,
          accessorKey: 'id',
          textAlign: 'end',
          cell: (info) => (
            <FormattedNumber value={info.getValue()} notation="compact" />
          ),
        },
      ],
      [formatMessage, defaultColumns]
    );

    const dataMemo = useMemo(() => data, [data]);

    const table = useReactTable({
      data: dataMemo,
      columns: columnsMemo,
      state: {
        columnVisibility,
        sorting,
        rowSelection: defaultValue,
      },
      getRowId: rowIdKey ? (row) => row[rowIdKey] : undefined,
      enableRowSelection: max ? selectedItemsCount < max : true,
      onColumnVisibilityChange: setColumnVisibility,
      onSortingChange: setSorting,
      onRowSelectionChange: setRowSelection,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
    });

    const getSortClass = (header) => {
      if (header.column.getCanSort() && header.column.getIsSorted()) {
        return header.column.getIsSorted() === 'desc' ? '_desc' : '_asc';
      }
      return '';
    };

    useImperativeHandle(ref, () => ({
      resetRowSelection: (v) => {
        table.resetRowSelection(v);
      },
      addRowSelection: (v) => {
        setRowSelection((prev) => {
          const next = { ...prev, [v]: true };
          return next;
        });
      },
      removeRowSelection: (v) => {
        setRowSelection((prev) => {
          const next = { ...prev };
          if (next[v]) {
            delete next[v];
          }
          return next;
        });
      },
    }));

    useEffect(() => {
      if (scrollerRef?.current?.getScrollElement()) {
        scrollerRef.current.getScrollElement().scrollTop = 0;
      }
    }, [data]);

    useEffect(() => {
      onChange(rowSelection);
    }, [rowSelection, max, onChange]);

    return (
      <SimpleBar ref={scrollerRef} style={{ height }}>
        <div className="datatable-custom">
          <Table
            borderless
            hover
            className="table-thead-bordered card-table dataTable no-footer table-align-middle"
          >
            <thead className="thead-light sticky-top">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={`text-nowrap ${
                        header.column.id !== 'select'
                          ? `sorting${getSortClass(header)}`
                          : ''
                      } ${
                        header.column.textAlign
                          ? `text-${header.column.textAlign}`
                          : ''
                      }`}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  onClick={() => {
                    row.toggleSelected();
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className={`${
                        cell.column.columnDef.wrap ? '' : 'text-nowrap'
                      } ${
                        cell.column.columnDef.textAlign
                          ? `text-${cell.column.columnDef.textAlign}`
                          : ''
                      }`}
                      style={{
                        minWidth: cell.column.columnDef.minWidth || undefined,
                      }}
                    >
                      {cell.column.columnDef.disableNullControl ||
                      cell.getValue() ||
                      cell.column.columnDef.id === 'select' ? (
                        flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )
                      ) : (
                        <FormattedMessage id="app.common.n/a" />
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </SimpleBar>
    );
  }
);

KeywordTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  height: PropTypes.number,
  onChange: PropTypes.func,
  isSelectAllowed: PropTypes.bool,
  isSelectAllAllowed: PropTypes.bool,
  rowIdKey: PropTypes.string,
  defaultValue: PropTypes.objectOf(PropTypes.any),
  max: PropTypes.number,
  selectedItemsCount: PropTypes.number,
};

KeywordTable.defaultProps = {
  data: [],
  height: 500,
  onChange: () => {},
  isSelectAllowed: true,
  isSelectAllAllowed: true,
  rowIdKey: undefined,
  defaultValue: {},
  max: undefined,
  selectedItemsCount: 0,
};

export default KeywordTable;
