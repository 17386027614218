export const Status = {
  Available: { id: 'Available', labelKey: 'available', color: 'success' },
  Busy: { id: 'Busy', labelKey: 'busy', color: 'danger' },
  Away: { id: 'Away', labelKey: 'away', color: 'warning' },
};

export const Roles = {
  Guest: { id: 'Guest', labelKey: 'guest' },
  Admin: { id: 'Admin', labelKey: 'admin' },
};
