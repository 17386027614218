import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { LineChart } from '../../../../../components/Charts';
import Constants from '../../../../../constants';
import Utils from '../../../../../utils';
import { ThemeContext } from '../../../../../context/ThemeContext';

const options = {
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'day',
        displayFormats: {
          day: Constants.DateFormats.APP.Moment.Common,
        },
      },
    },
    y: {
      min: 0,
    },
  },
};

function AnalyticsLineChart({ data }) {
  const { theme } = useContext(ThemeContext);

  const getDataset = () => ({
    labels: data.rows.map((item) => item[0]),
    datasets: [
      {
        data: data.rows.map((item) => parseInt(item[1], 10)),
        ...Utils.Chart.getLineChartOptions(0, theme),
      },
    ],
  });

  return (
    <div className="h-100 d-flex flex-column justify-content-center align-items-center">
      <LineChart data={getDataset()} options={options} />
    </div>
  );
}

AnalyticsLineChart.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AnalyticsLineChart;
