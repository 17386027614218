import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { LangContext } from '../../../context/LangContext';
import CountryFlag from '../../CountryFlag';

function LanguageSwitcherPositionEnd({ dropup, variant, ...props }) {
  const { languages, currentLanguage, changeLanguage } =
    useContext(LangContext);

  return (
    <Dropdown
      align="end"
      drop={dropup ? 'up' : 'down'}
      onSelect={changeLanguage}
      {...props}
    >
      <Dropdown.Toggle
        variant={variant}
        className="rounded-circle"
        bsPrefix="btn-icon"
      >
        <CountryFlag countryCode={currentLanguage} svg />
      </Dropdown.Toggle>
      <Dropdown.Menu
        as="div"
        className="navbar-dropdown-menu navbar-dropdown-menu-borderless"
        style={{
          width: '16rem',
        }}
        renderOnMount
      >
        {Object.keys(languages).map((key) => (
          <Dropdown.Item
            key={key}
            eventKey={key}
            active={currentLanguage === key}
          >
            <div className="d-flex align-items-center">
              <CountryFlag countryCode={key} svg className="me-2" />
              <span>{languages[key]}</span>
            </div>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

LanguageSwitcherPositionEnd.propTypes = {
  dropup: PropTypes.bool,
  variant: PropTypes.string,
};

LanguageSwitcherPositionEnd.defaultProps = {
  dropup: true,
  variant: 'ghost-light',
};

export default LanguageSwitcherPositionEnd;
