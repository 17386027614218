import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { camelCase } from 'lodash';
import { DoughnutChart } from '../../../../../components/Charts';
import Constants from '../../../../../constants';

const options = {
  layout: {
    padding: {
      left: 10,
      right: 10,
      top: 10,
      bottom: 10,
    },
  },
  plugins: {
    tooltip: {
      enabled: false,
    },
    legend: {
      labels: {
        generateLabels: (chart) => {
          const { data } = chart;
          if (data.labels.length && data.datasets.length) {
            const {
              labels: { pointStyle },
            } = chart.legend.options;

            const total = chart.data.datasets[0].data.reduce(
              (acc, obj) => acc + obj,
              0
            );

            return data.labels.map((label, i) => {
              const meta = chart.getDatasetMeta(0);
              const style = meta.controller.getStyle(i);

              return {
                text: `${label} - ${
                  chart.data.datasets[0].data[i]
                } (${Math.round(
                  (chart.data.datasets[0].data[i] * 100) / total
                )}%)`,
                fillStyle: style.backgroundColor,
                strokeStyle: style.borderColor,
                lineWidth: style.borderWidth,
                pointStyle,
                hidden: !chart.getDataVisibility(i),

                index: i,
              };
            });
          }
          return [];
        },
      },
    },
    datalabels: {
      padding: {
        left: 8,
        right: 8,
        top: 4,
        bottom: 4,
      },
      display: 'auto',
      anchor: 'center',
      formatter: (value, ctx) => {
        const dataArr = ctx.chart.data.datasets[0].data;
        const total = dataArr.reduce((acc, obj) => acc + obj, 0);
        return `${value} (${Math.round((value * 100) / total)}%)`;
      },
    },
  },
};

function AnalyticsDoughnutChart({ data }) {
  const { formatMessage } = useIntl();

  const getData = () => {
    const datasetObj = {
      backgroundColor: [],
      data: [],
      borderWidth: 0,
      datalabels: { color: [] },
    };
    const labels = [];
    data.rows.forEach((item, i) => {
      datasetObj.data.push(parseInt(item[1], 10));
      datasetObj.datalabels.color.push(Constants.Colors.Palette[i]);
      datasetObj.backgroundColor.push(Constants.Colors.Palette[i]);
      const type = formatMessage({
        id: `app.googleAnalytics.${camelCase(item[0])}`,
      });
      labels.push(`${type}`);
    });
    return { labels, datasets: [datasetObj] };
  };

  return (
    <div className="h-100 d-flex flex-column justify-content-center align-items-center">
      <DoughnutChart
        data={getData()}
        options={options}
        plugins={{ datalabels: true }}
      />
    </div>
  );
}

AnalyticsDoughnutChart.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AnalyticsDoughnutChart;
