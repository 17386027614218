import React, { useRef, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid';
import trLocale from '@fullcalendar/core/locales/tr';
import moment from 'moment';
import { Row, Col, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { LangContext } from '../../../../context/LangContext';
import Utils from '../../../../utils';
import Constants from '../../../../constants';

const locales = {
  tr: trLocale,
};

function CalendarView({ data, date }) {
  const calendarRef = useRef();
  const { currentLanguage } = useContext(LangContext);
  const [currentMonthTitle, setCurrentMonthTitle] = useState('');

  const calendarWrapperRef = useCallback((node) => {
    if (node !== null) {
      const calendarElem = node.querySelector('.fc');
      if (calendarElem) {
        calendarElem.classList?.add('fullcalendar-custom');
      }
    }
  }, []);

  const getEvents = () => {
    const dates = Utils.Date.getDaysBetweenDates(date[0], date[1]).map((item) =>
      moment(item).format(Constants.DateFormats.API)
    );

    const currency =
      data.own.prices[0]?.currency ||
      Object.values(data.compe)[0]?.prices[0]?.currency ||
      '$';

    const getOwnPrice = (nDate) => {
      const val = data.own.prices.find((nItem) => nItem.date === nDate)?.price;

      return val || 0;
    };

    const getMedian = (nDate) => {
      const values = [];
      Object.values(data.compe).forEach((item) => {
        const val = item.prices.find((nItem) => nItem.date === nDate)?.price;
        if (val) {
          values.push(val);
        }
      });

      return Utils.Array.getMedian(values);
    };

    const result = dates.map((item) => {
      let title = 'Sold out';
      let className = 'bg-light';
      let info = '';

      const ownPrice = getOwnPrice(item);

      if (ownPrice > 0) {
        const median = getMedian(item);
        const percentageChange = Math.round((ownPrice / median) * 100 - 100);

        className = 'bg-success';

        if (percentageChange > 10) {
          className = 'bg-danger';
        }

        title = `${ownPrice} ${currency}`;
        info = percentageChange
          ? `${percentageChange}% vs. Comp`
          : 'Comps Sold out';
      }

      return {
        id: item,
        title,
        info,
        start: item,
        end: item,
        className,
        allDay: true,
      };
    });

    return result;

    /* return [
      {
        id: 'item.id',
        title: 'item.title',
        start: date[0],
        end: date[0],
        // className: 'bg-primary',
        backgroundColor: 'red',
        // textColor: Constants.Colors.Palette[0],
        allDay: true,
        // guestsField: ['David Harrison'],
        // image: './assets/svg/brands/pdf-icon.svg',
      },
    ]; */
  };

  const renderEventContent = (eventInfo) => (
    <div className="text-center">
      <div className="h4 mb-0">{eventInfo.event.title}</div>
      <div>{eventInfo.event.extendedProps.info}</div>
    </div>
  );

  return (
    <div>
      <Row className="align-items-sm-center mb-4">
        <Col lg="5" className="mb-2 mb-lg-0">
          <div className="d-flex align-items-center">
            <OverlayTrigger
              overlay={
                <Tooltip>
                  <FormattedMessage id="app.common.previousMonth" />
                </Tooltip>
              }
            >
              <Button
                variant="ghost-secondary"
                size="sm"
                className="btn-icon btn-no-focus rounded-circle me-1"
                onClick={() => {
                  calendarRef.current.getApi().prev();
                }}
              >
                <i className="bi-chevron-left" />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              overlay={
                <Tooltip>
                  <FormattedMessage id="app.common.nextMonth" />
                </Tooltip>
              }
            >
              <Button
                variant="ghost-secondary"
                size="sm"
                className="btn-icon btn-no-focus rounded-circle ms-1"
                onClick={() => {
                  calendarRef.current.getApi().next();
                }}
              >
                <i className="bi-chevron-right" />
              </Button>
            </OverlayTrigger>
            <div className="ms-3">
              <h4 className="h3 mb-0">{currentMonthTitle}</h4>
            </div>
          </div>
        </Col>
        <Col lg="7" />
      </Row>
      <div ref={calendarWrapperRef} id="fc-wrapper">
        <FullCalendar
          locale={currentLanguage !== 'en' && locales[currentLanguage]}
          ref={calendarRef}
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          headerToolbar={false}
          defaultAllDay={false}
          editable
          contentHeight="auto"
          dayMaxEventRows={1}
          displayEventTime
          eventDisplay="block"
          visibleRange={{
            start: date[0],
            end: date[1],
          }}
          validRange={{
            start: date[0],
            end: date[1],
          }}
          initialDate={date[0]}
          eventContent={renderEventContent}
          datesSet={(dateSet) => {
            // setCurrentStartDate(dateSet.start);
            // setCurrentEndDate(dateSet.end);
            setCurrentMonthTitle(dateSet.view.title);
          }}
          /* eventClick={(eventClickInfo) => {
            setShowEventDetail(false);
            setTimeout(() => {
              handleEventDetail(eventClickInfo);
            }, 100);
          }} */
          events={getEvents()}
        />
      </div>
    </div>
  );
}

CalendarView.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  date: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default CalendarView;
