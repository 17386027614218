import React, { useContext, useState } from 'react';
import { Row, Col, Card, Button, Modal, Alert } from 'react-bootstrap';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import moment from 'moment';
import { useAxiosQuery, useConfirmModal, useToast } from '../../../../hooks';
import { RequestLoading, RequestResult } from '../../../../components';
import SubscriptionPlansTable from './SubscriptionPlansTable';
import { AuthContext } from '../../../../context/AuthContext';
import Constants from '../../../../constants';

function BillingOverview() {
  const { pack, user } = useContext(AuthContext);

  const { confirm } = useConfirmModal({ confirmVariant: 'danger' });
  const { showToast } = useToast();

  const [subscriptionModalShow, setSubscriptionModalShow] = useState(false);

  const handleSubscriptionModalClose = () => {
    setSubscriptionModalShow(false);
  };
  const handleSubscriptionModalShow = () => setSubscriptionModalShow(true);

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/xtransactions/list',
  });

  const currentPlan =
    Constants.Billing.SubscriptionPlans.find(
      (item) => item.apiKey === pack.name
    ) || Constants.Billing.SubscriptionPlans[0];

  const cancelSubscription = async () => {
    const isSuccess = await confirm({
      title: 'cancelSubscription',
      message: <FormattedMessage id="app.common.cancelSubscription" />,
      requestUrl: '/user/cancel_subscription',
    });

    if (isSuccess) {
      showToast({
        type: 'success',
        autohide: true,
        title: <FormattedMessage id="app.common.success" />,
        message: <FormattedMessage id="app.common.cancelSubscription" />,
      });
    }
  };

  return (
    <>
      {(apiLoading || apiError || (!apiLoading && !apiError && !apiData)) && (
        <Card.Body>
          <RequestLoading loading={apiLoading} size="lg" margin="5" />
          <RequestResult type="error" message={apiError} />

          {!apiLoading && !apiError && !apiData && (
            <RequestResult type="secondary" message="app.common.noData" />
          )}
        </Card.Body>
      )}

      {!apiLoading && !apiError && apiData && (
        <>
          <Card.Body>
            <Row>
              {user.isCancelled && (
                <Col xs="12" className="mb-4">
                  <Alert variant="danger">
                    <Alert.Heading as="h5">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <i className="bi-info-circle-fill" />
                        </div>
                        <div className="ms-2">
                          <FormattedMessage id="warnings.packageCancelledInfo.title" />
                        </div>
                      </div>
                    </Alert.Heading>
                    <FormattedMessage
                      id="warnings.packageCancelledInfo.message"
                      values={{
                        date: moment(apiData[0].end_date).format(
                          Constants.DateFormats.APP.Moment.Common
                        ),
                      }}
                    />
                  </Alert>
                </Col>
              )}
              <Col md className="mb-4 mb-md-0">
                <div className="mb-4">
                  <Card.Subtitle>
                    <FormattedMessage id="app.common.currentPlan" />
                    {currentPlan.isBilledYearly && (
                      <span className="ms-1">
                        (<FormattedMessage id="app.common.billedYearly" />)
                      </span>
                    )}
                  </Card.Subtitle>
                  <h3>
                    <FormattedMessage
                      id={`app.subscriptionPlans.${currentPlan.id}.title`}
                    />
                    {` - ${moment(apiData[0].end_date).format(
                      Constants.DateFormats.APP.Moment.Common
                    )}`}
                  </h3>
                </div>
                <div>
                  <Card.Subtitle>
                    <FormattedMessage id="app.common.annualTotalCost" />
                  </Card.Subtitle>
                  <h1 className="text-primary">
                    <span className="me-1">
                      <FormattedNumber
                        value={currentPlan.prices.yearly}
                        // eslint-disable-next-line react/style-prop-object
                        style="currency"
                        currency="USD"
                        currencyDisplay="code"
                      />
                    </span>
                  </h1>
                </div>
              </Col>
              <Col md="auto">
                <div className="d-grid d-sm-flex gap-3">
                  {!user.isCancelled && (
                    <Button variant="white" onClick={cancelSubscription}>
                      <FormattedMessage id="app.common.cancelSubscription" />
                    </Button>
                  )}

                  {pack.name !== 'Business' && (
                    <Button
                      className="w-100 w-sm-auto"
                      onClick={handleSubscriptionModalShow}
                    >
                      <FormattedMessage id="app.common.updatePlan" />
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          </Card.Body>
          {/* <hr className="my-3" />
          <Card.Body>
            <Row className="align-items-center flex-grow-1 mb-2">
              <Col>
                <Card.Title bsPrefix="card-header-title" as="h4">
                  Storage usage
                </Card.Title>
              </Col>
              <Col xs="auto">
                <span className="text-dark fw-semi-bold me-1">
                  {Utils.String.humanFileSize(4270000000, true, 2)}
                </span>
                used of {Utils.String.humanFileSize(6000000000, true, 0)}
              </Col>
            </Row>
            <ProgressBar className="rounded-pill mb-3">
              <ProgressBar now={33} />
              <ProgressBar now={25} className="opacity-50" />
            </ProgressBar>
            <ul className="list-inline list-px-2">
              <li className="list-inline-item">
                <span className="legend-indicator bg-primary" />
                Personal usage space
              </li>
              <li className="list-inline-item">
                <span className="legend-indicator bg-primary opacity-50" />
                Shared space
              </li>
              <li className="list-inline-item">
                <span className="legend-indicator" />
                Unused space
              </li>
            </ul>
                  </Card.Body> */}
          <Modal
            show={subscriptionModalShow}
            onHide={handleSubscriptionModalClose}
            size="xl"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <FormattedMessage id="app.common.subscriptionPlans" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <SubscriptionPlansTable />
              <div className="d-flex justify-content-center justify-content-sm-end gap-3 mt-4">
                <Button onClick={handleSubscriptionModalClose} variant="white">
                  <FormattedMessage id="app.common.cancel" />
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
}

export default BillingOverview;
