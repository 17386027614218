import React, { useContext } from 'react';
import { Col } from 'react-bootstrap';
import BrandRatingCard from './BrandRatingCard';
import { PropertyContext } from '../../../../context/PropertyContext';
import { useAxiosQuery } from '../../../../hooks';
import { RequestLoading, RequestResult } from '../../../../components';

function BrandRatingList() {
  const { activeProperty } = useContext(PropertyContext);

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/reputation_manager/get_platforms',
    preventFetch: !activeProperty?.id,
    params: {
      id: activeProperty?.id,
    },
    select: (data) => data.sort((a, b) => a.site.localeCompare(b.site)),
  });

  return (
    <>
      <RequestLoading loading={apiLoading} size="lg" margin="5" />
      <RequestResult type="error" message={apiError} />
      {!apiLoading && !apiError && apiData && (
        <>
          {apiData.map((item, index) => (
            <Col
              key={`${index.toString()}`}
              sm="6"
              lg="3"
              className="mb-3 mb-lg-5"
            >
              <BrandRatingCard
                brand={item.site}
                totalReviews={item.total_reviews}
                ratings={item.ratings}
              />
            </Col>
          ))}
        </>
      )}
    </>
  );
}

export default BrandRatingList;
