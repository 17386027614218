export const Theme = {
  Primary: '#377dff',
  Success: '#00c9a7',
  Danger: '#ed4c78',
  Warning: '#f5ca99',
};

export const Palette = [
  '#377dff',
  '#00c9db',
  '#fd7f6f',
  '#b2e061',
  '#bd7ebe',
  '#ffb55a',
  '#ffee65',
  '#beb9db',
  '#fdcce5',
  '#8bd3c7',
];

export const RatePalette = [
  { color: '#479131', textColor: '#FFFFFF' },
  { color: '#5baf44', textColor: '#FFFFFF' },
  { color: '#88cd52', textColor: '#FFFFFF' },
  { color: '#b0ce54', textColor: '#FFFFFF' },

  { color: '#e6d05c', textColor: '#FFFFFF' },
  { color: '#f0c85e', textColor: '#FFFFFF' },
  { color: '#ecbf4d', textColor: '#FFFFFF' },
  { color: '#dfb042', textColor: '#FFFFFF' },
  { color: '#eaa93c', textColor: '#FFFFFF' },
  { color: '#e0a039', textColor: '#FFFFFF' },
  { color: '#d1702f', textColor: '#FFFFFF' },

  { color: '#df5d3c', textColor: '#FFFFFF' },
  { color: '#d85232', textColor: '#FFFFFF' },
  { color: '#da4324', textColor: '#FFFFFF' },
  { color: '#d03024', textColor: '#FFFFFF' },
  { color: '#a92318', textColor: '#FFFFFF' },
];
