import React, { useContext, useCallback } from 'react';
import { Row, Col, Card, Badge } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { SmartTable } from '../../../components';
import Constants from '../../../constants';
import { PropertyContext } from '../../../context/PropertyContext';

/*
{
    "BackLinkID": 2,
    "PropertyID": 1025,
    "ReferringPageTitle": "15-Year-Old Is Arrested for the Murder of a Tennessee UMC Pastor",
    "ReferringPageURL": "https://vitae20.com/15-year-old-is-arrested-for-the-murder-of-a-tennessee-umc-pastor/",
    "Language": "en",
    "Platform": "wordpress",
    "ReferringPageHTTPCode": "200",
    "DomainRating": "14",
    "DomainTraffic": "1",
    "ReferringDomains": "0",
    "LinkedDomains": "6",
    "ExternalLinks": "10",
    "PageTraffic": "0",
    "Keywords": "0",
    "TargetURL": "https://calirank.com/",
    "LeftContext": "“Autura’s presence and",
    "Anchor": "leadership",
    "RightContext": "in Methodist circles have been exemplary. … Her leadership was",
    "Type": "text",
    "Content": "TRUE",
    "Nofollow": "FALSE",
    "UGC": "FALSE",
    "Sponsored": "FALSE",
    "Rendered": "FALSE",
    "Raw": "TRUE",
    "LostStatus": "",
    "DropReason": "",
    "DiscoveredStatus": "pagefound",
    "FirstSeen": "8/4/22 0:07",
    "LastSeen": "9/14/22 21:07",
    "Lost": "",
    "LinksInGroup": "1"
}
*/

function Backlinks() {
  const { activeProperty } = useContext(PropertyContext);

  // moment not like this format, so parsing date to javascript Date object first /or manually parse
  // manual parse if needed
  // const partials = date.split(' ')[0].split('/');
  // return new Date(`20${partials[2]}`, Number(partials[0]) - 1, partials[1]);
  // moment: Deprecation warning: value provided is not in a recognized RFC2822 or ISO format.
  const parseDate = (date) => new Date(date);

  const columns = [
    {
      Header: <FormattedMessage id="app.common.referringPage" />,
      accessor: 'ReferringPageTitle',
      wrap: true,
      minWidth: 300,
      Cell: useCallback(
        ({
          cell: {
            value,
            row: { original },
          },
        }) => (
          <>
            {value && <div className="h5 mb-1">{value}</div>}
            {original.ReferringPageURL && (
              <a
                href={original.ReferringPageURL}
                target="_blank"
                rel="nofollow noreferrer"
                className="link-success small mb-1 mt-1"
              >
                {original.ReferringPageURL}
              </a>
            )}
            <div>
              {original.Language && (
                <Badge bg="secondary" className="text-uppercase mt-1 me-1">
                  {original.Language}
                </Badge>
              )}
              {original.Platform && (
                <Badge bg="secondary" className="mt-1 text-uppercase">
                  {original.Platform}
                </Badge>
              )}
            </div>
          </>
        ),
        []
      ),
    },
    {
      Header: <FormattedMessage id="app.common.domainRating" />,
      accessor: 'DomainRating',
    },
    {
      Header: <FormattedMessage id="app.common.domainTraffic" />,
      accessor: 'DomainTraffic',
    },
    {
      Header: <FormattedMessage id="app.common.referringDomains" />,
      accessor: 'ReferringDomains',
    },
    {
      Header: <FormattedMessage id="app.common.linkedDomains" />,
      accessor: 'LinkedDomains',
    },
    {
      Header: <FormattedMessage id="app.common.externalLinks" />,
      accessor: 'ExternalLinks',
    },
    {
      Header: <FormattedMessage id="app.common.pageTraffic" />,
      accessor: 'PageTraffic',
    },
    {
      Header: <FormattedMessage id="app.common.keywords" />,
      accessor: 'Keywords',
    },
    {
      Header: <FormattedMessage id="app.common.anchorAndTargetUrl" />,
      accessor: 'Anchor',
      wrap: true,
      minWidth: 200,
      Cell: useCallback(
        ({
          cell: {
            value,
            row: { original },
          },
        }) => (
          <>
            <p className="mb-1">
              {original.LeftContext && <span>{original.LeftContext}</span>}
              {value && <span className="text-primary mx-1">{value}</span>}
              {original.RightContext && <span>{original.RightContext}</span>}
            </p>
            {original.TargetURL && (
              <a
                href={original.TargetURL}
                target="_blank"
                rel="nofollow noreferrer"
                className="link-success small mb-1 mt-1"
              >
                {original.TargetURL}
              </a>
            )}
          </>
        ),
        []
      ),
    },
    {
      Header: (
        <>
          <FormattedMessage id="app.common.firstSeen" />
          <br />
          <FormattedMessage id="app.common.lastSeen" />
        </>
      ),
      accessor: 'FirstSeen',
      wrap: true,
      Cell: useCallback(
        ({
          cell: {
            value,
            row: { original },
          },
        }) => (
          <div className="text-start">
            {value && (
              <div className="text-nowrap">
                {moment(parseDate(value)).format(
                  Constants.DateFormats.APP.Moment.Common
                )}
              </div>
            )}
            {original.LastSeen && (
              <div className="text-nowrap">
                {moment(parseDate(original.LastSeen)).format(
                  Constants.DateFormats.APP.Moment.Common
                )}
              </div>
            )}
          </div>
        ),
        []
      ),
    },
  ];

  return (
    <Row>
      <Col>
        <Card>
          <Card.Header>
            <Card.Title bsPrefix="card-header-title" as="h4">
              <FormattedMessage id="app.common.backlinks" />
            </Card.Title>
          </Card.Header>
          {activeProperty && (
            <SmartTable
              columns={columns}
              requestUrl="/backlinks/list"
              requestParams={{ PropertyID: activeProperty?.id }}
            />
          )}
        </Card>
      </Col>
    </Row>
  );
}

export default Backlinks;
