import React, { useContext } from 'react';
import PropertyWizard from './PropertyWizard/PropertyWizard';
import { AuthContext } from '../../../context/AuthContext';
import { PropertyContext } from '../../../context/PropertyContext';
import { NoAccessInfo } from '../../../components';

function CreateProperty() {
  const { pack } = useContext(AuthContext);
  const { properties } = useContext(PropertyContext);
  if (properties.length >= pack.usage.max_property) {
    return <NoAccessInfo />;
  }
  return <PropertyWizard />;
}

export default CreateProperty;
