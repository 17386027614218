import React, { useContext } from 'react';
import { Card } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  Rating,
  BrandIcon,
  RequestLoading,
  RequestResult,
  ShowMoreText,
  CountryFlag,
  Tooltip,
} from '../../../../components';
import { PropertyContext } from '../../../../context/PropertyContext';
import { useAxiosQuery } from '../../../../hooks';
import { Routes as AppRoutes } from '../../../../router/routeMapping';
import Constants from '../../../../constants';
import { languages } from '../../../../context/LangContext';

function RecentReviews() {
  const { activeProperty } = useContext(PropertyContext);

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/reviews/get_by_title',
    preventFetch: !activeProperty?.id,
    params: {
      property_id: activeProperty?.id,
      quantity: 4,
      justme: 1,
    },
  });

  return (
    <Card className="h-100">
      <Card.Header>
        <Card.Title bsPrefix="card-header-title" as="h4">
          <FormattedMessage id="app.common.recentReviews" />
          <Tooltip
            content={
              <FormattedMessage id="app.helpers.reputationManager.overview.tooltips.recentReviews" />
            }
          >
            <i className="bi-question-circle text-body ms-1" />
          </Tooltip>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <RequestLoading loading={apiLoading} margin="5" />
        <RequestResult type="error" message={apiError} />
        {!apiLoading && !apiError && apiData?.own?.reviews.length === 0 && (
          <RequestResult
            type="secondary"
            title="emptyState.reviews.title"
            message="emptyState.reviews.message"
          />
        )}
        {!apiLoading && !apiError && apiData?.own?.reviews.length > 0 && (
          <ul className="list-unstyled list-py-3 mb-0">
            {apiData.own.reviews.map((item, index) => (
              <li key={`${index.toString()}`}>
                <div className="d-flex gap-3 align-items-center">
                  <BrandIcon brand={item.site} />
                  <Rating initVal={item.rating} />
                  <div>
                    <div className="fs-6 text-body mb-0">
                      <ShowMoreText
                        text={item.comment}
                        maxLine={2}
                        basedOn="words"
                        hideButton
                      />
                    </div>
                    <div>
                      <span className="h6">
                        {item.lang &&
                          Object.keys(languages)[0] !== item.lang && (
                            <CountryFlag
                              countryCode={item.lang}
                              svg
                              className="me-2"
                            />
                          )}
                        {item.review_title}
                      </span>
                      <span className="text-muted small">
                        ,{' '}
                        {moment(item.date).format(
                          Constants.DateFormats.APP.Moment.Common
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </Card.Body>
      {!apiLoading && !apiError && apiData?.own?.reviews.length > 0 && (
        <Card.Footer className="card-footer text-center">
          <Link
            to={
              AppRoutes.protected.REPUTATION_MANAGER.children
                .REPUTATION_MANAGER_REVIEWS.path
            }
          >
            <FormattedMessage id="app.common.viewAllReviews" />
            <i className="bi-chevron-right ms-1" />
          </Link>
        </Card.Footer>
      )}
    </Card>
  );
}

export default RecentReviews;
