import React from 'react';
import PropTypes from 'prop-types';
// import { ThemeContext } from '../../context/ThemeContext';

const radius = 175;
const diameter = Math.round(Math.PI * radius * 2);
const getOffset = (val = 0) =>
  Math.round(((100 - Math.min(val, 100)) / 100) * diameter);

function CircleChart({
  showPercentage,
  percentSpacing,
  textColor,
  progress,
  size,
  bgColor,
  progressColor,
  lineWidth,
  animate,
  animationDuration,
  roundedStroke,
  responsive,
  onAnimationEnd,
  showPercentageSymbol,
  textStyle,
}) {
  // const { theme } = useContext(ThemeContext);

  const strokeDashoffset = getOffset(progress);
  const transition = animate
    ? `stroke-dashoffset ${animationDuration} ease-out`
    : undefined;
  const strokeLinecap = roundedStroke ? 'round' : 'butt';
  const svgSize = responsive ? '100%' : size;

  const getText = () => {
    if (!showPercentage) return null;

    return (
      <text
        style={textStyle}
        fill={textColor}
        x={radius}
        y={radius}
        textAnchor="middle"
        dominantBaseline="central"
      >
        {progress}
        {showPercentageSymbol && <tspan dx={percentSpacing}>%</tspan>}
      </text>
    );
  };

  return (
    <svg width={svgSize} height={svgSize} viewBox="-25 -25 400 400">
      <circle
        stroke={bgColor}
        cx="175"
        cy="175"
        r="175"
        strokeWidth={lineWidth}
        fill="none"
      />
      <circle
        stroke={progressColor}
        transform="rotate(-90 175 175)"
        cx="175"
        cy="175"
        r="175"
        strokeDasharray="1100"
        strokeWidth={lineWidth}
        strokeDashoffset="1100"
        strokeLinecap={strokeLinecap}
        fill="none"
        style={{ strokeDashoffset, transition }}
        onTransitionEnd={onAnimationEnd}
      />
      {getText()}
    </svg>
  );
}

const ColorPropType = PropTypes.oneOf([
  'primary',
  'secondary',
  'error',
  'danger',
  'warning',
  'info',
  'success',
  'light',
  'dark',
]);

CircleChart.propTypes = {
  progress: PropTypes.number,
  animate: PropTypes.bool,
  animationDuration: PropTypes.string,
  showPercentage: PropTypes.bool,
  showPercentageSymbol: PropTypes.bool,
  progressColor: PropTypes.string,
  bgColor: ColorPropType,
  textColor: ColorPropType,
  size: PropTypes.string,
  lineWidth: PropTypes.string,
  percentSpacing: PropTypes.number,
  textStyle: PropTypes.object,
  roundedStroke: PropTypes.bool,
  responsive: PropTypes.bool,
  onAnimationEnd: PropTypes.func,
};

CircleChart.defaultProps = {
  progress: 0,
  animate: true,
  animationDuration: '1s',
  showPercentage: true,
  showPercentageSymbol: true,
  progressColor: 'rgb(76, 154, 255)',
  bgColor: 'primary',
  textColor: 'primary',
  size: '100',
  lineWidth: '25',
  percentSpacing: 10,
  textStyle: { fontFamily: 'Arial', fontWeight: 'bold' },
  roundedStroke: false,
  responsive: true,
  onAnimationEnd: () => {},
};

export default CircleChart;
