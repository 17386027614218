import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Card,
  Dropdown,
  ListGroup,
  Nav,
  Tab,
  Form,
  Spinner,
  Button,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useClickAway } from 'react-use';
import { useAxiosQuery, useAxiosMutation } from '../../../hooks';
import RequestResult from '../../RequestResult';
import RequestLoading from '../../RequestLoading';
import SmartImage from '../../SmartImage';
import Illustration from '../../../assets/images/svg/illustrations/oc-megaphone.svg';
import IllustrationLight from '../../../assets/images/svg/illustrations-light/oc-megaphone.svg';
import Utils from '../../../utils';
import {
  protectedAreaBasePath,
  Routes as AppRoutes,
} from '../../../router/routeMapping';

const viewAllNotifications = true;
const QUANTITY = 99999999;

function MessageListItem({ item, onChange, onMouseDown }) {
  const [checked, setChecked] = useState(!item.is_Read);

  const { isLoading: apiLoading, mutate: apiFetch } = useAxiosMutation({
    url: '/notifications/update',
  });

  const markAs = (isRead) => {
    apiFetch(
      { NotificationID: item.NotificationID, is_Read: isRead ? 0 : 1 },
      {
        onSuccess: (data) => {
          if (data) {
            setChecked(!checked);
            onChange(!checked ? 0 : 1);
          }
        },
      }
    );
  };

  return (
    <ListGroup.Item className="form-check-select">
      <Row>
        <Col xs="auto">
          <div className="d-flex align-items-center">
            {apiLoading ? (
              <div style={{ minWidth: 24 }}>
                <Spinner animation="border" size="xs" />
              </div>
            ) : (
              <Form.Check>
                <Form.Check.Input
                  checked={checked}
                  onChange={() => {
                    markAs(!checked);
                  }}
                />
                <Form.Check.Label />
                <span className="form-check-stretched-bg" />
              </Form.Check>
            )}
          </div>
        </Col>
        <Col className="ms-n2">
          <h5 className="mb-1">
            {item.Title ? (
              <FormattedMessage
                id={`app.notifications.${item.Title}`}
                values={{ n: item.ValueNew }}
                defaultMessage={item.Title}
              />
            ) : (
              <FormattedMessage id="app.common.noTitle" />
            )}
          </h5>
          <p className="text-body small">
            {item.Description ? (
              <FormattedMessage
                id={`app.notifications.${item.Description}`}
                defaultMessage={item.Description}
              />
            ) : (
              <FormattedMessage id="app.common.noDescription" />
            )}
          </p>
        </Col>
        <Col xs="auto" as="span" className="text-muted text-cap">
          {moment(item.Date).fromNow(true)}
        </Col>
      </Row>
      {item.URL && (
        <Link
          className="stretched-link"
          to={
            Utils.String.checkUrlProtocol(item.URL)
              ? item.URL
              : `${protectedAreaBasePath}${item.URL}`
          }
          target={
            Utils.String.checkUrlProtocol(item.URL) ? '_blank' : undefined
          }
          rel={
            Utils.String.checkUrlProtocol(item.URL)
              ? 'nofollow noreferrer'
              : undefined
          }
          onMouseDown={() => {
            markAs(false);
            onMouseDown();
          }}
        />
      )}
    </ListGroup.Item>
  );
}

MessageListItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func,
  onMouseDown: PropTypes.func,
};

MessageListItem.defaultProps = {
  onChange: () => {},
  onMouseDown: () => {},
};

function DropdownNotificationsGhostLight({ isMobile }) {
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [listData, setListData] = useState([]);
  const [isDropdownVisible, setDropdownIsVisible] = useState(false);

  const ref = useRef();
  useClickAway(ref, () => {
    setDropdownIsVisible(false);
  });

  const {
    isFetching: listLoading,
    error: listError,
    refetch: listFetch,
  } = useAxiosQuery({
    url: '/notifications/List',
    dataWithRoot: true,
    params: { quantity: QUANTITY },
    keepPreviousData: true,
    onSuccess: (data) => {
      if (data) {
        setListData(data.rows);
        setUnreadMessagesCount(data.not_read);
      }
    },
  });

  const {
    isLoading: updateLoading,
    error: updateError,
    mutate: updateFetch,
  } = useAxiosMutation({
    url: '/notifications/update_status',
  });

  const markAsAllReadOrUnread = (status) => {
    const NotificationID = listData.map((item) => item.NotificationID);
    updateFetch(
      { NotificationID, is_Read: status },
      {
        onSuccess: (data) => {
          if (data) {
            listFetch();
          }
        },
      }
    );
  };

  const readStatusChanged = (id, status) => {
    const nData = [...listData].map((item) => {
      const nItem = item;
      if (nItem.NotificationID === id) {
        nItem.is_Read = status;
      }
      return nItem;
    });
    setListData(nData);
    setUnreadMessagesCount(nData.filter((item) => item.is_Read === 0).length);
  };

  useEffect(() => {
    setLoading(listLoading || updateLoading);
  }, [listLoading, updateLoading]);

  useEffect(() => {
    setError(listError || updateError);
  }, [listError, updateError]);

  if (isMobile) {
    return (
      <Button
        as={Link}
        variant="ghost-light"
        className="btn-icon rounded-circle"
        to={AppRoutes.protected.NOTIFICATIONS.path}
      >
        <i className="bi-bell" />
        {unreadMessagesCount > 0 && (
          <span className="btn-status btn-sm-status btn-status-danger" />
        )}
      </Button>
    );
  }

  return (
    <div ref={ref}>
      <Dropdown show={isDropdownVisible} align="end">
        <Dropdown.Toggle
          bsPrefix="btn-icon"
          variant="ghost-light"
          className="rounded-circle"
          disabled={loading}
          onClick={() => {
            if (!listLoading) {
              listFetch();
            }
            setDropdownIsVisible(!isDropdownVisible);
          }}
        >
          <i className="bi-bell" />

          {loading ? (
            <Spinner
              animation="border"
              variant="danger"
              size="xs"
              as="span"
              className="position-absolute btn-sm-status"
            />
          ) : (
            unreadMessagesCount > 0 && (
              <span className="btn-status btn-sm-status btn-status-danger" />
            )
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu
          rootCloseEvent="mousedown"
          className="dropdown-card navbar-dropdown-menu navbar-dropdown-menu-borderless"
          style={{ width: '25rem' }}
        >
          <Card.Header className="card-header-content-between">
            <Card.Title as="h4" className="mb-0">
              <FormattedMessage id="app.common.notifications" />
            </Card.Title>
            <Dropdown align="end" navbar={false}>
              <Dropdown.Toggle
                bsPrefix="btn"
                variant="ghost-secondary"
                size="sm"
                className="btn-icon rounded-circle"
              >
                <i className="bi-three-dots-vertical" />
              </Dropdown.Toggle>
              <Dropdown.Menu
                className="navbar-dropdown-menu-borderless"
                renderOnMount
              >
                <Dropdown.Item onClick={() => markAsAllReadOrUnread(1)}>
                  <i className="bi-check2-all dropdown-item-icon" />
                  <span>
                    <FormattedMessage id="app.common.markAllAsRead" />
                  </span>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => markAsAllReadOrUnread(0)}>
                  <i className="bi-app-indicator dropdown-item-icon" />
                  <span>
                    <FormattedMessage id="app.common.markAllAsUnread" />
                  </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Card.Header>
          <Tab.Container defaultActiveKey="messages">
            <Nav variant="tabs" justify as="ul" navbar={false}>
              <Nav.Item as="li">
                <Nav.Link eventKey="messages" style={{ cursor: 'pointer' }}>
                  <FormattedMessage id="app.common.messages" />
                  {unreadMessagesCount > 0 && (
                    <span className="ms-1">{`(${unreadMessagesCount})`}</span>
                  )}
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item as="li">
              <Nav.Link eventKey="archive" style={{ cursor: 'pointer' }}>
                <FormattedMessage id="app.common.archive" />
              </Nav.Link>
            </Nav.Item> */}
            </Nav>
            <div id="scroll-node" className="card-body-height">
              <Tab.Content>
                <Tab.Pane eventKey="messages">
                  <RequestLoading loading={loading} />
                  <RequestResult type="error" message={error} className="m-2" />
                  {!loading && !error && listData.length === 0 && (
                    <div className="card-body text-center">
                      <div className="mb-3">
                        <SmartImage
                          className="avatar avatar-xl"
                          src={Illustration}
                          darkSrc={IllustrationLight}
                        />
                      </div>
                      <p>
                        <FormattedMessage id="app.common.noMessages" />
                      </p>
                    </div>
                  )}
                  {!loading && !error && listData?.length > 0 && (
                    <ListGroup
                      variant="flush"
                      className="navbar-card-list-group"
                    >
                      {listData
                        .sort((a, b) => a.is_Read - b.is_Read)
                        .map((item) => (
                          <MessageListItem
                            key={item.NotificationID}
                            item={item}
                            onChange={(status) => {
                              readStatusChanged(item.NotificationID, status);
                            }}
                            onMouseDown={() => {
                              setDropdownIsVisible(false);
                            }}
                          />
                        ))}
                    </ListGroup>
                  )}
                </Tab.Pane>
                {/* <Tab.Pane eventKey="archive" /> */}
              </Tab.Content>
            </div>
            {viewAllNotifications && (
              <Card.Footer
                as={Link}
                to={AppRoutes.protected.NOTIFICATIONS.path}
                className="text-center"
              >
                <FormattedMessage id="app.common.viewAllNotifications" />
                <i className="bi-chevron-right ms-1" />
              </Card.Footer>
            )}
          </Tab.Container>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

DropdownNotificationsGhostLight.propTypes = {
  isMobile: PropTypes.bool,
};

DropdownNotificationsGhostLight.defaultProps = {
  isMobile: false,
};

export default DropdownNotificationsGhostLight;
