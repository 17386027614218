import React, { useEffect, useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, Button, Card, Col, Row } from 'react-bootstrap';
import { FormattedMessage, FormattedPlural, useIntl } from 'react-intl';
import { debounce } from 'lodash';
import { useAxiosInfiniteQuery } from '../../../../../hooks';
import { GoogleMap, RequestResult, Tooltip } from '../../../../../components';
import PropertyList from './PropertyList';
import Constants from '../../../../../constants';

const RADIUS_MIN = 1;
const RADIUS_MAX = 30;
const RADIUS_STEP = 1;
const RADIUS_DEFAULT = 10;
const MILES_TO_METERS = 1609.344;

/* const testItem = {
  id: 61520,
  place_id: 'test-ChIJaeA24-v9Lj4Ray95g2BNeak',
  name: 'test X.Y.Z Hotel',
  website: null,
  address:
    '3993 Al Imam Saud Ibn Abdul Aziz Branch Road At Taawun Riyadh Riyadh Principality Riyadh Province Saudi Arabia 12476',
  lat: '24.7671922',
  lng: '46.706015',
  formatted_address:
    '6690\u060c 3993 Al Imam Saud Ibn Abdul Aziz Branch Rd, At Taawun, Riyadh 12476, Saudi Arabia',
  formatted_phone_number: '055 342 2240',
  map_url: 'https://maps.google.com/?cid=12211876941509767019',
  utc_offset: '180',
  compound_code: 'QP84+VC At Taawun, Riyadh Saudi Arabia',
  global_code: '7HP8QP84+VC',
  last_updated_date: '2024-03-30 03:18:50',
  created_date: '2024-03-30 03:18:50',
  zip: '12476',
  city: 'At Taawun',
  state: 'Ri',
  street: '3993 Al Imam Saud Ibn Abdul Aziz Branch Road',
  types: ['lodging', 'point_of_interest', 'establishment'],
  weekday_texts: [],
}; */

function SelectYourCompetitorsStep({
  onNext,
  onPrev,
  onLoading,
  placeId,
  types,
  value,
  onChange,
  max,
  onUpdateProperty,
}) {
  const { formatMessage } = useIntl();
  const mapRef = useRef();
  const [radius, setRadius] = useState(RADIUS_DEFAULT);
  const [searchQuery, setSearchQuery] = useState('');
  // const [selectedCompetitors, setSelectedCompetitors] = useState(value);

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useAxiosInfiniteQuery({
    url: '/places/get_nearby',
    preventFetch: !placeId || !radius,
    params: {
      placeid: placeId,
      name: searchQuery?.length > 2 ? searchQuery : undefined,
      types: searchQuery?.length > 2 ? undefined : types,
      radius: radius * MILES_TO_METERS,
    },
    cursor: 'next_page_token',
    cursorDefault: undefined,
    // staleTime: Infinity,
    getNextPageParam: (lastPage) => lastPage.next_page_token,
    select: (data) => {
      const nResult = [];

      data.pages.forEach((page) =>
        page.res.forEach(({ lat, lng, place_id: placeid, ...rest }) => {
          nResult.push({
            lat: Number(lat),
            lng: Number(lng),
            /* color:
              placeid === placeId ? Constants.Colors.Theme.Primary : undefined,
            iconText: placeid === placeId ? '&#9733;' : `${iconIndex}`,
            mapIndex: placeid !== placeId && iconIndex, */
            place_id: placeid,
            ...rest,
          });
        })
      );

      return nResult;
    },
    onSuccess: (data) => {
      const founded = data.find((item) => item.place_id === placeId);
      if (founded) {
        onUpdateProperty(founded);
      }
    },
  });

  const getOrderedRequestResults = useCallback(
    (data, isForMap = false) => {
      const nData = data || [];
      const selectedItemsIds = value.map((item) => item.place_id);

      const requestResultItems = [];

      const items = nData.filter((item) => item.place_id !== placeId);

      let iconIndex = 1;

      if (isForMap) {
        const founded = nData.find((item) => item.place_id === placeId);
        if (founded) {
          items.unshift(founded);
        }
        iconIndex = 0;
      }

      items.forEach((item) => {
        const add = !isForMap
          ? true
          : !selectedItemsIds.includes(item.place_id);
        if (add) {
          const modifiedItem = {
            ...item,
            iconText: item.place_id === placeId ? '&#9733;' : `${iconIndex}`,
            mapIndex: item.place_id !== placeId && iconIndex,
            color:
              item.place_id === placeId
                ? Constants.Colors.Theme.Primary
                : undefined,
            selected: false,
          };
          requestResultItems.push(modifiedItem);
        }
        iconIndex += 1;
      });

      return requestResultItems;
    },
    [value, placeId]
  );

  const getInitialMarks = useCallback(
    (data) => {
      const selectedItems = value.map((item, index) => ({
        ...item,
        iconText: `${index + 1}`,
        mapIndex: index + 1,
        color: Constants.Colors.Theme.Success,
        selected: true,
      }));

      const r = [...selectedItems, ...getOrderedRequestResults(data, true)];

      return r;
    },
    [value, getOrderedRequestResults]
  );

  const sliderFormatter = (tooltip) => {
    const formatter = (v) =>
      tooltip
        ? `${Math.round(v)} ${formatMessage({ id: 'app.common.miles' })}`
        : Math.round(v);

    return {
      to(v) {
        return formatter(v);
      },
      from(v) {
        return formatter(v);
      },
    };
  };

  const handleRadiusChange = (r) => {
    setRadius(r);
  };

  const handleSearch = debounce((query) => {
    setSearchQuery(query);
  }, 100);

  useEffect(() => {
    onLoading(apiLoading);
  }, [onLoading, apiLoading]);

  return (
    <Row className="py-10">
      <Col sm="12" className="mb-3">
        <Card>
          <Card.Header className="card-header-content-between align-items-center">
            <div className="d-flex align-items-center pe-2">
              <h6 className="mb-0 ">
                <FormattedPlural
                  value={value.length}
                  one={
                    <FormattedMessage id="app.common.oneCompetitorSelected" />
                  }
                  other={
                    <FormattedMessage
                      id="app.common.nCompetitorsSelected"
                      values={{ n: value.length }}
                    />
                  }
                />
              </h6>
              {value.length > 0 && (
                <Button
                  variant="danger"
                  size="xs"
                  className="rounded-pill ms-2"
                  onClick={() => {
                    onChange([]);
                  }}
                >
                  <i className="bi-trash me-1" />
                  <FormattedMessage id="app.common.removeAll" />
                </Button>
              )}
            </div>
            {max && (
              <Tooltip
                placement="left"
                content={
                  <FormattedMessage id="app.helpers.propertySelect.competitorsSelect.maxCompetitorsInfo" />
                }
              >
                <h6 className="mb-0">
                  <span
                    className={`${
                      value.length < max ? 'text-success' : 'text-danger'
                    }`}
                  >
                    (
                    <FormattedMessage
                      id="app.common.maxN"
                      values={{ n: max }}
                    />
                    )
                  </span>
                  <i className="bi-question-circle text-body ms-1" />
                </h6>
              </Tooltip>
            )}
          </Card.Header>
          {value.length > 0 ? (
            <Card.Body>
              {value.map((item, index) => (
                <Badge
                  pill
                  key={`keyword_${index.toString()}`}
                  className="me-3 mb-3 position-relative fs-6 fw-normal"
                  as="div"
                  style={{ cursor: 'default' }}
                  onMouseEnter={() => {
                    mapRef.current?.focusToItem(item);
                  }}
                  onMouseLeave={() => {
                    mapRef.current?.unfocus();
                  }}
                >
                  {item.name}
                  <Badge bg="dark" className="fw-normal ms-2">
                    {index + 1}
                  </Badge>
                  <div className="position-absolute top-0 start-100 translate-middle">
                    <Button
                      variant="danger"
                      size="xs"
                      className="btn-icon rounded-pill"
                      onClick={() => {
                        const removedData = value.filter(
                          (nItem) => nItem.place_id !== item.place_id
                        );
                        onChange(removedData);
                        // tableRef.current?.removeRowSelection(item.id);
                      }}
                    >
                      <i className="bi-x-lg" />
                    </Button>
                  </div>
                </Badge>
              ))}
            </Card.Body>
          ) : (
            <Card.Body className="text-center">
              <span className="text-muted small">
                <FormattedMessage id="app.common.noCompetitorsSelected" />
              </span>
            </Card.Body>
          )}
          <Card.Footer className="d-flex justify-content-between">
            <Button variant="ghost-primary" onClick={onPrev}>
              <i className="bi-chevron-left me-1" />
              <FormattedMessage id="app.common.previousStep" />
            </Button>
            <Button
              onClick={() => {
                onNext();
              }}
            >
              <FormattedMessage id="app.common.continue" />
            </Button>
          </Card.Footer>
        </Card>
      </Col>
      <Col xs="12">
        <RequestResult type="error" message={apiError} className="mb-5" />

        <Row>
          <Col lg="4" className="mb-3 mb-lg-0">
            <PropertyList
              data={getOrderedRequestResults(apiData)}
              multiple
              value={value}
              onChange={onChange}
              onMouseEnter={(item) => {
                mapRef.current?.focusToItem(item);
              }}
              onMouseLeave={() => {
                mapRef.current?.unfocus();
              }}
              hasMore={hasNextPage}
              onLoadMore={() => {
                fetchNextPage();
              }}
              max={max}
              radius={{
                min: RADIUS_MIN,
                max: RADIUS_MAX,
                step: RADIUS_STEP,
                value: radius,
                onChange: handleRadiusChange,
                formatter: sliderFormatter,
              }}
              onSearch={handleSearch}
              isLoading={apiLoading}
              isNextPageLoading={isFetchingNextPage}
            />
          </Col>
          <Col lg="8">
            <GoogleMap
              ref={mapRef}
              height={540}
              // initialZoom={16 - Math.log(radius / 500) / Math.log(2)}
              // initialZoom={14}
              initialCenter={getInitialMarks(apiData)?.[0]}
              initialMarks={getInitialMarks(apiData)}
              withCircle={radius * MILES_TO_METERS}
              circleOnChange={(r) => {
                let fr = Math.round(r / MILES_TO_METERS);
                if (fr < RADIUS_MIN) {
                  fr = RADIUS_MIN;
                } else if (fr > RADIUS_MAX) {
                  fr = RADIUS_MAX;
                }
                handleRadiusChange(fr);
              }}
              isLoading={apiLoading}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

SelectYourCompetitorsStep.propTypes = {
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  onLoading: PropTypes.func,
  placeId: PropTypes.string,
  types: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  onChange: PropTypes.func,
  max: PropTypes.number,
  onUpdateProperty: PropTypes.func,
};

SelectYourCompetitorsStep.defaultProps = {
  onNext: () => {},
  onPrev: () => {},
  onLoading: () => {},
  placeId: undefined,
  types: [],
  value: [],
  onChange: () => {},
  max: undefined,
  onUpdateProperty: () => {},
};

export default SelectYourCompetitorsStep;
