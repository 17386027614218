import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Constants from '../../constants';
import { ThemeContext } from '../../context/ThemeContext';
import { ReactComponent as Logoipsum } from '../../assets/images/brands/logo/logoipsum.svg';
import { ReactComponent as Airbnb } from '../../assets/images/brands/logo/airbnb.svg';
import { ReactComponent as Bookingcom } from '../../assets/images/brands/logo/bookingcom.svg';
import { ReactComponent as Expedia } from '../../assets/images/brands/logo/expedia.svg';
import { ReactComponent as Facebook } from '../../assets/images/brands/logo/facebook.svg';
import { ReactComponent as Google } from '../../assets/images/brands/logo/google.svg';
import { ReactComponent as Hotelscom } from '../../assets/images/brands/logo/hotelscom.svg';
import { ReactComponent as TripAdvisor } from '../../assets/images/brands/logo/tripadvisor.svg';
import { ReactComponent as TrustPilot } from '../../assets/images/brands/logo/trustpilot.svg';
import { ReactComponent as Yelp } from '../../assets/images/brands/logo/yelp.svg';
import { ReactComponent as Yahoo } from '../../assets/images/brands/logo/yahoo.svg';
import { ReactComponent as Pinterest } from '../../assets/images/brands/logo/pinterest.svg';
import { ReactComponent as Twitter } from '../../assets/images/brands/logo/twitter.svg';
import { ReactComponent as TikTok } from '../../assets/images/brands/logo/tiktok.svg';
import { ReactComponent as Instagram } from '../../assets/images/brands/logo/instagram.svg';
import { ReactComponent as Lawyerscom } from '../../assets/images/brands/logo/lawyerscom.svg';
import { ReactComponent as Thumbtack } from '../../assets/images/brands/logo/thumbtack.svg';
import { ReactComponent as Angi } from '../../assets/images/brands/logo/angi.svg';
import { ReactComponent as Realtor } from '../../assets/images/brands/logo/realtor.svg';
import { ReactComponent as Zillow } from '../../assets/images/brands/logo/zillow.svg';
import { ReactComponent as Healthgrades } from '../../assets/images/brands/logo/healthgrades.svg';
import { ReactComponent as CarGurus } from '../../assets/images/brands/logo/cargurus.svg';
import { ReactComponent as Carscom } from '../../assets/images/brands/logo/carscom.svg';
import { ReactComponent as Martindale } from '../../assets/images/brands/logo/martindale.svg';

const defaulSize = 30;
const defaultFill = '#000000';
const defaultDarkFill = '#ffffff';

function BrandLogo({ brand, size, style, forceFill, ...props }) {
  const { theme } = useContext(ThemeContext);

  let BrandComponent;
  let s;
  let fill;
  let darkFill;
  switch (brand) {
    case 'airbnb':
      BrandComponent = Airbnb;
      s = (size || defaulSize) * 0.9;
      break;
    case 'booking':
      BrandComponent = Bookingcom;
      s = (size || defaulSize) * 0.7;
      break;
    case 'expedia':
      BrandComponent = Expedia;
      s = (size || defaulSize) * 0.8;
      fill = '#191e3b';
      darkFill = defaultDarkFill;
      break;
    case 'facebook':
      BrandComponent = Facebook;
      s = (size || defaulSize) * 0.7;
      break;
    case 'google':
      BrandComponent = Google;
      s = size || defaulSize;
      break;
    case 'hotels':
      BrandComponent = Hotelscom;
      s = (size || defaulSize) * 0.9;
      fill = '#191e3b';
      darkFill = defaultDarkFill;
      break;
    case 'tripadvisor':
      BrandComponent = TripAdvisor;
      s = size || defaulSize;
      fill = defaultFill;
      darkFill = defaultDarkFill;
      break;
    case 'trustpilot':
      BrandComponent = TrustPilot;
      s = size || defaulSize;
      fill = defaultFill;
      darkFill = defaultDarkFill;
      break;
    case 'yelp':
      BrandComponent = Yelp;
      s = size || defaulSize;
      fill = defaultFill;
      darkFill = defaultDarkFill;
      break;
    case 'yahoo':
      BrandComponent = Yahoo;
      s = (size || defaulSize) * 0.85;
      break;
    case 'pinterest':
      BrandComponent = Pinterest;
      s = (size || defaulSize) * 0.85;
      break;
    case 'twitter':
      BrandComponent = Twitter;
      s = (size || defaulSize) * 0.65;
      break;
    case 'tiktok':
      BrandComponent = TikTok;
      s = size || defaulSize;
      fill = defaultFill;
      darkFill = defaultDarkFill;
      break;
    case 'instagram':
      BrandComponent = Instagram;
      s = (size || defaulSize) * 2;
      break;
    case 'lawyers':
      BrandComponent = Lawyerscom;
      s = (size || defaulSize) * 1.1;
      fill = '#475055';
      darkFill = defaultDarkFill;
      break;
    case 'thumbtack':
      BrandComponent = Thumbtack;
      s = (size || defaulSize) * 0.6;
      darkFill = defaultDarkFill;
      break;
    case 'angi':
      BrandComponent = Angi;
      s = size || defaulSize;
      break;
    case 'realtor':
      BrandComponent = Realtor;
      s = (size || defaulSize) * 0.7;
      fill = defaultFill;
      darkFill = defaultDarkFill;
      break;
    case 'zillow':
      BrandComponent = Zillow;
      s = size || defaulSize;
      break;
    case 'healthgrades':
      BrandComponent = Healthgrades;
      s = size || defaulSize;
      fill = '#626366';
      darkFill = defaultDarkFill;
      break;
    case 'cargurus':
      BrandComponent = CarGurus;
      s = (size || defaulSize) * 0.7;
      break;
    case 'cars':
      BrandComponent = Carscom;
      s = size || defaulSize;
      break;
    case 'martindale':
      BrandComponent = Martindale;
      s = size || defaulSize;
      fill = defaultFill;
      darkFill = defaultDarkFill;
      break;
    default:
      BrandComponent = Logoipsum;
      s = (size || defaulSize) * 0.8;
      break;
  }

  const getFill = () => {
    const rule = forceFill || theme;

    return rule === 'dark' ? darkFill : fill;
  };

  return (
    <div
      className="brand-logo-container d-flex align-items-center"
      style={{ height: size, ...style }}
      {...props}
    >
      <div
        className="brand-logo-wrapper d-flex align-items-center justify-content-center"
        style={{ height: s }}
      >
        <BrandComponent className="brand-logo" fill={getFill()} />
      </div>
    </div>
  );
}

BrandLogo.propTypes = {
  brand: PropTypes.oneOf(Object.keys(Constants.Brands)).isRequired,
  size: PropTypes.number,
  style: PropTypes.objectOf(PropTypes.any),
  forceFill: PropTypes.oneOf(['default', 'dark']),
};

BrandLogo.defaultProps = {
  size: null,
  style: null,
  forceFill: null,
};

export default BrandLogo;
