import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { useGoogleAnalyticsApi } from '../../../../../hooks/Google';
import AnalyticsTable from './AnalyticsTable';
import AnalyticsLineChart from './AnalyticsLineChart';
import AnalyticsDoughnutChart from './AnalyticsDoughnutChart';
import { RequestLoading, RequestResult } from '../../../../../components';

function AnalyticsCard({ type, title, query, token }) {
  const [lastQuery, setLastQuery] = useState();
  const [apiData, setApiData] = useState();
  const [hasData, setHasData] = useState(false);
  const { apiLoading, apiError, apiFetch } = useGoogleAnalyticsApi(token, {
    onComplete: (data) => {
      setApiData(data);
      if (
        data?.totalResults > 0 &&
        data?.rows?.reduce((acc, obj) => acc + parseInt(obj[1], 10), 0) > 0
      ) {
        setHasData(true);
      }
    },
  });

  useEffect(() => {
    if (query !== lastQuery) {
      setApiData(null);
      setHasData(false);
      setLastQuery(query);
      apiFetch('/data/ga?', query);
    }
  }, [apiFetch, query, lastQuery]);

  return (
    <Card className="h-100">
      <Card.Header>
        <Card.Title>{title}</Card.Title>
      </Card.Header>
      <Card.Body
        className={
          type === 'table' && apiData && hasData && !apiError ? 'p-0' : ''
        }
      >
        <RequestLoading loading={apiLoading} size="lg" margin="5" />
        {!apiLoading && apiError && (
          <RequestResult type="error" message="app.common.somethingWentWrong" />
        )}
        {!apiLoading && apiData && !hasData && !apiError && (
          <RequestResult type="secondary" message="app.common.noData" />
        )}
        {!apiLoading && apiData && hasData && !apiError && (
          <>
            {type === 'table' && <AnalyticsTable data={apiData} />}
            {type === 'line' && <AnalyticsLineChart data={apiData} />}
            {type === 'doughnut' && <AnalyticsDoughnutChart data={apiData} />}
          </>
        )}
      </Card.Body>
    </Card>
  );
}

AnalyticsCard.propTypes = {
  token: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['table', 'line', 'doughnut']).isRequired,
  title: PropTypes.string.isRequired,
  query: PropTypes.object.isRequired,
};

export default AnalyticsCard;
